<template>
    <global-menu open="1" selected="1-5" pageTitle="咨询  订单" :isShowNew="false">
        <global-search :searchList="searchList" :searchDorm="searchForm" @search="search"></global-search>
        <div class="page-contener">
            <g-tab :tabs="tabs" @change="tabChange"></g-tab>
            <a-table class="mg-t-16" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">

                    <div v-if="column.dataIndex === 'type'">
                        <span v-if="record.type == 1">顾问咨询</span>
                        <span v-if="record.type == 2">开药咨询</span>
                        <span v-if="record.type == 3">预约咨询</span>
                    </div>
                    <div v-if="column.dataIndex === 'patient'">
                        <div v-if="record.patient.name">{{ record.patient.name }} （{{ record.patient.mobile }}）</div>
                        <div v-else>-</div>
                    </div>
                    <div class="pointer" v-if="column && column.dataIndex === 'meeting'" @click="showModal(record.conference.start_at ? 'meetInfo' : 'createMeet', record, 560)">
                        <img class="table-icon" src="../../../assets/img/icon_video_green.png" alt="" />
                        <span class="table-meeting" v-if="record.conference">{{ record.conference.start_at }}</span>
                    </div>
                    <div v-if="column && column.dataIndex === 'conference'">
                        {{ record.conference.start_at ? record.conference.start_at : '-' }}
                    </div>
                    <div v-if="column && column.dataIndex === 'inJapan'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_doctor_orange.png" alt=""
                            @click="showModal('local', record)" />
                    </div>
                    <div v-if="column && column.dataIndex === 'online'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_video_orange.png" alt=""
                            @click="showModal('online', record)" />
                    </div>
                    <div v-if="column && column.dataIndex === 'drug'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_medicine_orange.png" alt=""
                            @click="$common.toPage('OrderDrugDetail', { title: '开药清单', type: 'create', id: record.id, open: '1', selected: '1-5' })" />
                    </div>
                    <div v-if="column && column.dataIndex === 'finish'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_confirm.png" alt="" @click="finish(record.id)" />
                    </div>
                    <div v-if="column && column.dataIndex === 'delete'">
                        <img class="pointer" style="width: 24px; height: 24px" src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog :isShow="isModalShow" :title="modalTitle" @onCancel="closeModal" :loading="submitLoading" @onOk="submit" class="dialog-modal" :width="modalWidth">
        <div v-if="modalType == 'local' || modalType == 'online'">
            <div>
                <global-title class="mg-t-16" title="患者信息"></global-title>
                <div class="mg-t-26">
                    <g-input v-model="createForm.patient_name" class="form-item-data" placeholder="姓名（中文）"
                        :icon="require('@/assets/img/icon_user.png')"></g-input>
                    <g-input v-model="createForm.patient_en_name" class="form-item-data" placeholder="姓名拼音"
                        :icon="require('@/assets/img/icon_user.png')"></g-input>
                    <g-select :selectId="createForm.patient_sex" v-model="createForm.patient_sex" :icon="require('@/assets/img/icon_user.png')" placeholder="性别"
                        class="form-item-data" :options="sexs"></g-select>
                    <g-date class="form-item-data" placeholder="出生日期" :value="createForm.patient_birth" @change="value => dateChange(value, 'createForm', 'patient_birth')"></g-date>
                    <g-input v-model="createForm.patient_mobile" class="form-item-data" placeholder="联系电话" :icon="require('@/assets/img/icon_phone.png')"></g-input>
                    <g-input v-model="createForm.patient_idcard" class="form-item-data" placeholder="身份证号" :icon="require('@/assets/img/icon_user.png')"></g-input>
                </div>
                <a-divider />
                <global-title title="就诊信息"></global-title>
                <div class="mg-t-26">
                    <g-select :selectId="createForm.hospital_id" v-model="createForm.hospital_id" :icon="require('@/assets/img/icon_hospital.png')" placeholder="选择机构"
                        class="form-item-data" :options="hospitals"></g-select>
                    <global-doctor :doctorType="modalType" type="2" :value="createForm.hospital_id" class="form-item-data" :placeholder="modalType == 'local' ? '科室 / 医生' : '病症 / 医生'"
                        :icon="require('@/assets/img/icon_department.png')" @submit="doctorSubmit"></global-doctor>
                    <g-date class="form-item-data" placeholder="就诊时间" :showTime="true" :value="createForm.treated_at" @change="value => dateChange(value, 'createForm', 'treated_at')"></g-date>
                </div>
            </div>
        </div>
        <div v-if="modalType == 'createMeet'">
            <div class="meeting-title">会议时间</div>
            <g-date class="mg-t-24" placeholder="开始时间" :showTime="true" :value="meetForm.start_at" @change="value => dateChange(value, 'meetForm', 'start_at')"></g-date>
            <g-select :icon="require('@/assets/img/icon_time.png')" class="mg-t-24" placeholder="会议时长" :selectId="meetForm.meeting_at" v-model="meetForm.meeting_at" :options="[{id: 15, name: '15分钟'}, {id: 30, name: '30分钟'}, {id: 45, name: '45分钟'}, {id: 60, name: '60分钟'}]" />
        </div>
        <div v-if="modalType == 'meetInfo'">
            <div class="mg-t-16">
                <div class="meeting-info">
                    <a class="copy" @click="copy(detail.conference.number)">复制会议链接</a>
                    <div class="title">医疗咨询视频会议</div>
                    <!-- <div class="num-wapper">
                        <div class="num">{{ detail.conference.number }}</div>
                        <img class="icon pointer" src="../../../assets/img/icon_copy_white.png" alt="" @click="copy(detail.conference.number)" />
                    </div> -->
                    <!-- <div class="password-wapper">
                        <img class="icon" src="../../../assets/img/icon_key_white.png" alt="" />
                        <span class="password">{{ detail.conference.password }}</span>
                    </div> -->
                    <div class="time-wapper">
                        <div>
                            <div class="time">{{ $moment(detail.conference.start_at).format('HH:mm') }}</div>
                            <div class="date">{{ $moment(detail.conference.start_at).format('YYYY.MM.DD') }}</div>
                        </div>
                        <div class="duration">
                            {{ detail.conference.meeting_at }}分钟
                        </div>
                        <div>
                            <div class="time">{{ $moment(detail.conference.end_at).format('HH:mm') }}</div>
                            <div class="date">{{ $moment(detail.conference.end_at).format('YYYY.MM.DD') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer v-if="modalType == 'meetInfo'">
            <div>
                <g-button type="error" ghost>取消会议</g-button>
                <g-button type="default" class="mg-l-40" ghost @click="closeModal">关闭</g-button>
            </div>
        </template>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalDoctor from "@/components/GlobalDoctor.vue";
import GInput from "@/components/GInput.vue";
import GSelect from "@/components/GSelect.vue";
import GButton from "@/components/GButton.vue";
import GDate from "@/components/GDate.vue";
import { reactive, toRefs, onMounted, ref, getCurrentInstance } from "vue";
import GTab from "@/components/GTab.vue";
import Pagination from "@/components/Pagination.vue";
import { Modal } from "ant-design-vue";
import useClipboard from 'vue-clipboard3'
import { useRoute } from 'vue-router'
export default {
    components: {
        GlobalMenu,
        GlobalSearch,
        GlobalDialog,
        GInput,
        GSelect,
        GDate,
        GlobalDoctor,
        GButton,
        GTab,
        Pagination
    },

    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        const route = useRoute()
        let state = reactive({
            tabs: ['待沟通', '已完成'],
            tableColumns: [],
            tableData: [],
            loading: false,
            tabActive: 0,
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 0,
            modalType: 'create',
            modalWidth: 1008,
            isModalShow: false,
            isShowFooter: true,
            modalTitle: '',
            modalType: '',
            hospitals: [],
            meetForm: {
                start_at: undefined,
                meeting_at: undefined
            },
            createForm: {
                patient_name: undefined,
                patient_en_name: undefined,
                patient_sex: undefined,
                patient_birth: undefined,
                patient_mobile: undefined,
                patient_idcard: undefined,
                hospital_id: undefined,
                hospital_service_id: undefined,
                hospital_doctor_id: undefined,
                treated_at: undefined,
            },
            submitLoading: false,
            detail: null
        })
        let searchForm = reactive({
            contact_name: undefined,
            contact_mobile: undefined,
            consulted_at: undefined,
            created_at: undefined,
            custom_status: 2,
            page_size: 10,
            page: 1
        })
        let searchList = reactive([
            {
                type: "input",
                model: "contact_name",
                placeholder: "联系人",
                icon: require("@/assets/img/icon_user.png"),
            },
            {
                type: "input",
                model: "contact_mobile",
                placeholder: "联系电话",
                icon: require("@/assets/img/icon_phone.png"),
            },
            {
                type: "date",
                model: "consulted_at",
                placeholder: '创建时间',
                icon: require("@/assets/img/icon_date.png"),
            },
        ])
        const column = {
            0: [
                { dataIndex: "patient", title: "联系人信息",},
                { dataIndex: "consult_at", title: "预约咨询时间", width: 170},
                { dataIndex: "created_at", title: "创建时间", width: 170},
                { dataIndex: "type", title: "订单来源", width: 120},
                { dataIndex: "meeting", title: "会议",},
                { dataIndex: "inJapan", title: "在日", width: 80},
                { dataIndex: "online", title: "远程", width: 80},
                { dataIndex: "drug", title: "开药", width: 80},
                { dataIndex: "finish", title: "完成", width: 80},
            ],
            1: [
                { dataIndex: "patient", title: "联系人信息",},
                { dataIndex: "conference", title: "咨询时间", },
                { dataIndex: "created_at", title: "创建时间",},
                { dataIndex: "type", title: "订单来源",},
                { dataIndex: "updated_at", title: "完成时间",},
                { dataIndex: "delete", title: "删除", width: 80},
            ]
        }
        const { toClipboard } = useClipboard()
        const copy = async (msg) => {
            try {
                // 复制
                await toClipboard(msg)
                proxy.$message.success('复制成功')
            } catch (e) {
                proxy.$message.error('复制失败')
            }
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/order/consult/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            getData()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const finish = id => {
            Modal.confirm({
                title: '确定完成吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.get(`/order/consult_complete/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`已完成`)
                            getData()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const dateChange = (value, type, key) => {
            state[type][key] = value
        }
        function search(searchData) {
            console.log(searchData)
            searchForm = {... searchForm, ... searchData}
            if(state.tabActive == 1) {
                if(searchForm.created_at) {
                    searchForm.consulted_at = searchForm.created_at
                    searchForm.created_at = undefined
                }
            }
            state.pagination.page = 1
            refPagination.value.reset(1)
            getData()
        }
        const tabChange = index => {
            state.tabActive = index
            if(index == 0) {
                searchList[2].placeholder = '创建时间'
                searchList[2].model = 'created_at'
                if(searchForm.consulted_at) {
                    searchForm.created_at = searchForm.consulted_at
                    searchForm.consulted_at = undefined
                }
            }else {
                searchList[2].placeholder = '咨询时间'
                searchList[2].model = 'consulted_at'
                if(searchForm.created_at) {
                    searchForm.consulted_at = searchForm.created_at
                    searchForm.created_at = undefined
                }
            }
            state.tableColumns = column[index]
            search(searchForm)
        }
        const doctorSubmit = data => {
            state.createForm['hospital_service_id'] = data['hospital_service_id']
            state.createForm['hospital_doctor_id'] = data['doctor_id']
        }
        const submit = () => {
            if(state.modalType == 'createMeet') {
                if(!state.meetForm.start_at || !state.meetForm.meeting_at) {
                    proxy.$message.error('请填写必填项')
                    return
                }
                state.submitLoading = true
                proxy.$httpUtil.put(`/order/consult_conference_create/${state.detail.id}`, state.meetForm).then(res => {
                    if(res.success) {
                        getData()
                        closeModal()
                        state.submitLoading = false
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }else if(state.modalType == 'local' || state.modalType == 'online') {
                if(!state.createForm.patient_name || !state.createForm.patient_en_name || !state.createForm.patient_sex || !state.createForm.patient_birth || !state.createForm.patient_mobile || !state.createForm.patient_idcard || !state.createForm.hospital_id || !state.createForm.hospital_service_id || !state.createForm.treated_at) {
                    proxy.$message.error('请填写必填项')
                    return
                }
                state.submitLoading = true
                let url = '/order/appoint_local'
                if(state.modalType == 'online') {
                    url = '/order/appoint_online'
                }
                proxy.$httpUtil.post(url, state.createForm).then(res => {
                    if(res.success) {
                        getData()
                        closeModal()
                        state.submitLoading = false
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const showModal = (type, data, width = 1008) => {
            state.detail = data
            state.modalType = type
            state.isShowFooter = false
            if(type == 'createMeet') {
                state.modalTitle = '创建会议'
                state.meetForm = {
                    start_at: undefined,
                    meeting_at: undefined
                }
            }else if(type == 'meetInfo') {
                state.modalTitle = '会议信息'
            }else if(type == 'local' || type == 'online') {
                state.modalTitle = '在日'
                if(type == 'online') {
                    state.modalTitle = '远程'
                }
                state.createForm = {
                    patient_name: undefined,
                    patient_en_name: undefined,
                    patient_sex: undefined,
                    patient_birth: undefined,
                    patient_mobile: undefined,
                    patient_idcard: undefined,
                    hospital_id: undefined,
                    hospital_service_id: undefined,
                    hospital_doctor_id: undefined,
                    treated_at: undefined,
                }
            }
            state.isModalShow = true
            state.modalWidth = width
        }
        const closeModal = () => {
            state.isModalShow = false
        }
        const pageChange = data => {
            state.pagination.page = data.page
            getData()
        }
        const getData = async () => {
            state.loading = true
            searchForm.custom_status = state.tabActive == 0 ? 2 : 3
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getOrderConsult(searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        onMounted(async () => {
            if(route.query.id) {
                searchForm.id = route.query.id
            }
            tabChange(0)
            state.hospitals = await proxy.$api.getHospitalLists(2)
        });
        return {
            refPagination,
            ... toRefs(state),
            searchList,
            sexs: [
                {
                    id: '男',
                    name: '男'
                },
                {
                    id: '女',
                    name: '女'
                }
            ],
            searchForm,

            search,
            tabChange,
            del,
            showModal,
            closeModal,
            dateChange,
            submit,
            doctorSubmit,
            pageChange,
            finish,
            copy
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: calc(100% - 92px);
    overflow: auto;
    margin-top: 12px;
    padding: 16px;

    .tab-box {
        display: inline-block;
        background: #f4f8fe;
        border-radius: 5px;
        padding: 2px;

        .tab-btn {
            border: none;
            background: none;
            width: 144px;
            color: #252733;
            font-size: 16px;
            border-radius: 3px;
        }

        .tab-btn-active {
            color: #ffffff;
            background: #3addb7;
        }
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;

    }

    .table-meeting {
        color: #3ADDB7;
        font-size: 16px;
        line-height: 20px;
        margin-left: 40px;
    }
}

.dialog-modal {
    padding-bottom: 64px;

    .form-item-data {
        width: calc((100% - 64px) / 3) !important;
        margin-right: 32px;
        margin-bottom: 24px;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    ::v-deep .ant-divider-horizontal {
        margin: 8px 0px 30px;
    }

    .subtitle {
        color: #b6bbd7 !important;
        font-size: 20px;
        margin-left: 40px;
    }

    .inline-box {
        display: flex;
        justify-content: space-between;

        .line-item {
            width: calc((100% - 64px) / 2);
        }
    }

    .info-img {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        background: #e8e8e8;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    .file-box {
        display: flex;
        flex-wrap: wrap;
    }

    .info-file {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        border: 1px solid #e8e8e8;
        text-align: center;
        position: relative;

        &:nth-child(3n) {
            margin-right: 0px;
        }

        .img {
            width: 56px;
            height: 56px;
        }

        .file-name {
            color: #202020;
            font-size: 14px;
            line-height: 18px;
            margin-top: 16px;
        }

        .file-del {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 128px;
        height: 128px;
        border-radius: 8px;
        border: 1px solid #3addb7;
    }

    .meeting-title {
        color: #3addb7;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
    }

    .meeting-title:last-child {
        margin-top: 48px;
    }

    .meeting-info {
        position: relative;
        border-radius: 16px;
        background: url('../../../assets/img/meeting_bg.png');
        color: #ffffff;
        padding: 24px 32px;
        .copy {
            font-size: 14px;
            color: #fff;
            position: absolute;
            right: 12px;
            top: 10px;
            z-index: 1;
        }

        .title {
            padding-top: 8px;
            font-size: 24px;
            line-height: 40px;
            text-align: center;
        }

        .num-wapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 8px;

            .num {
                font-size: 44px;
                line-height: 64px;
                font-weight: 500;
            }

            .icon {
                width: 32px;
                height: 32px;
                margin-left: 24px;
            }
        }

        .password-wapper {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 24px;
            }

            .password {
                font-size: 24px;
                line-height: 24px;
            }
        }

        .time-wapper {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            .time {
                font-size: 40px;
                line-height: 48px;
            }

            .date {
                font-size: 20px;
                line-height: 28px;
            }

            .duration {
                width: 112px;
                height: 48px;
                font-size: 20px;
                line-height: 28px;
                border-bottom: 2px solid #ffffff;
                padding-top: 12px;
                padding-bottom: 8px;
                text-align: center;
            }
        }
    }
}
</style>