<template>
    <global-menu :open="open" :selected="selected" :isShowNew="false">
        <section class="page-contener">
            <global-header title="问诊报告"></global-header>
            <div class="content-wapper">
                <div class="flex-between">
                    <div>
                        <div class="user-info">{{ orderDetail.patient ? orderDetail.patient.name : '' }} {{ orderDetail.patient ? orderDetail.patient.sex : '' }} {{ orderDetail.patient ? orderDetail.patient.age : '' }}岁</div>
                        <div class="hospital">{{ orderDetail.hospital_name }}</div>
                    </div>
                    <vue-qr class="code-image" :text="reportCode" :size="142"></vue-qr>
                </div>
                <div class="depart-wapper">
                    <span>{{ orderDetail.service_name }}</span>
                    <span class="name">{{ orderDetail.doctor_name }}</span>
                    <span class="pull-right">{{ orderDetail.treated_at }}</span>
                </div>
                <div v-for="item, index in report" :key="index">
                    <global-title class="mg-t-32" :title="item.report_title"></global-title>
                    <a-textarea class="mg-t-24" :rows="4" placeholder="请输入" v-model:value="item.report_content" :disabled="orderDetail.status == 4"></a-textarea>
                </div>
                <div class="flex-align-center mg-t-32">
                    <span class="set-time">设置复诊时间</span>
                    <a-switch size="large" style="height: 22px" v-model:checked="switchValue" :disabled="orderDetail.status == 4" />
                </div>
                <div class="mg-t-32">
                    <g-date class="form-item-data" placeholder="复诊时间" :value="form.visited_at" @change="dateChange" v-if="switchValue" :disabled="orderDetail.status == 4"></g-date>
                </div>
            </div>
            <div class="btn-wapper" v-if="orderDetail.status != 4">
                <g-button type="primary" :loading="loading" @click="submit">保存</g-button>
                <g-button type="default" ghost class="mg-l-40" @click="$router.back()">关闭</g-button>
            </div>
        </section>
    </global-menu>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GDate from "@/components/GDate.vue";
import GButton from "@/components/GButton.vue";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router"
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
    components: {
        vueQr,
        GlobalMenu,
        GlobalHeader,
        GlobalTitle,
        GButton,
        GDate
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const route = useRoute()
        let state = reactive({
            open: '1',
            selected: '1-1',
            orderDetail: {},
            report: [],
            reportCode: '',
            switchValue: false,
            loading: false,
            form: {
                order_id: '',
                visited_at: '',
                report: []
            }
        })
        const submit = () => {
            state.form.report = []
            for(let i in state.report) {
                state.form.report.push({
                    title: state.report[i].report_title,
                    content: state.report[i].report_content
                })
            }
            state.loading = true
            proxy.$httpUtil.post('/order/appoint_report', state.form).then(res => {
                if(res.success) {
                    state.loading = false
                    proxy.$message.success('修改成功')
                    proxy.$router.back()
                }
            }).catch(() => {
                state.loading = false
            })
        }
        const dateChange = value => {
            state.form.visited_at = value
        }
        state.open = route.query.open
        state.selected = route.query.selected
        onMounted(async () => {
            state.form.order_id = route.query.id
            state.orderDetail = await proxy.$api.getOrderDetail(route.query.id)
            let res = await proxy.$api.getReportDetail(route.query.id)
            state.report = res.report_data
            state.form.visited_at = res.visited_at
            state.switchValue = res.visited_at ? true : false
            state.reportCode = res.report_code
        })
        return {
            ... toRefs(state),

            dateChange,
            submit
        }
    }
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;
    position: relative;

    .content-wapper {
        width: 740px;
        height: calc(100% - 96px - 128px);
        overflow: auto;
        margin: 16px auto;

        .user-info {
            color: #252733;
            font-size: 20px;
            line-height: 20px;
        }

        .hospital {
            color: #65697b;
            font-size: 16px;
            line-height: 20px;
            margin-top: 8px;
        }

        .code-image {
            width: 146px;
            height: 146px;
        }

        .depart-wapper {
            height: 68px;
            color: #65697b;
            font-size: 16px;
            line-height: 68px;
            border-top: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            margin-top: 24px;

            .name {
                margin-left: 80px;
            }
        }

        .set-time {
            color: #252733;
            font-size: 18px;
            line-height: 26px;
            margin-right: 30px;
        }
    }

    .btn-wapper {
        width: 100%;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}</style>