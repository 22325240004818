<template>
  <a-button
    :loading="loading"
    class="g-button"
    :class="{
      'g-button-default': type == 'default',
      'g-button-default-ghost': type == 'default' && ghost,
      'g-button-primary': type == 'primary',
      'g-button-primary-ghost': type == 'primary' && ghost,
      'g-button-warn': type == 'warn',
      'g-button-warn-ghost': type == 'warn' && ghost,
      'g-button-error': type == 'error',
      'g-button-error-ghost': type == 'error' && ghost,
      'g-button-white':type=='white'
    }"
  >
  <slot></slot>
  </a-button>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="less" scoped>
.g-button {
  border-radius: 8px;
  font-size: 18px;
  height: 48px;
  min-width: 168px;
  border: none;
  font-weight: bold;
  cursor: pointer;
}
.g-button-warn {
  background: #ffb03e;
  color: #ffffff;
}
.g-button-warn-ghost {
  color: #ffb03e;
  border: 1px solid #ffb03e;
  background: transparent;
}
.g-button-error {
  background: #ff504a;
  color: #ffffff;
  // &:hover{
  //   background: rgba(255, 80, 74, .7);
  // }
}
.g-button-error-ghost {
  color: #ff504a;
  border: 1px solid #ff504a;
  background: transparent;
}
.g-button-primary {
  background: #3ADDB7;
  color: #ffffff;
  // &:hover{
  //   background: rgba(58, 221, 183, .7);
  // }
}
.g-button-primary-ghost {
  color: #3ADDB7;
  border: 1px solid #3ADDB7;
  background: transparent;
}
.g-button-default {
  background: #B6BBD7;
  color: #ffffff;
  // &:hover{
  //   color: #3ADDB7;
  // border: 1px solid #3ADDB7;
  // }
}
.g-button-default-ghost {
  color: #B6BBD7;
  border: 1px solid #B6BBD7;
  background: transparent;
}
.g-button-white {
  background: #ffffff;
  color: #3ADDB7;
  // &:hover{
  //   color: #3ADDB7;
  // border: 1px solid #3ADDB7;
  // }
}
</style>