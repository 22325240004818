<template>
    <global-menu open="2" selected="2-4">
        <section class="page-contener">
            <global-header :title="title"></global-header>
            <div class="content-wapper" ref="content_wapper">
                <div class="flex-between">
                    <global-title title="项目列表"></global-title>
                    <plus-circle-filled :style="{ fontSize: '48px', color: '#3addb7' }" @click="add" />
                </div>
                <a-table class="mg-t-16" :columns="tableColumns" :dataSource="tableData" :loading="loading"
                    :pagination="false" :scroll="{ y: tableHeight }">
                    <template #bodyCell="{ column, record, index }">
                        <div v-if="column.dataIndex === 'org'" class="flex-align-center">
                            <img v-if="index == 0" class="table-icon" src="../../../assets/img/icon_cancel_grey.png" alt="">
                            <img @click="remove(index)" v-else class="table-icon pointer" src="../../../assets/img/icon_cancel_red.png" alt="">
                            <a-select class="g-select" placeholder="所属机构" v-model:value="record.hospital_id" @change="e => hospitalChange(e, record)">
                                <a-select-option v-for="item in hospitalOptions" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                            </a-select>
                        </div>
                        <div v-if="column.dataIndex === 'name'">
                            <g-select placeholder="项目名称" v-model="record.project_id" :selectId="record.project_id" :options="record.options"></g-select>
                        </div>
                    </template>
                </a-table>
                <div class="btn-wapper">
                    <g-button @click="submit" :loading="submitLoading">保存</g-button>
                    <g-button class="mg-l-40" type="default" ghost @click="$router.back">关闭</g-button>
                </div>
            </div>
        </section>
    </global-menu>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu.vue";
import GlobalHeader from "../../../components/GlobalHeader.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GSelect from "../../../components/GSelect.vue"
import GButton from "../../../components/GButton.vue"
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue"
import { useRoute } from "vue-router"
export default {
    components: {
        GlobalMenu,
        GlobalHeader,
        GlobalTitle,
        PlusCircleFilled,
        GSelect,
        GButton
    },
    setup() {
        const route = useRoute()
        let { proxy } = getCurrentInstance()
        let state = reactive({
            title: '',
            tableData: [],
            loading: false,
            tableHeight: 100,
            hospitalOptions: [],
            submitLoading: false
        })
        const submit = () => {
            console.log(state.tableData)
            if(!state.tableData.length || !state.tableData[0].hospital_id || !state.tableData[0].project_id) {
                proxy.$message.error('请至少添加一条数据')
                return
            }
            let project = []
            for(let i in state.tableData) {
                project.push(state.tableData[i].project_id)
            }
            state.submitLoading = true
            proxy.$httpUtil.post('/manage/recommend_project_category_bind', {
                category_id: route.query.id,
                project
            }).then(async res => {
                state.submitLoading = false
                proxy.$message.success('绑定成功')
                getData()
            }).catch(() => {
                state.submitLoading = false
            })
        }
        const add = () => {
            state.tableData.push({
                hospital_id: undefined,
                project_id: undefined,
                options: []
            })
        }
        const remove = index => {
            state.tableData.splice(index, 1)
        }
        const hospitalChange = async (value, data) => {
            data.options = await proxy.$api.getProjectByHospital(value)
        }
        const getData = async () => {
            state.loading = true
            state.tableData = await proxy.$api.getProjectBindRecommend(route.query.id)
            if(state.tableData.length) {
                for(let i in state.tableData) {
                    state.tableData[i].options = await proxy.$api.getProjectByHospital(state.tableData[i].hospital_id)
                }
            }
            state.loading = false
        }
        onMounted(async () => {
            state.title = route.query.title
            state.hospitalOptions = await proxy.$api.getHospitalLists(0)
            getData()
            proxy.$nextTick(() => {
                state.tableHeight = proxy.$refs.content_wapper.offsetHeight - 48 - 32 - 55
            })
        })
        return {
            route,
            ... toRefs(state),
            tableColumns: [
                {
                    dataIndex: 'org',
                    title: '所属机构'
                },
                {
                    dataIndex: 'name',
                    title: '项目名称'
                }
            ],
            submit,
            hospitalChange,
            add,
            remove
        }
    }
};
</script>
<style lang="less" scoped>
.g-select{
    width: 100%;
    padding: 8px 11px;
    border-radius: 8px;
    background: #F4F8FE;
    display: block;
    .icon{
        width: 20px;
        height: 20px;
    }
}
::v-deep .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: none;
}
.page-contener {
    height: calc(100vh - 40px);
    position: relative;

    .content-wapper {
        height: calc(100% - 96px - 128px);
        padding: 16px 40px;

        .table-icon {
            width: 24px;
            height: 24px;
            margin-right: 40px;
        }

    }

    .btn-wapper {
        height: 128px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}
</style>