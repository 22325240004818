<template>
    <global-menu open="2" selected="2-2">
        <section class="page-contener">
            <global-header :title="title"></global-header>
            <div class="content-wapper">
                <g-tab :tabs="tabs" style="width:100%" width="calc(100% / 3)" @change="tabChange"></g-tab>
                <global-title title="基本信息" class="mg-t-48"></global-title>
                <g-input class="mg-t-24" placeholder="医生名" :icon="require('../../../assets/img/icon_user.png')" v-model="form.name.cn" v-if="tabIndex == 0"></g-input>
                <g-input class="mg-t-24" placeholder="医生名" :icon="require('../../../assets/img/icon_user.png')" v-model="form.name.jp" v-if="tabIndex == 1"></g-input>
                <g-input class="mg-t-24" placeholder="医生名" :icon="require('../../../assets/img/icon_user.png')" v-model="form.name.en" v-if="tabIndex == 2"></g-input>
                <GlobalDoctor class="mg-t-24" type="3" :value="hospital_id" :detail="detail" placeholder="关联机构医生" :icon="require('../../../assets/img/icon_hospital.png')" @submit="doctorSubmit" />
                <global-title title="头衔" class="mg-t-48"></global-title>
                <g-input class="mg-t-24" placeholder="头衔" :icon="require('../../../assets/img/icon_classifcation.png')" v-model="form.rank.cn" v-if="tabIndex == 0"></g-input>
                <g-input class="mg-t-24" placeholder="头衔" :icon="require('../../../assets/img/icon_classifcation.png')" v-model="form.rank.jp" v-if="tabIndex == 1"></g-input>
                <g-input class="mg-t-24" placeholder="头衔" :icon="require('../../../assets/img/icon_classifcation.png')" v-model="form.rank.en" v-if="tabIndex == 2"></g-input>

                <global-title title="医生头图" class="mg-t-48"></global-title>
                <div class="flex-align-center mg-t-24" v-show="tabIndex == 0">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                        :customRequest="e => fileChange(e, 'cn')">
                        <div v-if="imgUrl.cn" class="select-wapper">
                            <img class="select-img" :src="imgUrl.cn" alt="" />
                        </div>

                        <img class="upload-img" v-else src="../../../assets/img/icon_pic_grey.png" alt="" />
                    </a-upload>
                    <div class="upload-explain">
                        点击上传图片<br />
                        尺寸1 : 1，大小1M以内，jpg、png格式
                    </div>
                </div>
                <div class="flex-align-center mg-t-24" v-show="tabIndex == 1">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                        :customRequest="e => fileChange(e, 'jp')">
                        <div v-if="imgUrl.jp" class="select-wapper">
                            <img class="select-img" :src="imgUrl.jp" alt="" />
                        </div>

                        <img class="upload-img" v-else src="../../../assets/img/icon_pic_grey.png" alt="" />
                    </a-upload>
                    <div class="upload-explain">
                        点击上传图片<br />
                        尺寸1 : 1，大小1M以内，jpg、png格式
                    </div>
                </div>
                <div class="flex-align-center mg-t-24" v-show="tabIndex == 2">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                        :customRequest="e => fileChange(e, 'en')">
                        <div v-if="imgUrl.en" class="select-wapper">
                            <img class="select-img" :src="imgUrl.en" alt="" />
                        </div>

                        <img class="upload-img" v-else src="../../../assets/img/icon_pic_grey.png" alt="" />
                    </a-upload>
                    <div class="upload-explain">
                        点击上传图片<br />
                        尺寸1 : 1，大小1M以内，jpg、png格式
                    </div>
                </div>
                <global-title title="医生简介" class="mg-t-48"></global-title>
                <a-textarea class="mg-t-24" placeholder="简介内容" :rows="4" v-model:value="form.intro.cn" v-if="tabIndex == 0"></a-textarea>
                <a-textarea class="mg-t-24" placeholder="简介内容" :rows="4" v-model:value="form.intro.jp" v-if="tabIndex == 1"></a-textarea>
                <a-textarea class="mg-t-24" placeholder="简介内容" :rows="4" v-model:value="form.intro.en" v-if="tabIndex == 2"></a-textarea>
                <global-title title="主治病症" class="mg-t-48"></global-title>
                <a-input class="mg-t-24" placeholder="输入后回车" v-model:value="diseaseText" @keydown.enter="addDisease"></a-input>
                <div class="diseases-wapper" v-if="tabIndex == 0">
                    <div class="item flex-align-center" v-for="(item, index) in form.attend_disease.cn" :key="index">
                        {{ item }}
                        <img class="icon pointer" src="../../../assets/img/icon_close.png" alt="" @click="removeDisease(index, 'cn')" />
                    </div>
                </div>
                <div class="diseases-wapper" v-if="tabIndex == 1">
                    <div class="item flex-align-center" v-for="(item, index) in form.attend_disease.jp" :key="index">
                        {{ item }}
                        <img class="icon pointer" src="../../../assets/img/icon_close.png" alt="" @click="removeDisease(index, 'jp')" />
                    </div>
                </div>
                <div class="diseases-wapper" v-if="tabIndex == 2">
                    <div class="item flex-align-center" v-for="(item, index) in form.attend_disease.en" :key="index">
                        {{ item }}
                        <img class="icon pointer" src="../../../assets/img/icon_close.png" alt="" @click="removeDisease(index, 'en')" />
                    </div>
                </div>
                <div class="flex-between mg-t-48">
                    <global-title title="医学荣誉"></global-title>
                    <plus-circle-filled class="pointer" :style="{ fontSize: '48px', color: '#3addb7' }" @click="addHonor" />
                </div>
                <div v-if="tabIndex == 0">
                    <div class="flex-align-center" :class="{ 'mg-t-16': index == 0, 'mg-t-24': index != 0 }" v-for="(item, index) in honor.cn" :key="index">
                        <img v-if="index == 0" class="cancel-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                        <img v-else class="cancel-icon pointer" src="../../../assets/img/icon_cancel_red.png" alt="" @click="removeHonor(index, 'cn')" />
                        <g-input placeholder="荣誉" v-model="item.name"></g-input>
                    </div>
                </div>
                <div v-if="tabIndex == 1">
                    <div class="flex-align-center" :class="{ 'mg-t-16': index == 0, 'mg-t-24': index != 0 }" v-for="(item, index) in honor.jp" :key="index">
                        <img v-if="index == 0" class="cancel-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                        <img v-else class="cancel-icon pointer" src="../../../assets/img/icon_cancel_red.png" alt="" @click="removeHonor(index, 'jp')" />
                        <g-input placeholder="荣誉" v-model="item.name"></g-input>
                    </div>
                </div>
                <div v-if="tabIndex == 2">
                    <div class="flex-align-center" :class="{ 'mg-t-16': index == 0, 'mg-t-24': index != 0 }" v-for="(item, index) in honor.en" :key="index">
                        <img v-if="index == 0" class="cancel-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                        <img v-else class="cancel-icon pointer" src="../../../assets/img/icon_cancel_red.png" alt="" @click="removeHonor(index, 'en')" />
                        <g-input placeholder="荣誉" v-model="item.name"></g-input>
                    </div>
                </div>
                
                <div class="flex-between mg-t-48">
                    <global-title title="个人经历"></global-title>
                    <plus-circle-filled class="pointer" :style="{ fontSize: '48px', color: '#3addb7' }" @click="addContent" />
                </div>
                <div v-if="tabIndex == 0">
                    <div :class="{ 'mg-t-16': index == 0, 'mg-t-24': index != 0 }" v-for="(item, index) in form.content.cn"
                        :key="'undergo' + index">
                        <div class="flex-align-center">
                            <img v-if="index == 0" class="cancel-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                            <img v-else class="cancel-icon pointer" src="../../../assets/img/icon_cancel_red.png" alt="" @click="removeContent(index, 'cn')" />
                            <g-input placeholder="经历时间" v-model="item.date"></g-input>
                        </div>
                        <div style="margin-left: 38px" class="mg-t-16">
                            <a-textarea placeholder="经历内容" :rows="4" v-model:value="item.content"></a-textarea>
                        </div>
                    </div>
                </div>
                <div v-if="tabIndex == 1">
                    <div :class="{ 'mg-t-16': index == 0, 'mg-t-24': index != 0 }" v-for="(item, index) in form.content.jp"
                        :key="'undergo' + index">
                        <div class="flex-align-center">
                            <img v-if="index == 0" class="cancel-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                            <img v-else class="cancel-icon pointer" src="../../../assets/img/icon_cancel_red.png" alt="" @click="removeContent(index, 'jp')" />
                            <g-input placeholder="经历时间" v-model="item.date"></g-input>
                        </div>
                        <div style="margin-left: 38px" class="mg-t-16">
                            <a-textarea placeholder="经历内容" :rows="4" v-model:value="item.content"></a-textarea>
                        </div>
                    </div>
                </div>
                <div v-if="tabIndex == 2">
                    <div :class="{ 'mg-t-16': index == 0, 'mg-t-24': index != 0 }" v-for="(item, index) in form.content.en"
                        :key="'undergo' + index">
                        <div class="flex-align-center">
                            <img v-if="index == 0" class="cancel-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                            <img v-else class="cancel-icon pointer" src="../../../assets/img/icon_cancel_red.png" alt="" @click="removeContent(index, 'en')" />
                            <g-input placeholder="经历时间" v-model="item.date"></g-input>
                        </div>
                        <div style="margin-left: 38px" class="mg-t-16">
                            <a-textarea placeholder="经历内容" :rows="4" v-model:value="item.content"></a-textarea>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="btn-wapper">
                <g-button @click="submit" :loading="submitLoading">确定</g-button>
                <g-button class="mg-l-40" ghost @click="isModalShow = true">预览</g-button>
                <g-button class="mg-l-40" type="default" ghost @click="$router.back">关闭</g-button>
                <!-- <g-button class="mg-l-40" type="error" ghost v-if="route.query.id" @click="del">删除</g-button> -->
            </div>
        </section>
    </global-menu>
    <global-dialog :width="600" :isShow="isModalShow" :isShowFooter="false">
        <div class="dialog-wapper">
            <img class="image" :src="imgUrl.cn" alt="" v-if="tabIndex == 0" />
            <img class="image" :src="imgUrl.jp" alt="" v-if="tabIndex == 1" />
            <img class="image" :src="imgUrl.en" alt="" v-if="tabIndex == 2" />
            <div style="margin-top: -100px"></div>
            <div class="info-wapper">
                <div class="doctor-wapper">
                    <div class="flex-align-center">
                        <img class="photo" :src="imgUrl.cn" alt="" v-if="tabIndex == 0" />
                        <img class="photo" :src="imgUrl.jp" alt="" v-if="tabIndex == 1" />
                        <img class="photo" :src="imgUrl.en" alt="" v-if="tabIndex == 2" />
                        <div class="mg-l-24">
                            <div class="name" v-if="tabIndex == 0">{{ form.name.cn }}</div>
                            <div class="name" v-if="tabIndex == 1">{{ form.name.jp }}</div>
                            <div class="name" v-if="tabIndex == 2">{{ form.name.en }}</div>
                            <div class="job" v-if="tabIndex == 0">{{ form.rank.cn }}</div>
                            <div class="job" v-if="tabIndex == 1">{{ form.rank.jp }}</div>
                            <div class="job" v-if="tabIndex == 2">{{ form.rank.en }}</div>
                        </div>
                    </div>
                    <div class="address-wapper flex-align-center">
                        <img class="icon" src="../../../assets/img/icon_hospital.png" alt="" />
                        {{ detail.hospital_name ? detail.hospital_name : '' }}
                    </div>
                    <div class="content" v-if="tabIndex == 0">{{ form.intro.cn }}</div>
                    <div class="content" v-if="tabIndex == 1">{{ form.intro.jp }}</div>
                    <div class="content" v-if="tabIndex == 2">{{ form.intro.en }}</div>
                </div>
                <div class="disease-wapper">
                    <div class="flex-center">
                        <div class="point"></div>
                        <div class="title">主治病症</div>
                        <div class="point"></div>
                    </div>
                    <div class="item-wapper" v-if="tabIndex == 0">
                        <div class="item" v-for="item, index in form.attend_disease.cn" :key="index">{{ item }}</div>
                    </div>
                    <div class="item-wapper" v-if="tabIndex == 1">
                        <div class="item" v-for="item, index in form.attend_disease.jp" :key="index">{{ item }}</div>
                    </div>
                    <div class="item-wapper" v-if="tabIndex == 2">
                        <div class="item" v-for="item, index in form.attend_disease.en" :key="index">{{ item }}</div>
                    </div>
                </div>
                <div class="honor-wapper">
                    <div class="flex-center">
                        <div class="point"></div>
                        <div class="title">医学荣誉</div>
                        <div class="point"></div>
                    </div>
                    <div class="content" v-if="tabIndex == 0">
                        <div v-for="item, index in honor.cn" :key="index">{{ item.name }}</div>
                    </div>
                    <div class="content" v-if="tabIndex == 1">
                        <div v-for="item, index in honor.jp" :key="index">{{ item.name }}</div>
                    </div>
                    <div class="content" v-if="tabIndex == 2">
                        <div v-for="item, index in honor.en" :key="index">{{ item.name }}</div>
                    </div>
                </div>
                <div class="undergo-wapper">
                    <div class="flex-center">
                        <div class="point"></div>
                        <div class="title">个人经历</div>
                        <div class="point"></div>
                    </div>
                    <div v-if="tabIndex == 0">
                        <div class="flex mg-t-24" v-for="item, index in form.content.cn" :key="index">
                            <div class="year">{{ item.date }} | </div>
                            <div class="content">{{ item.content }}</div>
                        </div>
                    </div>
                    <div v-if="tabIndex == 1">
                        <div class="flex mg-t-24" v-for="item, index in form.content.jp" :key="index">
                            <div class="year">{{ item.date }} | </div>
                            <div class="content">{{ item.content }}</div>
                        </div>
                    </div>
                    <div v-if="tabIndex == 2">
                        <div class="flex mg-t-24" v-for="item, index in form.content.en" :key="index">
                            <div class="year">{{ item.date }} | </div>
                            <div class="content">{{ item.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-btn-wapper">
            <div class="btn pointer pull-right mg-l-32" @click="isModalShow = false">
                <img class="icon" src="../../../assets/img/icon_close_white.png" alt="" />
            </div>
            <!-- <div class="btn pull-right">
                <img class="icon" src="../../../assets/img/icon_sure.png" alt="" />
            </div> -->
        </div>
    </global-dialog>
</template>
<script>
import $router from "@/router"
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "../../../components/GlobalHeader.vue";
import GTab from "../../../components/GTab.vue";
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { useRoute } from "vue-router";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GSelect from "../../../components/GSelect.vue";
import GInput from "../../../components/GInput.vue";
import GButton from "../../../components/GButton.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalDoctor from "../../../components/GlobalDoctor.vue";
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
export default {
    components: {
        GlobalMenu,
        GlobalHeader,
        GTab,
        GlobalTitle,
        GSelect,
        GInput,
        GButton,
        GlobalDialog,
        PlusCircleFilled,
        GlobalDoctor,
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const route = useRoute()
        let state = reactive({
            tabIndex: 0,
            title: '',
            form: {
                doctor_id: undefined,
                name: {
                    cn: '',
                    jp: '',
                    en: ''
                },
                rank: {
                    cn: '',
                    jp: '',
                    en: ''
                },
                avatar: {
                    cn: '',
                    jp: '',
                    en: ''
                },
                intro: {
                    cn: '',
                    jp: '',
                    en: ''
                },
                attend_disease: {
                    cn: [],
                    jp: [],
                    en: []
                },
                honor: {
                    cn: [],
                    jp: [],
                    en: []
                },
                content: {
                    cn: [{
                        date: '',
                        content: ''
                    }],
                    jp: [{
                        date: '',
                        content: ''
                    }],
                    en: [{
                        date: '',
                        content: ''
                    }]
                },
            },
            imgUrl: {
                cn: '',
                jp: '',
                en: ''
            },
            fileData: {
                cn: '',
                jp: '',
                en: ''
            },
            honor: {
                cn: [
                    {
                        name: ''
                    }
                ],
                jp: [
                    {
                        name: ''
                    }
                ],
                en: [
                    {
                        name: ''
                    }
                ]
            },
            isModalShow: false,
            submitLoading: false,
            diseaseText: '',
            detail: {},
        })
        const doctorSubmit = data => {
            state.form.doctor_id = data.doctor_id
            state.detail.hospital_name = data.hospital_name
        }
        const del = () => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/recommend_docker/${route.query.id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            $router.back()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const submit = async () => {
            state.form.honor.cn = []
            state.form.honor.jp = []
            state.form.honor.en = []
            let arr = ['cn', 'jp', 'en']
            for(let i in arr) {
                let type = arr[i]
                for(let j in state.honor[type]) {
                    if(state.honor[type][j].name) {
                        state.form.honor[type].push(state.honor[type][j].name)
                    }
                }
            }
            if(!state.form.doctor_id || !state.form.name.cn || !state.form.name.jp || !state.form.name.en || !state.form.rank.cn || !state.form.rank.jp || !state.form.rank.en || !state.form.intro.cn || !state.form.intro.jp || !state.form.intro.en || !state.form.attend_disease.cn.length || !state.form.attend_disease.jp.length || !state.form.attend_disease.en.length|| !state.form.honor.cn.length || !state.form.honor.jp.length || !state.form.honor.en.length || !state.form.content.cn[0].date || !state.form.content.cn[0].content || !state.form.content.jp[0].date || !state.form.content.jp[0].content || !state.form.content.en[0].date || !state.form.content.en[0].content) {
                proxy.$message.error('请输入必填项')
                return
            }
            if((!state.imgUrl.cn || !state.imgUrl.jp || !state.imgUrl.en) && (!state.fileData.cn || !state.fileData.jp || !state.fileData.en)) {
                proxy.$message.error('请上传图片')
                return
            }
            state.submitLoading = true
            for(let i in state.fileData) {
                if(state.fileData[i]) {
                    let res = await proxy.$httpUtil.upload('/platform/file_upload', state.fileData[i], 'doctor')
                    if(res.file_url) {
                        state.form.avatar[i] = res.file_url
                    }else {
                        proxy.$message.error('图片上传失败')
                        state.submitLoading = false
                        return
                    }
                }
            }
            if (route.query.id) {
                proxy.$httpUtil.put(`/manage/recommend_docker/${route.query.id}`, state.form).then(res => {
                    proxy.$message.success('修改成功')
                    $router.back()
                }).catch(() => {
                    state.submitLoading = false
                })
            }else {
                proxy.$httpUtil.post(`/manage/recommend_docker`, state.form).then(res => {
                    proxy.$message.success('新增成功')
                    $router.back()
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const removeDisease = (index, type) => {
            state.form.attend_disease[type].splice(index, 1)
        }
        const addDisease = () => {
            if(!state.diseaseText) {
                proxy.$message.error('请先输入内容')
            }
            if(state.tabIndex == 0) {
                state.form.attend_disease.cn.push(state.diseaseText)
            }else if(state.tabIndex == 1) {
                state.form.attend_disease.jp.push(state.diseaseText)
            }else if(state.tabIndex == 2) {
                state.form.attend_disease.en.push(state.diseaseText)
            }
            state.diseaseText = ''
        }
        const removeContent = (index, type) => {
            state.form.content[type].splice(index, 1)
        }
        const addContent = () => {
            if(state.tabIndex == 0) {
                state.form.content.cn.push({name: ''})
            }else if(state.tabIndex == 1) {
                state.form.content.jp.push({name: ''})
            }else if(state.tabIndex == 2) {
                state.form.content.en.push({name: ''})
            }
        }
        const removeHonor = (index, type) => {
            state.honor[type].splice(index, 1)
        }
        const addHonor = () => {
            if(state.tabIndex == 0) {
                state.honor.cn.push({name: ''})
            }else if(state.tabIndex == 1) {
                state.honor.jp.push({name: ''})
            }else if(state.tabIndex == 2) {
                state.honor.en.push({name: ''})
            }
        }
        const tabChange = index => {
            state.tabIndex = index
            state.diseaseText = ''
        }
        const fileChange = (info, type) => {
            state.fileData[type] = info.file
            state.imgUrl[type] = proxy.$common.getFileUrl(info.file);
        }
        onMounted(async () => {
            if (route.query.id) {
                state.title = "医生 详情"
                let res = state.detail = await proxy.$api.getDoctorRecommendDetail(route.query.id)
                state.form = {
                    doctor_id: res.doctor_id,
                    name: res.name,
                    rank: res.rank,
                    avatar: res.avatar,
                    intro: res.intro,
                    attend_disease: res.attend_disease,
                    honor: res.honor,
                    content: res.content
                }
                state.imgUrl = res.avatar
                state.honor.cn = []
                state.honor.jp = []
                state.honor.en = []
                let arr = ['cn', 'jp', 'en']
                for(let i in arr) {
                    let type = arr[i]
                    for(let j in res.honor[type]) {
                        state.honor[type].push({name: res.honor[type][j]})
                    }
                }
            } else {
                state.title = "新增 医生"
            }
        })
        return {
            ... toRefs(state),
            tabs: ["中文", "日文", "英文"],
            route,

            fileChange,
            doctorSubmit,
            addHonor,
            removeHonor,
            removeContent,
            addContent,
            addDisease,
            removeDisease,
            tabChange,
            submit,
            del
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: calc(100vh - 40px);
    position: relative;

    .content-wapper {
        height: calc(100% - 96px - 128px);
        overflow: auto;
        width: 600px;
        margin: 16px auto;

        .upload-img {
            width: 24px;
            height: 24px;
        }

        .select-wapper {
            position: relative;

            .select-img {
                width: 120px;
                height: 120px;
                object-fit: cover;
            }

            .select-box {
                width: 120px;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(46, 49, 61, 0.7);
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .upload-explain {
            margin-left: 32px;
            color: #252733;
            font-size: 14px;
            line-height: 22px;
        }

        .diseases-wapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .item {
                margin-top: 16px;
                border-radius: 8px;
                background: #ffffff;
                height: 40px;
                padding: 0px 16px;
                color: #252733;
                font-size: 16px;
                line-height: 40px;
                margin-right: 16px;
                border: 1px solid #e8e8e8;

                .icon {
                    height: 16px;
                    width: 16px;
                    margin-left: 16px;
                }
            }
        }

        .cancel-icon {
            width: 24px;
            height: 24px;
            margin-right: 16px;
        }

        ::v-deep .ant-upload-picture-card-wrapper {
            width: 120px;
        }

        ::v-deep .ant-upload.ant-upload-select-picture-card {
            width: 120px;
            height: 120px;
            background: #f4f8fe;
        }
    }

    .btn-wapper {
        height: 128px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.dialog-wapper {
    position: relative;
    background: #f5f5f5;
    max-height: calc(100vh - 48px - 54px - 100px);
    border-radius: 16px;
    overflow: auto;

    .image {
        width: 100%;
        height: 600px;
        object-fit: cover;
    }

    .info-wapper {
        margin-top: -100px;
        // background: #f5f5f5;
        padding: 0px 32px 32px;
        position: relative;
        z-index: 2;

        .doctor-wapper {
            background: linear-gradient(135deg, #47b9ff 0%, #404dff 100%);
            padding: 28px;
            color: #ffffff;
            border-radius: 16px;

            .photo {
                width: 88px;
                height: 88px;
                border-radius: 50%;
                object-fit: cover;
            }

            .name {
                font-size: 32px;
                line-height: 44px;
                font-weight: 800;
            }

            .job {
                font-size: 20px;
                line-height: 28px;
            }

            .address-wapper {
                border-radius: 8px;
                background: #ffffff;
                height: 48px;
                padding: 0px 16px;
                margin-top: 20px;
                color: #135b87;
                font-size: 16px;
                line-height: 20px;

                .icon {
                    width: 24px;
                    height: 24px;
                    margin-right: 16px;
                }
            }

            .content {
                font-size: 18px;
                line-height: 28px;
                margin-top: 16px;
            }
        }

        .disease-wapper {
            border-radius: 16px;
            background: #ffffff;
            padding: 28px 18px;
            margin-top: 24px;

            .point {
                width: 6px;
                height: 6px;
                background: #47b9ff;
                transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }

            .title {
                color: #202020;
                font-size: 24px;
                line-height: 32px;
                margin-left: 16px;
                margin-right: 16px;
            }

            .item-wapper {
                margin-top: 4px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .item {
                    border: 2px solid #135b87;
                    padding: 12px 28px;
                    border-radius: 40px;
                    color: #135b87;
                    font-size: 22px;
                    line-height: 32px;
                    margin-left: 10px;
                    margin-right: 10px;
                    margin-top: 20px;
                }

                .item:nth-child(3n) {
                    margin-right: 0px;
                }
            }
        }

        .honor-wapper {
            padding: 28px;
            border-radius: 16px;
            margin-top: 24px;
            color: #202020;
            background: #ffffff;

            .point {
                width: 6px;
                height: 6px;
                background: #47b9ff;
                transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }

            .title {
                font-size: 24px;
                line-height: 32px;
                margin-left: 16px;
                margin-right: 16px;
            }

            .content {
                font-size: 20px;
                line-height: 32px;
                margin-top: 24px;
            }
        }

        .undergo-wapper {
            padding: 28px;
            border-radius: 16px;
            margin-top: 24px;
            color: #202020;
            background: #ffffff;

            .point {
                width: 6px;
                height: 6px;
                background: #47b9ff;
                transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }

            .title {
                font-size: 24px;
                line-height: 32px;
                margin-left: 16px;
                margin-right: 16px;
            }

            .year {
                width: 90px;
                font-size: 20px;
                line-height: 32px;
                font-weight: bold;
            }

            .content {
                width: calc(100% - 90px);
                font-size: 20px;
                line-height: 32px;
                margin-left: 8px;
            }
        }
    }

}
.dialog-btn-wapper {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 10;

    .btn {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(46, 49, 61, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 24px;
            height: 24px;
        }
    }
}

::v-deep .content {
    padding: 0px !important;
    border-radius: 16px;
}
</style>