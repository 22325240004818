<template>
    <global-menu open="7" selected="7-1" pageTitle="分类管理" :isShowNew="false">
        <div class="page-contener">
            <div class="flex-between">
                <g-tab :tabs="tabs" @change="tabChange"></g-tab>
                <plus-circle-filled @click="showModal()" :style="{ fontSize: '48px', color: '#3addb7' }" />
            </div>

            <a-table class="mg-t-32" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'image'">
                        <img class="table-img" :src="record.image" alt="" />
                    </div>
                    <div v-if="column.dataIndex === 'edit'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_edit_cyan.png" alt=""
                            @click="showModal(false, true, record.id)" />
                    </div>
                    <div v-if="column.dataIndex === 'color'">
                        <div class="color" :style="{'background-color': record.color}"></div>
                    </div>
                    <div v-if="column && column.dataIndex === 'delete'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_delete_red.png" alt=""
                            @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :width="560" :isShow="isBatchShow" title="批量操作" @onOk="batchSubmit" :zIndex="1001"
        @onCancel="isBatchShow = false">
        <global-title title="二级分类" class="mg-t-16"></global-title>
        <div class="sub-title">操作说明：</div>
        <div class="sub-tip">
            可在下列文本框内输入二级分类的名称，每一行即为一个分类，换行输入则是新的一条分类。分类的排列顺序以文本框中从上到下的顺序进行排列。
        </div>
        <a-textarea placeholder="正文" class="mg-t-16" :rows="10" v-model:value="batchValue"></a-textarea>
    </global-dialog>
    <global-dialog class="dialog-wapper" :width="modalWidth" :isShow="isShow" :title="modalTitle" :loading="submitLoading" @onOk="createOrUpdate"
        @onCancel="closeModal">
        <div v-if="modalTitle === '新增  商品分类' || modalTitle === '编辑  商品分类'">
            <global-title class="mg-t-16" title="一级分类名称"></global-title>
            <g-input class="mg-t-24" placeholder="一级分类名称" v-model="form.name"></g-input>
            <div class="mg-t-48 flex-between">
                <global-title title="二级分类名称"></global-title>
                <div class="flex-align-center">
                    <div class="batch-text" @click="showModal(true)">批量操作</div>
                    <plus-circle-filled @click="addGoodsSubs" :style="{ fontSize: '48px', color: '#3addb7' }" />
                </div>
            </div>
            <a-row>
                <a-col class="mg-t-26" :span="12" v-for="(item, index) in goodsSubs" :key="'subs' + index">
                    <div class="flex-align-center" :style="{ 'margin-left': index % 2 == 1 ? '57px' : '' }">
                        <img v-if="index == 0" class="delete-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                        <img v-else class="delete-icon" @click="deleteGoodsSubs(index)"
                            src="../../../assets/img/icon_cancel_red.png" alt="" />
                        <g-input style="width: 344px" placeholder="二级分类名称" v-model="item.name"></g-input>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div v-if="modalTitle === '新增  项目分类' || modalTitle === '编辑  项目分类'">
            <a-row class="mg-t-16">
                <a-col :span="12">
                    <global-title title="一级分类名称"></global-title>
                    <g-input style="width: 424px" class="mg-t-24" placeholder="一级分类名称" v-model="form.name"></g-input>
                </a-col>
                <a-col :span="12">
                    <global-title title="图标"></global-title>
                    <div class="mg-t-24 flex-align-center">
                        <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                            :customRequest="imgChange">
                            <img v-if="imgUrl" class="upload-icon" :src="imgUrl" alt="" />
                            <plus-circle-outlined v-else :style="{ fontSize: '64px', color: '#3addb7' }" />
                        </a-upload>
                        <div class="upload-text">
                            点击上传图片
                            <div style="margin-top: 8px">尺寸64 × 64像素；格式png、jpg</div>
                        </div>
                    </div>
                </a-col>
            </a-row>
            <div class="mg-t-48 flex-between">
                <global-title title="二级分类名称"></global-title>
                <div class="flex-align-center">
                    <div class="batch-text" @click="showModal(true)">批量操作</div>
                    <plus-circle-filled @click="addProjectSubs" :style="{ fontSize: '48px', color: '#3addb7' }" />
                </div>
            </div>
            <a-row>
                <a-col class="mg-t-26" :span="12" v-for="(item, index) in projectSubs" :key="'subs' + index">
                    <div class="flex-align-center" :style="{ 'margin-left': index % 2 == 1 ? '57px' : '' }">
                        <img v-if="index == 0" class="delete-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                        <img v-else class="delete-icon" @click="deleteProjectSubs(index)"
                            src="../../../assets/img/icon_cancel_red.png" alt="" />
                        <g-input style="width: 344px" placeholder="二级分类名称" v-model="item.name"></g-input>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div v-if="modalTitle === '新增  科室分类' || modalTitle === '编辑  科室分类'">
            <global-title title="分类名称" class="mg-t-16"></global-title>
            <g-input class="mg-t-24" placeholder="分类名称" v-model="form.name"></g-input>
            <global-title title="图标" class="mg-t-48"></global-title>
            <div class="mg-t-24 flex-align-center">
                <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                    :customRequest="imgChange">
                    <img v-if="imgUrl" class="upload-icon" :src="imgUrl" alt="" />
                    <plus-circle-outlined v-else :style="{ fontSize: '64px', color: '#3addb7' }" />
                </a-upload>
                <div class="upload-text">
                    点击上传图片
                    <div style="margin-top: 8px">尺寸64 × 64像素；格式png、jpg</div>
                </div>
            </div>
        </div>
        <div v-if="modalTitle === '新增  病症分类' || modalTitle === '编辑  病症分类'">
            <global-title title="分类名称" class="mg-t-16"></global-title>
            <g-input class="mg-t-24" placeholder="分类名称" v-model="form.name"></g-input>
            <a-row class="mg-t-48">
                <a-col :span="12" style="padding-right: 44px">
                    <global-title title="图标"></global-title>
                    <div class="mg-t-24 flex-align-center">
                        <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                            :customRequest="imgChange">
                            <img v-if="imgUrl" class="upload-icon" :src="imgUrl" alt="" />
                            <plus-circle-outlined v-else :style="{ fontSize: '64px', color: '#3addb7' }" />
                        </a-upload>
                        <div class="upload-text">
                            点击上传图片
                            <div style="margin-top: 8px">尺寸64 × 64像素；格式png、jpg</div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="12" style="padding-left: 44px">
                    <global-title title="颜色"></global-title>
                    <div class="flex-align-center mg-t-24">
                        <a-input class="color-wapper" type="color" v-model:value="colorValue" />
                        <div class="upload-text">点击选取颜色</div>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div v-if="modalTitle === '新增  资费分类' || modalTitle === '编辑  资费分类'">
            <global-title title="资费名" class="mg-t-16"></global-title>
            <g-input class="mg-t-24" placeholder="资费名" v-model="form.name"></g-input>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GTab from "../../../components/GTab";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import { ref, onMounted, getCurrentInstance } from "vue";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";

export default {
    components: {
        GlobalMenu,
        GTab,
        PlusCircleFilled,
        PlusCircleOutlined,
        GlobalDialog,
        GlobalTitle,
        GInput,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        let tableColumns = ref([]);
        let columns1 = ref([
            {
                dataIndex: "name",
                title: "一级分类名",
            },
            {
                dataIndex: "child_count",
                title: "二级分类",
                width: 250,
            },
            {
                dataIndex: "updated_at",
                title: "更新时间",
                width: 200,
            },
            {
                dataIndex: "edit",
                title: "编辑",
                width: 100,
            },
            {
                dataIndex: "delete",
                title: "删除",
                width: 100,
            },
        ]);
        let columns2 = ref([
            {
                dataIndex: "image",
                title: "图标",
            },
            {
                dataIndex: "name",
                title: "一级分类名",
            },
            {
                dataIndex: "child_count",
                title: "二级分类",
                width: 250,
            },
            {
                dataIndex: "updated_at",
                title: "更新时间",
                width: 200,
            },
            {
                dataIndex: "edit",
                title: "编辑",
                width: 100,
            },
            {
                dataIndex: "delete",
                title: "删除",
                width: 100,
            },
        ]);
        let columns3 = ref([
            {
                dataIndex: "image",
                title: "图标",
            },
            {
                dataIndex: "name",
                title: "分类名",
            },
            {
                dataIndex: "updated_at",
                title: "更新时间",
                width: 200,
            },
            {
                dataIndex: "edit",
                title: "编辑",
                width: 100,
            },
            {
                dataIndex: "delete",
                title: "删除",
                width: 100,
            },
        ]);
        let columns4 = ref([
            {
                dataIndex: "image",
                title: "图标",
            },
            {
                dataIndex: "name",
                title: "分类名",
            },
            {
                dataIndex: "color",
                title: "颜色",
            },
            {
                dataIndex: "updated_at",
                title: "更新时间",
                width: 200,
            },
            {
                dataIndex: "edit",
                title: "编辑",
                width: 100,
            },
            {
                dataIndex: "delete",
                title: "删除",
                width: 100,
            },
        ]);
        let columns5 = ref([
            {
                dataIndex: "name",
                title: "资费名",
            },
            {
                dataIndex: "updated_at",
                title: "更新时间",
                width: 200,
            },
            {
                dataIndex: "edit",
                title: "编辑",
                width: 100,
            },
            {
                dataIndex: "delete",
                title: "删除",
                width: 100,
            },
        ]);
        let tableData = ref([{}]);
        let loading = ref(false);
        let batchValue = ref(undefined)
        let total = ref(0)
        let tabs = ref([
            // "商品分类",
            "项目分类",
            "科室分类",
            "病症分类",
            "资费分类"
        ]);
        let isShow = ref(false);
        let isBatchShow = ref(false);
        let modalTitle = ref();
        let modalWidth = ref();
        let modalType = ref();
        let tabActive = ref(0);
        let isSubShow = ref(false);
        let goodsSubs = ref([{
            name: ""
        }]);
        let imgUrl = ref();
        let projectSubs = ref([{
            name: ""
        }]);
        let list_index = ref(0);
        let fileData = ref(null)
        let form = ref({});
        let ids = ref(0);
        let tableScrollHeight = ref(0)
        let colorValue = ref('#3addb7')
        let submitLoading = ref(false)
        tableColumns.value = columns2.value;
        function tabChange(index) {
            tabActive.value = index;
            // if (index == 0) {
            //     tableColumns.value = columns1.value;
            // } else 
            console.log(index)
            if (index == 0) {
                tableColumns.value = columns2.value;
            } else if (index == 1) {
                tableColumns.value = columns3.value;
            } else if (index == 2) {
                tableColumns.value = columns4.value;
            } else if (index == 3) {
                tableColumns.value = columns5.value;
            }
            list_index.value = index;
            getList(index);
        }
        function getList(index) {
            loading.value = true
            proxy.$httpUtil.get('/manage/category/' + (index + 2)).then(res => {
                tableData.value = res.data.category
                total.value = res.data.category.length;
                loading.value = false
                tableScrollHeight.value = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 32 - 56 - 64
            })
        }
        function del(id) {
            if (!id) {
                proxy.$message.warn('请选择数据')
                return false;
            }
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete('/manage/category_delete/' + (list_index.value + 2) + '/' + id, {}).then(res => {
                        closeModal();
                        getList(list_index.value);
                        return Promise.resolve()
                    })
                }
            })
        }
        const batchSubmit = () => {
            if(!batchValue.value) {
                proxy.$message.error('请输入正文')
                return
            }
            if(modalTitle.value === '新增  商品分类' || modalTitle.value === '编辑  商品分类') {
                goodsSubs.value = []
                let arrStr = batchValue.value.replaceAll('\n', ',')
                let arr = arrStr.split(',')
                for(let i in arr) {
                    goodsSubs.value.push({name: arr[i]})
                }
            }else if(modalTitle.value === '新增  项目分类' || modalTitle.value === '编辑  项目分类') {
                projectSubs.value = []
                let arrStr = batchValue.value.replaceAll('\n', ',')
                let arr = arrStr.split(',')
                for(let i in arr) {
                    projectSubs.value.push({name: arr[i]})
                }
            }
            isBatchShow.value = false
        }
        async function createOrUpdate() {
            if (!form.value.name) {
                proxy.$message.warn('请输入必填项')
                return false;
            }
            let child_name = [];
            // if (list_index.value == 0) {
            //     for (let i = 0; i < goodsSubs.value.length; i++) {
            //         if (!goodsSubs.value[i].name) {
            //             proxy.$message.warn('请输入必填项');
            //             return false;
            //             break;
            //         }
            //         child_name.push(goodsSubs.value[i].name);
            //     }
            //     if (child_name.length == 0) {
            //         proxy.$message.warn('请输入必填项')
            //         return false;
            //     }
            // }
            if (list_index.value == 0) {
                for (let i = 0; i < projectSubs.value.length; i++) {
                    if (!projectSubs.value[i].name) {
                        proxy.$message.warn('请输入必填项');
                        return false;
                        break;
                    }
                    child_name.push(projectSubs.value[i].name);
                }
                if (child_name.length == 0) {
                    proxy.$message.warn('请输入必填项')
                    return false;
                }
            }

            var url = '/manage/category_add';
            if (ids.value) {
                url = '/manage/category_update/' + (list_index.value + 2) + '/' + ids.value;
            }
            let postData = {
                type: (list_index.value + 2),
                name: form.value.name
            }
            if (list_index.value < 3) {
                postData = {
                    type: (list_index.value + 2),
                    name: form.value.name,
                    child_name: child_name,
                    image: ''
                }
                if(!imgUrl.value) {
                    proxy.$message.warn('请上传图片');
                    return false;
                }else {
                    postData.image = imgUrl.value
                }
                if(list_index.value == 2) {
                    postData.color = colorValue.value
                }
            }
            submitLoading.value = true
            if(fileData.value) {
                let path = 'project'
                if(list_index.value == 1) {
                    path = 'organization'
                }else if(list_index.value == 2) {
                    path = 'disease'
                }
                let res = await proxy.$httpUtil.upload('/platform/file_upload', fileData.value, path)
                if(res.file_url) {
                    postData.image = res.file_url
                }else {
                    proxy.$message.error('图片上传失败')
                    submitLoading.value = false
                    return
                }
            }
            if (ids.value) {
                proxy.$httpUtil.put(url, postData).then(res => {
                    closeModal();
                    getList(list_index.value);
                }).catch(() => {
                    submitLoading.value = false
                })
            } else {
                proxy.$httpUtil.post(url, postData).then(res => {
                    closeModal();
                    getList(list_index.value);
                }).catch(() => {
                    submitLoading.value = false
                })
            }

        }

        function getDetail(id) {
            proxy.$httpUtil.get('/manage/category_show/' + (list_index.value + 2) + '/' + id).then(res => {
                form.value.name = res.data.category.name;
                form.value.color = colorValue.value = res.data.category.color || '#3addb7';
                imgUrl.value = res.data.category.image || ''
                console.log(res.data);
                if (list_index.value == 0) {
                    var nameArr = [];
                    for (let i = 0; i < res.data.category.child_data.length; i++) {
                        nameArr.push({ name: res.data.category.child_data[i].name })
                    }
                    // if (list_index.value == 0) {
                    //     goodsSubs.value = nameArr;
                    // }
                    if (list_index.value == 0) {
                        projectSubs.value = nameArr;
                    }
                }
                isShow.value = true;
            })
        }
        function showModal(subs, isEdit, id) {
            fileData.value = null
            if (id) {
                ids.value = id;
                getDetail(id);
            }else {
                isShow.value = true;
            }
            if (subs) {
                batchValue.value = undefined
                if(modalTitle.value === '新增  商品分类' || modalTitle.value === '编辑  商品分类') {
                    if(goodsSubs.value.length) {
                        let arr = []
                        for(let i in goodsSubs.value) {
                            arr.push(goodsSubs.value[i].name)
                        }
                        batchValue.value = arr.join(',').replaceAll(',', '\n')
                    }
                }else if(modalTitle.value === '新增  项目分类' || modalTitle.value === '编辑  项目分类') {
                    if(projectSubs.value.length){
                        let arr = []
                        for(let i in projectSubs.value) {
                            arr.push(projectSubs.value[i].name)
                        }
                        batchValue.value = arr.join(',').replaceAll(',', '\n')
                    }
                }
                isBatchShow.value = true
            } else {
                // if (tabActive.value == 0) {
                //     if (isEdit) {
                //         modalTitle.value = "编辑  商品分类";
                //     } else {
                //         modalTitle.value = "新增  商品分类";
                //     }
                //     modalWidth.value = 1008;
                // } else 
                if (tabActive.value == 0) {
                    if (isEdit) {
                        modalTitle.value = "编辑  项目分类";
                    } else {
                        modalTitle.value = "新增  项目分类";
                    }

                    modalWidth.value = 1008;
                } else if (tabActive.value == 1) {
                    if (isEdit) {
                        modalTitle.value = "编辑  科室分类";
                    } else {
                        modalTitle.value = "新增  科室分类";
                    }
                    modalWidth.value = 560;
                } else if (tabActive.value == 2) {
                    if (isEdit) {
                        modalTitle.value = "编辑  病症分类";
                    } else {
                        modalTitle.value = "新增  病症分类";
                    }
                    modalWidth.value = 560;
                }else if (tabActive.value == 3) {
                    if (isEdit) {
                        modalTitle.value = "编辑  资费分类";
                    } else {
                        modalTitle.value = "新增  资费分类";
                    }
                    modalWidth.value = 560;
                }
            }
            
        }
        function closeModal() {
            ids.value = ''
            isShow.value = false;
            form.value.name = '';
            goodsSubs.value = [{name: ''}]
            projectSubs.value = [{name: ''}]
            imgUrl.value = ''
            submitLoading.value = false
            colorValue.value = '#3addb7'
        }

        function addGoodsSubs() {
            goodsSubs.value.push({ name: '' });
        }
        function deleteGoodsSubs(index) {
            goodsSubs.value.splice(index, 1);
        }
        function imgChange(info) {
            fileData.value = info.file
            imgUrl.value = proxy.$common.getFileUrl(info.file);
        }

        function addProjectSubs() {
            projectSubs.value.push({ name: '' });
        }
        function deleteProjectSubs(index) {
            projectSubs.value.splice(index, 1);
        }
        onMounted(() => {
            getList(list_index.value)
        })
        return {
            tableColumns,
            tableData,
            loading,
            tabs,
            isShow,
            isBatchShow,
            modalTitle,
            modalWidth,
            modalType,
            isSubShow,
            goodsSubs,
            imgUrl,
            projectSubs,
            batchValue,
            tableScrollHeight,
            colorValue,

            tabChange,
            showModal,
            createOrUpdate,
            batchSubmit,
            closeModal,
            addGoodsSubs,
            deleteGoodsSubs,
            imgChange,
            addProjectSubs,
            deleteProjectSubs,
            list_index,
            getList,
            form,
            ids,
            del
        };
    },
};
</script>
<style lang="less" scoped>
.color {
    width: 24px;
    height: 12px;
    border-radius: 4px;
}
.page-contener {
    height: 100%;
    padding: 16px;

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .table-img {
        width: 32px !important;
        height: 32px !important;
    }
}

.dialog-wapper {
    .batch-text {
        color: #3addb7;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .delete-icon {
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }

    .sub-title {
        color: #252733;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
    }

    .sub-tip {
        color: #65697b;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    .upload-icon {
        width: 64px;
        height: 64px;
        border-radius: 50%;
    }

    .upload-text {
        width: 100%;
        color: #252733;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: none;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    ::v-deep .ant-upload-picture-card-wrapper {
        width: auto;
    }

    .color-wapper {
        flex: 0 0 auto;
        height: 48px;
        width: 80px;
        border: 1px solid #b6bbd7;
        border-radius: 10px;
    }
}
</style>