<template>
    <div :class="`box ${type}`">
        <div class="flex-between">
            <span>{{ data.patient.name ? data.patient.name : '-' }}</span>
            <span>{{ type == 'consult' ? (data.consult_at ? $moment(data.consult_at).format('YYYY-MM-DD HH:mm') : '-') : (data.treated_at ? $moment(data.treated_at).format('YYYY-MM-DD HH:mm') : '待定') }}</span>
        </div>
        <div class="ellipsis text" v-if="type == 'consult'"><img class="icon" src="@/assets/img/icon_vidicon.png" alt=""> {{ data.conference && data.conference.start_at ? data.conference.start_at : '-' }}</div>
        <div class="ellipsis text" :title="`${data.hospital_name}（${data.service_name}）`" v-else>{{ data.hospital_name }}（{{ data.service_name }}）</div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            default: {}
        },
        type: {
            type: String,
            default: 'local'
        },
    }
};
</script>
<style lang="less" scoped>
    .box {
        position: relative;
        padding: 8px;
        padding-left: 14px;
        background-color: #E1FAE4;
        border-radius: 4px;
        margin-bottom: 12px;
        overflow: hidden;
        cursor: pointer;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: #3ADDB7;
            content: '';
        }
        &.online {
            background-color: #FAE0C6;
            &:before {
                background-color: #FC9732;
            }
        }
        &.consult {
            background-color: #D9EAFC;
            &:before {
                background-color: #62ABFA;
            }
        }
        .text {
            height: 18px;
            margin-top: 8px;
            line-height: 18px;
            font-size: 14px;
        }
        .icon {
            width: 16px;
        }
    }
</style>