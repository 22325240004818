<template>
    <global-menu open="7" selected="7-6" pageTitle="消息管理" @clickNew="showModal('create')">
        <template #btnText>新增</template>
        <div class="page-contener">
            <a-table :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'push_object'">
                        <span v-if="record.push_object == 1">用户手机</span>
                        <span v-if="record.push_object == 2">机构平板</span>
                        <span v-if="record.push_object == 3">用户手机、机构平板</span>
                    </div>
                    <div v-if="column.dataIndex === 'operation'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_edit_cyan.png" alt="" @click="showModal('edit', record)" />
                        <img class="table-icon mg-l-8  pointer" src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" :title="modalTitle" :loading="submitLoading" @onOk="submit" @onCancel="closeModal">
        <global-title title="标题"></global-title>
        <g-input class="mg-t-24" placeholder="标题（非必填）" v-model="form.title"></g-input>
        <div class="mg-t-24">
            <global-title title="推送对象"></global-title>
            <div class="mg-t-24 flex-align-center">
                <a-checkbox v-model:checked="checked">用户手机</a-checkbox>
                <a-checkbox v-model:checked="checked2">机构平板</a-checkbox>
            </div>
        </div>
        <div class="mg-t-24">
            <global-title title="内容"></global-title>
            <a-textarea
                class="mg-t-24"
                v-model:value="form.content"
                placeholder="内容"
                :auto-size="{ minRows: 3, maxRows: 6 }"
            />
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import Pagination from "@/components/Pagination.vue"

export default {
    components: {
        GlobalMenu,
        PlusCircleFilled,
        PlusCircleOutlined,
        Pagination,
        GlobalDialog,
        GlobalTitle,
        GInput,
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        let state = reactive({
            tableData: [],
            loading: false,
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
            isModalShow: false,
            modalType: 'create',
            modalTitle: '新增消息',
            form: {
                title: '',
                push_object: '',
                content: ''
            },
            checked: false,
            checked2: false,
            detail: null,
            submitLoading: false
        })
        let searchForm = reactive({
            page: 1,
            page_size: 10,
        })
        const submit = () => {
            if(state.checked) {
                state.form.push_object = 1
            }
            if(state.checked2) {
                state.form.push_object = 2
            }
            if(state.checked && state.checked2) {
                state.form.push_object = 3
            }
            if(!state.form.push_object || !state.form.content) {
                proxy.$message.error('请填写必填项')
                return
            }
            state.submitLoading = true
            if(state.modalType == 'edit') {
                proxy.$httpUtil.put(`/manage/notice/${state.detail.id}`, state.form).then(res => {
                    if(res.success) {
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }else {
                proxy.$httpUtil.post('/manage/notice', state.form).then(res => {
                    if(res.success) {
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/notice/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const showModal = (type, data) => {
            state.modalType = type
            state.modalTitle = '新增消息'
            state.detail = data
            if(type == 'edit') {
                state.modalTitle = '编辑消息'
                state.form = {
                    title: data.title,
                    push_object: data.push_object,
                    content: data.content
                }
                state.checked = data.push_object == 1 || data.push_object == 3 ? true : false
                state.checked2 = data.push_object == 2 || data.push_object == 3 ? true : false
            }else {
                state.form = {
                    title: '',
                    push_object: '',
                    content: ''
                }
                state.checked = false
                state.checked2 = false
            }
            state.isModalShow = true
        }
        const closeModal = () => {
            state.isModalShow = false
            state.submitLoading = false
        }
        const getData = async () => {
            state.loading = true
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getNotice(searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        onMounted(() => {
            getData()
        })
        return {
            tableColumns: [
                {
                    dataIndex: "title",
                    title: "标题",
                },
                {
                    dataIndex: "content",
                    title: "内容",
                },
                {
                    dataIndex: "push_object",
                    title: "推送对象",
                },
                {
                    dataIndex: "created_at",
                    title: "创建时间",
                },
                {
                    dataIndex: "operation",
                    title: "操作",
                    width: 120
                }
            ],
            refPagination,
            ... toRefs(state),

            showModal,
            closeModal,
            submit,
            del
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;
    padding: 16px;

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .table-img {
        width: 32px !important;
        height: 32px !important;
    }
}

.dialog-wapper {
    .batch-text {
        color: #3addb7;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .delete-icon {
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }

    .sub-title {
        color: #252733;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
    }

    .sub-tip {
        color: #65697b;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    /deep/.upload-icon {
        width: 343px;
        height: 114px;
        border-radius: 8px;
    }

    .upload-text {
        width: 100%;
        color: #252733;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: none;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    ::v-deep .ant-upload-picture-card-wrapper {
        width: auto;
    }

    .color-wapper {
        flex: 0 0 auto;
        height: 48px;
        width: 80px;
        border: 1px solid #b6bbd7;
        border-radius: 10px;
    }
}
</style>