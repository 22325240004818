<template>
  <global-menu open="4" selected="4-2">
    <section class="page-contener">
      <global-header title="设置价格"></global-header>
      <div class="content-wapper">
        <global-title title="基本信息"></global-title>
        <div class="mg-t-24">
          <div class="key">商品名称：</div>
          <div class="value mg-l-32">商品名称占位文本</div>
        </div>
        <div class="mg-t-24">
          <div class="key">商品分类：</div>
          <div class="value mg-l-32">分类名称</div>
        </div>
        <global-title class="mg-t-48" title="价格设置"></global-title>
        <div class="value-wapper">
          <div class="mg-t-24">
            <div class="key">商品规格：</div>
            <div class="value mg-l-32">200 ml/瓶</div>
            <div class="price">12,000 円</div>
          </div>
          <div class="mg-t-18 flex-align-center">
            <div class="key">设置价格：</div>
            <g-input
              class="mg-l-32"
              :icon="require('../../../assets/img/icon_cost.png')"
              placeholder="销售价格"
            ></g-input>
            <div class="value mg-l-16">円</div>
          </div>
        </div>
        <div class="value-wapper">
          <div class="mg-t-24">
            <div class="key">商品规格：</div>
            <div class="value mg-l-32">200 ml/瓶</div>
            <div class="price">12,000 円</div>
          </div>
          <div class="mg-t-18 flex-align-center">
            <div class="key">设置价格：</div>
            <g-input
              class="mg-l-32"
              :icon="require('../../../assets/img/icon_cost.png')"
              placeholder="销售价格"
            ></g-input>
            <div class="value mg-l-16">円</div>
          </div>
        </div>
        <div class="value-wapper">
          <div class="mg-t-24">
            <div class="key">商品规格：</div>
            <div class="value mg-l-32">200 ml/瓶</div>
            <div class="price">12,000 円</div>
          </div>
          <div class="mg-t-18 flex-align-center">
            <div class="key">设置价格：</div>
            <g-input
              class="mg-l-32"
              :icon="require('../../../assets/img/icon_cost.png')"
              placeholder="销售价格"
            ></g-input>
            <div class="value mg-l-16">円</div>
          </div>
        </div>
      </div>
      <div class="btn-wapper">
        <g-button>确定</g-button>
        <g-button class="mg-l-40" type="default" ghost @click="$router.back">关闭</g-button>
      </div>
    </section>
  </global-menu>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu.vue";
import GlobalHeader from "../../../components/GlobalHeader.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GButton from "../../../components/GButton.vue";
import GInput from "../../../components/GInput";
import { ref } from "vue";
export default {
  components: {
    GlobalMenu,
    GlobalHeader,
    GlobalTitle,
    GButton,
    GInput,
  },
  setup() {
    return {
    };
  },
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 40px);
  position: relative;
  .status-text {
    color: #ffb03e;
    font-size: 20px;
    line-height: 20px;
  }
  .content-wapper {
    width: 600px;
    height: calc(100% - 96px - 128px);
    margin: 16px auto;
    overflow: auto;
    .value-wapper {
      padding-bottom: 24px;
      border-bottom: 1px solid #e8e8e8;
    }
    .value-wapper:last-child{
      border-bottom: none;
    }
    .key {
      display: inline-block;
      flex: 0 0 auto;
      color: #b6bbd7;
      font-size: 16px;
      line-height: 22px;
      padding-left: 12px;
    }
    .value {
      display: inline-block;
      color: #252733;
      font-size: 16px;
      line-height: 22px;
    }
    .price {
      display: inline-block;
      color: #ff504a;
      font-size: 16px;
      line-height: 22px;
      margin-left: 32px;
    }
  }
  .btn-wapper {
    height: 128px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>