<template>
<div class="g-select">
    <div class=" flex-between">
        <img class="icon" v-if="icon" :src="icon" alt="">
        <a-select style="width:100%" :placeholder="placeholder"  @change="onChange" v-model:value="value">
            <a-select-option v-for="item,index in options" :key="'option'+index" :value="item.id">{{item.name}}</a-select-option>
        </a-select>
    </div>
</div>
    
</template>
<script>
import { ref, onMounted, watch } from "vue"
export default {
    props:{
        options:Array,
        icon:String,
        placeholder:String,
        selectId:Number
    },
    setup(props,context){
        function onChange(e){
            console.log(e)
            context.emit('update:modelValue',e)
        }
        const value = ref(undefined)
        watch(() => props.selectId, (_val, oldVal) => {
            value.value = _val
        })
        onMounted(() => {
            value.value = props.selectId || undefined
        })
        return {
            value,
            onChange
        }
    }
}
</script>
<style lang="less" scoped>
.g-select{
    width: 100%;
    padding: 8px 11px;
    border-radius: 8px;
    background: #F4F8FE;
    display: inline-block;
    .icon{
        width: 20px;
        height: 20px;
    }
    ::v-deep .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
        box-shadow: none;
    }
}
</style>