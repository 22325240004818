<template>
    <a-spin :spinning="loading">
        <div class="report">
            <a-textarea class="textarea" placeholder="请输入报告内容" v-model:value="content"></a-textarea>
            <div class="upload-box">
                <div class="item">
                    <input class="input" type="file" @change="fileChange">
                    <a-button class="upload-btn">+</a-button>
                </div>
                <div class="item" v-for="item, index in fileList" :key="index">
                    <img class="icon" src="@/assets/img/icon_close_red.png" alt="" @click="fileList.splice(index, 1)">
                    <img class="img" :src="item.url" alt="">
                </div>
            </div>
            <a-button class="btn" @click="submit">提交</a-button>
        </div>
    </a-spin>
</template>

<script>
import { ref, getCurrentInstance } from "vue"
import { useRoute } from 'vue-router'
export default {
    setup(){
        const {proxy} = getCurrentInstance()
        const route = useRoute()
        let fileList = ref([])
        let imgs = ref([])
        let content = ref('')
        let loading = ref(false)
        const fileChange = e => {
            let data = {
                url: proxy.$common.getFileUrl(e.target.files[0]), 
                file: e.target.files[0]
            }
            fileList.value.push(data)
        }
        const submit = async () => {
            imgs.value = []
            if(!content.value && !fileList.value.length) {
                proxy.$message.error('请输入报告内容或上传图片')
                return
            }
            loading.value = true
            if(fileList.value.length) {
                for(let i = 0; i < fileList.value.length; i ++) {
                    let res = await proxy.$httpUtil.upload('/platform/file_upload', fileList.value[i].file, 'order')
                    if(res.file_url) {
                        imgs.value.push(res.file_url)
                    }else {
                        proxy.$message.error('图片上传失败')
                        loading.value = false
                        return false
                    }
                }
            }
            proxy.$httpUtil.put(`/service_person/added_service_order_write_report/${route.query.id}`, {
                service_report_content: content.value,
                service_report_image: imgs.value
            }).then(res => {
                if(res.success) {
                    loading.value = false
                    proxy.$router.back()
                }
            }).catch(() => {
                loading.value = false
            })
        }
        return {
            fileList,
            loading,
            content,
            submit,
            fileChange
        }
    }
}
</script>

<style scoped lang="less">
    .report {
        height: calc(100vh - 48px);
        .textarea {
            height: 60%;
            resize: none;
            background-color: #fff;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #3ADDB7;
            &:focus {
                box-shadow: none;
            }
        }

        .upload-box {
            padding: 24px;
            height: 40%;
            overflow: auto;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 58px;
                height: 58px;
                margin-right: 12px;
                margin-bottom: 12px;
                position: relative;
                .upload-btn {
                    width: 58px;
                    height: 58px;
                    border: 1px solid #3ADDB7;
                    font-size: 24px;
                    color: #3ADDB7;
                }
                .input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 1;
                }
                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .icon {
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    right: -8px;
                    top: -8px;
                    z-index: 1;
                }
            }
        }
    }
    .btn {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 48px;
        background-color: #3ADDB7;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        box-shadow: none;
        border: none;
    }
</style>