<template>
    <global-menu open="1" selected="1-4" pageTitle="开药  订单" :isShowNew="false">
        <global-search :searchList="searchList" :searchDorm="searchForm" @search="search"></global-search>
        <div class="page-contener">
            <g-tab :tabs="tabs" @change="tabChange"></g-tab>
            <a-table class="mg-t-16" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'contact_info'">
                        <div v-if="record.contact_info">{{ record.contact_info.name }} （{{ record.contact_info.mobile }}）</div>
                        <div v-else>-</div>
                    </div>
                    <div v-if="column.dataIndex === 'money'">
                        {{ record.money }} （{{ record.freight_money }}）
                    </div>
                    <div v-if="column && column.dataIndex === 'meeting'" @click="showModal('会议信息', true, 560)">
                        <img class="table-icon" src="../../../assets/img/icon_video_green.png" alt="" />
                        <span class="table-meeting">2022-05-25 11:00</span>
                    </div>
                    <div v-if="column && column.dataIndex === 'drug'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_medicine_orange.png" alt=""
                            @click="$common.toPage('OrderDrugDetail', { title: '开药清单', open: '1', selected: '1-4' })" />
                    </div>
                    <div v-if="column && column.dataIndex === 'message'">
                        <div class="msg-box">
                            <span class="dian" v-if="record.is_feedback"></span>
                            <img class="table-icon pointer" src="../../../assets/img/icon_message_cyan.png" alt="" @click="showModal('msg', false, 0, record)" />
                        </div>
                    </div>
                    <div v-if="column && column.dataIndex === 'detail'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_detail.png" alt="" @click="$common.toPage('OrderDrugDetail', { title: '订单详情', type: 'detail', id: record.id, open: '1', selected: '1-4' })" />
                    </div>
                    <div v-if="column && column.dataIndex === 'deliver'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_delivery_orange.png" alt="" @click="$common.toPage('OrderDrugDetail', { title: '订单发货', type: 'detail', id: record.id, open: '1', selected: '1-4' })" />
                    </div>
                    <div v-if="column && column.dataIndex === 'cancel'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                    </div>
                    <div v-if="column && column.dataIndex === 'operation'">
                        <img style="width: 24px; height: 24px" src="../../../assets/img/icon_cancel_red.png" alt="" @click="cancel(record.id)" />
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog :isShow="isDeliverModalShow" title="物流设置" @onCancel="isDeliverModalShow = false" @onOk="deliverSubmit" :loading="deliverSubmitLoading" class="dialog-modal" :width="560">
        <global-title title="物流信息" />
        <g-input class="mg-t-24" placeholder="EMS（默认）" :icon="require('@/assets/img/icon_delivery_car.png')" :disable="true"></g-input>
        <g-input class="mg-t-24" placeholder="运单号" :icon="require('@/assets/img/icon_delivery_number.png')" v-model="deliverForm.logistics.number"></g-input>
    </global-dialog>
    <global-dialog :isShow="isModalShow" :title="modalTitle" @onCancel="closeModal" @onOk="submit"
        :isShowFooter="isShowFooter" class="dialog-modal" :width="modalWidth">
        <div v-if="modalTitle == '会议信息'">
            <div class="mg-t-16">
                <div class="meeting-info">
                    <a class="copy" @click="copy(detail.conference.number)">复制会议链接</a>
                    <div class="title">医疗咨询视频会议</div>
                    <!-- <div class="num-wapper">
                        <div class="num">{{ detail.conference.number }}</div>
                        <img class="icon pointer" src="../../../assets/img/icon_copy_white.png" alt="" @click="copy(detail.conference.number)" />
                    </div> -->
                    <!-- <div class="password-wapper">
                        <img class="icon" src="../../../assets/img/icon_key_white.png" alt="" />
                        <span class="password">{{ detail.conference.password }}</span>
                    </div> -->
                    <div class="time-wapper">
                        <div>
                            <div class="time">{{ $moment(detail.conference.start_at).format('HH:mm') }}</div>
                            <div class="date">{{ $moment(detail.conference.start_at).format('YYYY.MM.DD') }}</div>
                        </div>
                        <div class="duration">
                            {{ detail.conference.meeting_at }}分钟
                        </div>
                        <div>
                            <div class="time">{{ $moment(detail.conference.end_at).format('HH:mm') }}</div>
                            <div class="date">{{ $moment(detail.conference.end_at).format('YYYY.MM.DD') }}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <template #footer v-if="modalTitle == '会议信息'">
            <div>
                <g-button type="error" ghost>取消会议</g-button>
                <g-button type="default" class="mg-l-40" ghost @click="closeModal">关闭</g-button>
            </div>
        </template>
    </global-dialog>
    <message ref="refMessage" />
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalDoctor from "@/components/GlobalDoctor.vue";
import GInput from "@/components/GInput.vue";
import GSelect from "@/components/GSelect.vue";
import GButton from "@/components/GButton.vue";
import GDate from "@/components/GDate.vue";
import { reactive, toRefs, onMounted, ref, getCurrentInstance } from "vue";
import GTab from "@/components/GTab.vue";
import Pagination from "@/components/Pagination.vue"
import GlobalTitle from "@/components/GlobalTitle.vue"
import message from "@/components/message.vue"
import { Modal } from 'ant-design-vue'
export default {
    components: {
        GlobalMenu,
        GlobalSearch,
        GlobalDialog,
        GInput,
        GSelect,
        GDate,
        GlobalDoctor,
        GButton,
        GTab,
        Pagination,
        GlobalTitle,
        message
    },

    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        const refMessage = ref()
        let state = reactive({
            tabs: ['待回复  0', '待支付  0', '待发货  0', '已完成  0', '已取消  0'],
            tableColumns: [],
            tableData: [],
            loading: false,
            tabActive: 0,
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 0,
            modalType: 'create',
            isModalShow: false,
            isShowFooter: true,
            isDeliverModalShow: false,
            deliverForm: {
                order_id: '',
                logistics: {
                    name: 'EMS',
                    number: ''
                }
            },
            deliverSubmitLoading: false
        })
        const deliverSubmit = () => {
            if(!state.deliverForm.logistics.number) {
                proxy.$message.error('请输入运单号')
                return
            }
            state.deliverSubmitLoading = true
            proxy.$httpUtil.post('/order/drug_confirm_logistics', state.deliverForm).then(res => {
                if(res.success) {
                    proxy.$message.success('发货成功')
                    state.deliverSubmitLoading = false
                    state.isDeliverModalShow = false
                    getData()
                }
            }).catch(() => {
                state.deliverSubmitLoading = false
            })
        }
        const cancel = id => {
            Modal.confirm({
                title: '确定取消该订单吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.get(`/order/drug_cancel/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`取消成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            getCount()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该订单吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/order/drug/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            getCount()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        let searchForm = reactive({
            contact_name: undefined,
            contact_mobile: undefined,
            custom_status: 1,
            page_size: 10,
            page: 1
        })
        let modalWidth = ref(1008);
        const column = {
            0: [
                { dataIndex: "contact_info", title: "联系人信息",},
                { dataIndex: "money", title: "订单金额（运费）円" },
                { dataIndex: "created_at", title: "创建时间",},
                { dataIndex: "message", title: "消息", width: 80 },
                { dataIndex: "detail", title: "详情", width: 80},
            ],
            1: [
                { dataIndex: "contact_info", title: "联系人信息" },
                { dataIndex: "money", title: "订单金额（运费）円" },
                { dataIndex: "created_at", title: "创建时间" },
                { dataIndex: "detail", title: "详情", width: 80},
            ],
            2: [
                { dataIndex: "contact_info", title: "联系人信息" },
                { dataIndex: "money", title: "订单金额（运费）円" },
                { dataIndex: "created_at", title: "创建时间" },
                { dataIndex: "message", title: "消息", width: 80 },
                // { dataIndex: "deliver", title: "发货", width: 80 },
                { dataIndex: "detail", title: "详情", width: 80},
            ],
            3: [
                { dataIndex: "contact_info", title: "联系人信息" },
                { dataIndex: "money", title: "订单金额（运费）円" },
                { dataIndex: "created_at", title: "创建时间" },
                { dataIndex: "message", title: "消息", width: 80 },
                { dataIndex: "detail", title: "详情", width: 80 },
            ],
            4: [
                { dataIndex: "contact_info", title: "联系人信息" },
                { dataIndex: "money", title: "订单金额（运费）円" },
                { dataIndex: "created_at", title: "创建时间" },
                { dataIndex: "canceled_at", title: "取消时间" },
                { dataIndex: "message", title: "消息", width: 80 },
                { dataIndex: "detail", title: "详情", width: 80 },
                { dataIndex: "cancel", title: "删除", width: 80 },
            ]
        }
        let form = ref({});
        let sexs = ref([
            { id: 1, name: "男" },
            { id: 2, name: "女" },
        ]);
        let orgs = ref([
            {
                id: 1,
                name: "机构1",
            },
            {
                id: 2,
                name: "机构2",
            },
            {
                id: 3,
                name: "机构3",
            },
        ]);
        const pageChange = data => {
            state.pagination.page = data.page
            getData()
        }
        const deliverModalShow = id => {
            state.isDeliverModalShow = true
            state.deliverForm.order_id = id
            state.deliverForm.logistics.number = ''
        }
        const search = searchData => {
            searchForm = {... searchForm, ... searchData}
            state.pagination.page = 1
            refPagination.value.reset(1)
            getData()
            getCount()
        }
        const tabChange = index => {
            state.tabActive = index
            searchForm.custom_status = state.tabActive + 1
            state.tableColumns = column[index]
            state.pagination.page = 1
            refPagination.value.reset(1)
            getData()
        }
        function showModal(title, showFooter, width, data) {
            if(title == 'msg'){
                refMessage.value.show(data, 2)
                return
            }
            modalTitle.value = title;
            isModalShow.value = true;
            isShowFooter.value = showFooter;
            if (modalWidth) {
                modalWidth.value = width;
            } else {
                modalWidth.value = 1008;
            }
        }
        function closeModal() {
            isModalShow.value = false;
        }
        function submit() {
            console.log(form.value);
        }
        const getData = async () => {
            state.loading = true
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getOrderDrug(searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        const getCount = async () => {
            let res = await proxy.$api.getDrugStatistics({
                contact_name: searchForm.contact_name,
                contact_mobile: searchForm.contact_mobile,
            })
            let obj = {}
            for(let i in res) {
                obj[res[i].custom_status] = res[i].total
            } 
            state.tabs = [`待回复  ${obj[1]}`, `待支付  ${obj[2]}`, `待发货  ${obj[3]}`, `已完成  ${obj[4]}`, `已取消  ${obj[5]}`]
        }
        onMounted(() => {
            tabChange(0);
            getCount()
        });
        return {
            refMessage,
            refPagination,
            ... toRefs(state),
            searchList: [
                {
                    type: "input",
                    model: "contact_name",
                    placeholder: "联系人",
                    icon: require("@/assets/img/icon_user.png"),
                },
                {
                    type: "input",
                    model: "contact_mobile",
                    placeholder: "联系电话",
                    icon: require("@/assets/img/icon_phone.png"),
                },
            ],
            searchForm,
            form,
            sexs,
            orgs,

            search,
            tabChange,
            showModal,
            closeModal,
            submit,
            deliverModalShow,
            deliverSubmit,
            del,
            cancel,
            pageChange
        };
    },
};
</script>
<style lang="less" scoped>
.msg-box {
    position: relative;
    display: inline-block;
    .dian {
        position: absolute;
        right: -2px;
        top: -4px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: red;
    }
}
.page-contener {
    height: calc(100% - 92px);
    overflow: auto;
    margin-top: 12px;
    padding: 16px;

    .tab-box {
        display: inline-block;
        background: #f4f8fe;
        border-radius: 5px;
        padding: 2px;

        .tab-btn {
            border: none;
            background: none;
            width: 144px;
            color: #252733;
            font-size: 16px;
            border-radius: 3px;
        }

        .tab-btn-active {
            color: #ffffff;
            background: #3addb7;
        }
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;

    }

    .table-meeting {
        color: #3ADDB7;
        font-size: 16px;
        line-height: 20px;
        margin-left: 40px;
    }
}

.dialog-modal {
    padding-bottom: 64px;

    .form-item-data {
        width: calc((100% - 64px) / 3) !important;
        margin-right: 32px;
        margin-bottom: 24px;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    ::v-deep .ant-divider-horizontal {
        margin: 8px 0px 30px;
    }

    .subtitle {
        color: #b6bbd7 !important;
        font-size: 20px;
        margin-left: 40px;
    }

    .inline-box {
        display: flex;
        justify-content: space-between;

        .line-item {
            width: calc((100% - 64px) / 2);
        }
    }

    .info-img {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        background: #e8e8e8;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    .file-box {
        display: flex;
        flex-wrap: wrap;
    }

    .info-file {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        border: 1px solid #e8e8e8;
        text-align: center;
        position: relative;

        &:nth-child(3n) {
            margin-right: 0px;
        }

        .img {
            width: 56px;
            height: 56px;
        }

        .file-name {
            color: #202020;
            font-size: 14px;
            line-height: 18px;
            margin-top: 16px;
        }

        .file-del {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 128px;
        height: 128px;
        border-radius: 8px;
        border: 1px solid #3addb7;
    }

    .meeting-title {
        color: #3addb7;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
    }

    .meeting-title:last-child {
        margin-top: 48px;
    }

    .meeting-info {
        border-radius: 16px;
        background: url('../../../assets/img/meeting_bg.png');
        color: #ffffff;
        padding: 24px 32px;
        position: relative;
        .copy {
            font-size: 14px;
            color: #fff;
            position: absolute;
            right: 12px;
            top: 10px;
            z-index: 1;
        }

        .title {
            padding-top: 8px;
            font-size: 24px;
            line-height: 40px;
            text-align: center;
        }

        .num-wapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 8px;

            .num {
                font-size: 44px;
                line-height: 64px;
                font-weight: 500;
            }

            .icon {
                width: 32px;
                height: 32px;
                margin-left: 24px;
            }
        }

        .password-wapper {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 12px;
            }

            .password {
                font-size: 24px;
                line-height: 24px;
            }
        }

        .time-wapper {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            .time {
                font-size: 40px;
                line-height: 48px;
            }

            .date {
                font-size: 20px;
                line-height: 28px;
            }

            .duration {
                width: 112px;
                height: 48px;
                font-size: 20px;
                line-height: 28px;
                border-bottom: 2px solid #ffffff;
                padding-top: 12px;
                padding-bottom: 8px;
                text-align: center;
            }
        }

        .footer {}
    }
}
</style>