<template>
    <div style="display:inline-block;width:100%">
        <a-popconfirm icon=" " placement="bottom" :visible="visible" @visibleChange="handleVisibleChange">
            <template #title>
                <div>
                    <div class="g-doctor">
                        <!-- 医院 -->
                        <div class="hospital-box" v-if="type != 2">
                            <div class="hospital" :class="{ 'hospital-active': form.hospital_id || hospitals.length }">
                                医院
                            </div>
                            <div class="hospital-list">
                                <div class="hospital-item pointer" :class="{
                                    'hospital-item-active': form.hospital_id == item.id,
                                }" v-for="item in hospitals" :key="item.id" @click="hospitalChange(item)">
                                    <div class="point"></div>
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <!-- 科室 -->
                        <div class="hospital-box mg-l-8" v-if="type != 3">
                            <div class="hospital" :class="{ 'hospital-active': form.hospital_service_id || services.length }">
                                {{ doctorType == 'online' ? '病症' : '科室' }}
                            </div>
                            <div class="hospital-list">
                                <div class="hospital-item pointer" :class="{
                                    'hospital-item-active': form.hospital_service_id == item.id,
                                }" v-for="item in services" :key="item.id" @click="serviceChange(item)">
                                    <div class="point"></div>
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <!-- 医生 -->
                        <div class="hospital-box mg-l-8">
                            <div class="hospital" :class="{ 'hospital-active': form.doctor_id || doctors.length }">
                                医生
                            </div>
                            <div class="hospital-list">
                                <div class="hospital-item pointer" :class="{
                                    'hospital-item-active': form.doctor_id == item.id,
                                }" v-for="item in doctors" :key="item.id" @click="doctorChange(item)">
                                    <div class="point"></div>
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="g-doctor-btn">
                        <a-button style="width:128px;border-radius:8px" type="primary" @click="submit">确定</a-button>
                        <a-button style="width:128px;border-radius:8px" class="mg-l-32" type="primary" ghost @click="clear">清空</a-button>
                        <a-button style="width:128px;border-radius:8px" class="mg-l-32" @click="visible = false">关闭</a-button>
                    </div>
                </div>
            </template>
            <div class="flex-between g-doctor-box">
                <img class="icon" :src="icon" alt="">
                <a-select style="width: calc(100% - 20px)" :options="options" :placeholder="placeholder" :open="false" v-model:value="pickerValue">
                    <a-select-option value="jack">Jack</a-select-option>
                </a-select>
            </div>
        </a-popconfirm>
    </div>
</template>
<script>
import GButton from '@/components/GButton'
import { reactive, toRefs, onMounted, getCurrentInstance, watch } from "vue";
import { message } from 'ant-design-vue'
export default {
    components: { GButton },
    props: {
        placeholder: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: ""
        },
        type: {
            type: Number,
            default: ""
        },
        value: {
            type: Number,
            default: ""
        },
        detail: {
            type: Object,
            default: null
        },
        doctorType: {
            type: String,
            default: 'local'
        }
    },
    setup(props, context) {
        const { proxy } = getCurrentInstance()
        let state = reactive({
            form: {
                hospital_id: '',
                hospital_name: '',
                hospital_service_id: '',
                hospital_service_name: '',
                doctor_id: '',
                doctor_name: '',
            },
            hospitals: [],
            services: [],
            doctors: [],
            visible: false,
            pickerValue: undefined
        })
        const hospitalChange = async (data, fn) => {
            state.form.hospital_id = data.id
            state.form.hospital_name = data.name
            if(props.type == 3) {
                state.doctors = await proxy.$api.getHospitalDoctor({hospital_id: data.id})
            }else {
                state.doctors = []
                if(props.doctorType == 'online') {
                    state.services = await proxy.$api.getHospitalDisease({hospital_id: data.id, is_page: 2})
                }else {
                    state.services = await proxy.$api.getHospitalOrganization({hospital_id: data.id, is_page: 2})
                }
            }
            state.form.hospital_service_id = ''
            state.form.hospital_service_name = ''
            state.form.doctor_id = ''
            state.form.doctor_name = ''
            if(fn) {
                fn()
            }
        }
        const serviceChange = async (data, fn) => {
            state.form.hospital_service_id = data.id
            state.form.hospital_service_name = data.name
            let form = {}
            if(props.doctorType == 'online') {
                form = {hospital_id: state.form.hospital_id, disease_id: data.id}
            }else {
                form = {hospital_id: state.form.hospital_id, organization_id: data.id}
            }
            state.doctors = await proxy.$api.getHospitalDoctor(form)
            state.form.doctor_id = ''
            state.form.doctor_name = ''
            if(fn) {
                fn()
            }
        }
        const doctorChange = (data, fn) => {
            state.form.doctor_id = data.id
            state.form.doctor_name = data.name
            if(fn) {
                fn()
            }
        }
        const handleVisibleChange = value => {
            if(props.type == 2) {
                if(!state.form.hospital_id) {
                    message.error('请先选择机构')
                    return
                }
            }
            state.visible = value
        }
        watch(() => [props.value, props.detail], async (newval, oldVal) => {
            if(newval[0]){
                state.form.hospital_id = newval[0]
                if(props.doctorType == 'online') {
                    state.services = await proxy.$api.getHospitalDisease({hospital_id:newval[0], is_page: 2})
                }else {
                    state.services = await proxy.$api.getHospitalOrganization({hospital_id: newval[0], is_page: 2})
                }
                state.doctors = []
                state.form.hospital_service_id = ''
                state.form.hospital_service_name = ''
                state.form.doctor_id = ''
                state.form.doctor_name = ''
                submit(false)
            }else if(newval[1]) {
                hospitalChange({
                    id: newval[1].hospital_id,
                    name: newval[1].hospital_name,
                }, () => {
                    serviceChange({
                        id: newval[1].service_id,
                        name: newval[1].service_name,
                    }, () => {
                        doctorChange({
                            id: newval[1].doctor_id,
                            name: newval[1].doctor_name,
                        }, () => {
                            state.pickerValue = setPickerValue()
                        })
                    })
                })
            }
        })
        onMounted(async () => {
            if(props.detail) {
                hospitalChange({
                    id: props.detail.hospital_id,
                    name: props.detail.hospital_name,
                }, () => {
                    serviceChange({
                        id: props.detail.service_id,
                        name: props.detail.service_name,
                    }, () => {
                        doctorChange({
                            id: props.detail.doctor_id,
                            name: props.detail.doctor_name,
                        }, () => {
                            state.pickerValue = setPickerValue()
                        })
                    })
                })
            }
            if(props.type != 2) {
                state.hospitals = await proxy.$api.getHospitalLists(0)
            }
        })
        function clear() {
            if(props.type == 2) {
                state.form.hospital_service_id = ''
                state.form.doctor_id = ''
                state.form.hospital_service_name = ''
                state.form.doctor_name = ''
                state.doctors = []
            }else {
                state.form = {
                    hospital_id: '',
                    hospital_service_id: '',
                    doctor_id: '',
                    hospital_name: '',
                    hospital_service_name: '',
                    doctor_name: '',
                }
                state.services = []
                state.doctors = []
            }
            submit(false)
        }
        const setPickerValue = () => {
            let text = ''
            if(state.form.hospital_id) {
                if(props.type != 2) {
                    text = `${state.form.hospital_name}`
                }
            }
            if(state.form.hospital_service_id) {
                text = `${text}${props.type != 2 ? '/' : ''}${state.form.hospital_service_name}`
            }
            if(state.form.doctor_id) {
                text = `${text}/${state.form.doctor_name}`
            }
            return text ? text : undefined
        }
        function submit(stateu = true) {
            state.pickerValue = setPickerValue()
            if(stateu){
                state.visible = false
            }
            context.emit('submit', state.form)
        }
        return {
            ... toRefs(state),
            hospitalChange,
            serviceChange,
            doctorChange,
            clear,
            submit,
            handleVisibleChange
        };
    },
};
</script>
<style lang="less" scoped>
.g-doctor {
    display: flex;

    .hospital-box {
        flex: 0 0 auto;
        width: 240px;

        .hospital {
            width: 100%;
            height: 40px;
            line-height: 20px;
            color: #b9bbc7;
            font-size: 16px;
            border-bottom: 2px solid #e8e8e8;
            padding: 10px 16px;
        }

        .hospital-active {
            border-bottom: 2px solid #3addb7;
        }

        .hospital-list {
            height: 308px;
            overflow-x: hidden;
            overflow-y: auto;

            .hospital-item {
                padding: 18px 16px;
                color: #65697b;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
            }

            .hospital-item-active {
                color: #252733;
                font-weight: bold;

                .point {
                    width: 6px;
                    height: 2px;
                    background: #3addb7;
                    margin-right: 16px;
                }
            }
        }
    }
}

.g-doctor-btn {
    text-align: center;
    padding: 24px 24px 0px;
}

.g-doctor-box {
    padding: 8px 11px;
    background: #F4F8FE;
    border-radius: 8px;

    .icon {
        width: 20px;
        height: 20px;
    }

    ::v-deep .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none;
    }
}
</style>