<template>
    <div class="g-header">
        <div class="title-wapper">
            <left-outlined @click="$router.back()" />
            <div class="title">{{ title }}</div>
            <slot name="title_content"></slot>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import { LeftOutlined } from "@ant-design/icons-vue";
export default {
    props: ["title"],
    components: {
        LeftOutlined,
    },
};
</script>
<style lang="less" scoped>
.g-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 96px;
    padding: 32px 40px;

    .title-wapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .title {
            color: #252733;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            margin-left: 20px;
        }
    }
}
</style>