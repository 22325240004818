<template>
    <global-menu open="1" :selected="selected" :isShowNew="false">
        <section class="page-contener">
            <global-header title="账单管理">
                <template #title_content>
                    <div class="title-content">{{ orderDetail.patient ? orderDetail.patient.name : '' }}</div>
                </template>
            </global-header>
            <div class="content-wapper">
                <div class="flex-between">
                    <global-title title="账单列表"></global-title>
                    <plus-circle-filled :style="{ fontSize: '48px', color: '#3addb7' }" v-if="orderDetail.status != 4" @click="showModal" />
                </div>
                <a-table class="mg-t-20" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :pagination="false" :loading="loading">
                    <template #bodyCell="{ column, record }">
                        <div v-if="column.dataIndex === 'status'">
                            <span :class="`status-${record.status}`">{{ record.status_label }}</span>
                        </div>
                        <div v-if="column.dataIndex === 'pay_money'">
                            
                            {{ record.status == 1 ? '-' : record.pay_money }}
                        </div>
                        <div v-if="column.dataIndex === 'pay_method'">
                            <span v-if="record.status == 2">{{ record.pay_method == 3 ? '现金' : record.pay_method == 4 ? '信用卡' : '线上' }}</span>
                            <span v-if="record.status == 1">-</span>
                        </div>
                        <div v-if="column.dataIndex === 'detail'">
                            <img @click="$common.toPage('OrderTreatBillDetail', { open: '1', selected, id: record.id, orderId: orderDetail.id })"
                                class="table-icon pointer" src="../../../assets/img/icon_detail.png" alt="" />
                        </div>
                        <div v-if="column.dataIndex === 'del'">
                            <img class="table-icon pointer" src="../../../assets/img/icon_delete_red.png" alt="" v-if="record.status == 1 && orderDetail.status != 4" @click="delOrder(record.id)" />
                        </div>
                    </template>
                </a-table>
                <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
            </div>
        </section>
    </global-menu>
    <global-dialog :isShow="isModalShow" title="添加账单" @onOk="submit" @onCancel="closeModal" :loading="modalLoading">
        <div class="dialog-modal">
            <div class="flex-between">
                <global-title title="收费条目"></global-title>
                <plus-circle-filled :style="{ fontSize: '48px', color: '#3addb7' }" @click="addInventory" />
            </div>
            <a-table :pagination="false" :scroll="{ y: 500 }" :columns="chargeColumns" :dataSource="createForm.inventory"
                class="mg-t-30">
                <template #summary>
                    <a-table-summary-row>
                        <a-table-summary-cell :col-span="3">账单合计：</a-table-summary-cell>
                        <a-table-summary-cell>
                            <a-typography-text> {{ totals.totalNum }}</a-typography-text>
                        </a-table-summary-cell>
                        <a-table-summary-cell>
                            <a-typography-text style="color: #ff504a">{{
                                totals.totalPrice
                            }}</a-typography-text>
                        </a-table-summary-cell>
                    </a-table-summary-row>
                </template>

                <template #bodyCell="{ column, record, index }">
                    <div v-if="column.dataIndex == 'operation'">
                        <img v-if="index == 0" class="table-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                        <img v-else @click="delInventory(index)" class="table-icon pointer"
                            src="../../../assets/img/icon_cancel_red.png" alt="" />
                    </div>
                    <div v-if="column.dataIndex == 'name'" class="flex-between">
                        <a-input v-model:value="record.name"></a-input>
                    </div>
                    <div v-if="column.dataIndex == 'money'">
                        <a-input v-model:value="record.money"></a-input>
                    </div>
                    <div v-if="column.dataIndex == 'num'">
                        <a-input v-model:value="record.num"></a-input>
                    </div>
                    <div v-if="column.dataIndex == 'total'">
                        {{ Number(record.money) * Number(record.num) }}
                    </div>
                </template>
            </a-table>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import Pagination from "@/components/Pagination.vue";
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { reactive, toRefs, computed, ref, onMounted, getCurrentInstance } from "vue";
import { useRoute } from 'vue-router'
import { Modal } from 'ant-design-vue'
export default {
    components: {
        GlobalMenu,
        GlobalHeader,
        GlobalTitle,
        GlobalDialog,
        PlusCircleFilled,
        Pagination
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const route = useRoute()
        const refPagination = ref()
        let state = reactive({
            orderDetail: {},
            tableData: [],
            isModalShow: false,
            selected: '1-1',
            tableScrollHeight: 0,
            loading: false,
            searchForm: {
                platform_order_type: 1,
                platform_order_id: '',
                page_size: 10,
                page: 1
            },
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            createForm: {
                platform_order_id: '',
                inventory: [
                    {
                        name: '',
                        money: '',
                        num: ''
                    }
                ]
            },
            modalLoading: false
        })
        let totals = computed(() => {
            let totalNum = 0
            let totalPrice = 0
            state.createForm.inventory.forEach(({ num, money }) => {
                totalNum += Number(num)
                totalPrice += Number(num) * Number(money)
            })

            return { totalNum, totalPrice }
        })
        const pageChange = data => {
            state.pagination.page = data.page
            getData()
        }
        const showModal = () => {
            state.isModalShow = true
        }
        const closeModal = () => {
            state.isModalShow = false
        }
        const addInventory = () => {
            state.createForm.inventory.push({
                name: "",
                money: '',
                num: '',
            })
        }
        const delInventory = (index) => {
            state.createForm.inventory.splice(index, 1);
        }
        const submit = () => {
            for(let i in state.createForm.inventory) {
                if(!state.createForm.inventory[i].name || !state.createForm.inventory[i].money || !state.createForm.inventory[i].num) {
                    proxy.$message.error('请输入必填项')
                    return
                }
            }
            state.modalLoading = true
            proxy.$httpUtil.post('/order/bill', state.createForm).then(res => {
                if(res.success) {
                    state.isModalShow = false
                    state.modalLoading = false
                    state.pagination.page = 1
                    refPagination.value.reset(1)
                    getData()
                }
            }).catch(err => {
                state.modalLoading = false
            })
        }
        const delOrder = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/order/bill/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const getData = async () => {
            state.loading = true
            state.searchForm.page = state.pagination.page
            let res = await proxy.$api.getOrderBill(state.searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        if(route.query.type == 'online') {
            state.selected = '1-2'
            state.searchForm.platform_order_type = 2
        }
        state.searchForm.platform_order_id = route.query.id
        state.createForm.platform_order_id = route.query.id
        onMounted(async () => {
            state.orderDetail = await proxy.$api.getOrderDetail(route.query.id)
            getData()
        })
        return {
            route,
            refPagination,
            ... toRefs(state),
            tableColumns: [
                {
                    dataIndex: "status",
                    title: "状态",
                },
                {
                    dataIndex: "money",
                    title: "应付金额(円)",
                },
                {
                    dataIndex: "pay_money",
                    title: "支付金额(円)",
                },
                {
                    dataIndex: "pay_method",
                    title: "支付方式",
                },
                {
                    dataIndex: "pay_at",
                    title: "支付时间",
                },
                {
                    dataIndex: "created_at",
                    title: "创建时间",
                },
                {
                    dataIndex: "detail",
                    title: "详情",
                    width: 80
                },
                {
                    dataIndex: "del",
                    title: "删除",
                    width: 80
                },
            ],
            chargeColumns: [
                {
                    dataIndex: "operation",
                    title: "",
                    width: 50,
                },
                {
                    dataIndex: "name",
                    title: "条目名称",
                },
                {
                    dataIndex: "money",
                    title: "单价（円）",
                    width: 150,
                },
                {
                    dataIndex: "num",
                    title: "数量",
                    width: 120,
                },
                {
                    dataIndex: "total",
                    title: "合计（円）",
                    width: 150,
                },
            ],
            totals,

            pageChange,
            showModal,
            closeModal,
            addInventory,
            delInventory,
            submit,
            delOrder
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;

    .title-content {
        color: #b6bbd7;
        font-size: 20px;
        line-height: 20px;
        margin-left: 40px;
    }

    .content-wapper {
        padding: 16px 36px 32px;
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }
}
.status-1 {
    color: #FFB03E;
}
.status-3 {
    color: #FF504A;
}
.dialog-modal {
    overflow: auto;

    .table-icon {
        width: 20px;
        height: 20px;
    }
}
</style>