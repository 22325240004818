<template>
    <global-dialog :isShow="isModalShow" title="客户留言" @onCancel="close" :isShowFooter="false" class="dialog-modal">
        <div class="content">
            <div ref="refScroll" class="warpper" @scroll="handScroll">
                <a-spin :spinning="loading">
                    <div class="item" :class="{'self': item.type == 2}" v-for="item in list" :key="item.id">
                        <div class="name">
                            {{ item.type == 1 ? name : '超级管理员' }}
                            <span class="date">{{ $moment(item.created_at).format('YYYY-MM-DD HH:mm') }}</span>
                        </div>
                        <div class="msg-content">{{ item.content }}</div>
                    </div>
                </a-spin>
            </div>
            <div class="warpper">
                <div class="title">留言框</div>
                <a-textarea
                    class="textarea"
                    v-model:value="sendForm.content"
                    placeholder="请输入您要回复的内容"
                />
                <div class="text-center mg-t-24">
                    <g-button type="primary" class="btn" @click="submit">发送</g-button>
                </div>
            </div>
        </div>
    </global-dialog>
</template>
<script>
import GlobalDialog from "@/components/GlobalDialog.vue"
import GButton from "@/components/GButton.vue"
import { reactive, toRefs, ref, nextTick, getCurrentInstance } from 'vue'
export default {
    components: { GlobalDialog, GButton },
    setup(props, context){
        const { proxy } = getCurrentInstance()
        const refScroll = ref()
        let state = reactive({
            loading: false,
            isModalShow: false,
            name: '',
            form: {
                order_from: 1,
                order_id: '',
                last_id: '',
                first_id: ''
            },
            list: [],
            sendForm: {
                order_from: 1,
                order_id: '',
                content: '',
                image: []
            },
            timer: null,
        })
        const submit = () => {
            if(!state.sendForm.content){
                proxy.$message.error('请输入要发送的内容')
                return
            }
            proxy.$httpUtil.post(`/message/instation`, state.sendForm).then(res => {
                if(res.success) {
                    state.sendForm.content = ''
                    state.sendForm.image = []
                    getList('first_id', state.list.length ? state.list[state.list.length - 1].id : 0)
                }
            })
        }
        const getList = async (type, id) => {
            state.form.last_id = ''
            state.form.first_id = ''
            state.form[type] = id
            if(state.timer) {
                clearTimeout(state.timer)
                state.timer = null
            }
            if(type == 'last_id') {
                state.loading = true
            }
            let res = await proxy.$api.getMessage(state.form)
            state.list = type == 'last_id' ? [... res, ... state.list] : [...state.list, ... res]
            // if(state.list.length && type == 'first_id') {
                
            // }
            state.loading = false
            state.timer = setTimeout(() => {
                getList('first_id', state.list.length ? state.list[state.list.length - 1].id : 0)
            }, 10000);
        }
        const show = async (data, type) => {
            console.log(data)
            state.form.order_id = data.id
            state.form.order_from = type
            state.sendForm.order_id = data.id
            state.sendForm.order_from = type
            state.name = type == 1 ? data.patient.name : type == 2 ? data.contact_info.name : ''
            state.list = []
            state.isModalShow = true
            await getList('last_id', 0)
            nextTick(() => {
                refScroll.value.scrollTop = 99999999
            })
        }
        const close = () => {
            if(state.timer) {
                clearTimeout(state.timer)
                state.timer = null
            }
            state.isModalShow = false
        }
        const handScroll = () => {
            if(refScroll.value.scrollTop == 0) {
                getList('last_id', state.list[state.list.length - 1].id)
            }
        }
        return {
            refScroll,
            ... toRefs(state),
            close,
            show,
            handScroll,
            submit
        }
    }
}
</script>
<style lang="less" scoped>
    .textarea {
        height: calc(100% - 64px - 24px - 48px - 24px);
        font-size: 16px;
        resize: none;
    }
    .content {
        width: calc(100% + 80px);
        margin-left: -40px;
        height: 70vh;
        display: flex;
        .warpper {
            width: 50%;
            height: 100%;
            padding: 0 24px;
            overflow-y: auto;
            .title {
                line-height: 64px;
                font-size: 18px;
                font-weight: 600;
                color: #202020;
            }
            &:first-child {
                background-color: #FAFAFA;
                border-radius: 0 0 0 32px;
            }
            .item {
                margin-top: 24px;
                &:last-child {
                    margin-bottom: 24px;
                }
                .name {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 600;
                    color: #202020;
                    .date {
                        font-size: 14px;
                        color: #999;
                        margin-left: 24px;
                    }
                }
                .msg-content {
                    margin-top: 16px;
                    border-radius: 8px;
                    padding: 12px;
                    word-break: break-all;
                    line-height: 24px;
                    font-size: 16px;
                    color: #333;
                    background-color: #fff;
                    border: 1px solid #666;
                }
                &.self {
                    .name {
                        justify-content: flex-end;
                    }
                    .msg-content {
                        background-color: rgba(58, 221, 183, .2);
                        color: #3ADDB7;
                        border: 1px solid #3ADDB7;
                    }
                }
            }
        }
    }
</style>