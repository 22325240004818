<template>
    <global-menu open="7" selected="7-7" pageTitle="报告设置" :isShowNew="false">
        <section class="page-contener">
            <div class="flex-align-center">
                <div class="item-wapper">
                    <img class="item-bg" src="../../../assets/img/report_set_bg_green.png" alt="" />
                    <div class="content-wapper flex-between">
                        <div>
                            <div class="title">在日就诊 报告设置</div>
                            <g-button type="white" class="mg-t-40" @click="showModal(1)">设置</g-button>
                        </div>
                        <img class="icon" src="../../../assets/img/report_set_icon_green.png" alt="" />
                    </div>
                </div>
                <div class="item-wapper">
                    <img class="item-bg" src="../../../assets/img/report_set_bg_blue.png" alt="" />
                    <div class="content-wapper flex-between">
                        <div>
                            <div class="title">远程问诊 报告设置</div>
                            <g-button type="white" style="color: #47b9ff" class="mg-t-40" @click="showModal(2)">设置</g-button>
                        </div>
                        <img class="icon" src="../../../assets/img/report_set_icon_blue.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
    </global-menu>
    <global-dialog :width="560" :isShow="isShow" title="报告设置" :loading="loading" @onCancel="closeModal" @onOk="submit">
        <div class="dialog-wapper">
            <div class="flex-between mg-t-16">
                <global-title title="模块标题"></global-title>
                <plus-circle-filled @click="addTitles" :style="{ fontSize: '48px', color: '#3addb7' }" />
            </div>
            <div class="title-wapper flex-align-center" v-for="item, index in titleArray" :key="'title' + index">
                <img v-if="index == 0" class="icon" src="../../../assets/img/icon_cancel_grey.png" alt="">
                <img v-else @click="deleteTitles(index)" class="icon" src="../../../assets/img/icon_cancel_red.png" alt="">
                <g-input placeholder="模块标题" v-model="item.title"></g-input>
            </div>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GButton from "@/components/GButton.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GInput from "@/components/GInput.vue";
import { ref } from "vue";
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { getCurrentInstance } from "vue"
export default {
    components: {
        GlobalMenu,
        GButton,
        GlobalDialog,
        GlobalTitle,
        PlusCircleFilled,
        GInput
    },
    setup() {
        const { proxy } = getCurrentInstance()
        let id = ref(0)
        let isShow = ref(false);
        let loading = ref(false)
        let titleArray = ref([])
        async function showModal(data) {
            titleArray.value = []
            let res = await proxy.$api.getBusinessConfigReport(data)
            for(let i in res) {
                titleArray.value.push({
                    title: res[i]
                })
            }
            id.value = data
            isShow.value = true
        }
        const submit = () => {
            let report = []
            for(let i in titleArray.value) {
                if(titleArray.value[i].title) {
                    report.push(titleArray.value[i].title)
                }else {
                    proxy.$message.error('请输入必填项')
                    return
                }
            }
            loading.value = true
            proxy.$httpUtil.put(`/manage/business_config_report/${id.value}`, {report}).then(res => {
                if(res.success) {
                    proxy.$message.success('设置成功')
                    loading.value = false
                    closeModal()
                }
            }).catch(() => {
                loading.value = false
            })
        }
        function closeModal() {
            isShow.value = false;
            loading.value = false
        }
        function addTitles() {
            titleArray.value.push({
                title: ''
            })
        }
        function deleteTitles(index) {
            titleArray.value.splice(index, 1)
        }
        return {
            loading,
            isShow,
            titleArray,
            showModal,
            closeModal,
            addTitles,
            deleteTitles,
            submit
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: calc(100vh - 78px - 64px - 32px);
    overflow: auto;
    margin-top: 12px;
    padding: 16px;
    background: none;

    .item-wapper {
        position: relative;
        // width: calc((100% - 32px) / 2);
        width: 784px;
        height: 216px;
        border-radius: 16px;
        margin-right: 32px;

        .item-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .content-wapper {
            padding: 0px 20px 0px 48px;

            .title {
                color: #ffffff;
                font-size: 40px;
                line-height: 48px;
                font-weight: bold;
                position: relative;
                z-index: 1;
            }

            .icon {
                width: 320px;
                height: 100%;
                position: relative;
                z-index: 1;
            }
        }
    }
}

.dialog-wapper {
    .title-wapper {
        margin-top: 28px;

        .icon {
            width: 24px;
            height: 24px;
            margin-right: 24px;
        }
    }

    .title-wapper:first-child {
        margin-top: 26px;
    }
}
</style>