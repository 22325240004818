<template>
    <div class="g-date">
        <div class="flex-between">
            <img class="icon" src="../assets/img/icon_date.png" alt="">
            <a-month-picker inputReadOnly style="width: 100%" :value="date" :placeholder="placeholder" @change="onChange" :allowClear="false">
            </a-month-picker>
        </div>
    </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted, watch } from "vue";
export default {
    props: {
        placeholder: String,
        value: String
    },
    setup(props, context) {
        const { proxy } = getCurrentInstance()
        function onChange(date, dateString) {
            console.log(11)
            context.emit('change', dateString)
        }
        const date = ref(undefined)
        // watch(() => props.value, (_val, oldVal) => {
        //     console.log(_val)
        //     date.value = _val ? proxy.$moment(_val) : undefined
        // })
        onMounted(() => {
            console.log(props.value)
            date.value = props.value ? proxy.$moment(props.value) : undefined
        })
        return {
            date,
            onChange
        }
    }
}
</script>
<style lang="less" scoped>
.g-date {
    width: 100%;
    padding: 8px 11px;
    border-radius: 8px;
    background: #F4F8FE;
    display: inline-block;

    .icon {
        width: 20px;
        height: 20px;
    }

    ::v-deep .ant-picker-focused {
        box-shadow: none
    }

    ::v-deep .ant-picker-suffix {
        display: none;
    }
}
</style>