<template>
  <global-menu open="4" selected="4-1">
    <section class="page-contener">
      <global-header title="商品审核">
        <template #default>
          <span class="status-text">待审核</span>
        </template>
      </global-header>
      <div class="content-wapper">
        <global-title title="基本信息"></global-title>
        <div class="mg-t-24">
          <div class="key">商品名称：</div>
          <div class="value mg-l-32">商品名称占位文本</div>
        </div>
        <div class="mg-t-24">
          <div class="key">商品分类：</div>
          <div class="value mg-l-32">分类名称</div>
        </div>
        <div class="mg-t-24 flex-wapper">
          <div class="key">商品规格：</div>
          <div style="width: 100%" class="mg-l-32">
            <div class="value-wapper">
              <div class="value">200 ml/瓶</div>
              <div class="price">12,000 円</div>
            </div>
            <div class="value-wapper">
              <div class="value">200 ml/瓶</div>
              <div class="price">12,000 円</div>
            </div>
            <div class="value-wapper">
              <div class="value">200 ml/瓶</div>
              <div class="price">12,000 円</div>
            </div>
          </div>
        </div>
        <div class="mg-t-24 flex-wapper">
            <div class="key">商品封面：</div>
            <img class="image mg-l-32" src="" alt="">
        </div>
        <global-title title="商品详情" style="margin-top:50px"></global-title>
        <div class="info"></div>
      </div>
      <div class="btn-wapper">
        <g-button>通过</g-button>
        <g-button class="mg-l-40" type="error" @click="showModal">拒绝</g-button>
        <g-button class="mg-l-40"  ghost>预览</g-button>
      </div>
    </section>
  </global-menu>
  <global-dialog :width="560" :isShow="isShow" title="拒绝" @onCancel="closeModal">
    <div >
        <global-title class="mg-t-16" title="拒绝原因"></global-title>
        <a-textarea class="mg-t-24" placeholder="正文" :rows="6"></a-textarea>
    </div>
  </global-dialog>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu.vue";
import GlobalHeader from "../../../components/GlobalHeader.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GButton from "../../../components/GButton.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import {ref} from "vue"
export default {
  components: {
    GlobalMenu,
    GlobalHeader,
    GlobalTitle,
    GButton,
    GlobalDialog
  },
  setup() {
    let isShow=ref(false)


    function showModal(){
        isShow.value=true
    }
    function closeModal(){
        isShow.value=false
    }
    return {
        isShow,
        showModal,
        closeModal
    };
  },
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 40px);
  position: relative;
  .status-text {
    color: #ffb03e;
    font-size: 20px;
    line-height: 20px;
  }
  .content-wapper {
    width: 600px;
    height: calc(100% - 96px - 128px);
    margin: 16px auto;
    overflow: auto;
    .flex-wapper {
      display: flex;
    }
    .key {
      display: inline-block;
      flex: 0 0 auto;
      color: #b6bbd7;
      font-size: 16px;
      line-height: 22px;
      padding-left: 12px;
    }
    .value-wapper {
      display: inline-block;
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 2px solid #e8e8e8;
      margin-top: 14px;
    }
    .value-wapper:first-child{
        margin-top: 0px;
    }
    .value-wapper:last-child{
        border-bottom: none;
    }
    .value {
      display: inline-block;
      color: #252733;
      font-size: 16px;
      line-height: 22px;
    }
    .price {
      display: inline-block;
      color: #ff504a;
      font-size: 16px;
      line-height: 22px;
      margin-left: 32px;
    }
    .image {
      width: 256px;
      height: 224px;
      border-radius: 8px;
      background: #e8e8e8;
    }
    .info {
      background: #e8e8e8;
      width: 100%;
      height: 220px;
      margin-top: 24px;
      border-radius: 8px;
    }
  }
  .btn-wapper {
    height: 128px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>