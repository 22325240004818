<template>
    <global-menu open="3" selected="3-2" :isShowNew="false">
        <section class="page-contener">
            <global-header title="账单管理">
                <template #title_content>
                    <div class="title-content">{{ '机构名字' }}</div>
                </template>
            </global-header>
            <div class="content-wapper">
                <div class="flex-between">
                    <global-title title="账单列表"></global-title>
                </div>
                <a-table :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                    <template #bodyCell="{ column, record }">
                        <div v-if="column.dataIndex === 'data6'">
                            <span v-if="record.data6 == 2">待支付</span>
                            <a :style="{'color': record.data6 == 1 ? '#FF5733' : '#3addb7'}" v-else @click="showModal(record)">{{ record.data6 == 1 ? '待结算' : '已结算' }}</a>
                        </div>
                    </template>
                </a-table>
                <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
            </div>
        </section>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" :isShowFooter="modalType == 'settle'" :title="modalTitle" @onOk="submit" @onCancel="closeModal">
        <a-row :gutter="24">
            <a-col class="mg-t-26" :span="13">
                <global-title title="结算对象" />
                <g-input class="mg-t-24" placeholder="结算对象" :disable="modalType == 'info'"></g-input>
            </a-col>
            <a-col class="mg-t-26" :span="11">
                <global-title title="结算金额" />
                <g-input class="mg-t-24" placeholder="结算金额" suffix="円" :disable="modalType == 'info'"></g-input>
            </a-col>
        </a-row>
        <global-title class="mg-t-24" title="结算内容" />
        <a-textarea
            class="mg-t-24"
            placeholder="内容"
            :auto-size="{ minRows: 3, maxRows: 6 }"
            :disabled="modalType == 'info'"
        />
        <div class="mg-t-24">
            <global-title title="结算凭证" />
            <div class="file-list mg-t-24">
                <!-- <div class="item"></div> -->
                <div class="item" v-if="modalType != 'info'">
                    <a-upload name="avatar" class="avatar-uploader" :show-upload-list="false" :customRequest="fileChange">
                        <div class="flex-center pointer">
                            <div class="text-center">
                                <plus-circle-outlined :style="{ fontSize: '24px', color: '#3addb7' }" /><br>
                                点击上传
                            </div>
                        </div>
                    </a-upload>
                </div>
            </div>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import Pagination from "@/components/Pagination.vue";
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { reactive, toRefs, computed, ref, onMounted, getCurrentInstance } from "vue";
import { useRoute } from 'vue-router'
import { Modal, message } from 'ant-design-vue'
export default {
    components: {
        GlobalMenu,
        GlobalHeader,
        GlobalTitle,
        GlobalDialog,
        PlusCircleFilled,
        Pagination
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        let state = reactive({
            tabActive: 0,
            tableData: [
                {
                    data1: '公告',
                    data2: '123',
                    data3: '对象',
                    data4: '时间',
                    data5: '时间',
                    data6: '1',
                    data7: '2023-12-12 10:11:12',
                },
                {
                    data1: '公告',
                    data2: '123',
                    data3: '对象',
                    data4: '时间',
                    data5: '时间',
                    data6: '2',
                    data7: '2023-12-12 10:11:12',
                },
                {
                    data1: '公告',
                    data2: '123',
                    data3: '对象',
                    data4: '时间',
                    data5: '时间',
                    data6: '3',
                    data7: '2023-12-12 10:11:12',
                },
            ],
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
            isModalShow: false,
            modalType: 'settle',
            modalTitle: '结算',
            fileData: null,
            imgUrl: ''
        })
        const fileChange = info => {
            state.fileData = info.file
            state.imgUrl = proxy.$common.getFileUrl(info.file)
        }
        const showModal = (data) => {
            if(data.data6 == 1) {
                state.modalType = 'settle'
                state.modalTitle = '结算'
            }else {
                state.modalType = 'info'
                state.modalTitle = '结算信息'
            }
            state.isModalShow = true
        }
        const closeModal = () => {
            state.isModalShow = false
        }
        const tabChange = index => {
            state.tabActive = index
        }
        onMounted(() => {
            tabChange(0)
        })
        return {
            searchList: [
                {
                    type: "date",
                    model: "date",
                    placeholder: "选择统计年月",
                    icon: require("@/assets/img/icon_date.png"),
                }
            ],
            tableColumns: [
                {
                    dataIndex: "data1",
                    title: "订单编号",
                },
                {
                    dataIndex: "data2",
                    title: "机构",
                },
                {
                    dataIndex: "data3",
                    title: "订单金额(円)",
                },
                {
                    dataIndex: "data4",
                    title: "服务费(円)",
                },
                {
                    dataIndex: "data5",
                    title: "结算金额(円)",
                },
                {
                    dataIndex: "data6",
                    title: "状态",
                },
                {
                    dataIndex: "data7",
                    title: "创建时间",
                }
            ],
            refPagination,
            ... toRefs(state),

            tabChange,
            showModal,
            fileChange,
            closeModal
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;

    .title-content {
        color: #b6bbd7;
        font-size: 20px;
        line-height: 20px;
        margin-left: 40px;
    }

    .content-wapper {
        padding: 16px 36px 32px;
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }
}
.status-1 {
    color: #FFB03E;
}
.status-3 {
    color: #FF504A;
}
.dialog-modal {
    overflow: auto;

    .table-icon {
        width: 20px;
        height: 20px;
    }
}
</style>