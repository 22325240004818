<template>
  <global-menu open="2" selected="2-5">
    <section class="page-contener">
      <global-header title="新增 文章"></global-header>
      <div class="content-wapper">
        <global-title title="基础信息"></global-title>
        <g-input class="mg-t-24" :icon="require('../../../assets/img/icon_order.png')" placeholder="文章标题"></g-input>
        <g-input class="mg-t-24" :icon="require('../../../assets/img/icon_classifcation.png')" placeholder="分类"></g-input>
        <global-title class="mg-t-48" title="正文内容"></global-title>
      </div>
      <div class="btn-wapper">
        <g-button>确定</g-button>
        <g-button class="mg-l-40" ghost @click="showModal">预览</g-button>
        <g-button class="mg-l-40" type="default" ghost @click="$router.back">关闭</g-button>
      </div>
    </section>
  </global-menu>
  <global-dialog :width="680" :isShow="isModalShow">
    <div class="dialog-wapper">
        <div class="title">文章标题占位文本文章标题占位文本文章标题占位文本文章标题占位文本</div>
        <div class="flex-between">
            <div class="tag">文章分类名称</div>
            <div class="tag">2022-06-07  14:19</div>
        </div>
        <div class="content mg-t-32">文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本。</div>
        <div class="image"></div>
        <div class="content" style="margin-top:40px">文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本，文章正文内容占位文本。</div>
    </div>
    <template #footer>
        <g-button type="default" ghost class="btn" @click="closeModal"
            >取消</g-button
          >
    </template>
  </global-dialog>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu.vue";
import GlobalHeader from "../../../components/GlobalHeader.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import GButton from "../../../components/GButton.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import {ref} from "vue"
export default {
  components: {
    GlobalMenu,
    GlobalHeader,
    GlobalTitle,
    GInput,
    GButton,
    GlobalDialog,
  },
  setup(){
    let isModalShow = ref(false)

    function showModal(){
        isModalShow.value=true
    }
    function closeModal(){
        isModalShow.value=false
    }
    return {
        isModalShow,

        showModal,
        closeModal
    }
  }
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 40px);
  position: relative;
  .content-wapper {
    height: calc(100% - 96px - 128px);
    width: 880px;
    overflow: auto;
    margin: 16px auto;
  }
  .btn-wapper {
    height: 128px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.dialog-wapper{
    padding: 40px 0px;
    .title{
        color: #252733;
        font-size: 24px;
        line-height: 36px;
        font-weight: 800;
    }
    .tag{
        color: #B9BBC7;
        font-size: 18px;
        line-height: 36px;
        margin-top: 8px;
    }
    .content{
        color: #65697B;
        font-size: 20px;
        line-height: 36px;
    }
    .image{
        background: #E8E8E8;
        border-radius: 8px;
        height: 264px;
        margin-top: 24px;
    }
}
</style>