<template>
    <a-config-provider :locale="locale" :transformCellText="transformCellText">
        <router-view v-wechat-title="$route.meta.title"></router-view>
    </a-config-provider>
</template>

<script>
import { ConfigProvider } from "ant-design-vue"
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
export default {
    name: 'App',
    components: { ConfigProvider },
    data() {
        return {
            locale: zhCN,
        }
    },
    methods: {
        transformCellText({ text }) {
            if (typeof text == 'string') {
                return text ? text : "-";
            } else {
                return text && text.length > 0 ? text : "-";
            }

        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
