<template>
  <global-menu open="2" selected="2-3">
    <section class="page-contener">
      <global-header title="商品分类名称"></global-header>
      <div class="content-wapper" ref="content_wapper">
        <div class="flex-between">
          <global-title title="商品列表"></global-title>
          <plus-circle-filled
            :style="{ fontSize: '48px', color: '#3addb7' }"
            @click="addGoods"
          />
        </div>
        <a-table
        class="mg-t-16"
        :columns="tableColumns"
        :dataSource="tableData"
        :loading="loading"
        :pagination="false"
        :scroll="{y:tableHeight}"
        >
            <template #bodyCell="{ column,index }">
                <div v-if="column.dataIndex==='org'" class="flex-align-center">
                    <img v-if="index==0" class="table-icon" src="../../../assets/img/icon_cancel_grey.png" alt="">
                    <img @click="deleteGoods(index)" v-else class="table-icon" src="../../../assets/img/icon_cancel_red.png" alt="">
                    <g-select placeholder="供货机构"></g-select>
                </div>
                <div v-if="column.dataIndex==='name'">
                    <g-select placeholder="商品名称"></g-select>
                </div>
                <div v-if="column.dataIndex==='spic'">
                    <g-select placeholder="规格"></g-select>
                </div>
            </template>
        </a-table>
        <div class="btn-wapper">
        <g-button>保存</g-button>
        <g-button class="mg-l-40" type="default" ghost @click="$router.back">关闭</g-button>
      </div>
      </div>
    </section>
  </global-menu>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu.vue";
import GlobalHeader from "../../../components/GlobalHeader.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GSelect from "../../../components/GSelect.vue"
import GButton from "../../../components/GButton.vue"
import { PlusCircleFilled } from "@ant-design/icons-vue";
import {ref,onMounted,getCurrentInstance} from "vue"
export default {
  components: {
    GlobalMenu,
    GlobalHeader,
    GlobalTitle,
    PlusCircleFilled,
    GSelect,
    GButton
  },
  setup(){
    let {proxy} = getCurrentInstance()
    let tableColumns = ref([
        {
            dataIndex:'org',
            title:'供货机构'
        },
        {
            dataIndex:'name',
            title:'商品名称'
        },
        {
            dataIndex:'spic',
            title:'规格'
        },
    ])
    let tableData=ref([{}])
    let loading = ref(false)
    let tableHeight=ref()

    function addGoods(){
        tableData.value.push({})
    }
    function deleteGoods(index){
        tableData.value.splice(index,1)
    }
    onMounted(()=>{
        proxy.$nextTick(()=>{
            console.log(proxy.$refs.content_wapper.offsetHeight)
            tableHeight.value=proxy.$refs.content_wapper.offsetHeight - 48 - 32 - 55
        })
    })
    return {
        tableColumns,
        tableData,
        tableHeight,
        loading,

        addGoods,
        deleteGoods
    }
  }
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 40px);
  position: relative;
  .content-wapper {
    height: calc(100% - 96px - 128px);
    padding: 16px 40px;
    .table-icon{
        width: 24px;
        height: 24px;
        margin-right: 40px;
    }
    
  }
  .btn-wapper {
    height: 128px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>