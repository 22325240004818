import http from './httpUtil'

const getHospitalLists = type => {
    return new Promise((resolve) => {
        http.get('/manage/hospital_lists', {type}).then(res => {
            resolve(res.data.hospitals)
        })
    })
}
const getHospitalOrganization = data => {
    return new Promise((resolve) => {
        http.get(`/manage/hospital_organization`, data).then(res => {
            resolve(res.data.organization)
        })
    })
}
const getHospitalDisease = data => {
    return new Promise((resolve) => {
        http.get(`/manage/hospital_disease`, data).then(res => {
            resolve(res.data.disease)
        })
    })
}
const getHospitalDoctor = data => {
    return new Promise((resolve) => {
        http.get(`/manage/hospital_doctor_lists`, data).then(res => {
            resolve(res.data.doctors)
        })
    })
}
const getDoctor = data => {
    return new Promise((resolve) => {
        http.get(`/manage/hospital_doctor`, data).then(res => {
            resolve(res.data.doctors)
        })
    })
}
const getOrderAppoint = data => {
    return new Promise((resolve) => {
        http.get(`/order/appoint_local`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getOrderOnline = data => {
    return new Promise((resolve) => {
        http.get(`/order/appoint_online`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getOrderDetail = id => {
    return new Promise((resolve) => {
        http.get(`/order/appoint/${id}`).then(res => {
            resolve(res.data.appoint_order)
        })
    })
}
const getOrderBill = data => {
    return new Promise((resolve) => {
        http.get(`/order/bill`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getBillDetail = id => {
    return new Promise((resolve) => {
        http.get(`/order/bill/${id}`).then(res => {
            resolve(res.data)
        })
    })
}
const getReportDetail = id => {
    return new Promise((resolve) => {
        http.get(`/order/appoint_report/${id}`).then(res => {
            resolve(res.data)
        })
    })
}
const getOrderDrug = data => {
    return new Promise((resolve) => {
        http.get(`/order/drug`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getOrderConsult = data => {
    return new Promise((resolve) => {
        http.get(`/order/consult`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getOrderConsultDetail = id => {
    return new Promise((resolve) => {
        http.get(`/order/consult/${id}`).then(res => {
            resolve(res.data.order)
        })
    })
}
const getStatistics = data => {
    return new Promise((resolve) => {
        http.get(`/order/appoint_tab_statistics`, data).then(res => {
            resolve(res.data.items)
        })
    })
}
const getDrugStatistics = data => {
    return new Promise((resolve) => {
        http.get(`/order/drug_tab_statistics`, data).then(res => {
            resolve(res.data.items)
        })
    })
}
const getDrug = () => {
    return new Promise((resolve) => {
        http.get(`/manage/drug`).then(res => {
            resolve(res.data.drug)
        })
    })
}
const getTradeCooperative = data => {
    return new Promise((resolve) => {
        http.get(`/manage/trade_cooperative`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getTradeNonCooperative = data => {
    return new Promise((resolve) => {
        http.get(`/manage/trade_non_cooperative`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getTradePlatform = data => {
    return new Promise((resolve) => {
        http.get(`/manage/trade_platform`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getTradeAdded = data => {
    return new Promise((resolve) => {
        http.get(`/manage/trade_added_service`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getArticle = data => {
    return new Promise((resolve) => {
        http.get(`/manage/article`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getNotice = data => {
    return new Promise((resolve) => {
        http.get(`/manage/notice`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getConfig = data => {
    return new Promise((resolve) => {
        http.get(`/manage/config`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getOrganization = data => {
    return new Promise((resolve) => {
        http.get(`/manage/organization`, data).then(res => {
            resolve(res.data.organization)
        })
    })
}
const getDisease = data => {
    return new Promise((resolve) => {
        http.get(`/manage/disease`, data).then(res => {
            resolve(res.data.disease)
        })
    })
}
const getHospitalRecommend = data => {
    return new Promise((resolve) => {
        http.get(`/manage/recommend_hospital`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getDoctorRecommend = data => {
    return new Promise((resolve) => {
        http.get(`/manage/recommend_docker`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getProjectRecommend = data => {
    return new Promise((resolve) => {
        http.get(`/manage/recommend_project_category`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getProjectRecommendDetail = id => {
    return new Promise((resolve) => {
        http.get(`/manage/recommend_project_category/${id}`).then(res => {
            resolve(res.data.hospital)
        })
    })
}
const getProjectBindRecommend = id => {
    return new Promise((resolve) => {
        http.get(`/manage/recommend_project_category_bind/${id}`).then(res => {
            resolve(res.data.project)
        })
    })
}
const getHospitalRecommendDetail = id => {
    return new Promise((resolve) => {
        http.get(`/manage/recommend_hospital/${id}`).then(res => {
            resolve(res.data.hospital)
        })
    })
}
const getDoctorRecommendDetail = id => {
    return new Promise((resolve) => {
        http.get(`/manage/recommend_docker/${id}`).then(res => {
            resolve(res.data.doctor)
        })
    })
}
const getTradeSettleDetail = id => {
    return new Promise((resolve) => {
        http.get(`/manage/trade_settle/${id}`).then(res => {
            resolve(res.data.trade_settle)
        })
    })
}
const getOrderDrupDetail = id => {
    return new Promise((resolve) => {
        http.get(`/order/drug/${id}`).then(res => {
            resolve(res.data.goods_order)
        })
    })
}

const getOrderFile = id => {
    return new Promise((resolve) => {
        http.get(`/order/appoint_order_file/${id}`).then(res => {
            resolve(res.data)
        })
    })
}
const getHospitalDatabase = id => {
    return new Promise((resolve) => {
        http.get(`/manage/hospital_database/${id}`).then(res => {
            resolve(res.data.database)
        })
    })
}
const getHospitalNonDoctor = id => {
    return new Promise((resolve) => {
        http.get(`/manage/hospital_non_doctor/${id}`).then(res => {
            resolve(res.data.doctor)
        })
    })
}
const getBusinessConfigReport = id => {
    return new Promise((resolve) => {
        http.get(`/manage/business_config_report/${id}`).then(res => {
            resolve(res.data.item.report)
        })
    })
}
const getProjectByHospital = hospital_id => {
    return new Promise((resolve) => {
        http.get(`/manage/project_list_hospital`, {hospital_id}).then(res => {
            resolve(res.data.project)
        })
    })
}
const getMessage = data => {
    return new Promise((resolve) => {
        http.get(`/message/instation`, data).then(res => {
            resolve(res.data.messages)
        })
    })
}
const getWorkStatistics = () => {
    return new Promise((resolve) => {
        http.get(`/manage/workbench_statistics`).then(res => {
            resolve(res.data)
        })
    })
}
const getWorkList = () => {
    return new Promise((resolve) => {
        http.get(`/manage/workbench`).then(res => {
            resolve(res.data)
        })
    })
}
const getService = () => {
    return new Promise((resolve) => {
        http.get(`/manage/added_service`).then(res => {
            resolve(res.data)
        })
    })
}
const getServiceList = () => {
    return new Promise((resolve) => {
        http.get(`/manage/added_service_list`).then(res => {
            resolve(res.data.added_service)
        })
    })
}
const getServicePerson = () => {
    return new Promise((resolve) => {
        http.get(`/manage/service_person`).then(res => {
            resolve(res.data)
        })
    })
}
const getServicePersonList = added_service_id => {
    return new Promise((resolve) => {
        http.get(`/manage/service_person_list_added_service`, {added_service_id}).then(res => {
            resolve(res.data.service_person)
        })
    })
}
const getServiceOrder = data => {
    return new Promise((resolve) => {
        http.get(`/order/added_service_order`, data).then(res => {
            resolve(res.data)
        })
    })
}
const getServiceDetail = id => {
    return new Promise((resolve) => {
        http.get(`/order/added_service_order/${id}`).then(res => {
            resolve(res.data.added_service_order)
        })
    })
}
const getServiceReport = id => {
    return new Promise((resolve) => {
        http.get(`/order/added_service_order_report/${id}`).then(res => {
            resolve(res.data.added_service_order)
        })
    })
}
const getAppointIndex = data => {
    return new Promise((resolve) => {
        http.get(`/order/appoint_index`, data).then(res => {
            resolve(res.data)
        })
    })
}

export default {
    getAppointIndex,
    getServiceReport,
    getServiceDetail,
    getServicePersonList,
    getServiceOrder,
    getServiceList,
    getServicePerson,
    getService,
    getTradeAdded,
    getWorkList,
    getWorkStatistics,
    getMessage,
    getProjectByHospital,
    getProjectBindRecommend,
    getProjectRecommend,
    getProjectRecommendDetail,
    getDoctorRecommendDetail,
    getDoctorRecommend,
    getHospitalRecommendDetail,
    getHospitalRecommend,
    getBusinessConfigReport,
    getHospitalNonDoctor,
    getHospitalDatabase,
    getDoctor,
    getOrderDrupDetail,
    getDrugStatistics,
    getOrderFile,
    getDisease,
    getOrganization,
    getConfig,
    getNotice,
    getArticle,
    getTradePlatform,
    getTradeNonCooperative,
    getTradeSettleDetail,
    getTradeCooperative,
    getDrug,
    getOrderConsultDetail,
    getStatistics,
    getOrderConsult,
    getOrderDrug,
    getReportDetail,
    getBillDetail,
    getOrderDetail,
    getOrderBill,
    getOrderOnline,
    getOrderAppoint,
    getHospitalDisease,
    getHospitalOrganization,
    getHospitalDoctor,
    getHospitalLists
}