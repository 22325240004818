<template>
    <global-menu :open="open" :selected="selected" :isShowNew="false">
        <section class="page-contener">
            <global-header :title="pageTitle">
                <template #title_content>
                    <div class="status" v-if="pageType == 'detail'">
                        <span v-if="detail && detail.status == 1">待支付</span>
                        <span v-if="detail && detail.status == 2">待发货</span>
                        <span v-if="detail && detail.status == 3">已完成</span>
                    </div>
                </template>
            </global-header>
            <div class="content-wapper">
                <div class="warpper">
                    <div class="left-wapper">
                        <div class="flex-between">
                            <global-title title="药品列表"></global-title>
                            <plus-circle-filled :style="{ fontSize: '48px', color: '#3addb7' }" @click="addDrug" v-if="pageType == 'create'" />
                        </div>
                        <a-table class="mg-t-19" :columns="tableColumns" :dataSource="drug" :loading="loading"
                            :pagination="false">
                            <template #summary>
                                <a-table-summary-row>
                                    <a-table-summary-cell :col-span="2">商品合计：</a-table-summary-cell>
                                    <a-table-summary-cell>
                                        <a-typography-text>{{ total.num }}</a-typography-text>
                                    </a-table-summary-cell>
                                    <a-table-summary-cell>
                                        <a-typography-text style="color: #ff504a">{{ total.goods }}</a-typography-text>
                                    </a-table-summary-cell>
                                </a-table-summary-row>
                                <a-table-summary-row>
                                    <a-table-summary-cell :col-span="3">邮费：</a-table-summary-cell>
                                    <a-table-summary-cell>
                                        <a-typography-text style="color: #ffb03e">{{ createForm.freight_money }}</a-typography-text>
                                    </a-table-summary-cell>
                                </a-table-summary-row>
                                <a-table-summary-row>
                                    <a-table-summary-cell :col-span="3">订单总价：</a-table-summary-cell>
                                    <a-table-summary-cell>
                                        <a-typography-text style="color: #ff504a">{{ total.order }}</a-typography-text>
                                    </a-table-summary-cell>
                                </a-table-summary-row>
                                <a-table-summary-row v-if="pageType == 'detail'">
                                    <a-table-summary-cell :col-span="3">开药日期</a-table-summary-cell>
                                    <a-table-summary-cell>
                                        <a-typography-text>{{ detail ? detail.created_at : '-' }}</a-typography-text>
                                    </a-table-summary-cell>
                                </a-table-summary-row>
                            </template>
                            <template #bodyCell="{ column, record, index }">
                                <div class="flex-align-center" v-if="column.dataIndex === 'info' && pageType == 'create'">
                                    <img v-if="index == 0" class="table-icon pointer" src="../../../assets/img/icon_cancel_grey.png"
                                        alt="" />
                                    <img v-else class="table-icon pointer" src="../../../assets/img/icon_cancel_red.png" alt="" @click="deleteDrug(index)" />
                                    <a-select
                                        class="mg-l-16"
                                        size="large"
                                        style="width: 240px;"
                                        v-model:value="record.drug_name"
                                        showSearch
                                        :options="drugOptions"
                                        @search="value => selectSearch(value, 'drug_name', index)"
                                        @change="value => selectChange(value, index)"
                                        >
                                    </a-select>
                                    <a-select
                                        class="mg-l-16"
                                        size="large"
                                        style="width: 140px;"
                                        v-model:value="record.drug_spec_name"
                                        showSearch
                                        :options="record.options"
                                        @search="value => selectSearch(value, 'drug_spec_name', index)"
                                        
                                        >
                                    </a-select>
                                    <a-tooltip v-if="record.isNameNew || record.isSpecNew">
                                        <template #title>加入药品库</template>
                                        <DatabaseOutlined class="add-icon" @click="joinDrug(record)" />
                                    </a-tooltip>
                                </div>
                                <div v-if="column.dataIndex === 'info' && pageType == 'detail'">
                                    <div>{{ record.drug_name }}</div>
                                    <div>{{ record. drug_spec_name}}</div>
                                </div>
                                <div class="flex-align-center" v-if="column.dataIndex === 'price' && pageType == 'create'">
                                    <a-input size="large" v-model:value="record.drug_money"></a-input>
                                </div>
                                <div class="flex-align-center" v-if="column.dataIndex === 'price' && pageType == 'detail'">
                                    {{ record.drug_money }}
                                </div>
                                <div class="flex-align-center" v-if="column.dataIndex === 'num' && pageType == 'create'">
                                    <a-input size="large" v-model:value="record.num"></a-input>
                                </div>
                                <div class="flex-align-center" v-if="column.dataIndex === 'num' && pageType == 'detail'">
                                    {{ record.num }}
                                </div>
                                <div class="flex-align-center" v-if="column.dataIndex === 'total'">
                                    {{ record.drug_money && record.num ? record.drug_money * record.num : '-' }}
                                </div>
                            </template>
                        </a-table>
                        <!-- <div class="flex-between mg-t-32">
                            <div class="pd-l-16">开药日期：</div>
                            <div class="pd-r-16">2022-05-08 14:51</div>
                        </div> -->
                    </div>
                    <div class="right-wapper">
                        <global-title title="联系人信息"></global-title>
                        <div class="user-info">
                            <a-row :gutter="24">
                                <a-col :span="12">
                                    <div class="label">联系人</div>
                                    <div class="flex-align-centet value" v-if="pageType == 'create'">
                                        {{ detail && detail.patient ? detail.patient.name : '-' }}
                                    </div>
                                    <div class="flex-align-centet value" v-if="pageType == 'detail'">
                                        {{ detail && detail.contact_info ? detail.contact_info.name : '-' }}
                                    </div>
                                </a-col>
                                <a-col :span="12">
                                    <div class="label">联系电话</div>
                                    <div class="value" v-if="pageType == 'create'">{{ detail && detail.patient ? detail.patient.mobile : '-' }}</div>
                                    <div class="value" v-if="pageType == 'detail'">{{ detail && detail.contact_info ? detail.contact_info.mobile : '-' }}</div>
                                </a-col>
                            </a-row>
                        </div>
                        <global-title class="mg-t-48" title="收货信息"></global-title>
                        <div class="user-info">
                            <a-row :gutter="24">
                                <a-col :span="12">
                                    <div class="label">收货人</div>
                                    <div class="flex-align-centet value">
                                        {{ detail && detail.address ? detail.address.name : '-' }}
                                    </div>
                                </a-col>
                                <a-col :span="12">
                                    <div class="label">联系电话</div>
                                    <div class="value">{{ detail && detail.address ? detail.address.mobile : '-' }}</div>
                                </a-col>
                                <a-col :span="24" class="mg-t-32">
                                    <div class="label">详细地址</div>
                                    <div class="value">{{ detail && detail.address ? detail.address.address : '-' }}</div>
                                </a-col>
                                <a-col :span="24" class="mg-t-32" v-if="detail && detail.address && detail.address.idcard_file">
                                    <div class="label">清关证件</div>
                                    <div class="value flex">
                                        <div class="img" v-for="item, index in detail.idcard_file" :key="index">
                                            <img class="pointer" :src="item" alt="" @click="previewImg(item)" v-if="detail.idcard_file[index]">
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
                </div>
                <div class="pay-wapper flex-between" style="margin-top: 80px" v-if="pageType == 'detail' && detail && (detail.status == 2 || detail.status == 3)">
                    <div style="width: 50%">
                        <div class="title">支付信息</div>
                        <a-row class="mg-t-32">
                            <a-col :span="12">
                                <g-row-text title="支付金额：">{{ detail.pay_money }} 円</g-row-text>
                            </a-col>
                            <a-col :span="12">
                                <g-row-text title="支付方式：">{{ detail.pay_method }}</g-row-text>
                            </a-col>
                            <a-col :span="12" class="mg-t-24">
                                <g-row-text title="优惠金额：">{{ detail.rate_money }} 円</g-row-text>
                            </a-col>
                            <a-col :span="12" class="mg-t-24">
                                <g-row-text title="支付时间：">{{ detail.pay_at }}</g-row-text>
                            </a-col>
                        </a-row>
                    </div>
                    <div style="width: 50%" v-if="detail && (detail.status == 3)">
                        <div class="title">物流信息</div>
                        <a-row class="mg-t-32">
                            <a-col :span="12">
                                <g-row-text title="物流公司：">{{detail && detail.logistics ? detail.logistics.name : '' }}</g-row-text>
                            </a-col>
                            <a-col :span="12">
                                <g-row-text title="发货时间：">{{detail && detail.logistics ? detail.logistics.deliver_at : '-' }}</g-row-text>
                            </a-col>
                            <a-col :span="12" class="mg-t-24">
                                <g-row-text title="运单号：">{{detail && detail.logistics ? detail.logistics.number : '-' }}</g-row-text>
                            </a-col>
                            <!-- <a-col :span="12" class="mg-t-24">
                                <g-row-text title="收货时间：">-</g-row-text>
                            </a-col> -->
                        </a-row>
                    </div>
                </div>
            </div>
            <div class="btn-wapper">
                <g-button @click="showModal('postage')" v-if="pageType == 'create' || (pageType == 'detail' && detail && detail.status == 1)" type="warn">{{pageType == 'create' ? '设置' : '调整'}}邮费</g-button>
                <g-button v-if="pageType == 'create'" class="mg-l-40" @click="submit" :loading="submitLoading">提交</g-button>
                <g-button @click="showModal('logistics')" v-if="pageType == 'detail' && detail && detail.status == 2">发货</g-button>
                <g-button type="error" class="mg-l-40" @click="cancel" v-if="pageType == 'detail' && detail && detail.status == 1">取消订单</g-button>
            </div>
        </section>
    </global-menu>
    <global-dialog :isShow="isModalShow" :title="modalTitle" @onOk="dialogSubmit" @onCancel="closeModal" :width="560" :loading="submitLoading"
        class="dialog-wapper">
        <div v-if="modalType === 'postage'">
            <global-title title="邮费设置"></global-title>
            <div class="flex-align-center mg-t-24">
                <g-input :icon="require('@/assets/img/icon_cost.png')" v-model="createForm.freight_money"></g-input>
                <span class="unit">円</span>
            </div>
        </div>
        <div v-if="modalType === 'logistics'">
            <global-title title="物流信息"></global-title>
            <g-input class="mg-t-24" :icon="require('@/assets/img/icon_delivery_car.png')" placeholder="EMS（默认）"
                :disable="true"></g-input>
            <g-input class="mg-t-24" :icon="require('@/assets/img/icon_delivery_number.png')" placeholder="运单号" v-model="logisticsForm.logistics.number"></g-input>
        </div>
    </global-dialog>
    <preview :url="previewUrl" v-if="isPreviewShow" @hide="isPreviewShow = false" />
</template>
<script>
import $router from "@/router";
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GButton from "@/components/GButton.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GInput from "@/components/GInput.vue";
import GRowText from "@/components/GRowText.vue";
import GSelect from "@/components/GSelect.vue";
import preview from "@/components/preview.vue";
import { reactive, toRefs, computed, ref, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { PlusCircleFilled, DatabaseOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
export default {
    components: {
        GlobalMenu,
        GlobalHeader,
        GlobalTitle,
        GButton,
        GlobalDialog,
        GInput,
        GRowText,
        PlusCircleFilled,
        GSelect,
        DatabaseOutlined,
        preview
    },
    setup() {
        const { proxy } = getCurrentInstance()
        let state = reactive({
            loading: false,
            pageType: '',
            detail: null,
            createForm: {
                consult_order_id: '',
                freight_money: 0,
                drug: []
            },
            drug: [
                {
                    drug_name: '',
                    drug_money: '',
                    num: '',
                    drug_spec_name: '',
                    options: [],
                    isNameNew: false,
                    isSpecNew: false
                }
            ],
            logisticsForm: {
                order_id: '',
                logistics: {
                    name: 'EMS',
                    number: ''
                }
            },
            drugOptions: [],
            isModalShow: false,
            modalTitle: '',
            modalType: '',
            submitLoading: false,
            isPreviewShow: false,
            previewUrl: ''
        })
        let open = ref();
        let selected = ref();
        let pageTitle = ref();
        pageTitle.value = useRoute().query.title;
        open.value = useRoute().query.open;
        selected.value = useRoute().query.selected;

        let total = computed(() => {
            let num = 0
            let goods = 0
            let order = 0
            for(let i in state.drug) {
                num += Number(state.drug[i].num)
                goods += Number(state.drug[i].num) * Number(state.drug[i].drug_money)
            }
            order = Number(goods) + Number(state.createForm.freight_money)
            return {num, goods, order}
        })
        const previewImg = url => {
            state.previewUrl = url
            state.isPreviewShow = true
        }
        const cancel = () => {
            Modal.confirm({
                title: '确定取消该订单吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.get(`/order/drug_cancel/${state.detail.id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`取消成功`)
                            $router.back()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const dialogSubmit = () => {
            if(state.modalType == 'postage') {
                if(state.pageType == 'detail') {
                    state.submitLoading = true
                    proxy.$httpUtil.put(`/order/drug_set_freight_money/${state.detail.id}`, {
                        freight_money: state.createForm.freight_money
                    }).then(async res => {
                        if(res.success) {
                            state.submitLoading = false
                            proxy.$message.success(`设置成功`)
                            closeModal()
                            state.detail = await proxy.$api.getOrderDrupDetail(useRoute().query.id)
                        }
                    }).catch(() => {
                        state.submitLoading = false
                    })
                }else {
                    closeModal()
                }
            }else {
                if(!state.logisticsForm.logistics.number) {
                    proxy.$message.error('请输入运单号')
                    return
                }
                state.logisticsForm.order_id = state.detail.id
                state.submitLoading = true
                proxy.$httpUtil.post(`/order/drug_confirm_logistics`, state.logisticsForm).then(res => {
                    if(res.success) {
                        state.submitLoading = false
                        proxy.$message.success(`发货成功`)
                        closeModal()
                        $router.back()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const submit = () => {
            state.createForm.drug = []
            for(let i in state.drug) {
                if(!state.drug[i].drug_name || !state.drug[i].drug_money || !state.drug[i].num || !state.drug[i].drug_spec_name) {
                    proxy.$message.error('请完善药品信息')
                    return
                }
                state.createForm.drug.push({
                    drug_name: state.drug[i].drug_name,
                    drug_money: state.drug[i].drug_money,
                    num: state.drug[i].num,
                    drug_spec_name: state.drug[i].drug_spec_name
                })
            }
            state.createForm.consult_order_id = state.detail.id
            state.submitLoading = true
            proxy.$httpUtil.post(`/order/drug`, state.createForm).then(res => {
                if(res.success) {
                    state.submitLoading = false
                    proxy.$message.success(`开药成功`)
                    $router.back()
                }
            }).catch(() => {
                state.submitLoading = false
            })
        }
        const showModal = type => {
            state.modalType = type
            if(type == 'postage') {
                state.modalTitle = '邮费'
            }else if(type == 'logistics') {
                state.modalTitle = '物流设置'
            }
            state.isModalShow = true
        }
        function closeModal() {
            state.isModalShow = false
            state.submitLoading = false
            state.logisticsForm.logistics.number = ''
        }
        const joinDrug = data => {
            state.loading = true
            if(data.isNameNew) {
                proxy.$httpUtil.post(`/manage/drug`, {
                    name: data.drug_name,
                    specification: [data.drug_spec_name]
                }).then(res => {
                    if(res.success) {
                        state.loading = false
                        proxy.$message.success(`加入成功`)
                        data.isNameNew = false
                        data.isSpecNew = false
                        state.drug = [... state.drug]
                        getDrugOption()
                    }
                })
            }else {
                if(data.isSpecNew) {
                    let id = ''
                    let specification = []
                    for(let i in state.drugOptions) {
                        if(state.drugOptions[i].value == data.drug_name) {
                            id = state.drugOptions[i].id
                        }
                    }
                    for(let i in data.options) {
                        specification.push(data.options[i].value)
                    }
                    specification.push(data.drug_spec_name)
                    proxy.$httpUtil.put(`/manage/drug/${id}`, {
                        name: data.drug_name,
                        specification
                    }).then(res => {
                        if(res.success) {
                            state.loading = false
                            proxy.$message.success(`加入成功`)
                            data.isNameNew = false
                            data.isSpecNew = false
                            state.drug = [... state.drug]
                            getDrugOption()
                        }
                    })
                }
            }
        }
        const addDrug = () => {
            state.drug.push({
                drug_name: '',
                drug_money: '',
                num: '',
                drug_spec_name: '',
                options: [],
                isNameNew: false,
                isSpecNew: false
            })
        }
        const deleteDrug = index => {
            state.drug.splice(index, 1);
        }
        const selectSearch = (value, type, index) => {
            if(value) {
                state.drug[index][type] = value
                if(type == 'drug_name') {
                    state.drug[index].options = []
                    state.drug[index].drug_spec_name = ''
                    state.drug[index].isNameNew = true
                }else if(type == 'drug_spec_name') {
                    state.drug[index].isSpecNew = true
                }
                state.drug = [... state.drug]
            }
        }
        const selectChange = (value, index) => {
            state.drug[index].isNameNew = false
            state.drug[index].isSpecNew = false
            state.drug[index].options = []
            let option = []
            for(let i in state.drugOptions) {
                if(state.drugOptions[i].value == value) {
                    option = state.drugOptions[i].specification_text.split(' | ')
                }
            }
            for(let i in option) {
                state.drug[index].options.push({
                    label: option[i],
                    value: option[i]
                })
            }
        }
        const getDrugOption = async () => {
            state.drugOptions = await proxy.$api.getDrug()
            for(let i in state.drugOptions) {
                state.drugOptions[i].label = state.drugOptions[i].name
                state.drugOptions[i].value = state.drugOptions[i].name
            }
        }
        onMounted(async () => {
            state.pageType = useRoute().query.type
            if(state.pageType == 'create') {
                state.detail = await proxy.$api.getOrderConsultDetail(useRoute().query.id)
                getDrugOption()
            }else if(state.pageType == 'detail') {
                state.detail = await proxy.$api.getOrderDrupDetail(useRoute().query.id)
                state.drug = state.detail.inventory
                state.createForm.freight_money = state.detail.freight_money
                state.detail.idcard_file = JSON.parse(state.detail.address.idcard_file)
                console.log(state.detail.idcard_file)
            }
        })
        return {
            ... toRefs(state),
            pageTitle,
            tableColumns: [
                {
                    dataIndex: "info",
                    title: "药品信息",
                },
                {
                    dataIndex: "price",
                    title: "单价（円）",
                    width: 120
                },
                {
                    dataIndex: "num",
                    title: "数量",
                    width: 100
                },
                {
                    dataIndex: "total",
                    title: "合计（円）",
                    width: 180
                },
            ],
            open,
            selected,
            total,
            previewImg,
            showModal,
            closeModal,
            addDrug,
            deleteDrug,
            selectSearch,
            selectChange,
            joinDrug,
            submit,
            dialogSubmit,
            cancel
        };
    },
};
</script>
<style lang="less" scoped>
.status {
    width: calc(100% - 130px);
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    color: #FFB03E;
}
.add-icon {
    font-size: 18px;
    cursor: pointer;
    color: #3addb7;
    margin-left: 8px;
}
.page-contener {
    height: calc(100vh - 40px);
    position: relative;

    .title-content {
        color: #b6bbd7;
        font-size: 20px;
        line-height: 20px;
        margin-left: 40px;
    }

    .status-text {
        color: #3addb7;
        font-size: 20px;
        line-height: 20px;
    }

    .content-wapper {
        padding: 16px 40px;
        height: calc(100% - 200px);
        overflow: auto;
        box-sizing: border-box;
        .warpper {
            display: flex;
            justify-content: space-between;
        }

        .left-wapper {
            flex: 0 0 auto;
            width: calc((100% - 40px) / 3 * 2);

            .table-icon {
                width: 18px;
                height: 18px;
            }
        }

        .right-wapper {
            flex: 0 0 auto;
            width: calc((100% - 40px) / 3);

            .user-info {
                background: #f3fbf9;
                border: 1px solid #3addb7;
                border-radius: 8px;
                margin-top: 16px;
                padding: 32px;

                .label {
                    color: #b6bbd7;
                    font-size: 14px;
                    line-height: 18px;
                    display: block;
                    padding: 0;
                    border-radius: 0;
                    text-align: left;
                    font-weight: normal;
                }

                .value {
                    color: #252733;
                    font-size: 18px;
                    line-height: 26px;
                    margin-top: 12px;
                    .img {
                        width: calc(50% - 24px);
                        height: 120px;
                        margin-right: 48px;
                        &:last-child {
                            margin-right: 0;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .icon {
                    flex: 0 0 auto;
                    width: 20px;
                    height: 20px;
                    margin-left: 16px;
                }
            }

            .user-info:first-child {
                background: #f9f9f9;
                border: 1px solid #d9d9d9;
            }
        }

        .pay-wapper {
            border-top: 1px solid #e8e8e8;
            padding-top: 46px;

            .title {
                color: #3addb7;
                font-size: 16px;
            }
        }
    }

    .btn-wapper {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.dialog-wapper {
    .unit {
        color: #252733;
        font-size: 16px;
        line-height: 22px;
        margin-left: 16px;
    }
}
</style>