<template>
    <div class="g-search">
        <a-form>
            <a-row>
                <a-col :span="5" v-for="(item, index) in searchList" :key="index">
                    <g-input v-model="form[item.model]" v-if="item.type == 'input'" class="form-item-data" :placeholder="item.placeholder" :icon="item.icon"></g-input>
                    <global-doctor v-if="item.type == 'doctor'" :doctorType="item.doctorType" :placeholder="item.placeholder" :icon="item.icon" @submit="doctorSubmit" />
                    <g-select v-if="item.type == 'select'" v-model="form[item.model]" :placeholder="item.placeholder" :options="item.options" :icon="item.icon" />
                    <g-date v-if="item.type == 'date'" :placeholder="item.placeholder" @change="value => dateChange(value, item.model)"></g-date>
                    <g-month v-if="item.type == 'month'" :placeholder="item.placeholder" @change="value => monthChange(value, item.model)"></g-month>
                </a-col>
                <a-col :span="btnSpan">
                    <div class="text-right">
                        <g-button type="primary" @click="search">检 索</g-button>
                    </div>
                </a-col>
            </a-row>
        </a-form>
    </div>
</template>
<script>
import { toRefs, ref, computed, watch } from "vue";
import GlobalDoctor from "../components/GlobalDoctor.vue";
import GInput from "../components/GInput.vue";
import GSelect from "../components/GSelect.vue";
import GDate from "../components/GDate.vue";
import GMonth from "../components/GMonth.vue";
import GButton from "../components/GButton.vue";
export default {
    components: {
        GlobalDoctor,
        GInput,
        GSelect,
        GDate,
        GButton,
        GMonth
    },
    props: {
        searchList: {
            type: Object,
            default: () => {
                return [];
            },
        },
        searchForm: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    setup(props, context) {
        console.log(props.searchList.length);
        let btnSpan = computed(() => {
            let searchLength = props.searchList.length;
            if (searchLength % 5 == 0) {
                return 24;
            } else if (searchLength % 5 == 1) {
                return 19;
            } else if (searchLength % 5 == 2) {
                return 14;
            } else if (searchLength % 5 == 3) {
                return 9;
            } else if (searchLength % 5 == 4) {
                return 4;
            }
        });
        let form = ref({})
        watch(() => props.searchForm, (_val, oldVal) => {
            form.value = _val
        })
        const dateChange = (value, type) => {
            form.value[type] = value
        }
        const monthChange = (value, type) => {
            form.value[type] = value
        }
        const doctorSubmit = data => {
            form.value['hospital_id'] = data['hospital_id']
            form.value['hospital_service_id'] = data['hospital_service_id']
            form.value['doctor_id'] = data['doctor_id']
        }
        function search() {
            context.emit("search", form.value);
        }
        return {
            btnSpan,
            form,
            doctorSubmit,
            search,
            dateChange,
            monthChange
        };
    },
};
</script>
<style lang="less" scoped>
.g-search {
    background: white;
    border-radius: 8px;
    padding: 16px;

    .icon {
        width: 20px;
        height: 20px;
    }

    .date-wapper {
        padding-left: 11px;
        background: #F4F8FE;
        border-radius: 8px;
    }
}

::v-deep .ant-col-5 {
    padding-right: 16px;
}

::v-deep .ant-popover-message-title {
    padding-left: 0px;
}</style>