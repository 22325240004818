<template>
    <global-menu open="5" selected="5-2">
        <section class="page-contener">
            <global-header title="机构详情">
                <template #default>
                    <span class="date-text">{{ detail.created_at }}</span>
                </template>
            </global-header>
            <div class="content-wapper">
                <div class="flex-between">
                    <global-title title="机构信息"></global-title>
                    <img class="edit-img" src="../../../assets/img/icon_edit_cyan.png" alt=""
                        @click="showModal('编辑其他机构')" />
                </div>

                <div class="info-wapper">
                    <a-row>
                        <a-col :span="12">
                            <div class="label">机构名称</div>
                            <div class="value">{{ detail.name }}</div>
                        </a-col>
                        <a-col :span="12">
                            <div class="label">联系电话</div>
                            <div class="value">{{ detail.mobile }}</div>
                        </a-col>
                        <a-col :span="12" class="mg-t-32">
                            <div class="label">机构地址</div>
                            <div class="value">
                                {{ detail.address }}
                            </div>
                        </a-col>
                        <a-col :span="12" class="mg-t-32">
                            <div class="label">联系邮箱</div>
                            <div class="value">{{ detail.email }}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="mg-t-48 flex-wapper">
                    <div class="item-wapper">
                        <div class="flex-between">
                            <global-title title="关联科室"></global-title>
                            <plus-circle-filled :style="{ fontSize: '48px', color: '#3addb7' }"
                                @click="showModal('关联新科室')" />
                        </div>
                        <a-table class="mg-t-16" :pagination="false" :columns="departColumns" :dataSource="departData">
                            <template #bodyCell="{ column, record, index }">
                                <div v-if="column.dataIndex === 'operation'">
                                    <img class="table-icon pointer" src="../../../assets/img/icon_cancel_red.png"
                                        @click="deleteTable('depart', record.id, index)" alt="" />
                                </div>
                            </template>
                        </a-table>
                    </div>
                    <div class="item-wapper">
                        <div class="flex-between">
                            <global-title title="关联病症"></global-title>
                            <plus-circle-filled :style="{ fontSize: '48px', color: '#3addb7' }"
                                @click="showModal('关联新病症')" />
                        </div>
                        <a-table class="mg-t-16" :pagination="false" :columns="diseaseColumns" :dataSource="diseaseData">
                            <template #bodyCell="{ column, record, index }">
                                <div v-if="column.dataIndex === 'operation'">
                                    <img class="table-icon pointer" src="../../../assets/img/icon_cancel_red.png"
                                        @click="deleteTable('disease', record.id, index)" alt="" />
                                </div>
                            </template>
                        </a-table>
                    </div>
                    <div class="item-wapper">
                        <div class="flex-between">
                            <global-title title="关联医生"></global-title>
                            <div style="height: 48px"></div>
                            <!-- <plus-circle-filled :style="{ fontSize: '48px', color: '#3addb7' }"
                                @click="showModal('关联新医生')" /> -->
                        </div>
                        <a-table class="mg-t-16" :pagination="false" :columns="doctorColumns" :dataSource="doctorData">
                            <!-- <template #bodyCell="{ column, record, index }">
                                <div v-if="column.dataIndex === 'operation'">
                                    <img v-if="index == 0" class="table-icon" src="../../../assets/img/icon_cancel_grey.png"
                                        alt="" />
                                    <img v-else class="table-icon pointer" src="../../../assets/img/icon_cancel_red.png"
                                        @click="deleteTable('doctor', record.id, index)" alt="" />
                                </div>
                            </template> -->
                        </a-table>
                    </div>
                </div>
            </div>
            <div class="btn-wapper">
                <g-button ghost @click="showModal('成为合作机构')">成为合作机构</g-button>
                <g-button type="default" class="mg-l-40" ghost @click="$router.back">关闭</g-button>
            </div>
        </section>
    </global-menu>
    <global-dialog :width="560" :isShow="isShow" :title="modalTitle" @onCancel="closeModal" @onOk="submit" :loading="submitLoading">
        <div v-if="modalTitle == '成为合作机构'">
            <global-title class="mg-t-16" title="机构信息"></global-title>
            <g-input class="mg-t-24" :icon="require('../../../assets/img/icon_address.png')" placeholder="机构地址" v-model="joinForm.address"></g-input>
            <g-input class="mg-t-24" :icon="require('../../../assets/img/icon_phone.png')" placeholder="联系电话" v-model="joinForm.mobile"></g-input>
            <g-input class="mg-t-24" :icon="require('../../../assets/img/icon_email.png')" placeholder="联系邮箱" v-model="joinForm.email"></g-input>
            <global-title class="mg-t-48" title="Pad 信息"></global-title>
            <g-input class="mg-t-24" :icon="require('../../../assets/img/icon_pad.png')" placeholder="Pad ID" v-model="joinForm.device_code"></g-input>
        </div>
        <div v-if="modalTitle == '关联新科室'">
            <global-title class="mg-t-16" title="科室信息"></global-title>
            <g-select class="mg-t-24" :icon="require('../../../assets/img/icon_department.png')" placeholder="选择科室" :options="organizationList" v-model="organizationId"></g-select>
        </div>
        <div v-if="modalTitle == '关联新病症'">
            <global-title class="mg-t-16" title="病症信息"></global-title>
            <g-select class="mg-t-24" :icon="require('../../../assets/img/icon_hospital.png')" placeholder="选择病症" :options="diseaseList" v-model="diseaseId"></g-select>
        </div>
        <div v-if="modalTitle == '关联新医生'">
            <global-title class="mg-t-16" title="医生信息"></global-title>
            <g-select class="mg-t-24" :icon="require('../../../assets/img/icon_user.png')" placeholder="选择医生"></g-select>
        </div>
        <div class="dialog-wapper" v-if="modalTitle == '编辑其他机构'">
            <global-title class="mg-t-16" title="机构信息"></global-title>
            <g-input v-model="editForm.name" class="mg-t-24" :icon="require('../../../assets/img/icon_hospital.png')"
                placeholder="机构名称"></g-input>
            <div class="tip">以下为非必填</div>
            <g-input v-model="editForm.address" class="mg-t-24" :icon="require('../../../assets/img/icon_address.png')"
                placeholder="机构地址"></g-input>
            <g-input v-model="editForm.mobile" class="mg-t-24" :icon="require('../../../assets/img/icon_phone.png')"
                placeholder="联系电话"></g-input>
            <g-input v-model="editForm.email" class="mg-t-24" :icon="require('../../../assets/img/icon_email.png')"
                placeholder="联系邮箱"></g-input>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu";
import GlobalHeader from "../../../components/GlobalHeader";
import GlobalTitle from "../../../components/GlobalTitle";
import GButton from "../../../components/GButton";
import GSelect from "../../../components/GSelect";
import GlobalDialog from "../../../components/GlobalDialog";
import GInput from "../../../components/GInput"
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router"
import { Modal } from "ant-design-vue";
export default {
    components: {
        GlobalMenu,
        GlobalHeader,
        GlobalTitle,
        PlusCircleFilled,
        GButton,
        GlobalDialog,
        GInput,
        GSelect
    },
    setup() {
        const route = useRoute()
        let { proxy } = getCurrentInstance()
        let state = reactive({
            organizationList: [],
            organizationId: '',
            diseaseList: [],
            diseaseId: '',
            editForm: {
                name: undefined,
                mobile: undefined,
                email: undefined,
                address: undefined,
            },
            joinForm: {
                device_code: undefined,
                mobile: undefined,
                email: undefined,
                address: undefined,
            },
            submitLoading: false
        })
        let departColumns = ref([
            {
                dataIndex: "operation",
                title: "",
                width: 64,
            },
            {
                dataIndex: "name",
                title: "科室名称",
            },
        ]);
        let departData = ref([]);
        let diseaseColumns = ref([
            {
                dataIndex: "operation",
                title: "",
                width: 64,
            },
            {
                dataIndex: "name",
                title: "病症名称",
            },
        ]);
        let diseaseData = ref([]);
        let doctorColumns = ref([
            // {
            //     dataIndex: "operation",
            //     title: "",
            //     width: 64,
            // },
            {
                dataIndex: "name",
                title: "医生名称",
            },
        ]);
        let doctorData = ref([]);
        let isShow = ref(false)
        let modalTitle = ref()
        let detail = ref({})//信息
        const pickerChange = (value, type) => {
            console.log(value)
        }
        function submit() {
            if (modalTitle.value == "关联新科室") {
                if(!state.organizationId) {
                    proxy.$message.error('请先选择科室')
                    return
                }
                state.submitLoading = true
                proxy.$httpUtil.post(`/manage/hospital_organization`, {
                    hospital_id: detail.value.id,
                    organization_id: state.organizationId
                }).then(async res => {
                    proxy.$message.success('绑定成功')
                    departData.value = await proxy.$api.getHospitalOrganization({hospital_id: detail.value.id, is_page: 2})
                    closeModal()
                }).catch(() => {
                    state.submitLoading = false
                })
            } else if (modalTitle.value == "关联新病症") {
                if(!state.diseaseId) {
                    proxy.$message.error('请先选择病症')
                    return
                }
                state.submitLoading = true
                proxy.$httpUtil.post(`/manage/hospital_disease`, {
                    hospital_id: detail.value.id,
                    disease_id: state.diseaseId
                }).then(async res => {
                    proxy.$message.success('绑定成功')
                    diseaseData.value = await proxy.$api.getHospitalDisease({hospital_id: detail.value.id, is_page: 2})
                    closeModal()
                }).catch(() => {
                    state.submitLoading = false
                })
                closeModal()
            } else if (modalTitle.value == "关联新医生") {
                doctorData.value.push({})
            } else if(modalTitle.value == '编辑其他机构') {
                if(!state.editForm.name) {
                    proxy.$message.error('请填写机构名称')
                    return
                }
                state.submitLoading = true
                proxy.$httpUtil.put(`/manage/hospital/${detail.value.id}`, state.editForm).then(res => {
                    proxy.$message.success('编辑成功')
                    closeModal()
                    getDetail()
                }).catch(() => {
                    state.submitLoading = false
                })
            }else if (modalTitle.value == '成为合作机构') {
                if(!state.joinForm.device_code || !state.joinForm.mobile || !state.joinForm.email || !state.joinForm.address) {
                    proxy.$message.error('请填写完整')
                    return
                }
                state.submitLoading = true
                proxy.$httpUtil.put(`/manage/hospital_join/${detail.value.id}`, state.joinForm).then(res => {
                    proxy.$message.success('加入成功')
                    proxy.$common.replace('OrganizationIndex')
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        function deleteTable(type, id, index) {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    if (type == 'depart') {
                        proxy.$httpUtil.delete(`/manage/hospital_organization/${id}`).then(res => {
                            if(res.success) {
                                proxy.$message.success(`删除成功`)
                                departData.value.splice(index, 1)
                                return Promise.resolve()
                            }
                        })
                    } else if (type == 'disease') {
                        proxy.$httpUtil.delete(`/manage/hospital_disease/${id}`).then(res => {
                            if(res.success) {
                                proxy.$message.success(`删除成功`)
                                diseaseData.value.splice(index, 1)
                                return Promise.resolve()
                            }
                        })
                    } else if (type == 'doctor') {
                        doctorData.value.splice(index, 1)
                    }
                }
            })
            
        }
        function showModal(title) {
            state.organizationId = ''
            state.diseaseId = ''
            isShow.value = true
            modalTitle.value = title
            state.editForm = {
                name: detail.value.name,
                mobile: detail.value.mobile,
                email: detail.value.email,
                address: detail.value.address,
            }
            state.joinForm = {
                device_code: undefined,
                mobile: detail.value.mobile,
                email: detail.value.email,
                address: detail.value.address,
            }
        }
        function closeModal() {
            isShow.value = false
            state.submitLoading = false
        }
        function getDetail() {
            proxy.$httpUtil.get(`/manage/hospital/${route.query.id}`).then(res => {
                detail.value = res.data.hospital
                
            })
        }
        //获取医生列表
        function getDoctorList() {
            proxy.$httpUtil.get(`/manage/hospital_doctor/${id}`).then(res => {
                // doctorData.value=res.data.doctor.data
            })
        }
        onMounted(async () => {
            getDetail()
            state.organizationList = await proxy.$api.getOrganization()
            state.diseaseList = await proxy.$api.getDisease()
            departData.value = await proxy.$api.getHospitalOrganization({hospital_id: route.query.id, is_page: 2})
            diseaseData.value = await proxy.$api.getHospitalDisease({hospital_id: detail.value.id, is_page: 2})
            doctorData.value = await proxy.$api.getHospitalDoctor({hospital_id: detail.value.id})
        })
        return {
            ... toRefs(state),
            departColumns,
            departData,
            diseaseColumns,
            diseaseData,
            doctorColumns,
            doctorData,
            isShow,
            modalTitle,
            detail,

            submit,
            deleteTable,
            showModal,
            closeModal,
            pickerChange
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: calc(100vh - 40px);
    position: relative;

    .date-text {
        color: #b6bbd7;
        font-size: 20px;
    }

    .content-wapper {
        height: calc(100% - 96px - 128px);
        padding: 16px 40px;
        overflow: auto;

        .edit-img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .info-wapper {
            margin-top: 16px;
            background: #f9f9f9;
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            padding: 32px;

            .label {
                color: #b6bbd7;
                font-size: 14px;
                line-height: 18px;
                display: block;
                padding: 0;
                border-radius: 0;
                text-align: left;
                font-weight: normal;
            }

            .value {
                color: #252733;
                font-size: 18px;
                line-height: 26px;
                margin-top: 12px;
            }
        }

        .flex-wapper {
            display: flex;
        }

        .item-wapper {
            width: calc((100% - 128px) / 3);
            margin-left: 64px;

            .table-icon {
                width: 24px !important;
                height: 24px !important;
            }
        }

        .item-wapper:first-child {
            margin-left: 0px;
        }
    }

    .btn-wapper {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.dialog-wapper {
    .tip {
        color: #B9BBC7;
        font-size: 16px;
        line-height: 20px;
        margin-top: 40px;
    }
}
</style>