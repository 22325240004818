<template>
    <global-menu open="7" selected="7-8" pageTitle="系统设置" :isShowNew="false">
        <div class="page-contener">
            <a-table :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'content'">
                        {{ record.content ? `${record.content}${record.uuid_code == 'PLATFORM_SERVICE_FEE' ? '%' : '(円)'}` : '-' }}
                    </div>
                    <div v-if="column.dataIndex === 'operation'">
                        <a @click="showModal(record)">修改</a>
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" :title="modalTitle" :loading="submitLoading" @onOk="submit" @onCancel="closeModal">
        <global-title class="mg-t-24" :title="modalTitle" />
        <g-input class="mg-t-24" placeholder="请输入" :suffix="modalTitle == '平台服务费' ? '%' : '円'" v-model="form.content"></g-input>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue"
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GInput from "@/components/GInput.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons-vue";
import Pagination from "@/components/Pagination.vue"

export default {
    components: {
        GlobalMenu,
        PlusCircleFilled,
        PlusCircleOutlined,
        Pagination,
        GlobalDialog,
        GlobalTitle,
        GInput,
        GlobalSearch
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        let state = reactive({
            loading: false,
            tableData: [],
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
            isModalShow: false,
            modalType: 'settle',
            modalTitle: '结算',
            form: {
                uuid_code: '',
                content: ''
            },
            submitLoading: false
        })
        let searchForm = reactive({
            page: 1,
            page_size: 10,
        })
        const submit = () => {
            if(!state.form.content) {
                proxy.$message.error('请填写完整')
                return
            }
            proxy.$httpUtil.put('/manage/config', state.form).then(res => {
                if(res.success) {
                    state.submitLoading = false
                    closeModal()
                    getData()
                }
            }).catch(() => {
                state.submitLoading = false
            })
        }
        const showModal = (data) => {
            state.modalTitle = data.title
            state.form = {
                uuid_code: data.uuid_code,
                content: data.content
            }
            state.isModalShow = true
        }
        const closeModal = () => {
            state.isModalShow = false
        }
        const getData = async () => {
            state.loading = true
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getConfig(searchForm)
            state.tableData = res.items
            state.statistics = res.statistics
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        onMounted(() => {
            getData()
        })
        return {
            tableColumns: [
                {
                    dataIndex: "title",
                    title: "项目",
                },
                {
                    dataIndex: "content",
                    title: "值",
                },
                {
                    dataIndex: "operation",
                    title: "操作",
                    width: 80
                }
            ],
            refPagination,
            ... toRefs(state),

            showModal,
            closeModal,
            submit
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;
    padding: 16px;
    .flex {
        padding-bottom: 24px;
        display: flex;
        .item {
            flex: 1 0 0 ;
            .label {
                font-size: 16px;
                line-height: 24px;
                display: block;
                padding: 0;
                border-radius: 0;
                text-align: left;
                font-weight: normal;
            }
            .price {
                margin-top: 12px;
                font-size: 36px;
                line-height: 48px;
                color: #3addb7;
            }
        }
    }
    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .table-img {
        width: 32px !important;
        height: 32px !important;
    }
}

.dialog-wapper {
    .batch-text {
        color: #3addb7;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .delete-icon {
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }

    .sub-title {
        color: #252733;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
    }

    .sub-tip {
        color: #65697b;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    /deep/.upload-icon {
        width: 343px;
        height: 114px;
        border-radius: 8px;
    }

    .upload-text {
        width: 100%;
        color: #252733;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: none;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    ::v-deep .ant-upload-picture-card-wrapper {
        width: auto;
    }

    .color-wapper {
        flex: 0 0 auto;
        height: 48px;
        width: 80px;
        border: 1px solid #b6bbd7;
        border-radius: 10px;
    }
}
</style>