import axios from "axios";
import $router from '@/router'
import { message } from "ant-design-vue";
axios.defaults.baseURL = window.env.baseUrl
const request = function(method, url, data, type) {
    console.log(type)
    axios.defaults.headers['NIHONQ_LANGUAGE'] = 'cn'
    axios.defaults.headers['Authorization'] = location.href.includes('/h5/') ? `Bearer ${localStorage['userToken']}` : `Bearer ${localStorage['Token']}`
    axios.defaults.headers['Content-Type'] = type || 'application/json'
    return new Promise((resolve, reject) => {
        let config = {
            method: method,
            url,
        }
        if (method == 'get') {
            config.params = data
        } else if(type && type.includes('form-data')){
            config.data = data
        }else {
            config.data = { data: data }
        }
        axios(config).then(res => {
                resolve(res.data)
            })
            .catch(err => {
                let currentName = $router.currentRoute.name;
                if (err.response.status == 401) {
                    if (currentName != 'Login') {
                        // localStorage.clear()
                        if(location.href.includes('/h5/')) {
                            localStorage.removeItem('userToken');
                            $router.replace({ name: 'HLogin' })
                        }else {
                            localStorage.removeItem('Token');
                            localStorage.removeItem('searchFormLoca');
                            $router.replace({ name: 'Login' })
                        }
                    } else {
                        console.log('already Login');
                    }
                } else if (err.response.status == 403 || err.response.status == 412) {
                    if (err.response.status == 412) {
                        // localStorage.clear();
                        localStorage.removeItem('Token');
                        localStorage.removeItem('searchFormLoca');
                    }
                    if (currentName != 'RoleErr') {
                        $router.replace({ name: 'RoleErr' })
                    } else {
                        console.log('already RoleErr');
                    }
                } else {
                    let str = err.response.data.message.replace(/\n/g, '<br>')
                        // message.error({
                        //     dangerouslyUseHTMLString: true,
                        //     message: str
                        // })
                    message.error(str)
                    reject(err)
                }
            })
    })
}

export default {
    post(url, data) {
        return new Promise((resolve, reject) => {
            request('post', url, data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    put(url, data) {
        return new Promise((resolve, reject) => {
            request('put', url, data).then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    get(url, data) {
        return new Promise((resolve, reject) => {
            request('get', url, data).then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    delete(url, data) {
        return new Promise((resolve, reject) => {
            request('delete', url, data).then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    upload(url, file, path, order_id) {
        console.log(file)
        let formData = new FormData();
        formData.append('file', file)
        if(path) formData.append('path', path)
        if(order_id) formData.append('order_id', order_id)
        
        console.log(formData)
        return new Promise((resolve, reject) => {
            request('post', url, formData, 'multipart/form-data; boundary=--------------------------817070723265323340320962').then(res => {
                if(order_id) {
                    resolve(res.data.file_info)
                }else {
                    resolve(res.data.file)
                }
            })
            .catch(err => {
                reject(err)
            })
        })
    }
}