<template>
    <div :id="selfId"></div>
</template>

<script> 
import { defineComponent, ref, onMounted } from 'vue';
import { message } from 'ant-design-vue';
export default defineComponent({
    props:{
        // 容器id
        id: {
            type: String,
            default: "summerNOte" + new Date().getTime()
        },
        // 富文本初始内容
        content: {
            type: String,
            default: ''
        }
    },
    setup(props){
        let selfId = ref('')

        onMounted(()=>{
            selfId.value = props.id + new Date().getTime()
        })
        const initValue = content => {
            window.$("#" + selfId.value).summernote("code", content);
        }
        return {
            selfId,
            initValue
        }
    },
    async mounted(){
        this.init()
    },
    methods:{
        init(content){
            setTimeout(async ()=>{
                await this.summernoteInit()
                window.$("#" + this.selfId).on("summernote.change",  () => {
                    this.summerNoteChange();
                });
                //初始赋值
                window.$("#" + this.selfId).summernote("code", content || this.content);
            }, 0)
        },
        summernoteInit() {
            const height = parseInt(window.getComputedStyle(document.querySelector('#'+ this.selfId)).height)
            //编辑器初始化
            return new Promise((resolve) => {
                //初始化摘要内容富文本编辑器
                window.$("#" + this.selfId).summernote({
                    lang: "zh-CN", //语言
                    placeholder: "请输入内容", //提示语
                    height: height, //高度
                    //  'auto',//宽度  也可以指定宽度
                    htmlMode: true,
                    toolbar: [
                        //工具栏配置
                        // ["style", ["style"]],
                        ["font", ["bold", "underline", "clear"]],
                        ["fontsize", ["fontsize"]],
                        ["fontname", ["fontname"]],
                        ["color", ["color"]],
                        ["para", ["ul", "ol", "paragraph", "height", "hr"]],
                        ["table", ["table"]],
                        ["insert", ["link", "picture"]],
                        // ["insert", ["link", "picture", "video"]],
                        // ["view", ["fullscreen", "codeview", "help"]],
                    ],
                    fontSizes: ["12", "14", "16", "18", "20", "24", "28", "32", "36","48"],
                    fontSizeUnits: ["px"],
                    maximumImageFileSize: 2048*1024, // 2M
                    fontNames: [
                        //字体类型配置
                        "宋体",
                        "微软雅黑",
                        "楷体",
                        "黑体",
                        "隶书",
                        "Arial",
                        "Arial Black",
                        "Comic Sans MS",
                        "Courier New",
                        "Georgia",
                        "Georgia2",
                        "Merriweather",
                    ],
                    callbacks: {
                        //回调函数
                        onSubmit: function () {
                        // vm.richContent = $('#summernote').summernote('code')
                        },
                        onKeyup: function () {
                        //
                        },
                        onImageUploadError: function (msg) {
                            if (msg == 'Maximum file size exceeded.') {
                                message.warning("图片大小已超出2M. 请重新调整图片大小。")
                            }
                        }
                    },
                });
                resolve();
            });
        },
        summerNoteChange() {
            //富文本编辑器发生改变
            this.$emit("input", window.$("#" + this.selfId).summernote("code"));
            // console.log(window.$("#" + this.selfId).summernote("code"))
        }
    }
})
</script>
<style lang="less">
.panel-default > .panel-heading{
    background-color: transparent !important;
}
</style>