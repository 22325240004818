import $router from '@/router'
import md5 from 'js-md5'
export default {
    toPage(name, query) {
        $router.push({
            name,
            query
        });
    },
    replace(name, query) {
        $router.replace({
            name,
            query
        });
    },

    openPage(name, query) {
        let {
            href
        } = this.$router.resolve({
            name,
            query
        })
        window.open(href, '_blank');
    },
    addKey(arr) {
        for (var i in arr) {
            arr[i].key = parseInt(i) + 1;
        }
        return arr
    },
    getFileUrl(file) {
        let url = '';
        if (window.createObjectURL !== undefined) { // basic
            url = window.createObjectURL(file);
        } else if (window.URL !== undefined) { // mozilla(firefox)
            url = window.URL.createObjectURL(file);
        } else if (window.webkitURL !== undefined) { // webkit or chrome
            url = window.webkitURL.createObjectURL(file);
        }
        console.log(url)
        return url;
    },
    mdPwd(password) {
        return md5(password)
    }
}