<template>
    <div class="login">
        <div class="warpper">
            <img class="logo" src="@/assets/img/logo.png" alt="">
            <div class="title">NihonQ</div>
            <div class="input-box">
                <a-input class="input" type="text" placeholder="请输入用户名" v-model:value="account" />
                <a-input class="input" type="password" placeholder="请输入密码" v-model:value="password" />
                <a-button class="btn" :loading="loading" @click="submit">登 录</a-button>
                <div>
                    <a class="change-pwd" @click="$common.toPage('HChangePwd')">修改密码</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue"
import { useRoute } from 'vue-router'
export default {
    setup(){
        const {proxy} = getCurrentInstance()
        const route = useRoute()
        let account = ref()
        let password = ref()
        let loading = ref(false)
        const submit = () => {
            if(!account.value || !password.value) {
                proxy.$message.error('请输入必填项')
                return false
            }
            proxy.$httpUtil.post('/auth_person/login',{
                account: account.value,
                // password: proxy.$common.mdPwd(password.value)
                password: password.value
            }).then(res=>{
                localStorage['userToken']= res.data.user.access_token
                proxy.$common.toPage('HDetail', {number: route.query.number})
            })
        }
        return {
            account,
            password,
            loading,
            submit
        }
    }
}
</script>

<style scoped lang="less">
    .login {
        height: 100vh;
        background-color: #3ADDB7;
        display: flex;
        align-items: center;
        text-align: center;
        .warpper {
            width: 100%;
            .logo {
                width: 80px;
            }
            .title {
                font-size: 36px;
                color: #fff;
                font-weight: 600;
            }
            .input-box {
                margin-top: 96px;
                padding: 0 46px;
                .input {
                    display: block;
                    height: 48px;
                    padding: 0 16px;
                    border: 1px solid #fff;
                    border-radius: 4px;
                    font-size: 14px;
                    margin-bottom: 12px;
                }
            }
            .btn {
                margin-top: 60px;
                width: 100%;
                height: 48px;
                background-color: #fff;
                border-radius: 4px;
                color: #3ADDB7;
                font-size: 16px;
                box-shadow: none;
                border: none;
                margin-bottom: 24px;
                font-weight: 600;
            }
            .change-pwd {
                color: #fff;
                font-size: 14px;
            }
        }
    }
</style>