<template>
    <div class="g-row">
        <span class="text-key">{{title}}</span>
        <span class="text-value"><slot></slot></span>
    </div>
</template>
<script>
export default {
    props:{
        title:String
    }
}
</script>
<style lang="less" scoped>
.g-row{
    .text-key{
        color: #B6BBD7;
        font-size: 16px;
        line-height: 22px;
    }
    .text-value{
        color: #252733;
        font-size: 16px;
        line-height: 22px;
        margin-left: 32px;
    }
}
    
</style>