<template>
    <global-menu open="7" selected="7-9" pageTitle="医生管理" @clickNew="showModal('create')">
        <template #btnText>新增医生</template>
        <div class="page-contener">
            <div class="table-toast">共 <span class="num">{{ pagination.total }}</span> 位医生</div>
            <a-table class="mg-t-32" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'sex'">
                        <span v-if="record.sex == 1">男</span>
                        <span v-if="record.sex == 2">女</span>
                    </div>
                    <div v-if="column.dataIndex === 'organization'">
                        {{ record.organization.length ? record.organization.join('、') : '-' }}
                    </div>
                    <div v-if="column.dataIndex === 'disease'">
                        {{ record.disease.length ? record.disease.join('、') : '-' }}
                    </div>
                    <div v-if="column.dataIndex === 'operation'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_edit_cyan.png" alt="" @click="showModal('edit', record)" />
                        <img class="table-icon mg-l-8  pointer" src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" :title="modalTitle" @onOk="submit" :loading="submitLoading" @onCancel="closeModal">
        <a-row :gutter="24">
            <a-col :span="6">
                <global-title title="照片"></global-title>
                <a-upload name="avatar" list-type="picture-card" class="avatar-uploader mg-t-16" :show-upload-list="false"
                    :customRequest="fileChange">
                    <div v-if="imgUrl" class="select-wapper">
                        <img class="select-img" :src="imgUrl" alt="" />
                    </div>

                    <img class="upload-img" v-else src="../../../assets/img/icon_pic_grey.png" alt="" />
                </a-upload>
            </a-col>
            <a-col :span="9">
                <global-title title="姓名"></global-title>
                <g-input class="mg-t-16" placeholder="请输入" v-model="form.name"></g-input>
                <global-title class="mg-t-16" title="职称"></global-title>
                <g-input class="mg-t-16" placeholder="请输入" v-model="form.rank"></g-input>
            </a-col>
            <a-col :span="9">
                <global-title title="性别"></global-title>
                <a-radio-group size="large" class="mg-t-16" button-style="solid" v-model:value="form.sex">
                    <a-radio-button :value="1">
                    男
                    </a-radio-button>
                    <a-radio-button :value="2">
                    女
                    </a-radio-button>
                </a-radio-group>
                <global-title class="mg-t-16" title="联系电话"></global-title>
                <g-input class="mg-t-16" placeholder="请输入" v-model="form.mobile"></g-input>
            </a-col>
        </a-row>
        <global-title class="mg-t-24" title="关联机构"></global-title>
        <a-select
            size="large"
            class="mg-t-16"
            style="width: 100%"
            placeholder="请选择"
            v-model:value="form.hospital_id"
            @change="hospitalChange"
        >
            <a-select-option v-for="item in hospitalOptions" :key="item.id">
                {{ item.name }}
            </a-select-option>
        </a-select>
        <global-title class="mg-t-24" title="关联科室"></global-title>
        <a-select
            size="large"
            class="mg-t-16"
            mode="multiple"
            style="width: 100%"
            placeholder="请选择"
            v-model:value="form.organization_id"
        >
            <a-select-option v-for="item in organizationOptions" :key="item.id">
                {{ item.name }}
            </a-select-option>
        </a-select>
        <global-title class="mg-t-24" title="关联病症"></global-title>
        <a-select
            size="large"
            class="mg-t-16"
            mode="multiple"
            style="width: 100%"
            placeholder="请选择"
            v-model:value="form.disease_id"
        >
            <a-select-option v-for="item in diseaseOptions" :key="item.id">
                {{ item.name }}
            </a-select-option>
        </a-select>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import Pagination from "@/components/Pagination.vue"

export default {
    components: {
        GlobalMenu,
        PlusCircleFilled,
        PlusCircleOutlined,
        Pagination,
        GlobalDialog,
        GlobalTitle,
        GInput,
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        let state = reactive({
            tableData: [],
            loading: false,
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
            isModalShow: false,
            modalType: 'create',
            modalTitle: '新增医生',
            form: {
                hospital_id: undefined,
                name: '',
                sex: 1,
                rank: '',
                mobile: '',
                avatar: '',
                organization_id: [],
                disease_id: []
            },
            fileData: null,
            imgUrl: '',
            detail: null,
            diseaseOptions: [],
            organizationOptions: [],
            hospitalOptions: [],
            submitLoading: false
        })
        let searchForm = reactive({
            page: 1,
            page_size: 10,
            type: 2
        })
        const submit = async () => {
            if(!state.form.name || !state.form.rank) {
                proxy.$message.error('请填写必填项')
                return
            }
            if(!state.imgUrl && !state.fileData) {
                proxy.$message.error('请上传照片')
                return
            }
            state.submitLoading = true
            if(state.fileData) {
                let res = await proxy.$httpUtil.upload('/platform/file_upload', state.fileData, 'doctor')
                if(res.file_url) {
                    state.form.avatar = res.file_url
                }else {
                    proxy.$message.error('图片上传失败')
                    state.submitLoading = false
                    return
                }
            }
            if(state.modalType == 'edit') {
                proxy.$httpUtil.put(`/manage/hospital_non_doctor/${state.detail.id}`, state.form).then(res => {
                    if(res.success) {
                        state.submitLoading = false
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }else {
                proxy.$httpUtil.post('/manage/hospital_non_doctor', state.form).then(res => {
                    if(res.success) {
                        state.submitLoading = false
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const hospitalChange = async () => {
            state.form.organization_id = []
            state.form.disease_id = []
            state.organizationOptions = await proxy.$api.getHospitalOrganization({hospital_id: state.form.hospital_id, is_page: 2})
            state.diseaseOptions = await proxy.$api.getHospitalDisease({hospital_id: state.form.hospital_id, is_page: 2})
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/hospital_non_doctor/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const fileChange = info => {
            state.fileData = info.file
            state.imgUrl = proxy.$common.getFileUrl(info.file)
        }
        const showModal = async (type, data) => {
            state.modalType = type
            state.modalTitle = '新增医生'
            state.detail = data
            if(type == 'edit') {
                let res = await proxy.$api.getHospitalNonDoctor(data.id)
                state.modalTitle = '编辑医生'
                state.form = {
                    hospital_id: res.hospital_id,
                    name: res.name,
                    sex: res.sex,
                    rank: res.rank,
                    mobile: res.mobile,
                    email: res.email,
                    avatar: res.avatar,
                    organization_id: res.organization_id,
                    disease_id: res.disease_id
                }
                state.organizationOptions = await proxy.$api.getHospitalOrganization({hospital_id: state.form.hospital_id, is_page: 2})
                state.diseaseOptions = await proxy.$api.getHospitalDisease({hospital_id: state.form.hospital_id, is_page: 2})
                state.imgUrl = res.avatar
                state.fileData = null
            }else {
                state.form = {
                    hospital_id: undefined,
                    name: '',
                    sex: 1,
                    rank: '',
                    mobile: '',
                    email: '',
                    avatar: '',
                    organization_id: [],
                    disease_id: []
                }
                state.imgUrl = ''
                state.fileData = null
            }
            state.isModalShow = true
        }
        const closeModal = () => {
            state.isModalShow = false
            state.submitLoading = false
        }
        const getData = async () => {
            state.loading = true
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getDoctor(searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64 - 32
        }
        onMounted(async () => {
            getData()
            state.hospitalOptions = await proxy.$api.getHospitalLists(2)
        })
        return {
            tableColumns: [
                {
                    dataIndex: "name",
                    title: "姓名",
                },
                {
                    dataIndex: "sex",
                    title: "性别",
                },
                {
                    dataIndex: "rank",
                    title: "职称",
                },
                {
                    dataIndex: "mobile",
                    title: "联系电话",
                },
                {
                    dataIndex: "hospital_name",
                    title: "关联机构",
                },
                {
                    dataIndex: "organization",
                    title: "关联科室",
                },
                {
                    dataIndex: "disease",
                    title: "关联病症",
                },
                {
                    dataIndex: "operation",
                    title: "操作",
                    width: 120
                }
            ],
            refPagination,
            ... toRefs(state),

            showModal,
            hospitalChange,
            closeModal,
            fileChange,
            submit,
            del
        };
    },
};
</script>
<style lang="less" scoped>
.table-toast {
        color: #252733;
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;

        .num {
            color: #3addb7;
        }
    }
.page-contener {
    height: 100%;
    padding: 16px;

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .table-img {
        width: 32px !important;
        height: 32px !important;
    }
}

.dialog-wapper {
    .select-wapper {
        position: relative;

        .select-img {
            width: 150px;
            height: 150px;
            object-fit: cover;
        }

        .select-box {
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(46, 49, 61, 0.7);
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
::v-deep .ant-upload-picture-card-wrapper {
    width: 150px;
}

::v-deep .ant-upload.ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
    background: #f4f8fe;
}
::v-deep .ant-radio-group-large .ant-radio-button-wrapper {
    width: 100px;
    height: 46px;
    line-height: 44px;
    text-align: center;
}
</style>