<template>
  <div class="g-input" :style="{'background':disable?'#F5F5F5':''}">
    <div class="flex-between" >
      <img class="icon" v-if="icon" :src="icon" alt="" />
      <a-input
        v-model:value="value"
        :type="type"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
        :disabled="disable"
        :suffix="suffix"
        :readonly="readonly"
        :style="{'color': color}"
      ></a-input>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, watch } from "vue"
export default {
  props: ["icon", "placeholder","type","disable",'modelValue','suffix', 'color', 'readonly'],
  setup(props, context) {
        const value = ref(undefined)
        watch(() => props.modelValue, (_val, oldVal) => {
            value.value = _val
        })
        onMounted(() => {
            value.value = props.modelValue || undefined
        })
        return {
            value,
        }
    }
};
</script>
<style lang="less" scoped>
.g-input {
  width: 100%;
  padding: 8px 11px;
  border-radius: 8px;
  background: #f4f8fe;
  display: inline-block;
  .icon {
    width: 20px;
    height: 20px;
  }
  ::v-deep .ant-input:focus {
    border: none;
    box-shadow: none;
  }
  /deep/.ant-input-affix-wrapper {
    border: none;
    background-color: transparent;
    color: #3addb7;
  }
  /deep/.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
}
</style>