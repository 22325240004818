<template>
    <div class="g-dialog" v-if="isShow" :style="{'z-index': zIndex}">
        <div class="content-wapper" :style="{ width: width + 'px' }" @click.stop>
            <div class="header" v-if="title">
                <div class="title">{{ title }} <slot name="subtitle"></slot>
                </div>
                <img class="close pointer" @click="onCancel" src="../assets/img/icon_close.png" alt="" />
            </div>
            <div class="content">
                <slot></slot>
            </div>
            <div class="footer" v-if="isShowFooter">
                <!-- <a-button type="primary" class="btn" @click="onOk">确定</a-button>
              <a-button class="btn mg-l-40" @click="onCancel">取消</a-button> -->
                <slot name="footer">
                    <g-button type="primary" class="btn" @click="onOk" :loading="loading">{{
                        okText ? okText : "确定"
                    }}</g-button>
                    <slot name="btns"></slot>
                    <g-button type="default" ghost class="btn mg-l-40" @click="onCancel">{{ cancelText }}</g-button>
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
import GButton from "@/components/GButton.vue";
export default {
    components: {
        GButton,
    },
    props: {
        isShowFooter: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "",
        },
        isShow: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 1008,
        },
        okText: {
            type: String,
            default: "",
        },
        cancelText: {
            type: String,
            default: "取消",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        zIndex: {
            type: Number,
            default: 1000,
        }
    },
    setup(props, context) {
        function onOk() {
            context.emit("onOk");
            console.log(props.width);
        }
        function onCancel() {
            context.emit("onCancel");
        }
        return {
            onOk,
            onCancel,
        };
    },
};
</script>
<style scoped lang='less'>
.g-dialog {
    background: rgba(37, 39, 51, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 99;

    .content-wapper {
        // width: 1008px;
        background: #ffffff;
        border-radius: 16px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 96px;
            padding: 0px 40px;

            .title {
                color: #252733;
                font-size: 24px;
                line-height: 32px;
                font-weight: bold;
            }

            .close {
                width: 32px;
                height: 32px;
            }
        }

        .content {
            padding: 0px 40px;
            overflow: auto;
            max-height: calc(100vh - 48px - 54px - 100px);
            position: relative;
        }

        .footer {
            height: 128px;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                width: 168px;
                height: 48px;
                border-radius: 8px;
            }
        }
    }
}
</style>