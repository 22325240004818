<template>
    <div class="pagination">
        <a-pagination :total="pagination.total" :showSizeChanger="false" v-model:pageSize="pagination.page_size" v-model:current="pagination.current" @change="handleChange" />
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
    props: {
        pagination: Object
    },
    setup(props, context){
        let state = reactive({
            pagination: props.pagination
        })
        const reset = (page, pageSize) => {
            state.pagination.current = page
        }
        const handleChange = (page, pageSize) => {
            state.pagination.current = page
            context.emit('change', {page})
        }
        return {
            handleChange,
            reset,
            ... toRefs(state)
        }
    }
}
</script>

<style lang='less' scoped>
    .pagination {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
</style>