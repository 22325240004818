<template>
  <global-menu open="1" selected="1-3">
    <section class="page-contener">
      <global-header :title="pageTitle">
        <template #title_content>
          <div class="title-content">
            DP23243546 <span class="mg-l-32">梅小青</span>
          </div>
        </template>
        <template #default>
          <span class="status-text">已支付</span>
        </template>
      </global-header>
      <div class="content-wapper">
        <div class="flex-between">
          <div class="left-wapper">
            <global-title title="商品信息"></global-title>
            <a-table
              class="mg-t-19"
              :columns="tableColumns"
              :dataSource="tableData"
              :loading="loading"
              :pagination="false"
            >
              <template #summary>
                <a-table-summary-row>
                  <a-table-summary-cell :col-span="3"
                    >商品合计：</a-table-summary-cell
                  >
                  <a-table-summary-cell>
                    <a-typography-text>
                      {{ totals.totalNum }}</a-typography-text
                    >
                  </a-table-summary-cell>
                  <a-table-summary-cell>
                    <a-typography-text style="color: #ff504a">{{
                      totals.totalPrice
                    }}</a-typography-text>
                  </a-table-summary-cell>
                  <a-table-summary-cell></a-table-summary-cell>
                </a-table-summary-row>
                <a-table-summary-row>
                  <a-table-summary-cell :col-span="5"
                    >运费（円）：</a-table-summary-cell
                  >
                  <a-table-summary-cell>
                    <a-typography-text style="color: #ffb03e"
                      >10000</a-typography-text
                    >
                  </a-table-summary-cell>
                </a-table-summary-row>
                <a-table-summary-row>
                  <a-table-summary-cell :col-span="5"
                    >订单合计（円）：</a-table-summary-cell
                  >
                  <a-table-summary-cell>
                    <a-typography-text style="color: #ff504a"
                      >5000</a-typography-text
                    >
                  </a-table-summary-cell>
                </a-table-summary-row>
              </template>
              <template #bodyCell="{ column }">
                <div v-if="column.dataIndex === 'stockout'">
                  <img
                    class="table-icon"
                    src="../../../assets/img/icon_check_black.png"
                    alt=""
                  />
                </div>
              </template>
            </a-table>
            <div class="flex-between mg-t-32">
              <div class="pd-l-16">下单日期：</div>
              <div class="pd-r-16">2022-05-08 14:51</div>
            </div>
          </div>
          <div class="right-wapper">
            <global-title title="收货信息"></global-title>
            <div class="user-info">
              <a-row :gutter="24">
                <a-col :span="12">
                  <div class="label">收货人</div>
                  <div class="flex-align-centet value">
                    梅小青
                    <img
                      class="icon"
                      src="../../../assets/img/icon_material_cyan.png"
                      alt=""
                    />
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="label">联系电话</div>
                  <div class="value">138 3674 8923</div>
                </a-col>
                <a-col :span="24" class="mg-t-32">
                  <div class="label">详细地址</div>
                  <div class="value">
                    四川省成都市武侯区天府三街吉泰路666号福年广场T2-2208
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
        <div class="pay-wapper flex-between" style="margin-top: 80px">
          <div style="width: 50%">
            <div class="title">支付信息</div>
            <a-row class="mg-t-32">
              <a-col :span="12">
                <g-row-text title="支付金额：">80,000 円</g-row-text>
              </a-col>
              <a-col :span="12">
                <g-row-text title="支付方式：">pay</g-row-text>
              </a-col>
              <a-col :span="12" class="mg-t-24">
                <g-row-text title="优惠金额：">1,000 円</g-row-text>
              </a-col>
              <a-col :span="12" class="mg-t-24">
                <g-row-text title="支付时间：">2022-05-20 11:06</g-row-text>
              </a-col>
            </a-row>
          </div>
          <div style="width: 50%">
            <div class="title">物流信息</div>
            <a-row class="mg-t-32">
              <a-col :span="12">
                <g-row-text title="物流公司：">EMS （默认）</g-row-text>
              </a-col>
              <a-col :span="12">
                <g-row-text title="发货时间：">2022-05-20  11:49</g-row-text>
              </a-col>
              <a-col :span="12" class="mg-t-24">
                <g-row-text title="运单号：">EMS2022112337849</g-row-text>
              </a-col>
              <a-col :span="12" class="mg-t-24">
                <g-row-text title="收货时间：">-</g-row-text>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <div class="btn-wapper">
        <g-button>确认订单</g-button>
        <g-button @click="showModal('物流设置')">立即发货</g-button>
        <g-button @click="showModal('邮费')" type="warn">调整邮费</g-button>
        <g-button type="error" class="mg-l-40" ghost>取消订单</g-button>
      </div>
    </section>
  </global-menu>
  <global-dialog
    :isShow="isModalShow"
    :title="modalTitle"
    @onOk="closeModal"
    @onCancel="closeModal"
    :width="560"
    class="dialog-wapper"
  >
    <div v-if="modalTitle === '邮费'">
      <global-title title="邮费设置"></global-title>
      <div class="flex-align-center mg-t-24">
        <g-input :icon="require('@/assets/img/icon_cost.png')"></g-input>
        <span class="unit">円</span>
      </div>
    </div>
    <div v-if="modalTitle === '物流设置'">
      <global-title title="物流信息"></global-title>
      <g-input
        class="mg-t-24"
        :icon="require('@/assets/img/icon_delivery_car.png')"
        placeholder="EMS（默认）"
        :disable="true"
      ></g-input>
      <g-input
        class="mg-t-24"
        :icon="require('@/assets/img/icon_delivery_number.png')"
        placeholder="运单号"
      ></g-input>
    </div>
  </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GButton from "@/components/GButton.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GInput from "@/components/GInput.vue";
import GRowText from "@/components/GRowText.vue";
import { onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  components: {
    GlobalMenu,
    GlobalHeader,
    GlobalTitle,
    GButton,
    GlobalDialog,
    GInput,
    GRowText,
  },
  setup() {
    let pageTitle = ref();
    let tableColumns = ref([
      {
        dataIndex: "name",
        title: "商品名称",
      },
      {
        dataIndex: "source",
        title: "货源",
      },
      {
        dataIndex: "price",
        title: "单价（円）",
      },
      {
        dataIndex: "num",
        title: "数量",
      },
      {
        dataIndex: "total",
        title: "合计（円）",
      },
      {
        dataIndex: "stockout",
        title: "缺货",
      },
    ]);
    let tableData = ref([
      {
        num: 10,
        total: 1000,
      },
    ]);
    let loading = ref(false);
    let totals = computed(() => {
      let totalNum = 0;
      let totalPrice = 0;
      tableData.value.forEach(({ num, total }) => {
        totalNum += Number(num);
        totalPrice += Number(total);
      });

      return { totalNum, totalPrice };
    });
    let isModalShow = ref(false);
    let modalTitle = ref();

    function showModal(title) {
      isModalShow.value = true;
      modalTitle.value = title;
    }
    function closeModal() {
      isModalShow.value = false;
    }
    onMounted(() => {
      pageTitle.value = useRoute().query.title;
    });
    return {
      pageTitle,
      tableColumns,
      tableData,
      loading,
      totals,
      isModalShow,
      modalTitle,

      showModal,
      closeModal,
    };
  },
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 40px);
  position: relative;
  .title-content {
    color: #b6bbd7;
    font-size: 20px;
    line-height: 20px;
    margin-left: 40px;
  }
  .status-text {
    color: #3addb7;
    font-size: 20px;
    line-height: 20px;
  }
  .content-wapper {
    padding: 16px 40px;
    .left-wapper {
      flex: 0 0 auto;
      width: calc((100% - 40px) / 3 * 2);
      .table-icon {
        width: 18px;
        height: 18px;
      }
    }
    .right-wapper {
      flex: 0 0 auto;
      width: calc((100% - 40px) / 3);
      .user-info {
        background: #f3fbf9;
        border: 1px solid #3addb7;
        border-radius: 8px;
        margin-top: 16px;
        padding: 32px;
        .label {
          color: #b6bbd7;
          font-size: 14px;
          line-height: 18px;
          display: block;
            padding: 0;
            border-radius: 0;
            text-align: left;
            font-weight: normal;
        }
        .value {
          color: #252733;
          font-size: 18px;
          line-height: 26px;
          margin-top: 12px;
        }
        .icon {
          flex: 0 0 auto;
          width: 20px;
          height: 20px;
          margin-left: 16px;
        }
      }
    }
    .pay-wapper {
      border-top: 1px solid #e8e8e8;
      padding-top: 46px;
      .title {
        color: #3addb7;
        font-size: 16px;
      }
    }
  }
  .btn-wapper {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.dialog-wapper {
  .unit {
    color: #252733;
    font-size: 16px;
    line-height: 22px;
    margin-left: 16px;
  }
}
</style>