<template>
    <global-menu open="2" selected="2-1" :isShowNew="false">
        <section class="page-contener">
            <global-header :title="title"></global-header>
            <div class="content-wapper">
                <g-tab :tabs="tabs" style="width:100%" width="calc(100% / 3)" @change="tabChange"></g-tab>
                <global-title title="基本信息" class="mg-t-48"></global-title>
                <g-select :selectId="form.hospital_id" v-model="form.hospital_id" class="mg-t-24" placeholder="选择医院" :icon="require('../../../assets/img/icon_hospital.png')" :options="hospitalOptions"></g-select>
                <g-input v-model="form.address.cn" class="mg-t-24" placeholder="医院地址" :icon="require('../../../assets/img/icon_address.png')" v-show="tabIndex == 0"></g-input>
                <g-input v-model="form.address.jp" class="mg-t-24" placeholder="医院地址" :icon="require('../../../assets/img/icon_address.png')" v-show="tabIndex == 1"></g-input>
                <g-input v-model="form.address.en" class="mg-t-24" placeholder="医院地址" :icon="require('../../../assets/img/icon_address.png')" v-show="tabIndex == 2"></g-input>
                <global-title title="医院简介" class="mg-t-48"></global-title>
                <a-textarea class="mg-t-24" placeholder="简介内容" :rows="4" v-model:value="form.intro.cn" v-show="tabIndex == 0"></a-textarea>
                <a-textarea class="mg-t-24" placeholder="简介内容" :rows="4" v-model:value="form.intro.jp" v-show="tabIndex == 1"></a-textarea>
                <a-textarea class="mg-t-24" placeholder="简介内容" :rows="4" v-model:value="form.intro.en" v-show="tabIndex == 2"></a-textarea>

                <global-title title="医院头图" class="mg-t-48"></global-title>
                <div class="flex-align-center mg-t-24" v-show="tabIndex == 0">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                        :customRequest="e => fileChange(e, 'cn')">
                        <div v-if="imgUrl.cn" class="select-wapper">
                            <img class="select-img" :src="imgUrl.cn" alt="" />
                        </div>

                        <img class="upload-img" v-else src="../../../assets/img/icon_pic_grey.png" alt="" />
                    </a-upload>
                    <div class="upload-explain">
                        点击上传图片<br />
                        尺寸16 : 9，大小1M以内，jpg、png格式
                    </div>
                </div>
                <div class="flex-align-center mg-t-24" v-show="tabIndex == 1">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                        :customRequest="e => fileChange(e, 'jp')">
                        <div v-if="imgUrl.jp" class="select-wapper">
                            <img class="select-img" :src="imgUrl.jp" alt="" />
                        </div>

                        <img class="upload-img" v-else src="../../../assets/img/icon_pic_grey.png" alt="" />
                    </a-upload>
                    <div class="upload-explain">
                        点击上传图片<br />
                        尺寸16 : 9，大小1M以内，jpg、png格式
                    </div>
                </div>
                <div class="flex-align-center mg-t-24" v-show="tabIndex == 2">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                        :customRequest="e => fileChange(e, 'en')">
                        <div v-if="imgUrl.en" class="select-wapper">
                            <img class="select-img" :src="imgUrl.en" alt="" />
                        </div>

                        <img class="upload-img" v-else src="../../../assets/img/icon_pic_grey.png" alt="" />
                    </a-upload>
                    <div class="upload-explain">
                        点击上传图片<br />
                        尺寸16 : 9，大小1M以内，jpg、png格式
                    </div>
                </div>
                <global-title title="详细介绍" class="mg-t-48"></global-title>
                <div class="mg-t-24 editor">
                    <div v-show="tabIndex == 0">
                        <editor ref="editorRefCn" :content="form.content.cn" id="contentCn" style="height: 400px;" @input="value => form.content.cn = value" />
                    </div>
                    <div v-show="tabIndex == 1">
                        <editor ref="editorRefJp" :content="form.content.jp" id="contentJp" style="height: 400px;" @input="value => form.content.jp = value" />
                    </div>
                    <div v-show="tabIndex == 2">
                        <editor ref="editorRefEn" :content="form.content.en" id="contentEn" style="height: 400px;" @input="value => form.content.en = value" />
                    </div>
                </div>
            </div>
            <div class="btn-wapper">
                <g-button :loading="submitLoading" @click="submit">{{ id ? '保存' : '确定' }}</g-button>
                <g-button class="mg-l-40" ghost @click="showModal">预览</g-button>
                <g-button class="mg-l-40" type="default" ghost @click="$router.back">关闭</g-button>
                <!-- <g-button v-if="route.query.id" class="mg-l-40" type="error" ghost @click="del">删除</g-button> -->
            </div>
        </section>
    </global-menu>
    <global-dialog :width="600" :isShow="isModalShow" :isShowFooter="false">
        <div class="dialog-wapper">
            <img class="image" :src="imgUrl.cn" alt="" v-if="tabIndex == 0" />
            <img class="image" :src="imgUrl.jp" alt="" v-if="tabIndex == 1" />
            <img class="image" :src="imgUrl.en" alt="" v-if="tabIndex == 2" />
            <div class="info-wapper">
                <div class="title">{{ hospitalName }}</div>
                <div class="intro" v-if="tabIndex == 0">
                    {{ form.intro.cn }}
                </div>
                <div class="intro" v-if="tabIndex == 1">
                    {{ form.intro.jp }}
                </div>
                <div class="intro" v-if="tabIndex == 2">
                    {{ form.intro.en }}
                </div>
                <div class="preview-content mg-t-24">
                    <div v-html="form.content.cn" v-if="tabIndex == 0"></div>
                    <div v-html="form.content.jp" v-if="tabIndex == 1"></div>
                    <div v-html="form.content.en" v-if="tabIndex == 2"></div>
                </div>
            </div>
        </div>
        <div class="dialog-btn-wapper">
            <div class="btn pull-right mg-l-32 pointer" @click="isModalShow = false">
                <img class="icon" src="../../../assets/img/icon_close_white.png" alt="" />
            </div>
        </div>
    </global-dialog>
</template>
<script>
import $router from "@/router"
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "../../../components/GlobalHeader.vue";
import GTab from "../../../components/GTab.vue";
import { reactive, toRefs, ref, getCurrentInstance, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GSelect from "../../../components/GSelect.vue";
import GInput from "../../../components/GInput.vue";
import GButton from "../../../components/GButton.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue"
import Editor from "@/components/Editor.vue"
import { Modal } from "ant-design-vue"
export default {
    components: {
        GlobalMenu,
        GlobalHeader,
        GTab,
        GlobalTitle,
        GSelect,
        GInput,
        GButton,
        GlobalDialog,
        Editor,
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const route = useRoute()
        const editorRefCn = ref()
        const editorRefJp = ref()
        const editorRefEn = ref()
        let state = reactive({
            tabIndex: 0,
            title: '',
            hospitalOptions: [],
            id: '',
            form: {
                hospital_id: '',
                content: {
                    cn: '',
                    jp: '',
                    en: ''
                },
                intro: {
                    cn: '',
                    jp: '',
                    en: ''
                },
                cover: {
                    cn: '',
                    jp: '',
                    en: ''
                },
                address: {
                    cn: '',
                    jp: '',
                    en: ''
                }
            },
            hospitalName: '',
            imgUrl: {
                cn: '',
                jp: '',
                en: ''
            },
            fileData: {
                cn: '',
                jp: '',
                en: ''
            },
            isModalShow: false,
            submitLoading: false,
        })
        const del = () => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/recommend_hospital/${route.query.id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            $router.back()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const submit = async () => {
            if(!state.form.hospital_id || !state.form.address.cn || !state.form.address.jp || !state.form.address.en || !state.form.content.cn || !state.form.content.jp || !state.form.content.en || !state.form.intro.cn || !state.form.intro.jp || !state.form.intro.en) {
                proxy.$message.error('请输入必填项')
                return
            }
            if((!state.imgUrl.cn || !state.imgUrl.jp || !state.imgUrl.en) && (!state.fileData.cn || !state.fileData.jp || !state.fileData.en)) {
                proxy.$message.error('请上传图片')
                return
            }
            state.submitLoading = true
            for(let i in state.fileData) {
                if(state.fileData[i]) {
                    let res = await proxy.$httpUtil.upload('/platform/file_upload', state.fileData[i], 'hospital')
                    if(res.file_url) {
                        state.form.cover[i] = res.file_url
                    }else {
                        proxy.$message.error('图片上传失败')
                        state.submitLoading = false
                        return
                    }
                }
            }
            if (route.query.id) {
                proxy.$httpUtil.put(`/manage/recommend_hospital/${route.query.id}`, state.form).then(res => {
                    proxy.$message.success('修改成功')
                    $router.back()
                }).catch(() => {
                    state.submitLoading = false
                })
            }else {
                proxy.$httpUtil.post(`/manage/recommend_hospital`, state.form).then(res => {
                    proxy.$message.success('新增成功')
                    $router.back()
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const tabChange = index => {
            state.tabIndex = index
        }
        const fileChange = (info, type) => {
            state.fileData[type] = info.file
            state.imgUrl[type] = proxy.$common.getFileUrl(info.file);
        }
        const showModal = () => {
            state.isModalShow = true
        }
        watch(() => state.form.hospital_id, (_val, oldVal) => {
            for(let i in state.hospitalOptions) {
                if(state.hospitalOptions[i].id == _val) {
                    state.hospitalName = state.hospitalOptions[i].name
                }
            }
        })
        onMounted(async () => {
            state.hospitalOptions = await proxy.$api.getHospitalLists(0)
            if (route.query.id) {
                state.title = "机构 详情"
                let res = await proxy.$api.getHospitalRecommendDetail(route.query.id)
                state.imgUrl = res.cover
                editorRefCn.value.init(res.content.cn)
                editorRefJp.value.init(res.content.jp)
                editorRefEn.value.init(res.content.en)
                state.form = {
                    hospital_id: res.hospital_id,
                    content: res.content,
                    intro: res.intro,
                    cover: res.cover,
                    address: res.address
                }
            } else {
                state.title = "新增 机构"
            }
        })
        return {
            ... toRefs(state),
            tabs: ["中文", "日文", "英文"],
            editorRefCn,
            editorRefJp,
            editorRefEn,
            route,

            tabChange,
            fileChange,
            submit,
            showModal,
            del
        };
    },
};
</script>
<style lang="less" scoped>
.preview-content {
    padding: 20px;
    background-color: #EBF5FB;
    border-radius: 8px;
}
.page-contener {
    height: calc(100vh - 40px);
    position: relative;

    .content-wapper {
        height: calc(100% - 96px - 128px);
        overflow: auto;
        width: 600px;
        margin: 16px auto;

        .upload-img {
            width: 24px;
            height: 24px;
        }

        .select-wapper {
            position: relative;

            .select-img {
                width: 212px;
                height: 120px;
            }

            .select-box {
                width: 212px;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(46, 49, 61, 0.7);
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .upload-explain {
            margin-left: 32px;
            color: #252733;
            font-size: 14px;
            line-height: 22px;
        }

        .editor {
            width: 100%;
            height: 600px;
        }

        ::v-deep .ant-upload-picture-card-wrapper {
            width: 212px;
        }

        ::v-deep .ant-upload.ant-upload-select-picture-card {
            width: 212px;
            height: 120px;
            background: #f4f8fe;
        }
    }

    .btn-wapper {
        height: 128px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.dialog-wapper {
    position: relative;
    max-height: calc(100vh - 48px - 54px - 100px);
    border-radius: 16px;
    overflow: auto;

    .image {
        width: 100%;
        height: 384px;
        border-radius: 16px 16px 0px 0px;
    }

    .info-wapper {
        margin-top: -56px;
        border-radius: 16px;
        background: #ffffff;
        padding: 32px;
        position: relative;
        z-index: 2;

        .title {
            color: #252733;
            font-size: 28px;
            line-height: 32px;
        }

        .intro {
            background: rgba(58, 221, 183, 0.1);
            border-radius: 8px;
            padding: 20px;
            margin-top: 28px;
            color: #202020;
            font-size: 18px;
            line-height: 32px;
        }
    }

    
}
.dialog-btn-wapper {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 10;

    .btn {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(46, 49, 61, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 24px;
            height: 24px;
        }
    }
}

::v-deep .content {
    padding: 0px !important;
}
</style>