<template>
    <global-menu open="1" selected="1-6" pageTitle="服务  订单" @clickNew="showModal('create')">
        <template #btnText>新增</template>
        <div class="page-contener">
            <div class="table-toast">共 <span class="num">{{ pagination.total }}</span> 个订单</div>
            <g-tab class="mg-t-16" :tabs="tabs" @change="tabChange"></g-tab>
            <a-table class="mg-t-16" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading"  :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column && column.dataIndex === 'patient'">
                        {{ record.patient.name }}（{{ record.patient.sex }} {{ record.patient.age }}岁）<br>
                        {{ record.patient.mobile }}
                    </div>
                    <div v-if="column && column.dataIndex === 'hospital_name'">
                        <a v-if="record.relation_order_id" @click="toDetail(record)">
                            {{ record.hospital_name }}<br>
                            {{ record.service_name }}
                        </a>
                        <span v-else>
                            {{ record.hospital_name }}<br>
                            {{ record.service_name }}
                        </span>
                    </div>
                    <div v-if="column && column.dataIndex === 'serviced_start_at'">
                        {{ record.serviced_start_at }}到<br>
                        {{ record.serviced_end_at }}
                    </div>
                    <div v-if="column && column.dataIndex === 'is_report'">
                        <span style="color: #ccc" v-if="record.is_report == 1">查看</span>
                        <a v-if="record.is_report == 2" @click="showReportModal(record)">查看</a>
                    </div>
                    <div v-if="column && column.dataIndex === 'operation'">
                        <a-tooltip>
                            <template v-slot:title>
                                <div>
                                    <img class="table-icon pointer" src="@/assets/img/icon_edit_white.png" alt="" @click="showModal('edit', record)" />
                                    <img class="table-icon pointer mg-l-16" src="@/assets/img/icon_barcode_white.png" alt=""  @click="showCodeModal(record)" />
                                    <img class="table-icon pointer mg-l-16" src="@/assets/img/icon_confirm_white.png" v-if="tabActive == 1" alt="" @click="finish(record.id)" />
                                </div>
                            </template>
                            <img class="table-icon pointer" src="@/assets/img/icon_more.png" alt="" />
                        </a-tooltip>
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
        <global-dialog class="dialog-wapper" :isShow="isModalShow" :title="modalType == 'create' ? '新增' : '编辑'" :loading="submitLoading" @onOk="submit" @onCancel="isModalShow = false">
            <global-title title="用户信息" />
            <a-row class="mg-t-24" :gutter="24">
                <a-col :span="6">
                    <g-input placeholder="联系人" v-model="form.patient.name"></g-input>
                </a-col>
                <a-col :span="6">
                    <a-select style="width: 100%;height: 46px" size="large" placeholder="性别" v-model:value="form.patient.sex">
                        <a-select-option value="男">男</a-select-option>
                        <a-select-option value="女">女</a-select-option>
                    </a-select>
                </a-col>
                <a-col :span="6">
                    <g-input placeholder="年龄" v-model="form.patient.age"></g-input>
                </a-col>
                <a-col :span="6">
                    <g-input placeholder="联系电话" v-model="form.patient.mobile"></g-input>
                </a-col>
            </a-row>
            <div class="flex-between mg-t-24">
                <global-title title="机构信息" />
                <a @click="showOrderModal">关联就诊订单</a>
            </div>
            <a-row class="mg-t-24" :gutter="24">
                <a-col :span="12">
                    <g-input placeholder="就诊医院" v-model="form.hospital_name"></g-input>
                </a-col>
                <a-col :span="12">
                    <g-input placeholder="就诊科室或病症（非必填）" v-model="form.service_name"></g-input>
                </a-col>
            </a-row>
            <global-title class="mg-t-24" title="服务信息" />
            <a-row class="mg-t-24" :gutter="24">
                <a-col :span="12">
                    <a-select style="width: 100%;height: 46px" size="large" placeholder="请选择服务内容" v-model:value="form.added_service_id">
                        <a-select-option :value="item.id" v-for="item in serviceOptions" :key="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                </a-col>
                <a-col :span="12">
                    <a-select style="width: 100%;height: 46px" size="large" placeholder="请选择服务人员（非必填）" v-model:value="form.service_person_id">
                        <a-select-option :value="item.id" v-for="item in personOptions" :key="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                </a-col>
                <a-col class="mg-t-24" :span="12">
                    <g-input placeholder="服务费用（非必填）" v-model="form.service_money"  suffix="円"></g-input>
                </a-col>
                <a-col class="mg-t-24" :span="12">
                    <a-row :gutter="24">
                        <a-col :span="12">
                            <g-date placeholder="服务开始时间" :value="form.serviced_start_at" @change="value => dateChange(value, 'serviced_start_at')"></g-date>
                        </a-col>
                        <a-col :span="12">
                            <g-date placeholder="服务结束时间" :value="form.serviced_end_at" @change="value => dateChange(value, 'serviced_end_at')"></g-date>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col class="mg-t-24" :span="24">
                    <a-textarea placeholder="备注（非必填）" v-model:value="form.remark"></a-textarea>
                </a-col>
            </a-row>
        </global-dialog>
        <global-dialog :width="280" :isShow="isCodeModalShow" title=" " @onCancel="isCodeModalShow = false" :isShowFooter="false" class="dialog-modal">
            <div style="padding-bottom: 20px">
                <vue-qr class="code-image" :text="reportCode" :size="200"></vue-qr>
            </div>
        </global-dialog>
        <global-dialog :isShow="isReportModalShow" title="服务报告" @onCancel="isReportModalShow = false" :isShowFooter="false" class="dialog-modal">
            <div class="report">
                <div class="text">{{ reportDetail.service_report_content ? reportDetail.service_report_content : '无内容' }}</div>
                <div class="imgs" v-if="reportDetail.service_report_image.length">
                    <img class="img pointer" :src="item" alt="" v-for="item, index in reportDetail.service_report_image" :key="index" @click="previewImg(item)">
                </div>
                <div class="imgs" v-else>
                    无图片
                </div>
            </div>
        </global-dialog>
        <global-dialog :isShow="isOrderModalShow" title="关联就诊订单" @onCancel="isOrderModalShow = false" @onOk="bindComfirm" class="dialog-modal">
            <a-select style="width: 200px;height: 46px" size="large" v-model:value="bindSearchForm.type" @change="bindSearchFormTypeChange">
                <a-select-option :value="1">在日就诊</a-select-option>
                <a-select-option :value="2">远程问诊</a-select-option>
            </a-select>
            <a-table class="mg-t-16" :scroll="{y: 500}" :columns="bindTableColumns" :dataSource="bindData" :loading="bindLoading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column && column.dataIndex === 'check'">
                        <a-checkbox :checked="relation_order_id == record.id" @change="e => checkboxChange(e, record.id)"></a-checkbox>
                    </div>
                    <div v-if="column && column.dataIndex === 'type'">
                        <span v-if="record.type == 1">在日就诊</span>
                        <span v-if="record.type == 2">远程问诊</span>
                    </div>
                    <div v-if="column && column.dataIndex === 'patient'">
                        {{ record.patient.name }}（{{ record.patient.sex }} {{ record.patient.age }}岁）<br>
                        {{ record.patient.mobile }}
                    </div>
                    <div v-if="column && column.dataIndex === 'hospital_name'">
                        {{ record.hospital_name }}<br>
                        {{ record.service_name }}
                    </div>
                </template>
            </a-table>
            <Pagination ref="refBindPagination" :pagination="bindPagination" @change="bindPageChange" v-show="bindData.length" />
        </global-dialog>
    </global-menu>
    <preview :url="previewUrl" v-if="isPreviewShow" @hide="isPreviewShow = false" />
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GInput from "@/components/GInput.vue";
import GSelect from "@/components/GSelect.vue";
import GButton from "@/components/GButton.vue";
import GDate from "@/components/GDate.vue";
import preview from "@/components/preview.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance, watch, nextTick } from "vue";
import GTab from "@/components/GTab.vue";
import Pagination from "@/components/Pagination.vue";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { Modal } from 'ant-design-vue'
import { useRoute } from 'vue-router'
export default {
    components: {
        GlobalMenu,
        GlobalSearch,
        GlobalDialog,
        GInput,
        GSelect,
        GDate,
        GlobalTitle,
        GButton,
        GTab,
        Pagination,
        vueQr,
        preview
    },

    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        const refBindPagination = ref()
        const route = useRoute()
        let state = reactive({
            tableColumns: [],
            tableData: [],
            loading: false,
            tabActive: 0,
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 0,
            isModalShow: false,
            modalType: 'create',
            submitLoading: false,
            serviceOptions: [],
            personOptions: [],
            form: {},
            detail: null,
            isCodeModalShow: false,
            reportCode: '',
            reportDetail: {},
            isReportModalShow: false,
            isOrderModalShow: false,
            bindSearchForm: {
                custom_status: 2,
                hospital_type: 2,
                type: 1,
                page: 1,
                page_size: 10,
            },
            bindPagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            bindTotal: 0,
            bindData: [],
            bindLoading: false,
            previewUrl: '',
            isPreviewShow: false,
            relation_order_id: 0,
            isEdit: false
        })
        let searchForm = reactive({
            status: 1,
            page_size: 10,
            page: 1
        })
        const column = {
            0: [
                { dataIndex: "patient", title: "用户信息",width: 180},
                { dataIndex: "hospital_name", title: "机构信息",width: 160},
                { dataIndex: "service_person_name", title: "服务人员", width: 100},
                { dataIndex: "added_service_name", title: "服务内容", width: 100},
                { dataIndex: "serviced_start_at", title: "服务期间", width: 160},
                { dataIndex: "service_money", title: "费用(円)", width: 120},
                { dataIndex: "remark", title: "备注"},
                { dataIndex: "created_at", title: "创建时间", width: 170},
                { dataIndex: "operation", title: "操作", width: 80},
            ],
            1: [
                { dataIndex: "patient", title: "用户信息",width: 180},
                { dataIndex: "hospital_name", title: "机构信息",width: 160},
                { dataIndex: "service_person_name", title: "服务人员", width: 100},
                { dataIndex: "added_service_name", title: "服务内容", width: 100},
                { dataIndex: "serviced_start_at", title: "服务期间", width: 160},
                { dataIndex: "service_money", title: "费用(円)", width: 120},
                { dataIndex: "remark", title: "备注"},
                { dataIndex: "is_report", title: "服务报告", width: 90},
                { dataIndex: "created_at", title: "创建时间", width: 170},
                { dataIndex: "operation", title: "操作", width: 80},
            ],
            2: [
                { dataIndex: "patient", title: "联系人信息",width: 180},
                { dataIndex: "hospital_name", title: "就诊信息",width: 160},
                { dataIndex: "service_person_name", title: "服务人员", width: 100},
                { dataIndex: "added_service_name", title: "服务内容", width: 100},
                { dataIndex: "serviced_start_at", title: "服务期间", width: 160},
                { dataIndex: "service_money", title: "费用(円)", width: 120},
                { dataIndex: "remark", title: "备注"},
                { dataIndex: "is_report", title: "服务报告", width: 100},
                { dataIndex: "created_at", title: "创建时间", width: 170}
            ]
        }
        const submit = () => {
            if(!state.form.patient.name || !state.form.patient.sex || !state.form.patient.age || !state.form.patient.mobile || !state.form.hospital_name || !state.form.added_service_id || !state.form.serviced_start_at || !state.form.serviced_end_at) {
                proxy.$message.error('请填写必填项')
                return
            }
            if(proxy.$moment(state.form.serviced_start_at).diff(proxy.$moment(state.form.serviced_end_at)) > 0) {
                proxy.$message.error('开始时间不能大于结束时间')
                return
            }
            state.submitLoading = true
            if(state.modalType == 'edit') {
                proxy.$httpUtil.put(`/order/added_service_order/${state.detail.id}`, state.form).then(res => {
                    if(res.success) {
                        state.isModalShow = false
                        state.submitLoading = false
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }else {
                proxy.$httpUtil.post('/order/added_service_order', state.form).then(res => {
                    if(res.success) {
                        state.isModalShow = false
                        state.submitLoading = false
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const toDetail = data => {
            if(data.relation_order_type == 1) {
                proxy.$common.toPage('OrderTreatIndex', {id: data.relation_order_id})
            }else if(data.relation_order_type == 2) {
                proxy.$common.toPage('OrderOnlineIndex', {id: data.relation_order_id})
            }
        }
        const previewImg = url => {
            state.previewUrl = url
            state.isPreviewShow = true
        }
        const bindComfirm = () => {
            if(state.relation_order_id) {
                state.form.relation_order_id = state.relation_order_id
                for(let i in state.bindData) {
                    if(state.bindData[i].id == state.form.relation_order_id) {
                        state.form.hospital_name = state.bindData[i].hospital_name
                        state.form.service_name = state.bindData[i].service_name
                        state.form.patient = state.bindData[i].patient
                    }
                }
            }
            state.isOrderModalShow = false
        }
        const checkboxChange = (e, id) => {
            state.relation_order_id = 0
            if(e.target.checked) {
                state.relation_order_id = id
            }
        }
        const getBindData = async () => {
            state.bindLoading = true
            state.bindSearchForm.page = state.bindPagination.page
            let res = await proxy.$api.getAppointIndex(state.bindSearchForm)
            state.bindData = res.items
            state.bindPagination.total = res.total
            state.bindLoading = false
        }
        const bindSearchFormTypeChange = () => {
            refBindPagination.value.reset(1)
            state.bindPagination.page = 1
            getBindData()
        }
        const showOrderModal = async () => {
            state.bindPagination.page = 1
            state.bindSearchForm.type = 1
            state.relation_order_id = state.form.relation_order_id
            getBindData()
            state.isOrderModalShow = true
            nextTick(() => {
                refBindPagination.value.reset(1)
            })
        }
        const finish = id => {
            Modal.confirm({
                title: '确定完成该订单吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.get(`/order/added_service_order_confirm/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`订单已完成`)
                            getData()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const showReportModal = async data => {
            state.reportDetail = await proxy.$api.getServiceReport(data.id)
            state.isReportModalShow = true
        }
        const showCodeModal = async data => {
            state.reportCode = `${location.origin}/h5/login?number=${data.number}`
            state.isCodeModalShow = true
        }
        const dateChange = (value, type) => {
            state.form[type] = value
        }
        const showModal = async (type, data) => {
            state.modalType = type
            state.form = {
                relation_order_id: 0,
                patient: {}
            }
            if(type == 'edit') {
                state.detail = await proxy.$api.getServiceDetail(data.id)
                state.form = {
                    relation_order_id: state.detail.relation_order_id,
                    added_service_id: state.detail.added_service_id,
                    service_person_id: state.detail.service_person_id || undefined,
                    service_money: state.detail.service_money,
                    patient: {
                        name: state.detail.patient.name,
                        mobile: state.detail.patient.mobile,
                        age: state.detail.patient.age,
                        sex: state.detail.patient.sex,
                    },
                    hospital_name: state.detail.hospital_name,
                    service_name: state.detail.service_name,
                    remark: state.detail.remark,
                    serviced_start_at: state.detail.serviced_start_at,
                    serviced_end_at: state.detail.serviced_end_at,
                }
                state.relation_order_id = state.detail.relation_order_id
                state.isEdit = true
            }
            state.isModalShow = true
        }
        const tabChange = index => {
            state.tabActive = index
            state.tableColumns = column[index]
            state.pagination.page = 1
            refPagination.value.reset(1)
            getData()
        }
        const bindPageChange = data => {
            state.bindPagination.page = data.page
            getBindData()
        }
        const pageChange = data => {
            state.pagination.page = data.page
            getData()
        }
        const getData = async () => {
            state.loading = true
            searchForm.status = state.tabActive + 1
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getServiceOrder(searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64 - 68
        }
        const getPersonList = async id => {
            if(!state.isEdit) state.form.service_person_id = undefined
            state.personOptions = await proxy.$api.getServicePersonList(id)
            state.isEdit = false
        }
        watch(() => state.form.added_service_id, (_val, oldVal) => {
            getPersonList(_val)
        })
        onMounted(async () => {
            state.serviceOptions = await proxy.$api.getServiceList()
            tabChange(0);
        });
        return {
            ... toRefs(state),
            refPagination,
            tabs: ['未开始', '进行中', '已完成'],
            bindTableColumns: [
                { dataIndex: "check", title: "", width: 90},
                { dataIndex: "type", title: "订单类型", width: 180},
                { dataIndex: "patient", title: "用户信息"},
                { dataIndex: "hospital_name", title: "机构信息"}
            ],
            refBindPagination,
            bindComfirm,
            tabChange,
            showCodeModal,
            showModal,
            dateChange,
            pageChange,
            bindPageChange,
            submit,
            finish,
            showReportModal,
            showOrderModal,
            bindSearchFormTypeChange,
            checkboxChange,
            previewImg,
            toDetail
        }
    },
};
</script>
<style lang="less" scoped>
.report {
    display: flex;
    padding-bottom: 40px;
    .text {
        width: 50%;
        border: 1px solid #eee;
        padding: 12px;
        min-height: 500px;
    }
    .imgs {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 12px;
        .img {
            width: 94px;
            height: 94px;
            margin-right: 12px;
            margin-bottom: 12px;
        }
    }
}
/deep/.ant-select-selector,
/deep/.ant-select-selection-search-input {
    height: 100%!important;
    line-height: 46px!important;
    font-size: 14px;
}
/deep/.ant-select-selection-item,
/deep/.ant-select-selection-placeholder {
    line-height: 46px!important;
}
.table-toast {
    color: #252733;
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;

    .num {
        color: #3addb7;
    }
}
.table-icon {
    width: 24px !important;
    height: 24px !important;
}
.page-contener {
    height: 100%;
    padding: 16px;

    .tab-box {
        display: inline-block;
        background: #f4f8fe;
        border-radius: 5px;
        padding: 2px;

        .tab-btn {
            border: none;
            background: none;
            width: 144px;
            color: #252733;
            font-size: 16px;
            border-radius: 3px;
        }

        .tab-btn-active {
            color: #ffffff;
            background: #3addb7;
        }
    }


    .table-meeting {
        color: #3ADDB7;
        font-size: 16px;
        line-height: 20px;
        margin-left: 40px;
    }
}

.dialog-modal {

    .form-item-data {
        width: calc((100% - 64px) / 3) !important;
        margin-right: 32px;
        margin-bottom: 24px;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    ::v-deep .ant-divider-horizontal {
        margin: 8px 0px 30px;
    }

    .subtitle {
        color: #b6bbd7 !important;
        font-size: 20px;
        margin-left: 40px;
    }

    .inline-box {
        display: flex;
        justify-content: space-between;

        .line-item {
            width: calc((100% - 64px) / 2);
        }
    }

    .info-img {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        background: #e8e8e8;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    .file-box {
        display: flex;
        flex-wrap: wrap;
    }

    .info-file {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        border: 1px solid #e8e8e8;
        text-align: center;
        position: relative;

        &:nth-child(3n) {
            margin-right: 0px;
        }

        .img {
            width: 56px;
            height: 56px;
        }

        .file-name {
            color: #202020;
            font-size: 14px;
            line-height: 18px;
            margin-top: 16px;
        }

        .file-del {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 128px;
        height: 128px;
        border-radius: 8px;
        border: 1px solid #3addb7;
    }

    .meeting-title {
        color: #3addb7;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
    }

    .meeting-title:last-child {
        margin-top: 48px;
    }

    .meeting-info {
        border-radius: 16px;
        background: #35b8ff;
        color: #ffffff;
        padding: 24px 32px;

        .title {
            font-size: 24px;
            line-height: 40px;
            text-align: center;
        }

        .num-wapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 8px;

            .num {
                font-size: 44px;
                line-height: 64px;
                font-weight: 500;
            }

            .icon {
                width: 32px;
                height: 32px;
                margin-left: 24px;
            }
        }

        .password-wapper {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 24px;
            }

            .password {
                font-size: 24px;
                line-height: 24px;
            }
        }

        .time-wapper {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            .time {
                font-size: 40px;
                line-height: 48px;
            }

            .date {
                font-size: 20px;
                line-height: 28px;
            }

            .duration {
                width: 112px;
                height: 48px;
                font-size: 20px;
                line-height: 28px;
                border-bottom: 2px solid #ffffff;
                padding-top: 12px;
                padding-bottom: 8px;
            }
        }
    }
}</style>