<template>
  <global-menu
    open="1"
    selected="1-3"
    pageTitle="商品  订单"
    :isShowNew="false"
  >
    <global-search
      class="mg-t-19"
      :searchList="searchList"
      :searchDorm="searchForm"
      @search="search"
    ></global-search>
    <div class="page-contener">
      <g-tab :tabs="tabs" @change="tabChange"></g-tab>
      <a-table
        class="mg-t-16"
        :columns="tableColumns"
        :dataSource="tableData"
        :loading="loading"
      >
        <template #bodyCell="{ column }">
          
          <div v-if="column && column.dataIndex === 'message'">
            <img
              class="table-icon"
              src="../../../assets/img/icon_message_cyan.png"
              alt=""
            />
          </div>
          <div v-if="column && column.dataIndex === 'pay'">
            <img
              class="table-icon"
              src="../../../assets/img/icon_amount_green.png"
              alt=""
            />
          </div>
          <div v-if="column && column.dataIndex === 'deliver'">
            <img
              class="table-icon"
              src="../../../assets/img/icon_delivery_orange.png"
              alt=""
            />
          </div>
          <div v-if="column && column.dataIndex === 'operation'">
            <img
              v-if="tabActive==6"
              style="width: 24px; height: 24px"
              src="../../../assets/img/icon_delete_red.png"
              alt=""
            />
            <img
              v-else-if="tabActive==3||tabActive==5"
              style="width: 24px; height: 24px"
              src="../../../assets/img/icon_detail.png"
              alt=""
               @click="$common.toPage('OrderGoodsDetail',{title:'订单详情'})"
            />
            <img
              v-else
              style="width: 24px; height: 24px"
              src="../../../assets/img/icon_report_orange.png"
              alt=""
              @click="$common.toPage('OrderGoodsDetail',{title:'订单处理'})"
            />
            <!-- <a-tooltip >
              <template v-slot:title>
                <div>
                  <img
                    style="width: 24px; height: 24px"
                    src="../../../assets/img/icon_edit_white.png"
                    alt=""
                    @click="edit"
                  />
                  <img
                    style="width: 24px; height: 24px"
                    class="mg-l-16"
                    src="../../../assets/img/icon_cancel_white.png"
                    alt=""
                    @click="del"
                  />
                  <img
                    style="width: 24px; height: 24px"
                    class="mg-l-16"
                    src="../../../assets/img/icon_barcode_white.png"
                    alt=""
                  />
                </div>
              </template>
              <img
                class="table-icon"
                src="../../../assets/img/icon_more.png"
                alt=""
              />
            </a-tooltip> -->
          </div>
        </template>
      </a-table>
    </div>
  </global-menu>
  <global-dialog
    :isShow="isModalShow"
    :title="modalTitle"
    @onCancel="closeModal"
    @onOk="submit"
    :isShowFooter="isShowFooter"
    class="dialog-modal"
    :width="modalWidth"
  >
    <div v-if="modalTitle == '新增'">
      <div>
        <global-title class="mg-t-16" title="患者信息"></global-title>
        <div class="mg-t-26">
          <g-input
            v-model="form.name"
            class="form-item-data"
            placeholder="姓名（中文）"
            :icon="require('@/assets/img/icon_user.png')"
          ></g-input>
          <g-input
            v-model="form.name"
            class="form-item-data"
            placeholder="姓名拼音"
            :icon="require('@/assets/img/icon_user.png')"
          ></g-input>
          <g-select
            v-model="form.sex"
            :icon="require('@/assets/img/icon_user.png')"
            placeholder="性别"
            class="form-item-data"
            :options="sexs"
          ></g-select>
          <g-date class="form-item-data" placeholder="出生日期"></g-date>
          <g-input
            v-model="form.name"
            class="form-item-data"
            placeholder="联系电话"
            :icon="require('@/assets/img/icon_phone.png')"
          ></g-input>
        </div>
        <a-divider />
        <global-title title="就诊信息"></global-title>
        <div class="mg-t-26">
          <!-- <a-select placeholder="选择机构" class="form-item-data" size="large">
            <a-select-option :value="1">机构1</a-select-option>
            <a-select-option :value="2">机构2</a-select-option>
          </a-select> -->
          <g-select
            v-model="form.org"
            :icon="require('@/assets/img/icon_hospital.png')"
            placeholder="选择机构"
            class="form-item-data"
            :options="orgs"
          ></g-select>
          <global-doctor
            class="form-item-data"
            placeholder="科室 / 医生"
            :icon="require('@/assets/img/icon_department.png')"
          ></global-doctor>
          <g-date class="form-item-data" placeholder="就诊时间"></g-date>
        </div>
      </div>
    </div>
    <template v-slot:subtitle v-if="modalTitle == '就诊资料'">
      <span class="subtitle">梅小青</span>
    </template>
    <div v-if="modalTitle == '就诊资料'">
      <div class="inline-box mg-t-16">
        <div class="line-item">
          <global-title title="患者资料"></global-title>
          <div class="mg-t-28">
            <img class="info-img" src="" alt="" />
            <img class="info-img" src="" alt="" />
            <img class="info-img" src="" alt="" />
            <img class="info-img" src="" alt="" />
            <img class="info-img" src="" alt="" />
            <img class="info-img" src="" alt="" />
            <img class="info-img" src="" alt="" />
          </div>
        </div>
        <div class="line-item">
          <global-title title="机构资料"></global-title>
          <div class="mg-t-28 file-box">
            <a-upload
              class="avatar-uploader info-file"
              name="avatar"
              list-type="picture-card"
              :show-upload-list="false"
            >
              <div>
                <loading-outlined v-if="fileLoading"></loading-outlined>
                <plus-outlined v-else style="color: #3addb7"></plus-outlined>
                <div class="ant-upload-text" style="color: #3addb7">
                  点击上传
                </div>
              </div>
            </a-upload>

            <div class="info-file pd-12">
              <img class="img" />
              <div class="file-name ellipsis-2">
                机构上传的文件的名称有可能…
              </div>
              <img
                class="file-del"
                src="../../../assets/img/icon_delete_red.png"
                alt=""
              />
            </div>
            <div class="info-file pd-12">
              <img class="img" />
              <div class="file-name ellipsis-2">
                机构上传的文件的名称有可能…
              </div>
              <img
                class="file-del"
                src="../../../assets/img/icon_delete_red.png"
                alt=""
              />
            </div>
            <div class="info-file pd-12">
              <img class="img" />
              <div class="file-name ellipsis-2">
                机构上传的文件的名称有可能…
              </div>
              <img
                class="file-del"
                src="../../../assets/img/icon_delete_red.png"
                alt=""
              />
            </div>
            <div class="info-file pd-12">
              <img class="img" />
              <div class="file-name ellipsis-2">
                机构上传的文件的名称有可能…
              </div>
              <img
                class="file-del"
                src="../../../assets/img/icon_delete_red.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modalTitle == '创建会议'">
      <div class="meeting-title">会议时间</div>
      <g-date class="mg-t-24" placeholder="开始时间"></g-date>
      <g-input
        :icon="require('@/assets/img/icon_time.png')"
        class="mg-t-24"
        placeholder="会议时长"
      ></g-input>
      <div class="meeting-title">会议密码</div>
      <g-input
        :icon="require('@/assets/img/icon_password.png')"
        class="mg-t-24"
        placeholder="密码"
        type="password"
      ></g-input>
    </div>
    <div v-if="modalTitle == '会议信息'">
      <div class="mg-t-16">
        <div class="meeting-info">
          <div class="title">腾讯会议</div>
          <div class="num-wapper">
            <div class="num">869 941 495</div>
            <img
              class="icon"
              src="../../../assets/img/icon_report_white.png"
              alt=""
            />
          </div>
          <div class="password-wapper">
            <img
              class="icon"
              src="../../../assets/img/icon_password.png"
              alt=""
            />
            <span class="password">J8HY9T</span>
          </div>
          <div class="time-wapper">
            <div>
              <div class="time">10:30</div>
              <div class="date">2022.03.28</div>
            </div>
            <div class="duration">
              30分钟
            </div>
            <div>
              <div class="time">11:30</div>
              <div class="date">2022.03.28</div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <template #footer v-if="modalTitle == '会议信息'">
        <div>
          <g-button type="error" ghost>取消会议</g-button>
          <g-button type="default" class="mg-l-40" ghost @click="closeModal">关闭</g-button>
        </div>
      </template>
  </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalDoctor from "@/components/GlobalDoctor.vue";
import GInput from "@/components/GInput.vue";
import GSelect from "@/components/GSelect.vue";
import GButton from "@/components/GButton.vue";
import GDate from "@/components/GDate.vue";
import { onMounted, ref } from "vue";
import GTab from "@/components/GTab.vue"
export default {
  components: {
    GlobalMenu,
    GlobalSearch,
    GlobalDialog,
    GInput,
    GSelect,
    GDate,
    GlobalDoctor,
    GButton,
    GTab
  },

  setup() {
    let tabs=ref(['待回复  3','待确认  3','待支付  5','待发货  2','已完成  9','已取消  2'])
    let isModalShow = ref(false);
    let modalTitle = ref();
    let modalWidth = ref(1008);
    let isShowFooter = ref(true);
    let searchList = ref([
      {
        type: "input",
        model: "num",
        placeholder: "订单编号",
        icon: require("@/assets/img/icon_order.png"),
      },
      {
        type: "input",
        model: "user",
        placeholder: "下单用户",
        icon: require("@/assets/img/icon_user.png"),
      },
      {
        type: "date",
        model: "address",
        placeholder: "下单时间",
      },
    ]);
    let searchForm = ref({
      name: undefined,
      phone: undefined,
      address: undefined,
      date: undefined,
    });
    let tabActive = ref(0);
    const tableColumns = ref([]);
    let column1 = ref([
      { dataIndex: "data1", title: "订单编号" },
      { dataIndex: "data2", title: "订单金额(円) (不含运费)" },
      { dataIndex: "data3", title: "下单用户" },
      { dataIndex: "data4", title: "下单时间" },
      { dataIndex: "message", title: "消息" },
      { dataIndex: "operation", title: "操作" },
    ]);
    let column2 = ref([
      { dataIndex: "data1", title: "订单编号" },
      { dataIndex: "data3", title: "下单用户" },
      { dataIndex: "data4", title: "订单金额 (含运费)(円)" },
      { dataIndex: "data5", title: "下单时间" },
      { dataIndex: "message", title: "消息" },
      { dataIndex: "operation", title: "详情" },
    ]);
    let column3 = ref([
      { dataIndex: "data1", title: "订单编号" },
      { dataIndex: "data3", title: "下单用户" },
      { dataIndex: "data4", title: "订单金额(円)" },
      { dataIndex: "data5", title: "下单时间" },
      { dataIndex: "message", title: "消息" },
      { dataIndex: "pay", title: "支付" },
       { dataIndex: "deliver", title: "发货" },
    ]);
    let column4 = ref([
      { dataIndex: "data1", title: "订单编号" },
      { dataIndex: "data3", title: "下单用户" },
      { dataIndex: "data4", title: "订单金额(円)" },
      { dataIndex: "data5", title: "下单时间" },
      { dataIndex: "message", title: "消息" },
      { dataIndex: "pay", title: "支付" },
       { dataIndex: "operation", title: "详情" },
    ]);
    let column5 = ref([
      { dataIndex: "data1", title: "订单编号" },
      { dataIndex: "data3", title: "下单用户" },
      { dataIndex: "data4", title: "订单金额(円) (不含运费)" },
      { dataIndex: "data5", title: "下单时间" },
      { dataIndex: "data6", title: "取消时间" },
       { dataIndex: "operation", title: "操作" },
    ]);
    let tableData = ref([{}]);
    let loading = ref(false);
    let form = ref({});
    let sexs = ref([
      { id: 1, name: "男" },
      { id: 2, name: "女" },
    ]);
    let orgs = ref([
      {
        id: 1,
        name: "机构1",
      },
      {
        id: 2,
        name: "机构2",
      },
      {
        id: 3,
        name: "机构3",
      },
    ]);

    function search(searchData) {
      console.log(searchData);
    }
    function tabChange(index) {
      tabActive.value = index;
      if(index==0||index==1){
        tableColumns.value = column1.value;
      } else if (index == 2) {
        tableColumns.value = column2.value;
      } else if (index == 3) {
        tableColumns.value = column3.value;
      } else if(index==4){
        tableColumns.value = column4.value;
      }else{
        tableColumns.value = column5.value;
      }
    }
    function edit() {
      console.log("edit");
    }
    function del() {
      console.log("del");
    }
    function showModal(title, showFooter, width) {
      console.log(width);
      modalTitle.value = title;
      isModalShow.value = true;
      isShowFooter.value = showFooter;
      if (modalWidth) {
        modalWidth.value = width;
      } else {
        modalWidth.value = 1008;
      }
    }
    function closeModal() {
      isModalShow.value = false;
    }
    function submit() {
      console.log(form.value);
    }
    onMounted(() => {
      tabChange(0);
    });
    return {
      searchList,
      searchForm,
      tabActive,
      tableColumns,
      tableData,
      loading,
      isModalShow,
      modalTitle,
      modalWidth,
      isShowFooter,
      form,
      sexs,
      orgs,
      tabs,

      search,
      tabChange,
      edit,
      del,
      showModal,
      closeModal,
      submit,
    };
  },
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 78px - 64px - 32px);
  overflow: auto;
  margin-top: 12px;
  padding: 16px;
  .tab-box {
    display: inline-block;
    background: #f4f8fe;
    border-radius: 5px;
    padding: 2px;
    .tab-btn {
      border: none;
      background: none;
      width: 144px;
      color: #252733;
      font-size: 16px;
      border-radius: 3px;
    }
    .tab-btn-active {
      color: #ffffff;
      background: #3addb7;
    }
  }
  .table-icon {
    width: 24px !important;
    height: 24px !important;
  }
}
.dialog-modal {
  padding-bottom: 64px;
  .form-item-data {
    width: calc((100% - 64px) / 3) !important;
    margin-right: 32px;
    margin-bottom: 24px;
    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
  ::v-deep .ant-divider-horizontal {
    margin: 8px 0px 30px;
  }
  .subtitle {
    color: #b6bbd7 !important;
    font-size: 20px;
    margin-left: 40px;
  }
  .inline-box {
    display: flex;
    justify-content: space-between;
    .line-item {
      width: calc((100% - 64px) / 2);
    }
  }
  .info-img {
    width: 128px;
    height: 128px;
    margin-right: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    background: #e8e8e8;
    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
  .file-box {
    display: flex;
    flex-wrap: wrap;
  }
  .info-file {
    width: 128px;
    height: 128px;
    margin-right: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid #e8e8e8;
    text-align: center;
    position: relative;
    &:nth-child(3n) {
      margin-right: 0px;
    }
    .img {
      width: 56px;
      height: 56px;
    }
    .file-name {
      color: #202020;
      font-size: 14px;
      line-height: 18px;
      margin-top: 16px;
    }
    .file-del {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  ::v-deep .ant-upload.ant-upload-select-picture-card {
    width: 128px;
    height: 128px;
    border-radius: 8px;
    border: 1px solid #3addb7;
  }
  .meeting-title {
    color: #3addb7;
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
  }
  .meeting-title:last-child {
    margin-top: 48px;
  }
  .meeting-info {
    border-radius: 16px;
    background: #35b8ff;
    color: #ffffff;
    padding: 24px 32px;
    .title {
      font-size: 24px;
      line-height: 40px;
      text-align: center;
    }
    .num-wapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 8px;
      .num {
        font-size: 44px;
        line-height: 64px;
        font-weight: 500;
      }
      .icon {
        width: 32px;
        height: 32px;
        margin-left: 24px;
      }
    }
    .password-wapper {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 24px;
      }
      .password {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .time-wapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .time {
        font-size: 40px;
        line-height: 48px;
      }
      .date {
        font-size: 20px;
        line-height: 28px;
      }
      .duration {
        width: 112px;
        height: 48px;
        font-size: 20px;
        line-height: 28px;
        border-bottom: 2px solid #ffffff;
        padding-top: 12px;
        padding-bottom: 8px;
      }
    }
    .footer{

    }
  }
}
</style>