<template>
    <global-menu open="3" selected="3-3" pageTitle="平台业务" :isShowNew="false">
        <global-search
            :searchList="searchList"
            :searchDorm="searchForm"
            @search="search"
        />
        <div class="page-contener">
            <g-tab :tabs="tab" @change="tabChange"></g-tab>
            <div class="flex mg-t-24">
                <div class="item">
                    <div class="label">订单总额(円)</div>
                    <div class="price">{{ statistics.business_order_money_total }}</div>
                </div>
                <div class="item">
                    <div class="label">通道费(円)</div>
                    <div class="price">{{ statistics.platform_service_money_total }}</div>
                </div>
                <!-- <div class="item">
                    <div class="label">收入(円)</div>
                    <div class="price">{{ statistics.business_settle_money_total }}</div>
                </div> -->
                <div class="item">
                    <div class="label">支出(円)</div>
                    <div class="price">{{ statistics.income_money_total }}</div>
                </div>
                <div class="item">
                    <div class="label">利润(円)</div>
                    <div class="price">{{ statistics.profit_money_total }}</div>
                </div>
            </div>
            <a-table :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'income_money'">
                        <a @click="showModal">{{ record.income_money }}</a>
                    </div>
                    <div v-if="column.dataIndex === 'status'">
                        <a :style="{'color': record.status == 2 ? '#FF5733' : '#3addb7'}">{{ record.status == 1 ? '待结算' : '已结算' }}</a>
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" title="支出" @onOk="submit" :loading="submitLoading" @onCancel="closeModal">
        <a-row :gutter="24">
            <a-col class="mg-t-26" :span="13">
                <global-title title="支出对象" />
                <g-input class="mg-t-24" v-model="form.business_object" placeholder="支出对象"></g-input>
            </a-col>
            <a-col class="mg-t-26" :span="11">
                <global-title title="支出金额" />
                <g-input class="mg-t-24" v-model="form.business_settle_money" placeholder="支出金额" suffix="円"></g-input>
            </a-col>
        </a-row>
        <global-title class="mg-t-24" title="支出内容" />
        <a-textarea
            class="mg-t-24"
            placeholder="内容"
            :auto-size="{ minRows: 3, maxRows: 6 }"
            v-model:value="form.business_content"
        />
        <div class="mg-t-24">
            <global-title title="支出凭证" />
            <div class="file-list mg-t-24">
                <div class="item" v-for="item, index in files" :key="index">
                    <img class="img pointer" :src="item.path" alt="" @click="previewImg(item.path)">
                    <CloseCircleFilled class="icon" v-if="modalType == 'settle'" @click.stop="removeFile(index)" />
                </div>
                <div class="item" v-if="modalType != 'info'">
                    <a-upload name="avatar" class="avatar-uploader" :show-upload-list="false" :customRequest="fileChange">
                        <div class="flex-center pointer">
                            <div class="text-center">
                                <plus-circle-outlined :style="{ fontSize: '24px', color: '#3addb7' }" /><br>
                                点击上传
                            </div>
                        </div>
                    </a-upload>
                </div>
            </div>
        </div>
    </global-dialog>
    <preview :url="previewUrl" v-if="isPreviewShow" @hide="isPreviewShow = false" />
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue"
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GInput from "@/components/GInput.vue";
import preview from "@/components/preview.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue";
import { PlusCircleFilled, PlusCircleOutlined, CloseCircleFilled } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import Pagination from "@/components/Pagination.vue"
import GTab from "@/components/GTab.vue"

export default {
    components: {
        GlobalMenu,
        PlusCircleFilled,
        PlusCircleOutlined,
        Pagination,
        GlobalDialog,
        GlobalTitle,
        GInput,
        GlobalSearch,
        CloseCircleFilled,
        GTab,
        preview
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        let state = reactive({
            tabActive: 0,
            loading: false,
            tableData: [],
            statistics: {},
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
            isModalShow: false,
            detail: {},
            files: [],
            form: {
                business_object: undefined,
                business_content: undefined,
                business_settle_money: undefined,
                business_voucher: []
            },
            submitLoading: false,
            previewUrl: '',
            isPreviewShow: false
        })
        let searchForm = reactive({
            statistics_month: undefined,
            business_source: 5,
            page: 1,
            page_size: 10,
        })
        const previewImg = url => {
            state.previewUrl = url
            state.isPreviewShow = true
        }
        const submit = async () => {
            if(!state.form.business_object || !state.form.business_content || !state.form.business_settle_money) {
                proxy.$message.error('请填写完整')
                return
            }
            if(!state.files.length && !state.form.business_voucher.length) {
                proxy.$message.error('请上传凭证')
                return
            }
            state.submitLoading = true
            state.form.business_voucher = []
            for(let i = 0; i < state.files.length; i ++) {
                if(state.files[i].file) {
                    let res = await proxy.$httpUtil.upload('/platform/file_upload', state.files[i].file, 'order')
                    if(res.file_url) {
                        state.form.business_voucher.push(res.file_url)
                    }else {
                        proxy.$message.error('图片上传失败')
                        state.submitLoading = false
                        return false
                    }
                }else if(state.files[i].path) {
                    state.form.business_voucher.push(state.files[i].path)
                }
            }
            proxy.$httpUtil.put(`/manage/trade_settle/${state.detail.id}`, state.form).then(res => {
                if(res.success) {
                    state.submitLoading = false
                    closeModal()
                    getData()
                }
            }).catch(() => {
                state.submitLoading = false
            })
        }
        const search = (searchData) => {
            searchForm = {... searchForm, ... searchData}
            state.pagination.page = 1
            refPagination.value.reset(1)
            getData()
        }
        const pageChange = data => {
            state.pagination.page = data.page
            getData()
        }
        const fileChange = info => {
            state.files.push({
                file: info.file,
                path: proxy.$common.getFileUrl(info.file)
            })
        }
        const showModal = (data) => {
            state.detail = data
            state.isModalShow = true
            state.files = []
            state.modalType = 'settle'
            state.modalTitle = '结算'
            state.form = {
                business_object: undefined,
                business_content: undefined,
                business_settle_money: undefined,
                business_voucher: []
            }
        }
        const closeModal = () => {
            state.isModalShow = false
        }
        const tabChange = index => {
            state.tabActive = index
            getData()
        }
        const getData = async () => {
            state.loading = true
            searchForm.page = state.pagination.page
            searchForm.business_source = state.tabActive + 5
            let res = await proxy.$api.getTradePlatform(searchForm)
            state.tableData = res.items
            state.statistics = res.statistics
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 48 - 108 - 56 - 48
        }
        onMounted(() => {
            tabChange(0)
        })
        return {
            tab: ['人工咨询', '处方开药', '商品销售'],
            searchList: [
                {
                    type: "month",
                    model: "statistics_month",
                    placeholder: "选择统计年月",
                    icon: require("@/assets/img/icon_date.png"),
                }
            ],
            searchForm,
            tableColumns: [
                {
                    dataIndex: "business_order_number",
                    title: "订单编号",
                },
                {
                    dataIndex: "business_order_number",
                    title: "订单金额(円)",
                },
                {
                    dataIndex: "platform_service_money",
                    title: "通道费(円)",
                },
                {
                    dataIndex: "business_settle_money",
                    title: "收入(円)",
                },
                {
                    dataIndex: "income_money",
                    title: "支出(円)",
                },
                {
                    dataIndex: "profit_money",
                    title: "利润(円)",
                },
                // {
                //     dataIndex: "status",
                //     title: "状态",
                // },
                {
                    dataIndex: "created_at",
                    title: "创建时间",
                },
            ],
            refPagination,
            ... toRefs(state),

            tabChange,
            search,
            showModal,
            fileChange,
            closeModal,
            submit,
            previewImg,
            pageChange
        };
    },
};
</script>
<style lang="less" scoped>
.file-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;
    .item {
        position: relative;
        width: 138px;
        height: 80px;
        margin-right: 12px;
        margin-bottom: 12px;
        .flex-center {
            width: 138px;
            height: 80px;
            border: 2px dotted #3addb7;
            color: #3addb7;
            border-radius: 8px;
        }
        .img {
            width: 138px;
            height: 80px;
            border-radius: 8px;
            object-fit: cover;
        }
        .icon {
            position: absolute;
            right: -8px;
            top: -8px;
            width: 16px;
            height: 16px;
            font-size: 16px;
            color: red;
            z-index: 1;
            cursor: pointer;
        }
    }
}
.page-contener {
    height: calc(100% - 92px);
    margin-top: 12px;
    padding: 16px;
    .flex {
        padding-bottom: 24px;
        display: flex;
        .item {
            flex: 1 0 0 ;
            .label {
                font-size: 16px;
                line-height: 24px;
                display: block;
                padding: 0;
                border-radius: 0;
                text-align: left;
                font-weight: normal;
                color: #333;
            }
            .price {
                margin-top: 12px;
                font-size: 36px;
                line-height: 48px;
                color: #3addb7;
            }
        }
    }
    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .table-img {
        width: 32px !important;
        height: 32px !important;
    }
}

.dialog-wapper {
    .batch-text {
        color: #3addb7;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .delete-icon {
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }

    .sub-title {
        color: #252733;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
    }

    .sub-tip {
        color: #65697b;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    /deep/.upload-icon {
        width: 343px;
        height: 114px;
        border-radius: 8px;
    }

    .upload-text {
        width: 100%;
        color: #252733;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: none;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    ::v-deep .ant-upload-picture-card-wrapper {
        width: auto;
    }

    .color-wapper {
        flex: 0 0 auto;
        height: 48px;
        width: 80px;
        border: 1px solid #b6bbd7;
        border-radius: 10px;
    }
}
</style>