<template>
    <global-menu :open="open" :selected="selected" :isShowNew="false">
        <section class="page-contener">
            <global-header title="账单详情">
                <template #title_content>
                    <div class="title-content">{{ detail.number }}</div>
                </template>
                <template #default>
                    <span :class="`status-text status-${detail.status}`">{{ detail.status == 1 ? '待支付' : detail.status == 2 ? '已支付' : '已退款' }}</span>
                </template>
            </global-header>
            <div class="content-wapper" :class="{'has-footer': orderDetail.status != 4}">
                <global-title title="收款明细"></global-title>
                <a-table class="mg-t-20" :columns="tableColumns" :dataSource="tableData" :pagination="false">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'total'">
                            {{ record.cost_money * record.num }}
                        </template>
                    </template>
                    <template #summary>
                        <a-table-summary-row>
                            <a-table-summary-cell :col-span="2">账单合计：</a-table-summary-cell>
                            <a-table-summary-cell>
                                <a-typography-text>{{ total.num }}</a-typography-text>
                            </a-table-summary-cell>
                            <a-table-summary-cell>
                                <a-typography-text style="color: #ff504a">{{ total.price }}</a-typography-text>
                            </a-table-summary-cell>
                        </a-table-summary-row>
                        <a-table-summary-row>
                            <a-table-summary-cell :col-span="3">创建日期：</a-table-summary-cell>
                            <a-table-summary-cell style="text-align: right">
                                <a-typography-text>{{ detail.created_at }}</a-typography-text>
                            </a-table-summary-cell>
                        </a-table-summary-row>
                    </template>
                </a-table>
                <a-row :gutter="24" class="mg-t-48">
                    <a-col :span="12" v-if="detail.status != 1">
                        <global-title title="支付信息"></global-title>
                        <a-row :gutter="24" class="mg-t-32">
                            <a-col :span="12">
                                <g-row-text title="支付金额：">{{ detail.pay_money }}</g-row-text>
                            </a-col>
                            <a-col :span="12">
                                <g-row-text title="支付方式：">{{ detail.pay_method == 3 ? '现金' : detail.pay_method == 4 ? '信用卡' : '线上' }}</g-row-text>
                            </a-col>
                            <a-col :span="12" class="mg-t-24">
                                <g-row-text title="优惠金额：">{{ detail.rate_money }}</g-row-text>
                            </a-col>
                            <a-col :span="12" class="mg-t-24">
                                <g-row-text title="支付时间：">{{ detail.pay_at ? detail.pay_at : '-' }}</g-row-text>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="12" v-if="detail.status == 3">
                        <global-title title="退款信息"></global-title>
                        <a-row :gutter="24" class="mg-t-32">
                            <a-col :span="12">
                                <g-row-text title="退款金额：">{{ detail.refund_money }}</g-row-text>
                            </a-col>
                            <a-col :span="12">
                                <g-row-text title="退款时间：">{{ detail.refund_at }}</g-row-text>
                            </a-col>
                            <a-col :span="12" class="mg-t-24">
                                <g-row-text title="退款方式：">原路退回</g-row-text>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </div>
            <div class="btn-wapper flex-center" v-if="orderDetail.status != 4">
                <g-button type="warn" @click="showModal('get')" v-if="detail.status == 1">手动收款</g-button>
                <g-button type="error" class="mg-l-40" @click="showModal('back')" v-if="detail.status == 2">退款</g-button>
                <g-button type="error" ghost class="mg-l-40" v-if="detail.status == 1" @click="del">删除该账单</g-button>
            </div>
        </section>
    </global-menu>
    <global-dialog :title="modalType == 'get' ? '手动收款' : '账单退款'" :isShow="modalShow" @onOk="submit" @onCancel="closeModal" :loading="submitLoading" :width="560"
        :okText="modalType == 'get' ? '确定收款' : '确定退款'">
        <div class="dialog-wapper" v-if="modalType == 'get'">
            <global-title title="收款金额"></global-title>
            <div class="flex-between mg-t-24">
                <div class="input-box">
                    <g-input :icon="require('../../../assets/img/icon_cost.png')" placeholder="金额" v-model="payForm.pay_money"></g-input>
                </div>
                <span class="price-unit">円</span>
            </div>
            <global-title title="收款方式" class="mg-t-48"></global-title>
            <div class="flex-between mg-t-24">
                <div class="item pointer" :class="{ 'item-active': tabIndex == 1 }" @click="changeTab(1)">
                    现金
                </div>
                <div class="item pointer" :class="{ 'item-active': tabIndex == 2 }" @click="changeTab(2)">
                    信用卡
                </div>
                <div class="item pointer" :class="{ 'item-active': tabIndex == 3 }" @click="changeTab(3)">
                    支付宝
                </div>
                <div class="item pointer" :class="{ 'item-active': tabIndex == 4 }" @click="changeTab(4)">
                    微信
                </div>
            </div>
            <div class="text-center mg-t-32" v-show="tabIndex > 2">
                <vue-qr class="code-image" :text="payCode" :size="160"></vue-qr>
                <div class="code-text">收款二维码</div>
            </div>
        </div>
        <div class="dialog-wapper" v-if="modalType == 'back'">
            <global-title title="退款设置"></global-title>
            <div class="flex-between mg-t-24">
                <div class="input-box">
                    <g-input :icon="require('../../../assets/img/icon_cost.png')" placeholder="退款金额" v-model="backForm.refund_money"></g-input>
                </div>
                <span class="price-unit">円</span>
            </div>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GRowText from "@/components/GRowText.vue";
import GButton from "@/components/GButton.vue";
import GInput from "@/components/GInput.vue";
import GSelect from "@/components/GSelect.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router"
import { Modal } from 'ant-design-vue'
import $router from "@/router";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
    components: {
        vueQr,
        GlobalMenu,
        GlobalHeader,
        GlobalTitle,
        GRowText,
        GButton,
        GInput,
        GlobalDialog,
        GSelect
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const route = useRoute()
        let state = reactive({
            open: '1',
            selected: '1-1',
            tableData: [],
            detail: {},
            modalShow: false,
            modalType: '',
            tabIndex: 1,
            total: {
                num: 0,
                price: 0
            },
            payCode: '',
            payForm: {
                order_id: '',
                pay_method: 3,
                pay_money: ''
            },
            backForm: {
                order_id: '',
                refund_money: ''
            },
            submitLoading: false,
            orderDetail: {}
        })
        const submit = () => {
            if(state.modalType == 'get') {
                if(!state.payForm.pay_money) {
                    proxy.$message.error('请输入收款金额')
                    return
                }
                state.submitLoading = true
                if(state.payForm.pay_method == 5) {
                    proxy.$httpUtil.put(`/order/pay_bill_confirm/${state.payForm.order_id}`, {
                        pay_money: state.payForm.pay_money
                    }).then(async () => {
                        proxy.$message.success(`支付成功`)
                        let res = await proxy.$api.getBillDetail(route.query.id)
                        state.detail = res.bill_order
                        state.tableData = res.inventory_list
                        closeModal()
                    }).catch(() => {
                        state.submitLoading = false
                    })
                }else {
                    proxy.$httpUtil.post('/order/bill_pay', state.payForm).then(async () => {
                        proxy.$message.success(`支付成功`)
                        let res = await proxy.$api.getBillDetail(route.query.id)
                        state.detail = res.bill_order
                        state.tableData = res.inventory_list
                        closeModal()
                    }).catch(() => {
                        state.submitLoading = false
                    })
                }
            }else if(state.modalType == 'back') {
                if(!state.backForm.refund_money) {
                    proxy.$message.error('请输入退款金额')
                    return
                }
                state.submitLoading = true
                state.backForm.order_id = state.detail.id
                proxy.$httpUtil.post('/order/bill_refund', state.backForm).then(async () => {
                    proxy.$message.success(`退款成功`)
                    let res = await proxy.$api.getBillDetail(route.query.id)
                    state.detail = res.bill_order
                    state.tableData = res.inventory_list
                    closeModal()
                }).catch(() => {
                    state.submitLoading = false
                })
            }
            
        }
        const changeTab = value => {
            state.tabIndex = value
            state.payForm.pay_method = value + 2
            if(value == 3 || value == 4) {
                state.payForm.pay_method = 5
                proxy.$httpUtil.post('/order/pay', {
                    pay_method: value == 3 ? 2 : 1,
                    order_id: state.detail.id
                }).then(res => {
                    state.payCode = res.data.pay_params.pay_code_url
                })
            }
        }
        const showModal = type => {
            state.modalShow = true
            state.modalType = type
        }
        const closeModal = () => {
            state.modalShow = false
            state.submitLoading = false
        }
        const del = () => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/order/bill/${state.detail.id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            $router.back()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        state.open = route.query.open
        state.selected = route.query.selected
        onMounted(async () => {
            state.orderDetail = await proxy.$api.getOrderDetail(route.query.orderId)
            let res = await proxy.$api.getBillDetail(route.query.id)
            state.detail = res.bill_order
            state.tableData = res.inventory_list
            for(let i in state.tableData) {
                let itemTotal = state.tableData[i].cost_money * state.tableData[i].num
                state.total.num += state.tableData[i].num
                state.total.price += itemTotal
            }
            state.payForm.order_id = state.detail.id
        })
        return {
            ... toRefs(state),
            tableColumns: [
                {
                    dataIndex: "cost_name",
                    title: "收款项目",
                },
                {
                    dataIndex: "cost_money",
                    title: "单价（円）",
                    width: 250,
                },
                {
                    dataIndex: "num",
                    title: "数量",
                    width: 120,
                },
                {
                    dataIndex: "total",
                    title: "合计（円）",
                    width: 250,
                },
            ],

            showModal,
            closeModal,
            changeTab,
            submit,
            del
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;
    position: relative;

    .title-content {
        color: #b6bbd7;
        font-size: 20px;
        line-height: 20px;
        margin-left: 40px;
    }

    .status-text {
        width: 68px;
        color: #3addb7;
        font-size: 20px;
        line-height: 20px;
        &.status-1 {
            color: #FFB03E;
        }
        &.status-3 {
            color: #FF504A;
        }
    }

    .content-wapper {
        height: calc(100% - 96px - 40px);
        padding: 16px 36px 32px;
        overflow: auto;
        &.has-footer {
            height: calc(100% - 96px - 40px - 68px);
        }
        .pay-wapper {
            padding: 16px 6px;
        }
    }

    .btn-wapper {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 40px;
    }
}

.dialog-wapper {
    padding-top: 16px;

    .input-box {
        width: 100%;
        background: #f4f8fe;
        border-radius: 8px;
    }

    .price-unit {
        color: #252733;
        font-size: 16px;
        line-height: 20px;
        margin-left: 16px;
    }

    .item {
        width: calc((100% - 48px) / 4);
        height: 80px;
        color: #252733;
        font-size: 18px;
        line-height: 80px;
        border-radius: 8px;
        background: #f4f8fe;
        text-align: center;
    }

    .item-active {
        background: #f3fbf9;
        border: 1px solid #3addb7;
        color: #3addb7;
        font-size: 20px;
    }

    .code-img {
        width: 160px;
        height: 160px;
    }

    .code-text {
        color: #b6bbd7;
        font-size: 16px;
        line-height: 22px;
        margin-top: 20px;
    }
}
</style>