<template>
    <global-menu open="7" selected="7-10" pageTitle="增值服务设置" @clickNew="showModal('create')">
        <template #btnText>新增</template>
        <div class="page-contener">
            <a-table :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'operation'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_edit_cyan.png" alt="" @click="showModal('edit', record)" />
                        <img class="table-icon mg-l-8  pointer" src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" :title="modalTitle" :loading="submitLoading" @onOk="submit" @onCancel="closeModal">
        <global-title title="服务名"></global-title>
        <g-input class="mg-t-24" placeholder="服务名" v-model="form.name"></g-input>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import Pagination from "@/components/Pagination.vue"

export default {
    components: {
        GlobalMenu,
        PlusCircleFilled,
        PlusCircleOutlined,
        Pagination,
        GlobalDialog,
        GlobalTitle,
        GInput,
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        let state = reactive({
            tableData: [],
            loading: false,
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
            isModalShow: false,
            modalType: 'create',
            modalTitle: '新增消息',
            form: {},
            detail: null,
            submitLoading: false
        })
        let searchForm = reactive({
            page: 1,
            page_size: 10,
        })
        const submit = () => {
            if(!state.form.name) {
                proxy.$message.error('请填写必填项')
                return
            }
            state.submitLoading = true
            if(state.modalType == 'edit') {
                proxy.$httpUtil.put(`/manage/added_service/${state.detail.id}`, state.form).then(res => {
                    if(res.success) {
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }else {
                proxy.$httpUtil.post('/manage/added_service', state.form).then(res => {
                    if(res.success) {
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/added_service/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const showModal = (type, data) => {
            state.modalType = type
            state.modalTitle = '新增服务'
            state.detail = data
            if(type == 'edit') {
                state.modalTitle = '编辑服务'
                state.form = {
                    name: data.name
                }
            }else {
                state.form = {}
            }
            state.isModalShow = true
        }
        const closeModal = () => {
            state.isModalShow = false
            state.submitLoading = false
        }
        const getData = async () => {
            state.loading = true
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getService(searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        onMounted(() => {
            getData()
        })
        return {
            tableColumns: [
                {
                    dataIndex: "name",
                    title: "服务名",
                },
                {
                    dataIndex: "operation",
                    title: "操作",
                    width: 120
                }
            ],
            refPagination,
            ... toRefs(state),

            showModal,
            closeModal,
            submit,
            del
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;
    padding: 16px;

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .table-img {
        width: 32px !important;
        height: 32px !important;
    }
}

.dialog-wapper {
    .batch-text {
        color: #3addb7;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .delete-icon {
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }

    .sub-title {
        color: #252733;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
    }

    .sub-tip {
        color: #65697b;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    /deep/.upload-icon {
        width: 343px;
        height: 114px;
        border-radius: 8px;
    }

    .upload-text {
        width: 100%;
        color: #252733;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: none;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    ::v-deep .ant-upload-picture-card-wrapper {
        width: auto;
    }

    .color-wapper {
        flex: 0 0 auto;
        height: 48px;
        width: 80px;
        border: 1px solid #b6bbd7;
        border-radius: 10px;
    }
}
</style>