import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';
import moment from 'moment';
import { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less';
import VueWechatTitle from 'vue-wechat-title';
import '@/assets/less/common.less'
import commonJs from '@/assets/js/common.js'
import httpUtil from '@/assets/js/httpUtil'
import api from '@/assets/js/api'
// import VueUeditorWrap from 'vue-ueditor-wrap';
let app = createApp(App)
app.use(router)
app.use(Antd)
    // app.use(VueUeditorWrap)
app.config.globalProperties.$common = commonJs
app.config.globalProperties.$router = router
app.config.globalProperties.$httpUtil = httpUtil
app.config.globalProperties.$api = api
app.config.globalProperties.$message = message
app.config.globalProperties.$moment = moment
message.config({
    maxCount: 1
})
app.use(VueWechatTitle)
app.mount('#app')