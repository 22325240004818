<template>
    <div class="g-title">
        {{title}}
    </div>
</template>
<script>
export default {
    props:['title']
}
</script>
<style lang="less" scoped>
.g-title{
    color: #3ADDB7;
    font-size: 16px;
    line-height: 20px;
    padding-left: 10px;
    position: relative;
}
.g-title::before{
    content: '';
    height: 12px;
        width: 3px;
        position: absolute;
        left: 0;
        top: 4px;
        background: #3ADDB7;
}
</style>