<template>
  <global-menu
    open="2"
    selected="2-5"
    pageTitle="宣传文案"
    @clickNew="$common.toPage('MarketingOfficialDetail')"
  >
    <global-search
      class="mg-t-19"
      :searchList="searchList"
      :searchDorm="searchForm"
      @search="search"
    ></global-search>
    <div class="page-contener">
      <div class="table-toast">共 <span class="num">101</span> 篇文章</div>
      <a-table
        class="mg-t-32"
        :columns="tableColumns"
        :dataSource="tableData"
        :loading="loading"
      >
        <template #bodyCell="{ column }">
          <div v-if="column && column.dataIndex === 'detail'">
            <img
              class="table-icon"
              src="../../../assets/img/icon_detail.png"
              alt=""
              @click="$common.toPage('MarketingOfficialDetail')"
            />
          </div>
          <div v-if="column && column.dataIndex === 'delete'">
            <img
              class="table-icon"
              src="../../../assets/img/icon_delete_red.png"
              alt=""
            />
          </div>
        </template>
      </a-table>
    </div>
  </global-menu>
  <global-dialog :isShow="isModalShow" title='新增分类' @onCancel="closeModal" @onOk="closeModal">
    <div>
        <g-tab style="width:446px;margin-top:16px" :tabs="tabs" @change="tabChange"></g-tab>
        <global-title class="mg-t-48" title="项目 分类名称"></global-title>
        <g-input class="mg-t-24" :icon="require('../../../assets/img/icon_database.png')" placeholder="分类名称"></g-input>
        <global-title class="mg-t-48" title="项目 分类介绍"></global-title>
        <a-textarea class="mg-t-24" placeholder="分类介绍" :rows="4"></a-textarea>
    </div>
  </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GInput from "@/components/GInput.vue";
import GButton from "@/components/GButton.vue";
import GTab from "@/components/GTab.vue"
import GlobalTitle from "@/components/GlobalTitle.vue"
import { onMounted, ref } from "vue";

export default {
  components: {
    GlobalMenu,
    GlobalSearch,
    GlobalDialog,
    GInput,
    GButton,
    GTab,
    GlobalTitle
  },

 
    setup() {
    let isModalShow=ref(false)
    let modalTitle = ref()
    let searchList = ref([
      {
        type: "input",
        model: "title",
        placeholder: "标题",
        icon: require("@/assets/img/icon_order.png"),
      },
       {
        type: "input",
        model: "type",
        placeholder: "分类",
        icon: require("@/assets/img/icon_classifcation.png"),
      },
    ]);
    let searchForm = ref({
      name: undefined,
      phone: undefined,
      address: undefined,
      date: undefined,
    });
    const tableColumns = ref([
      { dataIndex: "data1", title: "文章标题", width: "400px" },
      { dataIndex: "data2", title: "所属分类", width: "200px" },
      { dataIndex: "data4", title: "更新时间", width: "200px" },
      { dataIndex: "detail", title: "详情", width: "100px" },
      { dataIndex: "delete", title: "删除", width: "100px" },
    ]);
    let tableData = ref([{}]);
    let loading = ref(false);
    let tabs=ref(['中文','日文','英文'])

    function search(searchData) {
      console.log(searchData);
    }
    function tabChange(){

    }
    function showModal(){
        isModalShow.value=true
    }
    function closeModal(){
        isModalShow.value=false
    }
    return {
        isModalShow,
        modalTitle,
      searchList,
      searchForm,
      tableColumns,
      tableData,
      loading,
      tabs,

      search,
      tabChange,
      showModal,
      closeModal
    };
  },
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 78px - 64px - 32px);
  overflow: auto;
  margin-top: 12px;
  padding: 16px;
  .table-toast {
    color: #252733;
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    .num {
      color: #3addb7;
    }
  }
  .table-icon {
    width: 24px !important;
    height: 24px !important;
  }
}
.dialog-modal {
  padding-bottom: 64px;
  .form-item-data {
    width: calc((100% - 64px) / 3) !important;
    margin-right: 32px;
    margin-bottom: 24px;
    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
  ::v-deep .ant-divider-horizontal {
    margin: 8px 0px 30px;
  }
  .subtitle {
    color: #b6bbd7 !important;
    font-size: 20px;
    margin-left: 40px;
  }
  .inline-box {
    display: flex;
    justify-content: space-between;
    .line-item {
      width: calc((100% - 64px) / 2);
    }
  }
  .info-img {
    width: 128px;
    height: 128px;
    margin-right: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    background: #e8e8e8;
    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
  .file-box {
    display: flex;
    flex-wrap: wrap;
  }
  .info-file {
    width: 128px;
    height: 128px;
    margin-right: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid #e8e8e8;
    text-align: center;
    position: relative;
    &:nth-child(3n) {
      margin-right: 0px;
    }
    .img {
      width: 56px;
      height: 56px;
    }
    .file-name {
      color: #202020;
      font-size: 14px;
      line-height: 18px;
      margin-top: 16px;
    }
    .file-del {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  ::v-deep .ant-upload.ant-upload-select-picture-card {
    width: 128px;
    height: 128px;
    border-radius: 8px;
    border: 1px solid #3addb7;
  }
  .meeting-title {
    color: #3addb7;
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
  }
  .meeting-title:last-child {
    margin-top: 48px;
  }
  .meeting-info {
    border-radius: 16px;
    background: #35b8ff;
    color: #ffffff;
    padding: 24px 32px;
    .title {
      font-size: 24px;
      line-height: 40px;
      text-align: center;
    }
    .num-wapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 8px;
      .num {
        font-size: 44px;
        line-height: 64px;
        font-weight: 500;
      }
      .icon {
        width: 32px;
        height: 32px;
        margin-left: 24px;
      }
    }
    .password-wapper {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 24px;
      }
      .password {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .time-wapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .time {
        font-size: 40px;
        line-height: 48px;
      }
      .date {
        font-size: 20px;
        line-height: 28px;
      }
      .duration {
        width: 112px;
        height: 48px;
        font-size: 20px;
        line-height: 28px;
        border-bottom: 2px solid #ffffff;
        padding-top: 12px;
        padding-bottom: 8px;
      }
    }
  }
}
</style>