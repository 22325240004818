<template>
    <global-menu open="5" selected="5-1" pageTitle="合作机构" @clickNew="showModal('新增  合作机构', 'new')">
        <global-search :searchList="searchList" :searchDorm="searchForm" @search="search"></global-search>
        <div class="page-contener">
            <div class="table-toast">共 <span class="num">{{ total }}</span> 个机构</div>
            <a-table class="mg-t-32" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'name'">
                        {{ record.name }}
                        <div>{{ record.address ? record.address : '' }}</div>
                    </div>
                    <div v-if="column.dataIndex === 'data'" @click="showModal('数据库信息', 'data', record)">
                        <a><CodeOutlined class="table-icon" /></a>
                    </div>
                    <div v-if="column.dataIndex === 'mobile'">
                        {{ record.mobile }}
                        <div>{{ record.email ? record.email : '' }}</div>
                    </div>
                    <div v-if="column.dataIndex === 'device_code'" style="color:#3ADDB7;cursor: pointer;"
                        @click="showModal('Pad ID 修改', 'pad', record)">
                        {{ record.device_code ? record.device_code : '-' }}</div>
                    <div v-if="column.dataIndex === 'operation'" @click="updateOrg(record)">
                        <a-tooltip v-if="record.account_status == 1">
                            <template #title>冻结</template>
                            <img class="table-icon pointer" src="../../assets/img/icon_forbid_red.png" alt="">
                        </a-tooltip>
                        <a-tooltip v-if="record.account_status == 2">
                            <template #title>恢复</template>
                            <img class="table-icon pointer" src="../../assets/img/icon_recover_cyan.png" alt="">
                        </a-tooltip>
                    </div>
                    <div v-if="column && column.dataIndex === 'reset'" @click="reset(record.id)">
                        <img class="table-icon pointer" src="../../assets/img/icon_password_cyan.png" alt="" />
                    </div>
                </template>
            </a-table>
        </div>
    </global-menu>
    <global-dialog :width="560" :isShow="isShow" :title="modalTitle" :okText='okText' @onCancel="closeModal" :loading="submitLoading"
        @onOk='createOrg'>
        <div v-if="modalType == 'new'">
            <global-title title="机构信息" class="mg-t-16"></global-title>
            <g-input v-model="form.name" class="mg-t-24" :icon="require('../../assets/img/icon_hospital.png')"
                placeholder="机构名称"></g-input>
            <g-input v-model="form.address" class="mg-t-24" :icon="require('../../assets/img/icon_address.png')"
                placeholder="机构地址"></g-input>
            <g-input v-model="form.mobile" class="mg-t-24" :icon="require('../../assets/img/icon_phone.png')"
                placeholder="联系电话"></g-input>
            <g-input v-model="form.email" class="mg-t-24" :icon="require('../../assets/img/icon_email.png')"
                placeholder="联系邮箱"></g-input>
            <global-title title="Pad 信息" class="mg-t-48"></global-title>
            <g-input v-model="form.device_code" class="mg-t-24" :icon="require('../../assets/img/icon_pad.png')"
                placeholder="Pad ID"></g-input>
        </div>
        <div v-if="modalType == 'pad'">
            <g-input class="mg-t-32" v-model="form.device_code" :icon="require('../../assets/img/icon_pad.png')" placeholder="Pad ID"></g-input>
        </div>
        <div v-if="modalType == 'data'">
            <global-title title="数据库信息" class="mg-t-16"></global-title>
            <g-input v-model="dataBaseForm.host" class="mg-t-24" :icon="require('../../assets/img/icon_address.png')"
                placeholder="地址"></g-input>
            <g-input v-model="dataBaseForm.port" class="mg-t-24" :icon="require('../../assets/img/icon_address.png')"
                placeholder="端口"></g-input>
            <g-input v-model="dataBaseForm.username" class="mg-t-24" :icon="require('../../assets/img/icon_hospital.png')"
                placeholder="账号"></g-input>
            <g-input v-model="dataBaseForm.password" class="mg-t-24" :icon="require('../../assets/img/icon_password_cyan.png')"
                placeholder="密码"></g-input>
            <g-input v-model="dataBaseForm.database" class="mg-t-24" :icon="require('../../assets/img/icon_hospital.png')"
                placeholder="名称"></g-input>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import GInput from "@/components/GInput.vue";
import GlobalDialog from "@/components/GlobalDialog.vue"
import GlobalTitle from "@/components/GlobalTitle.vue"
import { ref, onMounted, getCurrentInstance } from "vue"
import { CodeOutlined } from '@ant-design/icons-vue'
import { Modal } from "ant-design-vue";
export default {
    components: {
        GlobalMenu,
        GlobalSearch,
        GInput,
        GlobalDialog,
        GlobalTitle,
        CodeOutlined
    },
    setup() {
        let { proxy } = getCurrentInstance()
        let searchList = ref([
            {
                type: "input",
                model: "name",
                placeholder: "机构名称",
                icon: require("@/assets/img/icon_hospital.png"),
            },
            {
                type: "input",
                model: "mobile",
                placeholder: "机构电话",
                icon: require("@/assets/img/icon_phone.png"),
            },
            {
                type: "input",
                model: "email",
                placeholder: "机构邮箱",
                icon: require("@/assets/img/icon_email.png"),
            },
            {
                type: "input",
                model: "device_code",
                placeholder: "Pad ID",
                icon: require("@/assets/img/icon_pad.png"),
            },
        ]);
        let searchForm = ref({
            name: undefined,
            mobile: undefined,
            email: undefined,
            device_code: undefined,
            page: 1
        });
        let total = ref(0)
        const tableColumns = ref([
            { dataIndex: "name", title: "机构信息" },
            { dataIndex: "mobile", title: "联系方式", width: "200px" },
            { dataIndex: "device_code", title: "Pad ID", width: "200px" },
            { dataIndex: "data", title: "数据库", width: "100px" },
            { dataIndex: "created_at", title: "注册时间", width: "200px" },
            { dataIndex: "operation", title: "冻结/恢复", width: "100px" },
            { dataIndex: "reset", title: "重置密码", width: "100px" },
        ]);
        let tableData = ref([]);
        let loading = ref(false);
        let isShow = ref(false)
        let modalTitle = ref()
        let modalType = ref()
        let okText = ref()
        let form = ref({})
        let detail = ref(null)
        let submitLoading = ref(false)
        let dataBaseForm = ref ({})
        let tableScrollHeight = ref(500)
        async  function showModal(title, type, data) {
            modalTitle.value = title
            modalType.value = type
            if (type == 'pad') {
                okText.value = '修改'
                form.value.device_code = data.device_code
                detail.value = data
            } else if (type == 'data'){
                okText.value = '修改'
                await proxy.$httpUtil.get(`/manage/hospital_database/${data.id}`).then(res => {
                    let RES = res.data.database
                    dataBaseForm.value = {
                        hospital_id: data.id,
                        host: RES.db_host || '',
                        port: RES.db_port || '',
                        username: RES.db_username || '',
                        password: RES.db_password || '',
                        database: RES.db_database || ''
                    }
                    isShow.value = true
                }).catch(() => {
                    console.log(2)
                    dataBaseForm.value = {
                        hospital_id: data.id,
                        host: '',
                        port: '',
                        username: '',
                        password: '',
                        database: ''
                    }
                })
            }else {
                form.value = {}
                okText.value = ''
            }
            isShow.value = true
        }
        function search(searchData) {
            searchData.page = 1
            searchForm.value = searchData
            getList()
        }
        function closeModal() {
            isShow.value = false
            submitLoading.value = false
        }
        function getList() {
            proxy.$httpUtil.get('/manage/hospital_cooperation', searchForm.value).then(res => {
                tableData.value = res.data.cooperation.data
                total.value = res.data.cooperation.total
                tableScrollHeight.value = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
            })
        }
        function createOrg() {
            if(modalType.value == 'new') {
                if (!form.value.name || !form.value.mobile || !form.value.email || !form.value.address || !form.value.device_code) {
                    proxy.$message.warn('请输入必填项')
                    return
                }
                submitLoading.value = true
                form.value.type = 1
                proxy.$httpUtil.post('/manage/hospital', form.value).then(res => {
                    closeModal()
                    getList()
                }).catch(() => {
                    submitLoading.value = false
                })
            }else if(modalType.value == 'pad') {
                if (!form.value.device_code) {
                    proxy.$message.warn('请输入必填项')
                    return
                }
                submitLoading.value = true
                proxy.$httpUtil.put(`/manage/hospital_device_code_set/${detail.value.id}`, form.value).then(res => {
                    proxy.$message.success('修改成功')
                    closeModal()
                    getList()
                }).catch(() => {
                    submitLoading.value = false
                })
            }else if(modalType.value == 'data') {
                if (!dataBaseForm.value.host || !dataBaseForm.value.host || !dataBaseForm.value.port || !dataBaseForm.value.username || !dataBaseForm.value.password || !dataBaseForm.value.database) {
                    proxy.$message.warn('请输入必填项')
                    return
                }
                submitLoading.value = true
                proxy.$httpUtil.post(`/manage/hospital_set_database`, dataBaseForm.value).then(res => {
                    proxy.$message.success('设置成功')
                    closeModal()
                    getList()
                }).catch(() => {
                    submitLoading.value = false
                })
            }
        }
        function updateOrg(data) {
            Modal.confirm({
                title: `确定${data.account_status == 1 ? '冻结' : '恢复'}该数据吗？`,
                onOk: () => {
                    proxy.$httpUtil.get(`/manage/hospital_account_change_status/${data.id}`).then(res => {
                        proxy.$message.success(`操作成功`)
                        getList()
                    })
                }
            })
        }
        function reset(id) {
            Modal.confirm({
                title: `确定重置该机构密码吗？`,
                onOk: () => {
                    proxy.$httpUtil.get(`/manage/hospital_account_reset_password/${id}`).then(res => {
                        proxy.$message.success(`重置成功`)
                        getList()
                    })
                }
            })
            
        }
        onMounted(() => {
            getList()
        })
        return {
            isShow,
            modalTitle,
            modalType,
            okText,
            searchList,
            searchForm,
            tableColumns,
            tableData,
            loading,
            form,
            total,
            dataBaseForm,
            submitLoading,
            tableScrollHeight,


            showModal,
            closeModal,
            search,
            createOrg,
            updateOrg,
            reset

        }
    }
};
</script>
<style lang="less" scoped>
.page-contener {
    height: calc(100% - 92px);
    overflow: auto;
    margin-top: 12px;
    padding: 16px;

    .table-toast {
        color: #252733;
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;

        .num {
            color: #3addb7;

        }
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;
        font-size: 24px;
    }
}
</style>