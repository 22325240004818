<template>
    <global-menu open="1" selected="1-2" pageTitle="远程问诊  订单" @clickNew="showModal('create', true)">
        <global-search :searchList="searchList" :searchDorm="searchForm" @search="search"></global-search>
        <div class="page-contener">
            <g-tab :tabs="tabs" @change="tabChange" :active="tabActive"></g-tab>
            <a-table class="mg-t-16" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'patient'">
                        {{ record.patient.name }} ({{ record.patient.sex }} {{ record.patient.age }}岁)<br>
                        {{ record.patient.mobile }}
                    </template>
                    <template v-if="column.dataIndex === 'hospital_name'">
                        {{ record.hospital_name }}<br>
                        {{ record.service_name }} （{{ record.doctor_name ? record.doctor_name : '待定' }}）
                    </template>
                    <div v-if="column && column.dataIndex === 'questionnaire'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_questionnaire_grey.png" alt="" />
                    </div>
                    <div v-if="column && column.dataIndex === 'material'">
                        <div v-if="record.status == 5">-</div>
                        <img v-else class="table-icon pointer" src="../../../assets/img/icon_material_cyan.png" alt=""
                            @click="showModal('info', false, 1008, record)" />
                    </div>
                    <div v-if="column && column.dataIndex === 'message'">
                        <div class="msg-box">
                            <span class="dian" v-if="record.is_feedback"></span>
                            <img class="table-icon pointer" src="../../../assets/img/icon_message_cyan.png" alt="" @click="showModal('msg', false, 0, record)" />
                        </div>
                    </div>
                    <div v-if="column && column.dataIndex === 'bill'">
                        <div v-if="record.status == 5">-</div>
                        <div v-else style="color: #3addb7; cursor: pointer"
                        @click="$common.toPage('OrderTreatBill', {type: 'online', id: record.id})">{{ record.bill.all - record.bill.no_pay }} / {{ record.bill.all }}</div>
                    </div>
                    <div v-if="column && column.dataIndex === 'treated_at'">
                        <div v-if="record.treated_at"> {{ record.treated_at }}</div>
                        <div v-else>
                            <div v-if="record.appointed_at_type == 2">待推荐时间</div>
                            <div v-if="record.appointed_at_type == 1">
                                <div v-for="date in record.user_appointed_at" :key="date">{{ $moment(date).format('YYYY-MM-DD') }}{{ getTimeStr(date) }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="column && column.dataIndex === 'meeting'">
                        <div v-if="record.status == 5">-</div>
                        <div v-else>
                            <img v-if="!record.conference" class="table-icon pointer" @click="showModal('createMeet', true, 560, record)"
                                src="../../../assets/img/icon_video_orange.png" alt="" />
                            <img v-else @click="showModal('meetInfo', true, 560, record)" class="table-icon pointer"
                                src="../../../assets/img/icon_video_green.png" alt="" />
                        </div>
                    </div>
                    <div v-if="column && column.dataIndex === 'report'">
                        <div v-if="record.status == 5">-</div>
                        <div v-else>
                            <img v-if="record.status == 4" @click="$common.toPage('OrderTreatReport', { open: '1', selected: '1-2', id: record.id })"
                                class="table-icon pointer" src="../../../assets/img/icon_report_green.png" alt="" />
                            <img v-else @click="$common.toPage('OrderTreatReport', { open: '1', selected: '1-2', id: record.id })" class="table-icon pointer"
                                src="../../../assets/img/icon_report_orange.png" alt="" />
                        </div>
                    </div>
                    <div v-if="column && column.dataIndex === 'operation'">
                        <img class="pointer" v-if="tabActive == 3" style="width: 24px; height: 24px"
                            src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                        <a-tooltip v-else>
                            <template v-slot:title>
                                <div>
                                    <img class="pointer" style="width: 24px; height: 24px" src="../../../assets/img/icon_edit_white.png"
                                        alt="" @click="showModal('edit', true, 1008, record)" />
                                    <img style="width: 24px; height: 24px" class="mg-l-16 pointer"
                                        src="../../../assets/img/icon_cancel_white.png" alt="" @click="cancel(record.id)" />
                                    <img style="width: 24px; height: 24px" class="mg-l-16 pointer"
                                        src="../../../assets/img/icon_barcode_white.png" alt=""  @click="showCodeModal(record.id)" />
                                </div>
                            </template>
                            <img class="table-icon pointer" src="../../../assets/img/icon_more.png" alt="" />
                        </a-tooltip>
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog :width="280" :isShow="isCodeModalShow" title=" " @onCancel="isCodeModalShow = false" :isShowFooter="false" class="dialog-modal">
        <div style="padding-bottom: 20px">
            <vue-qr class="code-image" :text="reportCode" :size="200"></vue-qr>
        </div>
    </global-dialog>
    <global-dialog :isShow="isModalShow" :title="modalTitle" :loading="submitLoading" @onCancel="closeModal" @onOk="submit"
        :isShowFooter="isShowFooter" class="dialog-modal" :width="modalWidth">
        <div v-if="modalType == 'create' || modalType == 'edit'">
            <div>
                <global-title class="mg-t-16" title="患者信息"></global-title>
                <div class="mg-t-26">
                    <g-input v-model="createForm.patient_name" class="form-item-data" placeholder="姓名（中文）"
                        :icon="require('@/assets/img/icon_user.png')"></g-input>
                    <g-input v-model="createForm.patient_en_name" class="form-item-data" placeholder="姓名拼音"
                        :icon="require('@/assets/img/icon_user.png')"></g-input>
                    <g-select :selectId="createForm.patient_sex" v-model="createForm.patient_sex" :icon="require('@/assets/img/icon_user.png')" placeholder="性别"
                        class="form-item-data" :options="sexs"></g-select>
                    <g-date class="form-item-data" placeholder="出生日期" :value="createForm.patient_birth" @change="value => dateChange(value, 'patient_birth')"></g-date>
                    <g-input v-model="createForm.patient_mobile" class="form-item-data" placeholder="联系电话" :icon="require('@/assets/img/icon_phone.png')"></g-input>
                    <g-input v-model="createForm.patient_idcard" class="form-item-data" placeholder="身份证号" :icon="require('@/assets/img/icon_user.png')"></g-input>

                </div>
                <a-divider />
                <global-title title="就诊信息"></global-title>
                <div class="mg-t-26">
                    <g-select :selectId="createForm.hospital_id" v-model="createForm.hospital_id" :icon="require('@/assets/img/icon_hospital.png')" placeholder="选择机构"
                        class="form-item-data" :options="hospitals"></g-select>
                    <global-doctor doctorType="online" type="2" :value="createForm.hospital_id" :detail="detail" class="form-item-data" placeholder="病症 / 医生"
                        :icon="require('@/assets/img/icon_department.png')" @submit="doctorSubmit"></global-doctor>
                    <g-date class="form-item-data" placeholder="就诊时间" :showTime="true" :value="createForm.treated_at" @change="value => dateChange(value, 'treated_at')"></g-date>
                </div>
            </div>
        </div>
        <template v-slot:subtitle v-if="modalType == 'info'">
            <span class="subtitle">{{ detail.patient.name }}</span>
        </template>
        <div v-if="modalType == 'info'">
            <div class="inline-box mg-t-16">
                <div class="line-item">
                    <global-title title="患者资料"></global-title>
                    <div class="mg-t-28">
                        <div class="info-img" v-for="item, index in fileList.user" :key="index">
                            <img class="img" :src="item.path" alt=""  />
                            <a class="download flex-center" :href="item.path">
                                <CloudDownloadOutlined class="icon" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="line-item">
                    <global-title title="机构资料"></global-title>
                    <div class="mg-t-28 file-box">
                        <a-upload class="avatar-uploader info-file" name="avatar" list-type="picture-card" :show-upload-list="false" accept=".jpg,.png" :customRequest="fileChange">
                            <div>
                                <loading-outlined v-if="fileLoading"></loading-outlined>
                                <plus-outlined v-else style="color:#3ADDB7"></plus-outlined>
                                <div class="ant-upload-text" style="color:#3ADDB7">点击上传</div>
                            </div>
                        </a-upload>
                        <div class="info-file pd-12" v-for="item, index in fileList.platform" :key="index">
                            <img class="img" :src="item.path">
                            <div class="file-name ellipsis-2">{{ item.name }}</div>
                            <img class="file-del pointer" src="../../../assets/img/icon_close_red.png" alt="" @click="delFile(item.id)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="modalType == 'createMeet'">
            <div class="meeting-title">会议时间</div>
            <g-date class="mg-t-24" placeholder="开始时间" :showTime="true" :value="meetForm.start_at" @change="value => meetForm.start_at = value"></g-date>
            <g-select :icon="require('@/assets/img/icon_time.png')" class="mg-t-24" placeholder="会议时长" :selectId="meetForm.meeting_at" v-model="meetForm.meeting_at" :options="[{id: 15, name: '15分钟'}, {id: 30, name: '30分钟'}, {id: 45, name: '45分钟'}, {id: 60, name: '60分钟'}]" />
            <!-- <div class="meeting-title">会议密码</div> -->
            <!-- <g-input :icon="require('@/assets/img/icon_password.png')" class="mg-t-24" placeholder="密码" v-model="meetForm.password"></g-input> -->
        </div>
        <div v-if="modalType == 'meetInfo'">
            <div class="mg-t-16">
                <div class="meeting-info">
                    <a class="copy" @click="copy(detail.conference.number)">复制会议链接</a>
                    <div class="title">医疗咨询视频会议</div>
                    <!-- <div class="num-wapper">
                        <div class="num">{{ detail.conference.number }}</div>
                        <img class="icon pointer" src="../../../assets/img/icon_copy_white.png" alt="" @click="copy(detail.conference.number)" />
                    </div> -->
                    <!-- <div class="password-wapper">
                        <img class="icon" src="../../../assets/img/icon_key_white.png" alt="" />
                        <span class="password">{{ detail.conference.password }}</span>
                    </div> -->
                    <div class="time-wapper">
                        <div>
                            <div class="time">{{ $moment(detail.conference.start_at).format('HH:mm') }}</div>
                            <div class="date">{{ $moment(detail.conference.start_at).format('YYYY.MM.DD') }}</div>
                        </div>
                        <div class="duration">
                            {{ detail.conference.meeting_at }}分钟
                        </div>
                        <div>
                            <div class="time">{{ $moment(detail.conference.end_at).format('HH:mm') }}</div>
                            <div class="date">{{ $moment(detail.conference.end_at).format('YYYY.MM.DD') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer v-if="modalType == 'meetInfo'">
            <div>
                <g-button type="error" ghost>取消会议</g-button>
                <g-button type="default" class="mg-l-40" ghost @click="closeModal">关闭</g-button>
            </div>
        </template>
    </global-dialog>
    <message ref="refMessage" />
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalDoctor from "@/components/GlobalDoctor.vue";
import GInput from "@/components/GInput.vue";
import GSelect from "@/components/GSelect.vue";
import GButton from "@/components/GButton.vue";
import GDate from "@/components/GDate.vue";
import { reactive, toRefs, onMounted, ref, getCurrentInstance } from "vue";
import GTab from "@/components/GTab.vue"
import Pagination from "@/components/Pagination.vue"
import { Modal } from 'ant-design-vue'
import useClipboard from 'vue-clipboard3'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { CloudDownloadOutlined } from "@ant-design/icons-vue";
import message from '@/components/message.vue'
import { useRoute } from 'vue-router'
export default {
    components: {
        CloudDownloadOutlined,
        vueQr,
        GlobalTitle,
        GlobalMenu,
        GlobalSearch,
        GlobalDialog,
        GInput,
        GSelect,
        GDate,
        GlobalDoctor,
        GButton,
        GTab,
        Pagination,
        message
    },

    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        const refMessage = ref()
        const route = useRoute()
        let state = reactive({
            tabs: ['待回复  0', '待就诊  0', '已完成  0', '已取消  0'],
            tableColumns: [],
            tableData: [],
            loading: false,
            tabActive: 0,
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 0,
            modalType: 'create',
            modalWidth: 500,
            modalTitle: '',
            isModalShow: false,
            isShowFooter: true,
            createForm: {
                patient_name: undefined,
                patient_en_name: undefined,
                patient_sex: undefined,
                patient_birth: undefined,
                patient_mobile: undefined,
                patient_idcard: undefined,
                hospital_id: undefined,
                hospital_service_id: undefined,
                hospital_doctor_id: undefined,
                treated_at: undefined,
            },
            hospitals: [],
            detail: null,
            fileList: [],
            meetForm: {
                password: undefined,
                start_at: undefined,
                meeting_at: undefined
            },
            submitLoading: false,
            isCodeModalShow: false,
            reportCode: ''
        })
        let searchForm = reactive({
            patient_name: undefined,
            treated_at: undefined,
            hospital_id: undefined,
            hospital_service_id: undefined,
            doctor_id: undefined,
            type: 2,
            custom_status: 1,
            hospital_type: 2,
            page_size: 10,
            page: 1
        })
        const column = reactive({
            0: [
                { dataIndex: "patient", title: "患者信息" },
                { dataIndex: "hospital_name", title: "问诊信息" },
                { dataIndex: "treated_at", title: "问诊时间" },
                { dataIndex: "questionnaire", title: "问卷", width: 80 },
                { dataIndex: "material", title: "资料", width: 80 },
                { dataIndex: "message", title: "消息", width: 80 },
                { dataIndex: "bill", title: "账单", width: 80 },
                { dataIndex: "meeting", title: "会议", width: 80 },
                { dataIndex: "report", title: "报告", width: 80 },
            ],
            1: [
                { dataIndex: "patient", title: "患者信息" },
                { dataIndex: "hospital_name", title: "问诊信息" },
                { dataIndex: "treated_at", title: "问诊时间" },
                { dataIndex: "questionnaire", title: "问卷", width: 80 },
                { dataIndex: "material", title: "资料", width: 80 },
                { dataIndex: "message", title: "消息", width: 80 },
                { dataIndex: "bill", title: "账单", width: 80 },
                { dataIndex: "meeting", title: "会议", width: 80 },
                { dataIndex: "report", title: "报告", width: 80 },
                { dataIndex: "operation", title: "操作", width: 80 },
            ],
            2: [
                { dataIndex: "patient", title: "患者信息" },
                { dataIndex: "hospital_name", title: "问诊信息" },
                { dataIndex: "treated_at", title: "问诊时间" },
                { dataIndex: "questionnaire", title: "问卷", width: 80 },
                { dataIndex: "material", title: "资料", width: 80 },
                { dataIndex: "message", title: "消息", width: 80 },
                { dataIndex: "bill", title: "账单", width: 80 },
                { dataIndex: "meeting", title: "会议", width: 80 },
                { dataIndex: "report", title: "报告", width: 80 },
            ],
            3: [
                { dataIndex: "patient", title: "患者信息" },
                { dataIndex: "hospital_name", title: "问诊信息" },
                { dataIndex: "treated_at", title: "问诊时间" },
                { dataIndex: "canceled_at", title: "取消时间" },
                { dataIndex: "message", title: "消息", width: 80 },
                { dataIndex: "operation", title: "删除", width: 80 },
            ]
        })
        const showCodeModal = async id => {
            let res = await proxy.$api.getReportDetail(id)
            state.reportCode = res.report_code
            state.isCodeModalShow = true
        }
        const { toClipboard } = useClipboard()
        const copy = async (msg) => {
            try {
                // 复制
                await toClipboard(msg)
                proxy.$message.success('复制成功')
            } catch (e) {
                proxy.$message.error('复制失败')
            }
        }
        const getTimeStr = date => {
            return proxy.$moment(date).format('HH') == 13 ? '下午' : '上午'
        }
        
        function search(searchData) {
            searchForm = {...searchForm, ... searchData}
            state.pagination.page = 1
            refPagination.value.reset(1)
            getData()
            getCount()
        }
        const fileChange = async data => {
            let res = await proxy.$httpUtil.upload('/order/appoint_order_file', data.file, 'order', state.detail.id)
            if(res.file_url) {
                state.fileList = await proxy.$api.getOrderFile(state.detail.id)
            }else {
                proxy.$message.error('上传文件失败')
            }
        }
        const tabChange = index => {
            state.tabActive = index
            state.tableColumns = column[index]
            state.pagination.page = 1
            refPagination.value.reset(1)
            getData()
        }
        const pageChange = data => {
            state.pagination.page = data.page
            getData()
        }
        const getData = async () => {
            state.loading = true
            searchForm.custom_status = state.tabActive + 1
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getOrderOnline(searchForm)
            for(let i in res.items) {
                res.items[i].user_appointed_at = res.items[i].user_appointed_at ? JSON.parse(res.items[i].user_appointed_at) : null
            }
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        const getCount = async () => {
            let res = await proxy.$api.getStatistics({
                patient_name: searchForm.patient_name,
                treated_at: searchForm.treated_at,
                patient_mobile: searchForm.patient_mobile,
                hospital_id: searchForm.hospital_id,
                doctor_id: searchForm.doctor_id,
                hospital_service_id: searchForm.hospital_service_id,
                hospital_type: searchForm.hospital_type,
                type: searchForm.type,
                id: searchForm.id
            })
            let obj = {}
            for(let i in res) {
                obj[res[i].custom_status] = res[i].total
            }
            state.tabs = [`待回复  ${obj[1]}`, `待就诊  ${obj[2]}`, `已完成  ${obj[3]}`, `已取消  ${obj[4]}`]
        }
        const cancel = id => {
            Modal.confirm({
                title: '确定取消该订单吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.get(`/order/appoint_cancel/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`取消成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            getCount()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该订单吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/order/appoint/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            getCount()
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const showModal = async (type, showFooter, width = 1008, data) => {
            state.modalType = type
            state.isShowFooter = showFooter
            state.modalWidth = width
            state.hospitals = await proxy.$api.getHospitalLists(2)
            if(type == 'edit') {
                state.modalTitle = '编辑'
                let detail = state.detail = await proxy.$api.getOrderDetail(data.id)
                state.createForm = {
                    patient_name: detail.patient.name,
                    patient_en_name: detail.patient.name_en,
                    patient_sex: detail.patient.sex,
                    patient_birth: detail.patient.birth,
                    patient_mobile: detail.patient.mobile,
                    patient_idcard: detail.patient.idcard,
                    hospital_id: detail.hospital_id,
                    hospital_service_id: detail.service_id,
                    hospital_doctor_id: detail.doctor_id,
                    treated_at: detail.treated_at || undefined,
                    order_id: data.id
                }
            }else if(type == 'info') {
                state.modalTitle = '就诊资料'
                state.detail = await proxy.$api.getOrderDetail(data.id)
                state.fileList = await proxy.$api.getOrderFile(data.id)
            }else if(type == 'msg') {
                refMessage.value.show(data, 1)
                return
            }else if (type == 'createMeet') {
                state.detail = data
                state.modalTitle = '创建会议'
                state.meetForm = {
                    password: undefined,
                    start_at: undefined,
                    meeting_at: undefined
                }
            }else if(type == 'meetInfo'){
                state.modalTitle = '会议信息'
                state.detail = await proxy.$api.getOrderDetail(data.id)
            }else {
                state.modalTitle = '新增'
                state.createForm = {
                    patient_name: undefined,
                    patient_en_name: undefined,
                    patient_sex: undefined,
                    patient_birth: undefined,
                    patient_mobile: undefined,
                    patient_idcard: undefined,
                    hospital_id: undefined,
                    hospital_service_id: undefined,
                    hospital_doctor_id: undefined,
                    treated_at: undefined,
                }
            }
            state.isModalShow = true
        }
        const delFile = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/order/appoint_order_file/${id}`).then(async res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            state.fileList = await proxy.$api.getOrderFile(state.detail.id)
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const closeModal = () => {
            state.isModalShow = false
            state.submitLoading = false
            state.detail = null
        }
        const dateChange = (value, type) => {
            state.createForm[type] = value
        }
        const doctorSubmit = data => {
            state.createForm['hospital_service_id'] = data['hospital_service_id']
            state.createForm['hospital_doctor_id'] = data['doctor_id']
        }
        function submit() {
            if(state.modalType == 'edit') {
                if(!state.createForm.patient_name || !state.createForm.patient_en_name || !state.createForm.patient_sex || !state.createForm.patient_birth || !state.createForm.patient_mobile || !state.createForm.patient_idcard || !state.createForm.hospital_id || !state.createForm.hospital_service_id || !state.createForm.treated_at) {
                    proxy.$message.error('请填写必填项')
                    return
                }
                state.submitLoading = true
                proxy.$httpUtil.put(`/order/appoint`, state.createForm).then(res => {
                    if(res.success) {
                        closeModal()
                        state.pagination.page = 1
                        refPagination.value.reset(1)
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }else if(state.modalType == 'createMeet') {
                if(!state.meetForm.start_at || !state.meetForm.meeting_at) {
                    proxy.$message.error('请填写必填项')
                    return
                }
                state.submitLoading = true
                proxy.$httpUtil.put(`/order/appoint_conference_create/${state.detail.id}`, state.meetForm).then(res => {
                    if(res.success) {
                        closeModal()
                        state.pagination.page = 1
                        refPagination.value.reset(1)
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            } else if(state.modalType == 'create') {
                if(!state.createForm.patient_name || !state.createForm.patient_en_name || !state.createForm.patient_sex || !state.createForm.patient_birth || !state.createForm.patient_mobile || !state.createForm.patient_idcard || !state.createForm.hospital_id || !state.createForm.hospital_service_id || !state.createForm.treated_at) {
                    proxy.$message.error('请填写必填项')
                    return
                }
                state.submitLoading = true
                proxy.$httpUtil.post(`/order/appoint_online`, state.createForm).then(res => {
                    if(res.success) {
                        closeModal()
                        state.pagination.page = 1
                        refPagination.value.reset(1)
                        getData()
                        getCount()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        onMounted(() => {
            if(route.query.id) {
                searchForm.id = route.query.id
            }
            tabChange(route.query.id ? 1 : 0)
            getCount()
        });
        return {
            refMessage,
            refPagination,
            ... toRefs(state),
            searchList: [
                {
                    type: "input",
                    model: "patient_name",
                    placeholder: "姓名",
                    icon: require('@/assets/img/icon_user.png')
                },
                {
                    type: "input",
                    model: "patient_mobile",
                    placeholder: "联系电话",
                    icon: require('@/assets/img/icon_phone.png')
                },
                {
                    type: "doctor",
                    model: "address",
                    doctorType: 'online',
                    placeholder: "医院/病症/医生",
                    icon: require('@/assets/img/icon_hospital.png')
                },
                {
                    type: "date",
                    model: "treated_at",
                    placeholder: "就诊日期",
                    icon: require('@/assets/img/icon_date.png')
                },
            ],
            sexs: [
                {
                    id: '男',
                    name: '男'
                },
                {
                    id: '女',
                    name: '女'
                }
            ],
            searchForm,

            pageChange,
            getTimeStr,
            search,
            tabChange,
            del,
            cancel,
            showModal,
            closeModal,
            submit,
            dateChange,
            doctorSubmit,
            fileChange,
            delFile,
            copy,
            showCodeModal
        };
    },
};
</script>
<style lang="less" scoped>
.msg-box {
    position: relative;
    display: inline-block;
    .dian {
        position: absolute;
        right: -2px;
        top: -4px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: red;
    }
}
.page-contener {
    height: calc(100% - 92px);
    overflow: auto;
    margin-top: 12px;
    padding: 16px;
    padding-bottom: 0;

    .tab-box {
        display: inline-block;
        background: #f4f8fe;
        border-radius: 5px;
        padding: 2px;

        .tab-btn {
            border: none;
            background: none;
            width: 144px;
            color: #252733;
            font-size: 16px;
            border-radius: 3px;
        }

        .tab-btn-active {
            color: #ffffff;
            background: #3addb7;
        }
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }
}

.dialog-modal {
    padding-bottom: 64px;

    .form-item-data {
        width: calc((100% - 64px) / 3) !important;
        margin-right: 32px;
        margin-bottom: 24px;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    ::v-deep .ant-divider-horizontal {
        margin: 8px 0px 30px;
    }

    .subtitle {
        color: #b6bbd7 !important;
        font-size: 20px;
        margin-left: 40px;
    }

    .inline-box {
        display: flex;
        justify-content: space-between;

        .line-item {
            width: calc((100% - 64px) / 2);
        }
    }

    .info-img {
        display: inline-block;
        vertical-align: top;
        position: relative;
        cursor: pointer;
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        background: #e8e8e8;
        overflow: hidden;
        .download {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.6);
            .icon {
                font-size: 32px;
                color: #fff;
            }
        }
        &:hover {
            .download {
                display: flex;
            }
        }
        .img {
            width: 128px;
            height: 128px;
            object-fit: cover;
            border-radius: 8px;
        }

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    .file-box {
        display: flex;
        flex-wrap: wrap;
    }

    .info-file {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        border: 1px solid #e8e8e8;
        text-align: center;
        position: relative;

        &:nth-child(3n) {
            margin-right: 0px;
        }

        .img {
            width: 56px;
            height: 56px;
        }

        .file-name {
            color: #202020;
            font-size: 14px;
            line-height: 18px;
            margin-top: 16px;
        }

        .file-del {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 128px;
        height: 128px;
        border-radius: 8px;
        border: 1px solid #3addb7;
    }

    .meeting-title {
        color: #3addb7;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
    }

    .meeting-title:last-child {
        margin-top: 48px;
    }

    .meeting-info {
        border-radius: 16px;
        background: url('../../../assets/img/meeting_bg.png');
        color: #ffffff;
        padding: 24px 32px;
        position: relative;
        .copy {
            font-size: 14px;
            color: #fff;
            position: absolute;
            right: 12px;
            top: 10px;
            z-index: 1;
        }

        .title {
            padding-top: 8px;
            font-size: 24px;
            line-height: 40px;
            text-align: center;
        }

        .num-wapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 8px;

            .num {
                font-size: 44px;
                line-height: 64px;
                font-weight: 500;
            }

            .icon {
                width: 32px;
                height: 32px;
                margin-left: 24px;
            }
        }

        .password-wapper {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 12px;
            }

            .password {
                font-size: 24px;
                line-height: 24px;
            }
        }

        .time-wapper {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            .time {
                font-size: 40px;
                line-height: 48px;
            }

            .date {
                font-size: 20px;
                line-height: 28px;
            }

            .duration {
                width: 112px;
                height: 48px;
                font-size: 20px;
                line-height: 28px;
                border-bottom: 2px solid #ffffff;
                padding-top: 12px;
                padding-bottom: 8px;
                text-align: center;
            }
        }

        .footer {}
    }
}
</style>