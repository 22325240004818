<template>
    <global-menu open="7" selected="7-4" pageTitle="药品管理" @clickNew="showModal('新增  药品')">
        <template #btnText>新增药品</template>
        <global-search :searchList="searchList" :searchDorm="searchForm" @search="search"></global-search>

        <div class="page-contener">
            <div class="table-toast">共 <span class="num">{{ total }}</span> 种药品</div>
            <a-table class="mg-t-32" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading">
                <template #bodyCell="{ column, record }">
                    <div v-if="column && column.dataIndex === 'edit'">
                        <img class="table-icon" src="../../../assets/img/icon_edit_cyan.png" alt=""
                            @click="showModal('编辑  药品', record.id)" />
                    </div>
                    <div v-if="column && column.dataIndex === 'delete'">
                        <img class="table-icon" src="../../../assets/img/icon_delete_red.png" alt=""
                            @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isShow" :title="modalTitle" :loading="submitLoading" @onCancel="closeModal" @onOk="createOrUpdate">
        <div v-if="modalTitle === '新增  药品' || modalTitle === '编辑  药品'">
            <global-title class="mg-t-16" title="药品名称"></global-title>
            <g-input class="mg-t-24" placeholder="药品名称" v-model="form.name"></g-input>
            <div class="mg-t-48 flex-between">
                <global-title title="规格"></global-title>
                <div class="flex-align-center">
                    <div class="batch-text" @click="showModal('批量操作')">批量操作</div>
                    <plus-circle-filled @click="addDrugSubs" :style="{ fontSize: '48px', color: '#3addb7' }" />
                </div>
            </div>
            <a-row>
                <a-col class="mg-t-26" :span="12" v-for="(item, index) in drugSubs" :key="'subs' + index">
                    <div class="flex-align-center" :style="{ 'margin-left': index % 2 == 1 ? '57px' : '' }">
                        <img v-if="index == 0" class="delete-icon" src="../../../assets/img/icon_cancel_grey.png" alt="" />
                        <img v-else class="delete-icon" @click="deleteDrugSubs(index)"
                            src="../../../assets/img/icon_cancel_red.png" alt="" />
                        <g-input style="width: 344px" placeholder="规格" v-model="item.name"></g-input>
                    </div>
                </a-col>
            </a-row>
        </div>
    </global-dialog>
    <global-dialog class="dialog-wapper" :width="560" :isShow="isBatchShow" title="批量操作" @onOk="batchSubmit" :zIndex="1001"
        @onCancel="isBatchShow = false">
        <global-title title="规格" class="mg-t-16"></global-title>
        <div class="sub-title">操作说明：</div>
        <div class="sub-tip">
            可在下列文本框内输入规格的名称，每一行即为一个规格，换行输入则是新的一条规格。规格的排列顺序以文本框中从上到下的顺序进行排列。
        </div>
        <a-textarea placeholder="正文" class="mg-t-16" :rows="10" v-model:value="batchValue"></a-textarea>
    </global-dialog>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu.vue";
import GlobalSearch from "../../../components/GlobalSearch.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import GSelect from "../../../components/GSelect.vue";
import { ref, onMounted, getCurrentInstance } from "vue"
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
export default {
    components: {
        GlobalMenu,
        GlobalDialog,
        GlobalTitle,
        GInput,
        GSelect,
        GlobalSearch,
        PlusCircleFilled
    },
    setup() {
        const { proxy } = getCurrentInstance();
        let searchList = ref([
            {
                type: "input",
                model: "name",
                placeholder: "药品名称",
                icon: require("@/assets/img/icon_medicine_.png"),
            },
        ]);
        let searchForm = ref({
            name: undefined,
        });
        const tableColumns = ref([
            { dataIndex: "name", title: "药品名", width: "300px" },
            { dataIndex: "specification_text", title: "规格", width: "200px" },
            { dataIndex: "updated_at", title: "更新时间", width: "200px" },
            { dataIndex: "edit", title: "编辑", width: "100px" },
            { dataIndex: "delete", title: "删除", width: "100px" },
        ]);
        let tableData = ref([{}]);
        let loading = ref(false);
        let submitLoading = ref(false);
        let isShow = ref(false);
        let isBatchShow = ref(false);
        let batchValue = ref(undefined);
        let modalTitle = ref()
        let drugSubs = ref([
            { name: '' }
        ])
        let total = ref(0);
        let form = ref({});
        let ids = ref(0);
        let tableScrollHeight = ref(0);
        const batchSubmit = () => {
            if(!batchValue.value) {
                proxy.$message.error('请输入正文')
                return
            }
            drugSubs.value = []
            let arrStr = batchValue.value.replaceAll('\n', ',')
            let arr = arrStr.split(',')
            for(let i in arr) {
                drugSubs.value.push({name: arr[i]})
            }
            isBatchShow.value = false
        }
        function showModal(title, id) {
            if (id) {
                ids.value = id;
                getDetail(id);
            }else {
                isShow.value = true;
            }
            if(title == '批量操作') {
                batchValue.value = undefined
                if(drugSubs.value.length) {
                    let arr = []
                    for(let i in drugSubs.value) {
                        arr.push(drugSubs.value[i].name)
                    }
                    batchValue.value = arr.join(',').replaceAll(',', '\n')
                }
                isBatchShow.value = true
            }else {
                modalTitle.value = title
                if(title == '新增  药品') {
                    form.value.name = ''
                    drugSubs.value = [{name: ''}]
                }
            }
            
        }

        function closeModal() {
            isShow.value = false;
            ids.value = ''
            submitLoading.value = false
        }

        function search(searchData) {
            searchData.page = 1
            searchForm.value = searchData
            getList()
        }

        function addDrugSubs() {
            drugSubs.value.push({ name: '' });
        }

        function deleteDrugSubs(index) {
            drugSubs.value.splice(index, 1);
        }
        function getList() {
            proxy.$httpUtil.get(`/manage/drug/`, searchForm.value).then(res => {
                tableData.value = res.data.drug
                total.value = res.data.drug.length;
                tableScrollHeight.value = document.querySelector('.page-contener').offsetHeight - 48 - 32 - 56 - 64
            })
        }
        function del(id) {
            if (!id) {
                proxy.$message.warn('请选择数据')
                return false;
            }
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/drug/${id}`, {}).then(res => {
                        closeModal();
                        getList();
                        return Promise.resolve()
                    })
                }
            })
        }
        function createOrUpdate() {
            if (!form.value.name) {
                proxy.$message.warn('请输入必填项')
                return false;
            }
            let child_name = [];
            for (let i = 0; i < drugSubs.value.length; i++) {
                if (!drugSubs.value[i].name) {
                    proxy.$message.warn('请输入必填项');
                    return false;
                    break;
                }
                child_name.push(drugSubs.value[i].name);
            }
            if (child_name.length == 0) {
                proxy.$message.warn('请输入必填项')
                return false;
            }
            submitLoading.value = true
            var url = '/manage/drug';
            if (ids.value) {
                url = `/manage/drug/${ids.value}`;
            }
            let postData = {
                name: form.value.name,
                specification: child_name
            }
            console.log(postData);
            if (ids.value) {
                proxy.$httpUtil.put(url, postData).then(res => {
                    closeModal();
                    getList();
                }).catch(() => {
                    submitLoading.value = false
                })
            } else {
                proxy.$httpUtil.post(url, postData).then(res => {
                    closeModal();
                    getList();
                }).catch(() => {
                    submitLoading.value = false
                })
            }

        }

        function getDetail(id) {
            proxy.$httpUtil.get(`/manage/drug_show/${id}`).then(res => {
                form.value.name = res.data.drug.name;
                var nameArr = [];
                if (res.data.drug.specification_arr.length > 0) {
                    for (let i = 0; i < res.data.drug.specification_arr.length; i++) {
                        nameArr.push({ name: res.data.drug.specification_arr[i] })
                    }
                }
                drugSubs.value = nameArr;
                isShow.value = true;
            })
        }
        onMounted(() => {
            getList()
        })
        return {
            searchList,
            searchForm,
            tableColumns,
            tableData,
            loading,
            submitLoading,
            isShow,
            isBatchShow,
            batchValue,
            modalTitle,
            drugSubs,
            total,
            form,
            tableScrollHeight,

            showModal,
            batchSubmit,
            closeModal,
            search,
            addDrugSubs,
            deleteDrugSubs,
            createOrUpdate,
            del
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: calc(100% - 92px);
    overflow: auto;
    margin-top: 12px;
    padding: 16px;

    .table-toast {
        color: #252733;
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;

        .num {
            color: #3addb7;
        }
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }
}

.dialog-wapper {
    .batch-text {
        color: #3addb7;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .delete-icon {
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }
}
</style>