<template>
    <a-layout id="components-layout-demo-fixed-sider">
        <a-layout>
            <a-layout-sider class="g-menu">
                <div class="menu-logo">
                    <div class="logo-box">
                        <img class="logo-img" src="@/assets/img/logo.png" alt="" />
                    </div>
                    <div class="logo-text">NihonQ<br />SAAS</div>
                </div>
                <a-menu class="menu-list" mode="inline" :openKeys="[openKey]" :selectedKeys="[selectedKey]">
                    <template v-for="item in menus" :key="'menu' + item.key">
                        <a-sub-menu :key="item.key" @titleClick="titleClick(item.key)" v-if="item.child">
                            <template #title>
                                <span>{{ item.title }}</span>
                            </template>

                            <a-menu-item v-for="subItem in item.child" :key="subItem.key"
                                @click="$common.toPage(subItem.to)">
                                <div class="flex-between">
                                    <div>{{ subItem.title }}</div>
                                    <div class="point" v-if="selectedKey == subItem.key"></div>
                                </div>
                            </a-menu-item>
                        </a-sub-menu>
                        <a-menu-item v-else :key="item.key" @click="$common.toPage(item.to)">
                            <div class="flex-between">
                                <div>{{ item.title }}</div>
                                <div class="point" v-if="selectedKey == item.key"></div>
                            </div>
                        </a-menu-item>
                    </template>
                </a-menu>
                <div class="user-info flex-center">
                    <a-dropdown :trigger="['click']">
                        <span class="ant-dropdown-link" @click="e => e.preventDefault()">
                            <div class="flex-center pointer">
                                <div class="icon"></div>
                                <div class="name">超级管理员</div>
                                <img class="arrow" src="../assets/img/icon_arrow_bottom.png" alt="">
                            </div>
                        </span>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item @click="logout">退出登录</a-menu-item>
                            </a-menu>
                        </template>>
                    </a-dropdown>
                </div>
            </a-layout-sider>
            <a-layout-content class="g-container">
                <div class="flex-between" v-if="pageTitle">
                    <div class="page-title">{{ pageTitle }}</div>
                    <g-button v-if="isShowNew" type="primary" style="width:160px" ghost @click="clickNew">
                        <slot name="btnText">新增</slot>
                    </g-button>
                </div>
                <div :class="`g-main ${pageTitle ? 'has-title' : ''} ${isShowNew ? 'has-btn' : ''}`">
                    <slot></slot>
                </div>
            </a-layout-content>
        </a-layout>
        <a-modal v-model="isModalShow" :title="modalTitle" :width="560" :centered="true" :maskClosable="false"
            @cancel="closeModal" :footer="null">
            <a-form-model v-if="modalType == 'set'" class="form" :layout="'horizontal'" ref="ruleForm" :model="form"
                :rules="formRules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="新密码" prop="pwd2">
                    <a-input type="password" v-model="form.pwd2" placeholder="请输入新密码" :max-length="16"></a-input>
                </a-form-model-item>
                <a-form-model-item label="再次确认" prop="pwd3">
                    <a-input type="password" v-model="form.pwd3" placeholder="请再次确认密码" :max-length="16"></a-input>
                </a-form-model-item>
            </a-form-model>
            <div v-if="modalType == 'logout'">
                <div class="logout-text">是否确定退出登录？</div>
            </div>
            <footer class="modal-footer">
                <a-button size="large" @click="closeModal">取消</a-button>
                <a-button size="large" class="mg-l-16" type="primary" @click="submit">确定</a-button>
            </footer>
        </a-modal>
    </a-layout>
</template>

<script>
import $router from "@/router";
import GButton from "../components/GButton.vue"
import { ref, onMounted } from "vue";
import { $message, Modal } from "ant-design-vue";
export default {
    components: {
        GButton
    },
    props: {
        selected: {
            type: String,
            default: ''
        },
        open: {
            type: String,
            default: ''
        },
        pageTitle: {
            type: String,
            default: ''
        },
        isShowNew: {
            type: Boolean,
            default: true
        }

    },
    setup(prop, context) {
        let labelCol = ref({ span: 6 })
        let wrapperCol = ref({ span: 16 })
        let layoutMargin = ref(240)
        let openKey = ref('')
        let selectedKey = ref('')
        let isModalShow = ref(false)
        let modalTitle = ref('')
        let modalType = ref('')
        let form = ref({
            pwd2: undefined,
            pwd3: undefined,
        })
        let formRules = ref({
            pwd2: [
                {
                    required: true,
                    message: "请输入新密码",
                },
            ],
            pwd3: [
                {
                    required: true,
                    message: "请再次输入新密码",
                },
                { validator: checkPass, trigger: "change" },
            ],
        })
        let isCodeModalShow = ref(false)
        let ruleKey = ref('')
        let menus = ref([
            {
                title: "工作台",
                to: "Index",
                key: "6",
            },
            {
                title: "订单管理",
                key: "1",
                child: [
                    {
                        title: "在日就诊",
                        to: "OrderTreatIndex",
                        key: "1-1",
                    },
                    {
                        title: "远程问诊",
                        to: "OrderOnlineIndex",
                        key: "1-2",
                    },
                    // {
                    //     title: "商品订单",
                    //     to: "OrderGoodsIndex",
                    //     key: "1-3",
                    // },
                    {
                        title: "开药订单",
                        to: "OrderDrugIndex",
                        key: "1-4",
                    },
                    {
                        title: "咨询订单",
                        to: "OrderConsultIndex",
                        key: "1-5",
                    },
                    {
                        title: "服务订单",
                        to: "OrderServeIndex",
                        key: "1-6",
                    },
                ],
            },
            {
                title: "机构管理",
                key: "5",
                child: [
                    {
                        title: "合作机构",
                        to: "OrganizationIndex",
                        key: "5-1",
                    },
                    {
                        title: "其他机构",
                        to: "OrganizationOtherIndex",
                        key: "5-2",
                    },
                ]
            },
            {
                title: "财务管理",
                to: "",
                key: "3",
                child: [
                    {
                        title: "合作机构",
                        to: "FinanceCooperation",
                        key: "3-1",
                    },
                    {
                        title: "非合作机构",
                        to: "FinanceNotCooperation",
                        key: "3-2",
                    },
                    {
                        title: "平台业务",
                        to: "FinancePlatform",
                        key: "3-3",
                    },
                    {
                        title: "增值服务",
                        to: "FinanceAdded",
                        key: "3-4",
                    }
                ],
            },
            {
                title: "营销管理",
                to: "",
                key: "2",
                child: [
                    {
                        title: "推荐医院",
                        to: "MarketingHospitalIndex",
                        key: "2-1",
                    },
                    {
                        title: "推荐医生",
                        to: "MarketingDoctorIndex",
                        key: "2-2",
                    },
                    // {
                    //     title: "推荐商品",
                    //     to: "MarketingGoodsIndex",
                    //     key: "2-3",
                    // },
                    {
                        title: "推荐项目",
                        to: "MarketingProjectIndex",
                        key: "2-4",
                    },
                    // {
                    //     title: "宣传文案",
                    //     to: "MarketingOfficialIndex",
                    //     key: "2-5",
                    // },
                ],
            },
            // {
            //     title: "商品管理",
            //     key: "4",
            //     child: [
            //         {
            //             title: "商品审核",
            //             to: "GoodsIndex",
            //             key: "4-1",
            //         },
            //         {
            //             title: "价格设置",
            //             to: "GoodsPriceIndex",
            //             key: "4-2",
            //         },
            //         {
            //             title: "在售商品",
            //             to: "GoodsSaleIndex",
            //             key: "4-3",
            //         },
            //     ]
            // },
            {
                title: "系统管理",
                key: "7",
                child: [
                    {
                        title: "分类管理",
                        to: "SystemSortIndex",
                        key: "7-1",
                    },
                    {
                        title: "科室管理",
                        to: "SystemDepartIndex",
                        key: "7-2",
                    },
                    {
                        title: "病症管理",
                        to: "SystemDiseaseIndex",
                        key: "7-3",
                    },
                    {
                        title: "医生管理",
                        to: "SystemDoctor",
                        key: "7-9",
                    },
                    {
                        title: "药品管理",
                        to: "SystemDrugIndex",
                        key: "7-4",
                    },
                    {
                        title: "内容管理",
                        to: "SystemContentIndex",
                        key: "7-5",
                    },
                    {
                        title: "消息管理",
                        to: "SystemMessageIndex",
                        key: "7-6",
                    },
                    {
                        title: "报告设置",
                        to: "SystemReportIndex",
                        key: "7-7",
                    },
                    {
                        title: "增值服务设置",
                        to: "SystemServerIndex",
                        key: "7-10",
                    },
                    {
                        title: "人员管理",
                        to: "SystemServerPerson",
                        key: "7-11",
                    },
                    {
                        title: "系统设置",
                        to: "SystemSet",
                        key: "7-8",
                    },
                ]
            },
        ])
        let userInfo = ref({})

        function checkPass(rule, value, callback) {
            if (form.value.pwd2 != form.value.pwd3) {
                callback(new Error("两次密码输入不一致"));
            } else {
                callback();
            }
        }
        function submit() {
            if (modalType.value == "set") {
                if (!form.value.pwd2) {
                    context.$message.error("请输入新密码");
                    return;
                } else if (!form.value.pwd3) {
                    $message.error("请再次输入新密码");
                    return;
                } else if (form.value.pwd2 != form.pwd3) {
                    $message.error("两次密码输入不一致");
                    return;
                } else if (
                    !checkPwd(form.value.pwd2) ||
                    !checkPwd(form.value.pwd3)
                ) {
                    $message.error("请输入6-16位密码");
                    return;
                }
                PUT(`admin/me`, {
                    data: {
                        password: mdPwd(form.value.pwd2),
                    },
                    success: () => {
                        closeModal();
                    },
                });
            } else if (modalType.value == "logout") {
                POST("admin/logout", {
                    success: () => {
                        closeModal();
                        window.localStorage.clear();
                        $router.push({ name: "Login" });
                    },
                });
            }
        }
        function closeModal() {
            // if ($refs.ruleForm) {
            //   $refs.ruleForm.resetFields();
            // }
            form.value.pwd2 = undefined;
            form.value.pwd3 = undefined;
            isModalShow.value = false;
        }
        function changePwd(title, type) {
            modalTitle.value = title;
            modalType.value = type;
            isModalShow.value = true;
        }
        function titleClick(key) {
            if (key == openKey.value) {
                openKey.value = 0;
            } else {
                openKey.value = key;
            }
        }
        function logout() {
            Modal.confirm({
                title: '确定退出登录吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    localStorage.clear();
                    $router.replace({ name: 'Login' })
                    return Promise.resolve()
                }
            })
        }
        function clickNew() {
            context.emit('clickNew')
        }
        onMounted(() => {
            openKey.value = prop.open;
            selectedKey.value = prop.selected;
        })
        return {
            labelCol,
            wrapperCol,
            layoutMargin,
            openKey,
            selectedKey,
            isModalShow,
            modalTitle,
            modalType,
            form,
            formRules,
            isCodeModalShow,
            ruleKey,
            menus,
            userInfo,

            checkPass,
            submit,
            closeModal,
            changePwd,
            titleClick,
            logout,
            clickNew
        }
    }
};
</script>

<style lang="less" scoped>
.g-menu {
    overflow: hidden;
    height: 100vh;
    flex: 0 0 240px !important;
    max-width: 240px !important;
    width: 240px !important;

    .menu-logo {
        height: 148px;
        background: #252733;
        display: flex;
        align-items: center;
        padding-left: 36px;

        .logo-box {
            background: #3addb7;
            border-radius: 6px;
            width: 52px;
            height: 52px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-img {
                width: 32px;
                height: 32px;
            }
        }

        .logo-text {
            color: #ffffff;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            margin-left: 20px;
        }
    }

    .menu-list {
        height: calc(100vh - 148px - 128px);
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background: #2e313d !important;
        border-right: none;

        .point {
            width: 6px;
            height: 2px;
            background: #3addb7;
            border-radius: 1px;
        }

        ::v-deep .ant-menu-submenu-title {
            color: #ffffff;
        }

        ::v-deep .ant-menu-item {
            color: #ffffff;
        }

        ::v-deep .ant-menu-submenu-arrow {
            display: none;
        }

        ::v-deep .ant-menu-sub.ant-menu-inline {
            background: #1f202b;
        }

        ::v-deep .ant-menu-item-selected {
            background-color: #1f202b !important;
            color: #3addb7 !important;
        }

        ::v-deep .ant-menu-inline .ant-menu-item::after {
            border-right: none;
        }
    }

    .user-info {
        height: 128px;

        .icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #B9BBC7;
            border: 1px solid #FFFFFF;
        }

        .name {
            color: #FFFFFF;
            font-size: 16px;
            margin-left: 12px;
        }

        .arrow {
            width: 16px;
            height: 16px;
            margin-left: 12px;

        }
    }
}

.g-container {
    position: relative;
    flex: 1 0 0;
    width: calc(100% - 240px);
    height: 100vh;
    background-color: #f0f2f5;
    overflow: auto;
    padding: 20px;

    .page-title {
        line-height: 30px;
        color: #252733;
        font-size: 24px;
        font-weight: bold;
        // background: #3ADDB7;
    }

    .g-main {
        height: 100%;

        &.has-title {
            height: calc(100% - 30px);
            padding-top: 12px;
        }

        &.has-btn {
            height: calc(100% - 48px);
            padding-top: 12px;
        }
    }
}

.drop-box {
    background: #fff;
    width: 256px;

    .user-info {
        display: flex;

        .head {
            width: 48px;
            height: 48px;
        }

        .title {
            color: #0d171c;
            font-size: 16px;
            line-height: 22px;
        }

        .content {
            color: fadeout(#0d171c, 40%);
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.modal-footer {
    text-align: center;
    margin-top: 72px;
}

.logout-text {
    color: #0d171c;
    font-size: 18px;
    text-align: center;
    margin-top: 80px;
}
</style>
