<template>
<div class="flex-center page" style="height:100vh">
    <div class="login-warpper">
        <img class="bg" src="@/assets/img/login_form_bg.png" alt="">
        <div class="form">
            <div class="logo">
                <img class="img" src="@/assets/img/logo_green.png" alt="">
                看看日医(NihonQ)
            </div>
            <div class="title">
                Hi~<br>
                欢迎登录后台系统
            </div>
            <g-input class="mg-t-24" placeholder="请输入用户名" :icon="require('@/assets/img/icon_user.png')" v-model="userName"></g-input>
            <g-input class="mg-t-24" placeholder="请输入密码" :icon="require('@/assets/img/icon_password.png')" v-model="password"></g-input>
            <div class="text-center mg-t-24 ">
                <g-button class="btn" @click="login">登录</g-button>
            </div>
        </div>
    </div>
</div>
   
</template>
<script>
import {ref,getCurrentInstance} from "vue"
import GInput from "@/components/GInput.vue"
import GButton from "@/components/GButton.vue"
export default {
    components: {
        GInput,
        GButton
    },
    setup(){
        const {proxy} = getCurrentInstance()
        let userName=ref('admin')
        let password= ref('123456')
        
        function login(){
            if(!userName.value || !password.value) {
                proxy.$message.error('请输入必填项')
                return
            }
            proxy.$httpUtil.post('/auth/login',{
                account:userName.value,
                // password:proxy.$common.mdPwd(password.value)
                password:password.value
            }).then(res=>{
                localStorage['Token']= res.data.user.access_token
                proxy.$common.toPage('Index')
            })
        }
        return {
            userName,
            password,
            login
        }
    }
}
</script>
<style scoped lang="less">
.page {
    background: url(../../assets/img/login_bg.png) no-repeat center center;
    background-size: cover;
    .login-warpper {
        position: relative;
        min-width: 800px;
        .bg {
            
            height: 500px;
        }
        .form {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 420px;
            padding: 60px;
            .btn {
                width: 100%; 
            }
            .title {
                margin-top: 48px;
                line-height: 32px;
                font-size: 24px;
                font-weight: 600;
                color: #202020;
            }
            .logo {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 600;
                color: #202020;
                .img {
                    width: 40px;
                    margin-right: 8px;
                }
            }
        }
    }
}
</style>