<template>
    <global-menu open="5" selected="5-2" pageTitle="其他机构" @clickNew="showModal">
        <global-search :searchList="searchList" :searchDorm="searchForm" @search="search"></global-search>
        <div class="page-contener">
            <div class="table-toast">共 <span class="num">{{ total }}</span> 个机构</div>
            <a-table class="mg-t-32" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'name'">
                        {{ record.name }}
                        <div>{{ record.address ? record.address : '' }}</div>
                    </div>
                    <div v-if="column.dataIndex === 'mobile'">
                        {{ record.mobile }}
                        <div>{{ record.email ? record.email : '' }}</div>
                    </div>
                    <div v-if="column.dataIndex === 'detail'">
                        <img class="table-icon pointer" @click="$common.toPage('OrganizationOtherDetail', { id: record.id })"
                            src="../../../assets/img/icon_detail.png" alt="">
                    </div>
                    <div v-if="column && column.dataIndex === 'delete'" @click="deleteOrg(record.id)">
                        <img class="table-icon pointer" src="../../../assets/img/icon_delete_red.png" alt="" />
                    </div>
                </template>
            </a-table>
        </div>
    </global-menu>
    <global-dialog :width="560" :isShow="isShow" title="新增  其他机构" :loading="submitlLoading" @onCancel="closeModal" @onOk="createOrg">
        <div class="dialog-wapper">
            <global-title class="mg-t-16" title="机构信息"></global-title>
            <g-input v-model="form.name" class="mg-t-24" :icon="require('../../../assets/img/icon_hospital.png')"
                placeholder="机构名称"></g-input>
            <div class="tip">以下为非必填</div>
            <g-input v-model="form.address" class="mg-t-24" :icon="require('../../../assets/img/icon_address.png')"
                placeholder="机构地址"></g-input>
            <g-input v-model="form.mobile" class="mg-t-24" :icon="require('../../../assets/img/icon_phone.png')"
                placeholder="联系电话"></g-input>
            <g-input v-model="form.email" class="mg-t-24" :icon="require('../../../assets/img/icon_email.png')"
                placeholder="联系邮箱"></g-input>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import GInput from "@/components/GInput.vue";
import GlobalTitle from "@/components/GlobalTitle.vue"
import GlobalDialog from "@/components/GlobalDialog.vue"
import { ref, onMounted, getCurrentInstance } from "vue"
import { Modal } from 'ant-design-vue';
export default {
    components: {
        GlobalMenu,
        GlobalSearch,
        GInput,
        GlobalTitle,
        GlobalDialog
    },
    setup() {
        let { proxy } = getCurrentInstance()
        let searchList = ref([
            {
                type: "input",
                model: "name",
                placeholder: "机构名称",
                icon: require("@/assets/img/icon_hospital.png"),
            },
            {
                type: "input",
                model: "mobile",
                placeholder: "机构电话",
                icon: require("@/assets/img/icon_phone.png"),
            },
            {
                type: "input",
                model: "email",
                placeholder: "机构邮箱",
                icon: require("@/assets/img/icon_email.png"),
            },
        ]);
        let searchForm = ref({
            name: undefined,
            mobile: undefined,
            email: undefined,
            page: 1
        });
        const tableColumns = ref([
            { dataIndex: "name", title: "机构信息", },
            { dataIndex: "mobile", title: "联系方式", width: "200px" },
            { dataIndex: "organization_num", title: "科室", width: "80px" },
            { dataIndex: "disease_num", title: "病症", width: "80px" },
            { dataIndex: "doctor_num", title: "医生", width: "80px" },
            { dataIndex: "created_at", title: "创建时间", width: "200px" },
            { dataIndex: "detail", title: "详情", width: "80px" },
            { dataIndex: "delete", title: "删除", width: "80px" },
        ]);
        let tableScrollHeight = ref(500)
        let total = ref(0)
        let tableData = ref([]);
        let loading = ref(false);
        let submitlLoading = ref(false);
        let isShow = ref(false)
        let form = ref({})

        function showModal() {
            isShow.value = true
        }
        function closeModal() {
            isShow.value = false
            submitlLoading.value = false
        }
        function getList() {
            loading.value = true
            proxy.$httpUtil.get('/manage/hospital_platform', searchForm.value).then(res => {
                tableData.value = res.data.platform.data
                total.value = res.data.platform.total
                loading.value = false
                tableScrollHeight.value = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
            })
        }
        function search(searchData) {
            searchData.page = 1
            searchForm.value = searchData
            getList()
        }
        function createOrg() {
            if (!form.value.name) {
                proxy.$message.warn('请输入必填项')
                return
            }
            submitlLoading.value = true
            form.value.type = 2
            proxy.$httpUtil.post('/manage/hospital', form.value).then(res => {
                closeModal()
                getList()
            }).catch(() => {
                submitlLoading.value = false
            })
        }
        function deleteOrg(id) {
            Modal.confirm({
                content: '确认删除该机构？',
                onOk() {
                    proxy.$httpUtil.delete(`/manage/hospital/${id}`, form.value).then(res => {
                        closeModal()
                        getList()
                    })
                }
            })

        }
        onMounted(() => {
            getList()
        })
        return {
            isShow,
            searchList,
            searchForm,
            tableColumns,
            tableData,
            loading,
            submitlLoading,
            total,
            form,
            tableScrollHeight,

            showModal,
            closeModal,
            search,
            createOrg,
            deleteOrg
        }
    }
};
</script>
<style lang="less" scoped>
.page-contener {
    height: calc(100% - 92px);
    overflow: auto;
    margin-top: 12px;
    padding: 16px;

    .table-toast {
        color: #252733;
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;

        .num {
            color: #3addb7;

        }
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }
}

.dialog-wapper {
    .tip {
        color: #B9BBC7;
        font-size: 16px;
        line-height: 20px;
        margin-top: 40px;
    }
}
</style>