<template>
    <div class="detail" v-if="detail.status == 1">
        <div class="person">
            <img class="avatar" src="@/assets/img/avatar.png" alt="">
            <div class="info">
                <div class="name">
                    {{ detail.patient ? detail.patient.name : '-' }}
                </div>
                <div class="text">
                    <span class="age">{{ detail.patient ? detail.patient.sex : '-' }}</span>
                    <span class="age">{{ detail.patient ? detail.patient.age : '-' }}岁</span>
                </div>
            </div>
        </div>
        <div class="mg-t-18 flex-align-center" style="padding-left: 14px;">
            <img class="icon" src="@/assets/img/icon_phone.png" alt="">
            {{ detail.patient ? detail.patient.mobile : '-' }}
        </div>
        <div class="mg-t-18 box">
            <div class="flex">
                <img class="icon" src="@/assets/img/icon_database.png" alt="">
                <div class="value">{{ detail.added_service_name ? detail.added_service_name : '-' }}</div>
            </div>
            <div class="mg-t-18 flex">
                <img class="icon" src="@/assets/img/icon_date.png" alt="">
                <div class="value">{{ detail.serviced_start_at }} 到 {{ detail.serviced_end_at }}</div>
            </div>
            <div class="mg-t-18 flex">
                <img class="icon" src="@/assets/img/icon_email.png" alt="">
                <div class="value">{{ detail.remark ? detail.remark : '-' }}</div>
            </div>
        </div>
        <a-button class="btn" @click="$common.toPage('HReport', {id: detail.id})">填写报告</a-button>
    </div>
    <div class="detail" v-else>
        <div class="flex-center" style="height: 100vh;">
            <div class="text-center">
                <img src="@/assets/img/result.png" alt="">
                <div class="result-text" v-if="detail.status">{{  detail.status == 3 ? '已完成' : '已提交' }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue"
import { useRoute } from 'vue-router'
export default {
    setup(){
        const {proxy} = getCurrentInstance()
        const route = useRoute()
        let detail = ref({})
        onMounted(() => {
            proxy.$httpUtil.get(`/service_person/added_service_order/${route.query.number}`).then(res => {
                detail.value = res.data.added_service_order
                if(detail.value.status == 2 && !detail.value.service_report_content && !detail.value.service_report_image.length) {
                    detail.value.status = 1
                }
            })
        })
        return {
            detail,
            route
        }
    }
}
</script>

<style scoped lang="less">
    .result-text {
        margin-top: 24px;
        font-size: 16px;
        color: red;
    }
    .detail {
        padding: 24px;
        padding-bottom: 48px;
        .box {
            border: 1px solid #3ADDB7;
            background-color: #E1FFF8;
            padding: 14px;
            border-radius: 4px;
        }
        .icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            margin-top: 2px;
        }
        .value {
            width: calc(100% - 24px);
        }
        .person {
            display: flex;
            align-items: center;
            padding: 0 14px;
            .avatar {
                width: 48px;
                margin-right: 12px;
            }
            .name {
                line-height: 24px;
                font-size: 16px;
                font-weight: 600;
                color: #333;
            }
            .text {
                margin-top: 8px;
                line-height: 18px;
                font-size: 14px;
                color: #333;
                .age {
                    margin-right: 8px;
                }
            }
        }
    }
    .btn {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 48px;
        background-color: #3ADDB7;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        box-shadow: none;
        border: none;
    }
</style>