<template>
    <global-menu open="7" selected="7-3" pageTitle="病症管理" @clickNew="showModal('新增  病症')">
        <template #btnText>新增病症</template>
        <div class="page-contener">
            <div class="table-toast">共 <span class="num">{{ total }}</span> 种病症</div>
            <a-table class="mg-t-32" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading">
                <template #bodyCell="{ column, record }">
                    <div v-if="column && column.dataIndex === 'edit'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_edit_cyan.png" alt=""
                            @click="showModal('编辑  病症', record.id)" />
                    </div>
                    <div v-if="column && column.dataIndex === 'delete'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_delete_red.png" alt=""
                            @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
        </div>
    </global-menu>
    <global-dialog :width="560" :isShow="isShow" :title="modalTitle" @onCancel="closeModal" :loading="submitLoading" @onOk="createOrUpdate">
        <div>
            <global-title class="mg-t-16" title="病症名称"></global-title>
            <g-input class="mg-t-24" placeholder="病症名称" v-model="form.name"></g-input>
            <global-title class="mg-t-48" title="病症分类"></global-title>
            <g-select @change="selectChange" :options="categoryOption" :selectId="form.parent_id" v-model="form.parent_id" class="mg-t-24" placeholder="选择分类"></g-select>
            <global-title class="mg-t-16" title="定金"></global-title>
            <g-input class="mg-t-24" placeholder="定金" v-model="form.money"></g-input>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import GSelect from "../../../components/GSelect.vue";
import { ref, onMounted, getCurrentInstance } from "vue"
import { Modal } from "ant-design-vue";
export default {
    components: {
        GlobalMenu,
        GlobalDialog,
        GlobalTitle,
        GInput,
        GSelect
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const tableColumns = ref([
            { dataIndex: "name", title: "病症名" },
            { dataIndex: "category_name", title: "所属分类", width: "200px" },
            { dataIndex: "money", title: "定金", width: "200px" },
            { dataIndex: "updated_at", title: "更新时间", width: "180px" },
            { dataIndex: "edit", title: "编辑", width: "80px" },
            { dataIndex: "delete", title: "删除", width: "80px" },
        ]);
        let tableData = ref([{}]);
        let loading = ref(false);
        let submitLoading = ref(false);
        let isShow = ref(false)
        let modalTitle = ref()
        let total = ref(0);
        let categoryOption = ref([])
        let form = ref({});
        let ids = ref(0);
        let tableScrollHeight = ref(0);
        function showModal(title, id) {
            modalTitle.value = title
            if (id) {
                ids.value = id;
                getDetail(id)
            }else {
                isShow.value = true
            }
        }

        function closeModal() {
            ids.value = ''
            isShow.value = false;
            form.value = {};
            submitLoading.value = false
        }
        function del(id) {
            if (!id) {
                proxy.$message.warn('请选择数据')
                return false;
            }
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/disease/${id}`, {}).then(res => {
                        closeModal();
                        getList();
                        return Promise.resolve()
                    })
                }
            })
        }
        function createOrUpdate() {
            //update
            if (!form.value.name) {
                proxy.$message.warn('请输入必填项')
                return false;
            }
            if (!form.value.parent_id) {
                proxy.$message.warn('请选择分类')
                return false;
            }
            const postData = {
                name: form.value.name,
                parent_id: form.value.parent_id,
                money: form.value.money,
            }
            submitLoading.value = true
            if (ids.value) {
                proxy.$httpUtil.put(`/manage/disease/${ids.value}`, postData).then(res => {
                    closeModal();
                    getList();
                }).catch(() => {
                    submitLoading.value = false
                })
            } else {
                proxy.$httpUtil.post(`/manage/disease`, postData).then(res => {
                    closeModal();
                    getList();
                }).catch(() => {
                    submitLoading.value = false
                })
            }
        }
        function getDetail(id) {

            proxy.$httpUtil.get(`/manage/disease_show/${id}`).then(res => {
                form.value.name = res.data.disease.name;
                form.value.parent_id = res.data.disease.parent_id;
                form.value.money = res.data.disease.money;
                isShow.value = true
            })
        }

        function getList() {
            proxy.$httpUtil.get(`/manage/disease`).then(res => {
                tableData.value = res.data.disease
                total.value = res.data.disease.length
                tableScrollHeight.value = document.querySelector('.page-contener').offsetHeight - 48 - 32 - 56 - 64
            })
        }
        function getCategory() {
            proxy.$httpUtil.get(`/manage/disease_category`).then(res => {
                categoryOption.value = res.data.category;
            })
        }
        function selectChange(e) {
            // form.value.parent_id = e;
        }
        onMounted(() => {
            getCategory();
            getList()
        })
        return {
            tableColumns,
            tableData,
            loading,
            submitLoading,
            isShow,
            modalTitle,
            total,
            categoryOption,
            form,
            tableScrollHeight,

            showModal,
            closeModal,
            del,
            selectChange,
            createOrUpdate,
        }
    }
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;
    overflow: auto;
    padding: 16px;

    .table-toast {
        color: #252733;
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;

        .num {
            color: #3addb7;
        }
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }
}
</style>