<template>
    <global-menu open="6" selected="6" pageTitle="工作台" :isShowNew="false">
        <div class="flex-warpper">
            <div class="flex-item">
                <div class="title flex-center">
                    在日就诊（{{ list.local ? list.local.order_total : 0 }}件）
                    <img class="icon pointer" src="@/assets/img/icon_message_red.png" alt="" v-if="list.local && list.local.is_feedback == 2" @click="$common.toPage('OrderTreatIndex')">
                    <img class="icon" src="@/assets/img/icon_message_gray.png" alt="" v-else>
                </div>
                <div class="list" v-if="list.local">
                    <item type="local" v-for="item in list.local.items" :key="item.id" :data="item" @click="showModal('local', item)" />
                </div>
            </div>
            <div class="flex-item">
                <div class="title flex-center">
                    远程问诊（{{ list.online ? list.online.order_total : 0 }}件）
                    <img class="icon pointer" src="@/assets/img/icon_message_red.png" alt="" v-if="list.online && list.online.is_feedback == 2" @click="$common.toPage('OrderOnlineIndex')">
                    <img class="icon" src="@/assets/img/icon_message_gray.png" alt="" v-else>
                </div>
                <div class="list" v-if="list.online">
                    <item type="online" v-for="item in list.online.items" :key="item.id" :data="item" @click="showModal('online', item)" />
                </div>
            </div>
            <div class="flex-item">
                <div class="title flex-center">
                    人工咨询（{{ list.consult ? list.consult.order_total : 0 }}件）
                </div>
                <div class="list" v-if="list.consult">
                    <item type="consult" v-for="item in list.consult.items" :key="item.id" :data="item" @click="$common.toPage('OrderConsultIndex', {id: item.id})" />
                </div>
            </div>
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" :title="modalTitle" cancelText="关闭" okText="查看更多" @onOk="submit" @onCancel="isModalShow = false">
        <a-row :gutter="24">
            <a-col :span="12">
                <global-title title="患者信息" />
                <div class="text">{{ detail.patient ? detail.patient.name : '-' }}（{{ detail.patient ? detail.patient.mobile : '-' }}）</div>
                <div class="text">{{ detail.patient ? detail.patient.sex : '-' }}</div>
                <div class="text">{{ detail.patient ? `${detail.patient.age}岁` : '-' }}</div>
            </a-col>
            <a-col :span="12">
                <global-title title="就诊信息" />
                <div class="text">{{ detail.hospital_name ? detail.hospital_name : '-' }}（{{ detail.service_name ? detail.service_name : '-' }}）</div>
                <div class="text">{{ detail.doctor_name ? detail.doctor_name : '待定' }}</div>
                <div class="text">{{ detail.treated_at ? $moment(detail.treated_at).format('YYYY-MM-DD HH:mm') : '待定' }}</div>
            </a-col>
        </a-row>
        <global-title class="mg-t-24" title="账单信息" />
        <a-table class="mg-t-20" :scroll="{y: 400}" :columns="tableColumns" :dataSource="tableData" :pagination="false">
            <template #bodyCell="{ column, record }">
                <div v-if="column.dataIndex === 'status'">
                    <span :class="`status-${record.status}`">{{ record.status_label }}</span>
                </div>
                <div v-if="column.dataIndex === 'pay_money'">
                    
                    {{ record.status == 1 ? '-' : record.pay_money }}
                </div>
                <div v-if="column.dataIndex === 'pay_method'">
                    <span v-if="record.status == 2">{{ record.pay_method == 3 ? '现金' : record.pay_method == 4 ? '信用卡' : '线上' }}</span>
                    <span v-if="record.status == 1">-</span>
                </div>
            </template>
        </a-table>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import item from './item.vue'

export default {
    components: {
        GlobalMenu,
        PlusCircleFilled,
        PlusCircleOutlined,
        GlobalDialog,
        GlobalTitle,
        item
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const state = reactive({
            count: {},
            list: {},
            isModalShow: false,
            modalTitle: '在日就诊',
            modalType: 'local',
            tableData: [],
            detail: {}
        })
        const submit = () => {
            if(state.modalType == 'local') {
                proxy.$common.toPage('OrderTreatIndex', {id: state.detail.id})
            }else {
                proxy.$common.toPage('OrderOnlineIndex', {id: state.detail.id})
            }
        }
        const showModal = async (type, data) => {
            state.modalType = type
            state.detail = data
            let form = {
                platform_order_id: data.id,
                page: 1,
                page_size: 10000,
                platform_order_type: type == 'local' ? 1 : 2
            }
            let res = await proxy.$api.getOrderBill(form)
            state.tableData = res.items
            state.isModalShow = true
        }
        onMounted(async () => {
            // state.count = await proxy.$api.getWorkStatistics()
            state.list = await proxy.$api.getWorkList()
        })
        return {
            ... toRefs(state),
            tableColumns: [
                {
                    dataIndex: "status",
                    title: "状态",
                },
                {
                    dataIndex: "money",
                    title: "应付金额(円)",
                },
                {
                    dataIndex: "pay_money",
                    title: "支付金额(円)",
                },
                {
                    dataIndex: "pay_method",
                    title: "支付方式",
                },
                {
                    dataIndex: "pay_at",
                    title: "支付时间",
                },
                {
                    dataIndex: "created_at",
                    title: "创建时间",
                }
            ],
            showModal,
            submit
        };
    },
};
</script>
<style lang="less" scoped>
    /deep/.g-container {
        background-color: #fff;
    }
    .status-1 {
        color: #FFB03E;
    }
    .status-3 {
        color: #FF504A;
    }
    .text {
        margin-top: 12px;
    }
    .flex-warpper {
        height: 100%;
        display: flex;
        .flex-item {
            width: calc((100% - 24px) / 3);
            height: 100%;
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
            .title {
                height: 40px;
                background-color: #3addb7;
                font-size: 16px;
                font-weight: 600;
                color: #fff;
                .icon {
                    width: 24px;
                    margin-left: 4px;
                }
            }
            .list {
                margin-top: 12px;
                height: calc(100% - 52px);
                overflow: auto;
            }
        }
    }
</style>