<template>
    <global-menu open="2" selected="2-1" pageTitle="推荐医院" @clickNew="$common.toPage('MarketingHospitalDetail')">
        <global-search :searchList="searchList" :searchDorm="searchForm" @search="search"></global-search>
        <div class="page-contener">
            <div class="table-toast">共 <span class="num">{{ pagination.total }}</span> 个机构</div>
            <a-table class="mg-t-32" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column && column.dataIndex === 'address'">
                        {{ record.address.cn }}
                    </div>
                    <div v-if="column && column.dataIndex === 'detail'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_detail.png" alt=""
                            @click="$common.toPage('MarketingHospitalDetail', { id: record.id })" />
                    </div>
                    <div v-if="column && column.dataIndex === 'delete'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import Pagination from "@/components/Pagination.vue";
import { reactive, toRefs, onMounted, ref, getCurrentInstance } from "vue";
import { Modal } from "ant-design-vue";
export default {
    components: {
        GlobalMenu,
        GlobalSearch,
        Pagination
    },

    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        let state = reactive({
            loading: false,
            tableData: [],
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
        })
        let searchForm = reactive({
            name: undefined,
            page: 1,
            per_page: 10
        });
        const search = (searchData) => {
            searchForm = {...searchForm, ... searchData}
            state.pagination.page = 1
            refPagination.value.reset(1)
            getData()
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/recommend_hospital/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const getData = async () => {
            state.loading = true
            let res = await proxy.$api.getHospitalRecommend(searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        const pageChange = data => {
            state.pagination.page = data.page
            getData()
        }
        onMounted(() => {
            getData()
        })
        return {
            ... toRefs(state),
            refPagination,
            searchList: [
                {
                    type: "input",
                    model: "hospital_name",
                    placeholder: "医院名称",
                    icon: require("@/assets/img/icon_hospital.png"),
                },
            ],
            searchForm,
            tableColumns: [
                { dataIndex: "hospital_name", title: "医院名称" },
                { dataIndex: "address", title: "地址" },
                { dataIndex: "updated_at", title: "更新时间", width: 170 },
                { dataIndex: "detail", title: "详情", width: 80 },
                { dataIndex: "delete", title: "删除", width: 80 },
            ],

            search,
            pageChange,
            del
        }
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: calc(100% - 92px);
    overflow: auto;
    margin-top: 12px;
    padding: 16px;

    .table-toast {
        color: #252733;
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;

        .num {
            color: #3ADDB7;
        }
    }

    .table-icon {
        width: 24px !important;
        height: 24px !important;

    }
}

.dialog-modal {
    padding-bottom: 64px;

    .form-item-data {
        width: calc((100% - 64px) / 3) !important;
        margin-right: 32px;
        margin-bottom: 24px;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    ::v-deep .ant-divider-horizontal {
        margin: 8px 0px 30px;
    }

    .subtitle {
        color: #b6bbd7 !important;
        font-size: 20px;
        margin-left: 40px;
    }

    .inline-box {
        display: flex;
        justify-content: space-between;

        .line-item {
            width: calc((100% - 64px) / 2);
        }
    }

    .info-img {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        background: #e8e8e8;

        &:nth-child(3n) {
            margin-right: 0px;
        }
    }

    .file-box {
        display: flex;
        flex-wrap: wrap;
    }

    .info-file {
        width: 128px;
        height: 128px;
        margin-right: 24px;
        border-radius: 8px;
        margin-bottom: 24px;
        border: 1px solid #e8e8e8;
        text-align: center;
        position: relative;

        &:nth-child(3n) {
            margin-right: 0px;
        }

        .img {
            width: 56px;
            height: 56px;
        }

        .file-name {
            color: #202020;
            font-size: 14px;
            line-height: 18px;
            margin-top: 16px;
        }

        .file-del {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 128px;
        height: 128px;
        border-radius: 8px;
        border: 1px solid #3addb7;
    }

    .meeting-title {
        color: #3addb7;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
    }

    .meeting-title:last-child {
        margin-top: 48px;
    }

    .meeting-info {
        border-radius: 16px;
        background: #35b8ff;
        color: #ffffff;
        padding: 24px 32px;

        .title {
            font-size: 24px;
            line-height: 40px;
            text-align: center;
        }

        .num-wapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 8px;

            .num {
                font-size: 44px;
                line-height: 64px;
                font-weight: 500;
            }

            .icon {
                width: 32px;
                height: 32px;
                margin-left: 24px;
            }
        }

        .password-wapper {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 24px;
            }

            .password {
                font-size: 24px;
                line-height: 24px;
            }
        }

        .time-wapper {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            .time {
                font-size: 40px;
                line-height: 48px;
            }

            .date {
                font-size: 20px;
                line-height: 28px;
            }

            .duration {
                width: 112px;
                height: 48px;
                font-size: 20px;
                line-height: 28px;
                border-bottom: 2px solid #ffffff;
                padding-top: 12px;
                padding-bottom: 8px;
            }
        }
    }
}</style>