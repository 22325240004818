<template>
  <global-menu open="1" selected="1-6">
    <section class="page-contener">
      <global-header title="服务详情">
        <template #title_content>
          <div class="title-content">
            DP23243546 <span class="mg-l-32">梅小青</span>
          </div>
        </template>
        <template #default>
          <span class="status-text">已支付</span>
        </template>
      </global-header>
      <div class="content-wapper">
        <div class="flex-between">
          <div class="item-wapper">
            <global-title title="患者信息"></global-title>
            <div class="item">
              <a-row :gutter="24">
                <a-col :span="12">
                  <div class="label">联系人</div>
                  <div class="value">梅小青 （女 26岁）</div>
                </a-col>
                <a-col :span="12">
                  <div class="label">联系电话</div>
                  <div class="value">138 3674 8923</div>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="item-wapper">
            <global-title title="就诊信息"></global-title>
            <!-- 在日：
就诊机构、科室、医生

远程：
问诊机构、病症、医生

项目：
服务机构、项目、医生 -->
            <div class="item">
              <a-row :gutter="24">
                <a-col :span="24">
                  <div class="label">就诊机构</div>
                  <div class="value">机构名称占位文本</div>
                </a-col>
                <a-col :span="12" class="mg-t-32">
                  <div class="label">科室/病症</div>
                  <div class="value">齿科/糖尿病</div>
                </a-col>
                <a-col :span="12" class="mg-t-32">
                  <div class="label">医生</div>
                  <div class="value">钟南山</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
        <div class="mg-t-48 flex-between">
          <global-title title="账单列表"></global-title>
          <plus-circle-filled
            :style="{ fontSize: '48px', color: '#3addb7' }"
            @click="showModal('添加账单')"
          />
        </div>
        <a-table
          class="mg-t-20"
          :columns="tableColumns"
          :dataSource="tableData"
          :loading="loading"
          :pagination="false"
        >
          <template #bodyCell="{ column }">
            <img
              v-if="column.dataIndex === 'detail'"
              class="table-icon"
              src="../../../assets/img/icon_detail.png"
              alt=""
              @click="
                $common.toPage('OrderTreatBillDetail', {
                  open: '1',
                  selected: '1-6',
                })
              "
            />
            <img
              v-if="column.dataIndex === 'delete'"
              class="table-icon"
              src="../../../assets/img/icon_delete_red.png"
              alt=""
            />
          </template>
        </a-table>
      </div>
      <div class="btn-wapper">
        <g-button>完成服务</g-button>
        <g-button type="default" class="mg-l-40" ghost @click="$router.back()">关闭</g-button>
      </div>
    </section>
  </global-menu>
  <global-dialog
    :isShow="isModalShow"
    :title="modalTitle"
    @onOk="closeModal"
    @onCancel="closeModal"
  >
    <div class="dialog-modal">
      <div class="flex-between">
        <global-title title="收费条目"></global-title>
        <plus-circle-filled
          :style="{ fontSize: '48px', color: '#3addb7' }"
          @click="addChargeItem"
        />
      </div>
      <a-table
        :pagination="false"
        :scroll="{ y: 500 }"
        :columns="chargeColumns"
        :dataSource="chargeData"
        class="mg-t-30"
      >
        <template #summary>
          <a-table-summary-row>
            <a-table-summary-cell :col-span="3"
              >账单合计：</a-table-summary-cell
            >
            <a-table-summary-cell>
              <a-typography-text> {{ totals.totalNum }}</a-typography-text>
            </a-table-summary-cell>
            <a-table-summary-cell>
              <a-typography-text style="color: #ff504a">{{
                totals.totalPrice
              }}</a-typography-text>
            </a-table-summary-cell>
          </a-table-summary-row>
        </template>

        <template #bodyCell="{ column, record, index }">
          <div v-if="column.dataIndex == 'operation'">
            <img
              v-if="index == 0"
              class="table-icon"
              src="../../../assets/img/icon_cancel_grey.png"
              alt=""
            />
            <img
              v-else
              @click="delChargeItem(index)"
              class="table-icon"
              src="../../../assets/img/icon_cancel_red.png"
              alt=""
            />
          </div>
          <div v-if="column.dataIndex == 'name'" class="flex-between">
            <a-input v-model:value="record.name"></a-input>
          </div>
          <div v-if="column.dataIndex == 'price'">
            <a-input v-model:value="record.price"></a-input>
          </div>
          <div v-if="column.dataIndex == 'num'">
            <a-input v-model:value="record.num"></a-input>
          </div>
        </template>
      </a-table>
    </div>
  </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalTitle from "@/components/GlobalTitle.vue";
import GlobalDialog from "@/components/GlobalDialog.vue";
import GButton from "@/components/GButton.vue";
import { PlusCircleFilled } from "@ant-design/icons-vue";
import { ref, computed } from "vue";
export default {
  components: {
    GlobalMenu,
    GlobalHeader,
    GlobalTitle,
    PlusCircleFilled,
    GButton,
    GlobalDialog,
  },
  setup() {
    let tableColumns = ref([
      {
        dataIndex: "type",
        title: "状态",
      },
      {
        dataIndex: "money",
        title: "付款金额（円）",
      },
      {
        dataIndex: "method",
        title: "支付方式",
      },
      {
        dataIndex: "pay_time",
        title: "支付时间",
      },
      {
        dataIndex: "create_at",
        title: "创建时间",
      },
      {
        dataIndex: "detail",
        title: "详情",
      },
      {
        dataIndex: "delete",
        title: "删除",
      },
    ]);
    let tableData = ref([{}]);
    let loading = ref(false);
    let isModalShow = ref(false);
    let modalTitle = ref();
    let chargeItems = ref([
      { name: "收费条目", price: 9000, num: 1, total: 0 },
    ]);
    let chargeData = computed(() => {
      let temp = [];
      chargeItems.value.forEach((item, index) => {
        item.total = item.num * item.price;
        item.key = index;
        temp.push(item);
      });
      return temp;
    });
    let chargeColumns = ref([
      {
        dataIndex: "operation",
        title: "",
        width: 50,
      },
      {
        dataIndex: "name",
        title: "条目名称",
      },
      {
        dataIndex: "price",
        title: "单价（円）",
        width: 150,
      },
      {
        dataIndex: "num",
        title: "数量",
        width: 120,
      },
      {
        dataIndex: "total",
        title: "合计（円）",
        width: 150,
      },
    ]);
    let totals = computed(() => {
      let totalNum = 0;
      let totalPrice = 0;
      chargeData.value.forEach(({ num, total }) => {
        totalNum += Number(num);
        totalPrice += Number(total);
      });

      return { totalNum, totalPrice };
    });

    //方法
    function showModal(title) {
      modalTitle.value = title;
      isModalShow.value = true;
    }
    function closeModal() {
      isModalShow.value = false;
    }
    function addChargeItem() {
      chargeItems.value.push({
        name: "收费条目",
        price: 9000,
        num: 1,
        total: 0,
      });
    }
    function delChargeItem(index) {
      chargeItems.value.splice(index, 1);
    }
    return {
      tableColumns,
      tableData,
      loading,
      isModalShow,
      modalTitle,
      chargeData,
      chargeColumns,
      totals,

      showModal,
      closeModal,
      addChargeItem,
      delChargeItem,
    };
  },
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 40px);
  position: relative;
  .title-content {
    color: #b6bbd7;
    font-size: 20px;
    line-height: 20px;
    margin-left: 40px;
  }
  .status-text {
    color: #3addb7;
    font-size: 20px;
    line-height: 20px;
  }
  .content-wapper {
    padding: 16px 40px;
    height: calc(100% - 96px - 128px);
    overflow: auto;
    .item-wapper {
      flex: 0 0 auto;
      width: calc((100% - 96px) / 2);

      .item {
        height: 208px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        background: #f9f9f9;
        padding: 32px;
        margin-top: 16px;
        .label {
          color: #b6bbd7;
          font-size: 14px;
          line-height: 18px;
          display: block;
            padding: 0;
            border-radius: 0;
            text-align: left;
            font-weight: normal;
        }
        .value {
          color: #252733;
          font-size: 18px;
          line-height: 26px;
          margin-top: 12px;
        }
      }
    }
    .table-icon {
      width: 24px;
      height: 24px;
    }
  }
  .btn-wapper {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.dialog-modal {
  overflow: auto;
  .table-icon {
    width: 20px;
    height: 20px;
  }
}
</style>