<template>
  <global-menu open="4" selected="4-2" pageTitle="价格设置" :isShowNew="false">
    <global-search
      class="mg-t-19"
      :searchList="searchList"
      :searchDorm="searchForm"
      @search="search"
    ></global-search>
    <div class="page-contener">
      <div class="table-toast">共 <span class="num">101</span> 件商品</div>
      <a-table
        class="mg-t-32"
        :columns="tableColumns"
        :dataSource="tableData"
        :loading="loading"
      >
        <template #bodyCell="{ column }">
          <div v-if="column && column.dataIndex === 'set'">
            <img
              class="table-icon"
              src="../../../assets/img/icon_amount_orange.png"
              alt=""
              @click="$common.toPage('GoodsPriceDetail')"
            />
          </div>
        </template>
      </a-table>
    </div>
  </global-menu>
</template>
<script>
import GlobalMenu from "../../../components/GlobalMenu.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import {ref} from "vue"
export default {
  components: {
    GlobalMenu,
    GlobalSearch
  },
  setup(){
    let searchList = ref([
      {
        type: "input",
        model: "classify",
        placeholder: "分类",
        icon: require("@/assets/img/icon_classifcation.png"),
      },
      {
        type: "select",
        model: "classify",
        placeholder: "供货机构",
        icon: require("@/assets/img/icon_hospital.png"),
      },
    ]);
    let searchForm = ref({
      name: undefined,
      phone: undefined,
      address: undefined,
      date: undefined,
    });
    const tableColumns = ref([
      { dataIndex: "data1", title: "商品信息", width: "400px" },
      { dataIndex: "data2", title: "供货机构", width: "300px" },
      { dataIndex: "data4", title: "审核时间", width: "300px" },
      { dataIndex: "set", title: "设置", width: "100px" },
    ]);
    let tableData = ref([{}]);
    let loading = ref(false);
    return {
        searchList,
        searchForm,
        tableColumns,
        tableData,
        loading

    }
  }
};
</script>
<style lang="less" scoped>
.page-contener {
  height: calc(100vh - 78px - 64px - 32px);
  overflow: auto;
  margin-top: 12px;
  padding: 16px;
  .table-toast {
    color: #252733;
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    .num {
      color: #3addb7;
    }
  }
  .table-icon {
    width: 24px !important;
    height: 24px !important;
  }
}
</style>