<template>
    <div class="mask flex-center" @click="onclick">
        <img :src="url" alt="">
    </div>
</template>
<script>
export default {
    props: {
        url: {
            type: String,
            default: '',
        }
    },
    setup(props, context){
        const onclick = () => {
            context.emit('hide')
        }
        return {
            onclick
        }
    }
}
</script>
<style lang="less" scoped>
    .mask {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10000;
        background-color: rgba(0,0,0,0.6);
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
</style>