<template>
    <global-menu open="7" selected="7-5" pageTitle="内容管理" @clickNew="showModal('create')">
        <template #btnText>新增</template>
        <div class="page-contener">
            <g-tab :tabs="tab" @change="tabChange"></g-tab>
            <a-table class="mg-t-32" :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record, index }">
                    <div v-if="column.dataIndex === 'cover'">
                        <img class="table-img" :src="record.cover.cn" alt="" v-if="record.cover" />
                        <span v-else>-</span>
                    </div>
                    <div v-if="column.dataIndex === 'title'">
                        {{ record.title.cn }}
                    </div>
                    <div v-if="column.dataIndex === 'is_top'">
                        <a-switch :checked="record.is_top == 2" @change="value => swithChange(value, record.id, index)" />
                    </div>
                    <div v-if="column.dataIndex === 'operation'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_edit_cyan.png" alt="" @click="showModal('edit', record)" />
                        <img class="table-icon mg-l-8  pointer" src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" :title="modalTitle" @onOk="submit" :loading="submitLoading" @onCancel="closeModal">
        <a-row :gutter="24">
            <a-col class="mg-t-26" :span="6">
                <global-title title="ID"></global-title>
                <g-input class="mg-t-24" placeholder="ID" v-model="form.uuid_code" :disable="modalType == 'edit' && tabActive != 0"></g-input>
            </a-col>
        </a-row>
        <g-tab class="mg-t-24" :tabs="languageTab" @change="languageTabChange"></g-tab>
        <global-title class="mg-t-24" title="标题"></global-title>
        <g-input class="mg-t-24" placeholder="标题" v-model="form.title.cn" v-if="languageTabActive == 0"></g-input>
        <g-input class="mg-t-24" placeholder="标题" v-model="form.title.jp" v-if="languageTabActive == 1"></g-input>
        <g-input class="mg-t-24" placeholder="标题" v-model="form.title.en" v-if="languageTabActive == 2"></g-input>
        <div class="mg-t-24">
            <global-title :title="tabActive == 0 ? '封面' : '封面（非必填）'"></global-title>
            <div class="mg-t-24 flex-align-center">
                <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                    :customRequest="fileChange">
                    <div v-if="languageTabActive == 0 && imgUrl.cn" class="select-wapper">
                        <img class="select-img" :src="imgUrl.cn" alt="" />
                    </div>
                    <div v-else-if="languageTabActive == 1 && imgUrl.jp" class="select-wapper">
                        <img class="select-img" :src="imgUrl.jp" alt="" />
                    </div>
                    <div v-else-if="languageTabActive == 2 && imgUrl.en" class="select-wapper">
                        <img class="select-img" :src="imgUrl.en" alt="" />
                    </div>
                    <img class="upload-img" v-else src="../../../assets/img/icon_pic_grey.png" alt="" />
                </a-upload>
                <div class="upload-text">
                    点击上传图片
                    <div style="margin-top: 8px">尺寸686 × 328像素；格式png、jpg</div>
                </div>
            </div>
        </div>
        <div class="mg-t-24">
            <global-title title="内容"></global-title>
            <div class="mg-t-24" v-show="languageTabActive == 0">
                <editor ref="editorRefCn" :content="form.content.cn" id="contentCn" style="height: 400px;" @input="value => form.content.cn = value" />
            </div>
            <div class="mg-t-24" v-show="languageTabActive == 1">
                <editor ref="editorRefJp" :content="form.content.jp" id="contentJp" style="height: 400px;" @input="value => form.content.jp = value" />
            </div>
            <div class="mg-t-24" v-show="languageTabActive == 2">
                <editor ref="editorRefEn" :content="form.content.en" id="contentEn" style="height: 400px;" @input="value => form.content.en = value" />
            </div>
        </div>
    </global-dialog>
</template>
<script>
import GlobalMenu from "@/components/GlobalMenu.vue";
import GTab from "../../../components/GTab";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance, nextTick } from "vue";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import Pagination from "@/components/Pagination.vue"
import Editor from "@/components/Editor.vue"

export default {
    components: {
        GlobalMenu,
        GTab,
        PlusCircleFilled,
        PlusCircleOutlined,
        Pagination,
        GlobalDialog,
        GlobalTitle,
        GInput,
        Editor
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        const editorRefCn = ref(null)
        const editorRefJp = ref(null)
        const editorRefEn = ref(null)
        let state = reactive({
            tabActive: 0,
            loading: false,
            tableColumns: [],
            tableData: [],
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
            isModalShow: false,
            modalType: 'create',
            modalTitle: '新增 新闻动态',
            languageTabActive: 0,
            fileData: {
                cn: null,
                jp: null,
                en: null,
            },
            imgUrl: {
                cn: undefined,
                jp: undefined,
                en: undefined,
            },
            form: {
                uuid_code: undefined,
                title: {
                    cn: undefined,
                    jp: undefined,
                    en: undefined,
                },
                cover: {
                    cn: undefined,
                    jp: undefined,
                    en: undefined,
                },
                category: 1,
                content: {
                    cn: undefined,
                    jp: undefined,
                    en: undefined,
                }
            },
            submitLoading: false,
            detail: ''
        })
        let searchForm = reactive({
            category: 1,
            page: 1,
            page_size: 10,
        })
        const submit = async () => {
            if(!state.form.uuid_code || !state.form.content.cn || !state.form.content.jp || !state.form.content.en || !state.form.title.cn || !state.form.title.en || !state.form.title.jp) {
                proxy.$message.error('请填写必填项')
                return
            }
            if(state.tabActive == 0 && (!state.fileData.cn || !state.fileData.jp || !state.fileData.en) && (!state.imgUrl.cn || !state.imgUrl.jp || !state.imgUrl.en)) {
                proxy.$message.error('请上传封面')
                return
            }
            state.submitLoading = true
            for(let i in state.fileData) {
                if(state.fileData[i]) {
                    let res = await proxy.$httpUtil.upload('/platform/file_upload', state.fileData[i], 'editor')
                    if(res.file_url) {
                        state.form.cover[i] = res.file_url
                    }else {
                        proxy.$message.error('图片上传失败')
                        state.submitLoading = false
                        return
                    }
                }
            }
            if(state.modalType == 'edit') {
                proxy.$httpUtil.put(`/manage/article/${state.detail.id}`, state.form).then(res => {
                    if(res.success) {
                        state.submitLoading = false
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }else {
                proxy.$httpUtil.post('/manage/article', state.form).then(res => {
                    if(res.success) {
                        state.submitLoading = false
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/article/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const swithChange = (value, id, index) => {
            proxy.$httpUtil.get(`/manage/article_top/${id}`).then(res => {
                if(res.success) {
                    let is_top = state.tableData[index].is_top
                    if(is_top == 1) {
                        proxy.$message.success(`置顶成功`)
                        state.tableData[index].is_top = 2
                    }else {
                        proxy.$message.success(`取消成功`)
                        state.tableData[index].is_top = 1
                    }
                }
            })
        }
        const fileChange = info => {
            let type = 'cn'
            if(state.languageTabActive == 1) {
                type = 'jp'
            }else if(state.languageTabActive == 2) {
                type = 'en'
            }
            state.fileData[type] = info.file
            state.imgUrl[type] = proxy.$common.getFileUrl(info.file)
        }
        const languageTabChange = index => {
            state.languageTabActive = index
        }
        const showModal = (type, data) => {
            state.modalType = type
            state.modalTitle = '新增'
            state.isModalShow = true
            if(type == 'edit') {
                state.modalTitle = '编辑'
                state.detail = data
                state.form = {
                    uuid_code: data.uuid_code,
                    title: data.title,
                    cover: data.cover,
                    category: state.tabActive + 1,
                    content: data.content
                }
                state.imgUrl = {... data.cover}
                state.fileData = {
                    cn: null,
                    jp: null,
                    en: null,
                }
                nextTick(() => {
                    editorRefCn.value.init(data.content.cn)
                    editorRefJp.value.init(data.content.jp)
                    editorRefEn.value.init(data.content.en)
                })
            }else {
                state.form = {
                    uuid_code: undefined,
                    title: {
                        cn: undefined,
                        jp: undefined,
                        en: undefined,
                    },
                    cover: {
                        cn: undefined,
                        jp: undefined,
                        en: undefined,
                    },
                    category: state.tabActive + 1,
                    content: {
                        cn: undefined,
                        jp: undefined,
                        en: undefined
                    }
                }
                state.imgUrl = {
                    cn: undefined,
                    jp: undefined,
                    en: undefined,
                }
                state.fileData = {
                    cn: null,
                    jp: null,
                    en: null,
                }
                nextTick(() => {
                    console.log(123)
                    editorRefCn.value.init(' ')
                    editorRefJp.value.init(' ')
                    editorRefEn.value.init(' ')
                })
                
            }
            if(state.tabActive == 0) {
                state.modalTitle += ' 新闻动态'
            }else if(state.tabActive == 1) {
                state.modalTitle += ' 用户手机'
            }else if(state.tabActive == 3) {
                state.modalTitle += ' 机构平板'
            }
        }
        const closeModal = () => {
            state.isModalShow = false
            state.languageTabActive = 0
        }
        const tabChange = index => {
            state.tabActive = index
            if(index == 0) {
                state.tableColumns = [
                    {
                        dataIndex: "uuid_code",
                        title: "ID",
                    },
                    {
                        dataIndex: "cover",
                        title: "封面",
                    },
                    {
                        dataIndex: "title",
                        title: "标题",
                    },
                    {
                        dataIndex: "is_top",
                        title: "置顶",
                    },
                    {
                        dataIndex: "operation",
                        title: "操作",
                        width: 120
                    }
                ]
            }else {
                state.tableColumns = [
                    {
                        dataIndex: "uuid_code",
                        title: "ID",
                    },
                    {
                        dataIndex: "cover",
                        title: "封面",
                    },
                    {
                        dataIndex: "title",
                        title: "标题",
                    },
                    {
                        dataIndex: "operation",
                        title: "操作",
                        width: 120
                    }
                ]
            }
            getData()
        }
        const pageChange = data => {
            state.pagination.page = data.page
            getData()
        }
        const getData = async () => {
            state.loading = true
            searchForm.page = state.pagination.page
            searchForm.category = state.tabActive + 1
            let res = await proxy.$api.getArticle(searchForm)
            state.tableData = res.items
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        onMounted(() => {
            tabChange(0)
        })
        return {
            languageTab: ['中文', '日文', '英文'],
            tab: ['新闻动态', '用户手机', '机构平板'],
            refPagination,
            editorRefCn,
            editorRefJp,
            editorRefEn,
            ... toRefs(state),

            tabChange,
            submit,
            showModal,
            fileChange,
            closeModal,
            languageTabChange,
            del,
            swithChange,
            pageChange
        };
    },
};
</script>
<style lang="less" scoped>
.page-contener {
    height: 100%;
    padding: 16px;

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .table-img {
        width: 32px !important;
        height: 32px !important;
    }
}

.dialog-wapper {
    .batch-text {
        color: #3addb7;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .delete-icon {
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }

    .sub-title {
        color: #252733;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
    }

    .sub-tip {
        color: #65697b;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }
    .select-wapper {
        position: relative;

        .select-img {
            width: 343px;
            height: 114px;
        }

        .select-box {
            width: 343px;
            height: 114px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(46, 49, 61, 0.7);
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .upload-text {
        width: 100%;
        color: #252733;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
    }

    ::v-deep .ant-upload-picture-card-wrapper {
        width: 343px;
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 343px;
        height: 114px;
        background: #f4f8fe;
    }

    .color-wapper {
        flex: 0 0 auto;
        height: 48px;
        width: 80px;
        border: 1px solid #b6bbd7;
        border-radius: 10px;
    }
}
</style>