<template>
    <global-menu open="7" selected="7-11" pageTitle="人员管理" @clickNew="showModal('create')">
        <template #btnText>新增</template>
        <div class="page-contener">
            <a-table :scroll="{y: tableScrollHeight}" :columns="tableColumns" :dataSource="tableData" :loading="loading" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <div v-if="column.dataIndex === 'added_servce'">
                        {{ record.added_servce.length ? record.added_servce.join('、') : '-' }}
                    </div>
                    <div v-if="column.dataIndex === 'operation'">
                        <img class="table-icon pointer" src="../../../assets/img/icon_edit_cyan.png" alt="" @click="showModal('edit', record)" />
                        <img class="table-icon mg-l-8  pointer" src="../../../assets/img/icon_delete_red.png" alt="" @click="del(record.id)" />
                    </div>
                </template>
            </a-table>
            <Pagination ref="refPagination" :pagination="pagination" @change="pageChange" v-show="tableData.length" />
        </div>
    </global-menu>
    <global-dialog class="dialog-wapper" :isShow="isModalShow" :title="modalTitle" :loading="submitLoading" @onOk="submit" @onCancel="closeModal">
        <a-row :gutter="24">
            <a-col :span="12">
                <global-title title="姓名" />
                <g-input class="mg-t-24" placeholder="姓名" v-model="form.name"></g-input>
            </a-col>
            <a-col :span="12">
                <global-title title="联系电话" />
                <g-input class="mg-t-24" placeholder="联系电话" v-model="form.mobile"></g-input>
            </a-col>
            <a-col class="mg-t-24" :span="12">
                <global-title title="服务内容" />
                <a-select class="mg-t-24" style="width: 100%;height: 46px" mode="multiple" size="large" placeholder="请选择" v-model:value="form.added_service_id">
                    <a-select-option :value="item.id" :key="item.id" v-for="item in options">{{ item.name }}</a-select-option>
                </a-select>
            </a-col>
            <a-col class="mg-t-24" :span="12">
                <global-title title="登录账号" />
                <g-input class="mg-t-24" placeholder="登录账号" v-model="form.account"></g-input>
            </a-col>
        </a-row>
        <template #btns>
            <g-button type="primary" class="btn  mg-l-40" @click="reset">重置密码</g-button>
        </template>
    </global-dialog>
</template>
<script>
import GButton from "@/components/GButton.vue"
import GlobalMenu from "@/components/GlobalMenu.vue";
import GlobalDialog from "../../../components/GlobalDialog.vue";
import GlobalTitle from "../../../components/GlobalTitle.vue";
import GInput from "../../../components/GInput.vue";
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from "vue";
import { PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import Pagination from "@/components/Pagination.vue"

export default {
    components: {
        GlobalMenu,
        PlusCircleFilled,
        PlusCircleOutlined,
        Pagination,
        GlobalDialog,
        GlobalTitle,
        GInput,
        GButton
    },
    setup() {
        const { proxy } = getCurrentInstance()
        const refPagination = ref()
        let state = reactive({
            tableData: [],
            loading: false,
            pagination: {
                page: 1,
                page_size: 10,
                total: 0
            },
            tableScrollHeight: 500,
            isModalShow: false,
            modalType: 'create',
            modalTitle: '新增消息',
            form: {},
            detail: null,
            submitLoading: false,
            options: []
        })
        let searchForm = reactive({
            page: 1,
            page_size: 10,
        })
        const submit = () => {
            if(!state.form.name || !state.form.mobile || !state.form.added_service_id || !state.form.account) {
                proxy.$message.error('请填写必填项')
                return
            }
            state.submitLoading = true
            if(state.modalType == 'edit') {
                proxy.$httpUtil.put(`/manage/service_person/${state.detail.id}`, state.form).then(res => {
                    if(res.success) {
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }else {
                proxy.$httpUtil.post('/manage/service_person', state.form).then(res => {
                    if(res.success) {
                        closeModal()
                        getData()
                    }
                }).catch(() => {
                    state.submitLoading = false
                })
            }
        }
        const reset = () => {
            proxy.$httpUtil.put(`/manage/service_person_change_password/${state.detail.id}`).then(res => {
                if(res.success) {
                    proxy.$message.success('密码重置成功')
                }
            })
        }
        const del = id => {
            Modal.confirm({
                title: '确定删除该数据吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    proxy.$httpUtil.delete(`/manage/service_person/${id}`).then(res => {
                        if(res.success) {
                            proxy.$message.success(`删除成功`)
                            for(let i in state.tableData) {
                                if(state.tableData[i].id == id) {
                                    state.tableData.splice(i, 1)
                                }
                            }
                            return Promise.resolve()
                        }
                    })
                }
            })
        }
        const showModal = (type, data) => {
            state.modalType = type
            state.modalTitle = '新增服务人员'
            state.detail = data
            if(type == 'edit') {
                state.modalTitle = '编辑服务人员'
                state.form = {
                    name: data.name,
                    mobile: data.mobile,
                    account: data.account,
                    added_service_id: data.serviceId,
                }
            }else {
                state.form = {}
            }
            state.isModalShow = true
        }
        const closeModal = () => {
            state.isModalShow = false
            state.submitLoading = false
        }
        const getData = async () => {
            state.loading = true
            searchForm.page = state.pagination.page
            let res = await proxy.$api.getServicePerson(searchForm)
            state.tableData = res.items
            for(let i in state.tableData) {
                let serviceText = []
                let serviceId = []
                for(let j in state.tableData[i].added_servce) {
                    serviceText.push(state.tableData[i].added_servce[j].name)
                    serviceId.push(state.tableData[i].added_servce[j].id)
                }
                state.tableData[i].serviceText = serviceText.join('、')
                state.tableData[i].serviceId = serviceId
            }
            state.pagination.total = res.total
            state.loading = false
            state.tableScrollHeight = document.querySelector('.page-contener').offsetHeight - 16 - 48 - 16 - 56 - 64
        }
        onMounted(async () => {
            state.options = await proxy.$api.getServiceList()
            getData()
        })
        return {
            tableColumns: [
                {
                    dataIndex: "serviceText",
                    title: "服务内容",
                },
                {
                    dataIndex: "name",
                    title: "人员姓名",
                },
                {
                    dataIndex: "mobile",
                    title: "联系电话",
                },
                {
                    dataIndex: "account",
                    title: "登录账号",
                },
                {
                    dataIndex: "created_at",
                    title: "创建时间",
                },
                {
                    dataIndex: "operation",
                    title: "操作",
                    width: 120
                }
            ],
            refPagination,
            ... toRefs(state),

            showModal,
            closeModal,
            submit,
            del,
            reset
        };
    },
};
</script>
<style lang="less" scoped>
/deep/.ant-select-selector {
    height: 100%;
}
.page-contener {
    height: 100%;
    padding: 16px;

    .table-icon {
        width: 24px !important;
        height: 24px !important;
    }

    .table-img {
        width: 32px !important;
        height: 32px !important;
    }
}

.dialog-wapper {
    .batch-text {
        color: #3addb7;
        font-size: 16px;
        line-height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .delete-icon {
        width: 24px;
        height: 24px;
        margin-right: 26px;
    }

    .sub-title {
        color: #252733;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
    }

    .sub-tip {
        color: #65697b;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    /deep/.upload-icon {
        width: 343px;
        height: 114px;
        border-radius: 8px;
    }

    .upload-text {
        width: 100%;
        color: #252733;
        font-size: 14px;
        line-height: 18px;
        margin-left: 20px;
    }

    ::v-deep .ant-upload.ant-upload-select-picture-card {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: none;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    ::v-deep .ant-upload-picture-card-wrapper {
        width: auto;
    }

    .color-wapper {
        flex: 0 0 auto;
        height: 48px;
        width: 80px;
        border: 1px solid #b6bbd7;
        border-radius: 10px;
    }
}
</style>