<template>
  <div class="g-tab">
    <a-button
      v-for="(item, index) in tabs"
      :key="index"
      class="tab-btn"
      :class="{ 'tab-btn-active': tabActive == index }"
      :style="{ width: width }"
      @click="onChange(index)"
      >{{ item }}</a-button
    >
  </div>
</template>
<script>
import { ref, watch } from "vue";
export default {
  props: {
    width: {
      type: String,
      default: "144px",
    },
    tabs: {
      type: Array,
      default: [],
    },
    active: {
        type: Number,
        default: 0,
    }
  },
  setup(props, context) {
    let tabActive = ref(0);
    function onChange(index) {
      tabActive.value = index;
      context.emit("change", index);
    }
    watch(() => props.active, (_val, oldVal) => {
        tabActive.value = _val
    })
    return {
      tabActive,
      onChange,
    };
  },
};
</script>
<style lang="less" scoped>
.g-tab {
  // width: 100%;
  height: 48px;
  display: inline-block;
  background: #f4f8fe;
  border-radius: 5px;
  padding: 4px 6px;
  
  .tab-btn {
    border: none;
    background: none;
    color: #252733;
    font-size: 16px;
    border-radius: 3px;
    font-weight: 500;
    height: 40px;
  }
  .tab-btn-active {
    color: #ffffff;
    background: #3addb7;
  }
}
</style>