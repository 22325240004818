import { createRouter, createWebHistory } from 'vue-router'
const routes = [{
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录'
        },
        component: () =>
            import ('../views/login/index.vue')
    },
    {
        path: '/index',
        name: 'Index',
        meta: {
            title: '工作台'
        },
        component: () =>
            import ('../views/work/index.vue')
    },
    {
        path: '/order/treat/index',
        name: 'OrderTreatIndex',
        meta: {
            title: '在日就诊'
        },
        component: () =>
            import ('../views/order/treat/index.vue')
    },
    {
        path: '/order/treat/bill',
        name: 'OrderTreatBill',
        meta: {
            title: '账单管理'
        },
        component: () =>
            import ('../views/order/treat/bill.vue')
    },
    {
        path: '/order/treat/bill/detail',
        name: 'OrderTreatBillDetail',
        meta: {
            title: '账单详情'
        },
        component: () =>
            import ('../views/order/treat/billDetail.vue')
    },
    {
        path: '/order/treat/report',
        name: 'OrderTreatReport',
        meta: {
            title: '问诊报告'
        },
        component: () =>
            import ('../views/order/treat/report.vue')
    },
    {
        path: '/order/online/index',
        name: 'OrderOnlineIndex',
        meta: {
            title: '远程问诊'
        },
        component: () =>
            import ('../views/order/online/index.vue')
    },
    {
        path: '/order/goods/index',
        name: 'OrderGoodsIndex',
        meta: {
            title: '商品订单'
        },
        component: () =>
            import ('../views/order/goods/index.vue')
    },
    {
        path: '/order/goods/detail',
        name: 'OrderGoodsDetail',
        meta: {
            title: '订单处理'
        },
        component: () =>
            import ('../views/order/goods/detail.vue')
    },
    {
        path: '/order/drug/index',
        name: 'OrderDrugIndex',
        meta: {
            title: '开药订单'
        },
        component: () =>
            import ('../views/order/drug/index.vue')
    },
    {
        path: '/order/drug/detail',
        name: 'OrderDrugDetail',
        meta: {
            title: '开药清单'
        },
        component: () =>
            import ('../views/order/drug/detail.vue')
    },
    {
        path: '/order/consult/index',
        name: 'OrderConsultIndex',
        meta: {
            title: '咨询订单'
        },
        component: () =>
            import ('../views/order/consult/index.vue')
    },
    {
        path: '/order/serve/index',
        name: 'OrderServeIndex',
        meta: {
            title: '服务订单'
        },
        component: () =>
            import ('../views/order/serve/index.vue')
    },
    {
        path: '/order/serve/detail',
        name: 'OrderServeDetail',
        meta: {
            title: '服务详情'
        },
        component: () =>
            import ('../views/order/serve/detail.vue')
    },
    {
        path: '/marketing/hospital/index',
        name: 'MarketingHospitalIndex',
        meta: {
            title: '推荐医院'
        },
        component: () =>
            import ('../views/marketing/hospital/index.vue')
    },
    {
        path: '/marketing/hospital/detail',
        name: 'MarketingHospitalDetail',
        meta: {
            title: '机构详情'
        },
        component: () =>
            import ('../views/marketing/hospital/detail.vue')
    },
    {
        path: '/marketing/doctor/index',
        name: 'MarketingDoctorIndex',
        meta: {
            title: '推荐医生'
        },
        component: () =>
            import ('../views/marketing/doctor/index.vue')
    },
    {
        path: '/marketing/doctor/detail',
        name: 'MarketingDoctorDetail',
        meta: {
            title: '医生详情'
        },
        component: () =>
            import ('../views/marketing/doctor/detail.vue')
    },
    {
        path: '/marketing/goods/index',
        name: 'MarketingGoodsIndex',
        meta: {
            title: '推荐商品'
        },
        component: () =>
            import ('../views/marketing/goods/index.vue')
    },
    {
        path: '/marketing/goods/detail',
        name: 'MarketingGoodsDetail',
        meta: {
            title: '商品分类名称'
        },
        component: () =>
            import ('../views/marketing/goods/detail.vue')
    },
    {
        path: '/marketing/project/index',
        name: 'MarketingProjectIndex',
        meta: {
            title: '推荐项目'
        },
        component: () =>
            import ('../views/marketing/project/index.vue')
    },
    {
        path: '/marketing/project/detail',
        name: 'MarketingProjectDetail',
        meta: {
            title: '项目分类名称'
        },
        component: () =>
            import ('../views/marketing/project/detail.vue')
    },
    {
        path: '/marketing/official/index',
        name: 'MarketingOfficialIndex',
        meta: {
            title: '宣传文案'
        },
        component: () =>
            import ('../views/marketing/official/index.vue')
    },
    {
        path: '/marketing/official/detail',
        name: 'MarketingOfficialDetail',
        meta: {
            title: '新增文章'
        },
        component: () =>
            import ('../views/marketing/official/detail.vue')
    },
    {
        path: '/goods/index',
        name: 'GoodsIndex',
        meta: {
            title: '商品审核'
        },
        component: () =>
            import ('../views/goods/index/index.vue')
    },
    {
        path: '/goods/detail',
        name: 'GoodsDetail',
        meta: {
            title: '商品审核'
        },
        component: () =>
            import ('../views/goods/index/detail.vue')
    },
    {
        path: '/goods/price/index',
        name: 'GoodsPriceIndex',
        meta: {
            title: '价格设置'
        },
        component: () =>
            import ('../views/goods/price/index.vue')
    },
    {
        path: '/goods/price/detail',
        name: 'GoodsPriceDetail',
        meta: {
            title: '设置价格'
        },
        component: () =>
            import ('../views/goods/price/detail.vue')
    },
    {
        path: '/goods/sale/index',
        name: 'GoodsSaleIndex',
        meta: {
            title: '在售商品'
        },
        component: () =>
            import ('../views/goods/sale/index.vue')
    },
    {
        path: '/goods/sale/detail',
        name: 'GoodsSaleDetail',
        meta: {
            title: '商品详情'
        },
        component: () =>
            import ('../views/goods/sale/detail.vue')
    },
    {
        path: '/organization/index',
        name: 'OrganizationIndex',
        meta: {
            title: '合作机构'
        },
        component: () =>
            import ('../views/organization/index.vue')
    },
    {
        path: '/organization/other/index',
        name: 'OrganizationOtherIndex',
        meta: {
            title: '其他机构'
        },
        component: () =>
            import ('../views/organization/other/index.vue')
    },
    {
        path: '/organization/other/detail',
        name: 'OrganizationOtherDetail',
        meta: {
            title: '机构详情'
        },
        component: () =>
            import ('../views/organization/other/detail.vue')
    },
    {
        path: '/system/sort/index',
        name: 'SystemSortIndex',
        meta: {
            title: '分类管理'
        },
        component: () =>
            import ('../views/system/sort/index.vue')
    },
    {
        path: '/system/depart/index',
        name: 'SystemDepartIndex',
        meta: {
            title: '科室管理'
        },
        component: () =>
            import ('../views/system/depart/index.vue')
    },
    {
        path: '/system/disease/index',
        name: 'SystemDiseaseIndex',
        meta: {
            title: '病症管理'
        },
        component: () =>
            import ('../views/system/disease/index.vue')
    },
    {
        path: '/system/drug/index',
        name: 'SystemDrugIndex',
        meta: {
            title: '药品管理'
        },
        component: () =>
            import ('../views/system/drug/index.vue')
    },
    {
        path: '/system/content/index',
        name: 'SystemContentIndex',
        meta: {
            title: '内容管理'
        },
        component: () =>
            import ('../views/system/content/index.vue')
    },
    {
        path: '/system/message/index',
        name: 'SystemMessageIndex',
        meta: {
            title: '消息管理'
        },
        component: () =>
            import ('../views/system/message/index.vue')
    },
    {
        path: '/system/doctor',
        name: 'SystemDoctor',
        meta: {
            title: '医生管理'
        },
        component: () =>
            import ('../views/system/doctor/index.vue')
    },
    {
        path: '/system/report/index',
        name: 'SystemReportIndex',
        meta: {
            title: '报告设置'
        },
        component: () =>
            import ('../views/system/report/index.vue')
    },
    {
        path: '/system/server/index',
        name: 'SystemServerIndex',
        meta: {
            title: '增值服务设置'
        },
        component: () =>
            import ('../views/system/server/index.vue')
    },
    {
        path: '/system/server/person',
        name: 'SystemServerPerson',
        meta: {
            title: '人员管理'
        },
        component: () =>
            import ('../views/system/server/person.vue')
    },
    {
        path: '/system/set',
        name: 'SystemSet',
        meta: {
            title: '系统设置'
        },
        component: () =>
            import ('../views/system/set.vue')
    },
    {
        path: '/finance/cooperation',
        name: 'FinanceCooperation',
        meta: {
            title: '财务管理-合作机构'
        },
        component: () =>
            import ('../views/finance/cooperation.vue')
    },
    {
        path: '/finance/not-Cooperation',
        name: 'FinanceNotCooperation',
        meta: {
            title: '财务管理-非合作机构'
        },
        component: () =>
            import ('../views/finance/notCooperation.vue')
    },
    {
        path: '/finance/bill',
        name: 'Financebill',
        meta: {
            title: '财务管理-非合作机构-账单管理'
        },
        component: () =>
            import ('../views/finance/bill.vue')
    },
    {
        path: '/finance/platform',
        name: 'FinancePlatform',
        meta: {
            title: '财务管理-平台业务'
        },
        component: () =>
            import ('../views/finance/platform.vue')
    },
    {
        path: '/finance/added',
        name: 'FinanceAdded',
        meta: {
            title: '财务管理-增值服务'
        },
        component: () =>
            import ('../views/finance/added.vue')
    },
    {
        path: '/h5/login',
        name: 'HLogin',
        meta: {
            title: '登录'
        },
        component: () =>
            import ('../views/h5/login.vue')
    },
    {
        path: '/h5/changePwd',
        name: 'HChangePwd',
        meta: {
            title: '修改密码'
        },
        component: () =>
            import ('../views/h5/changePwd.vue')
    },
    {
        path: '/h5/detail',
        name: 'HDetail',
        meta: {
            title: '服务详情'
        },
        component: () =>
            import ('../views/h5/detail.vue')
    },
    {
        path: '/h5/report',
        name: 'HReport',
        meta: {
            title: '服务报告'
        },
        component: () =>
            import ('../views/h5/report.vue')
    },
]
const router = new createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes,
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})
export default router